import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-nf-details",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./nf-details.component.html",
})
export class NfDetailsComponent {
  @Input() nfDetails;
}
