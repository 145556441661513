import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-products-details",
  templateUrl: "./products-details.component.html",
  standalone: true,
  imports: [CommonModule, NgbPopoverModule],
})
export class ProductsDetailsComponent {
  @Input() productsDetails;
}
