import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "module",
})
export class ModulePipe implements PipeTransform {
  modules = {
    1: "Abastecimento",
    2: "Manutenção",
    3: "Rastreamento",
    6: "Gestão de NF",
    7: "Pedágio",
    9: "Cursos",
  };
  transform(value: number): string {
    return this.modules[value];
  }
}
