<div class="w-100 ps-3 py-2 fs-6 text-dark fw-semibold">
  Fechamentos do período
</div>
<div class="w-100 table-responsive">
  <table class="table table-bordered mb-0 table-striped">
    <thead>
      <tr>
        <th></th>
        <th class="text-center">Cód Fechamento</th>
        <th class="text-center text-limited">Razão Social</th>
        <th class="text-center text-limited">Nome Fantasia</th>
        <th class="text-center">CNPJ</th>
        <th class="text-center">Total de cobranças</th>
        <th class="text-center">Total geral</th>
        <th class="text-center">Pago</th>
        <th class="text-center" style="min-width: 140px">Ações</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let closure of closures; let i = index">
        <tr>
          <td class="text-center border-start-0">
            <ng-container *ngIf="closure.cobrancas.length">
              <button
                class="btn btn-success btn-sm"
                *ngIf="closure.chargesCollapsed"
                (click)="expandChargesByIndex(i)"
              >
                <i class="fa-solid fa-plus fa-fw"></i>
              </button>
              <button
                class="btn btn-danger btn-sm"
                *ngIf="!closure.chargesCollapsed"
                (click)="expandChargesByIndex(i)"
              >
                <i class="fa-solid fa-minus fa-fw"></i>
              </button>
            </ng-container>
          </td>
          <td class="text-center">{{ closure.idfech }}</td>
          <td class="text-start">{{ closure.razao }}</td>
          <td class="text-start">{{ closure.nomeemp }}</td>
          <td class="text-center">{{ closure.cpfcnpj | cgc }}</td>
          <td class="text-center">{{ closure.cobrancas.length }}</td>
          <td class="text-end">
            {{ closure.valor | real }}
            <i
              class="fa-solid fa-circle-info"
              placement="bottom"
              triggers="mouseenter:mouseleave"
              [ngbPopover]="fechTotals"
            ></i>
            <ng-template #fechTotals>
              <table class="table table-sm table-striped mb-0">
                <tbody>
                  <tr *ngIf="!isInvoiceManager">
                    <th class="text-end" nowrap>Total de consumo (+)</th>
                    <td class="text-end text-black" nowrap>
                      {{ closure.liquido | real }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-end" nowrap>Total de taxa (+)</th>
                    <td class="text-end text-black" nowrap>
                      {{ closure.taxa | real }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-end" nowrap>Total de desconto (-)</th>
                    <td class="text-black text-end" nowrap>
                      {{ closure.desconto | real }}
                    </td>
                  </tr>
                  <tr *ngIf="!isInvoiceManager && !isCourses">
                    <th class="text-end" nowrap>Total de impostos (-)</th>
                    <td class="text-end text-black" nowrap>
                      {{ closure.totalRetencoes | real }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="bg-dark-subtle text-end" nowrap>
                      Total geral (=)
                    </th>
                    <td class="bg-dark-subtle text-end text-black" nowrap>
                      {{ closure.valor | real }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </ng-template>
          </td>
          <td class="text-center">
            <button
              class="btn btn-success rounded-circle btn-shadow btn-icon"
              *ngIf="closure.pago == 1"
              title="Sim"
            >
              <i class="fa-solid fa-s fa-sm"></i>
            </button>
            <button
              class="btn btn-warning rounded-circle btn-shadow btn-icon"
              *ngIf="closure.pago == 2"
              title="Parcial"
            >
              <i class="fa-solid fa-p fa-sm"></i>
            </button>
            <button
              class="btn btn-danger rounded-circle btn-shadow btn-icon"
              *ngIf="closure.pago == 0"
              title="Não"
            >
              <i class="fa-solid fa-n fa-sm"></i>
            </button>
          </td>
          <td align="center">
            <button
              type="button"
              class="btn btn-info btn-sm me-1"
              title="Visualizar Fechamento"
              (click)="visualizarFechamento(closure.idfech, closure.tipoFech)"
            >
              <i class="fa-solid fa-eye fa-fw"></i>
            </button>
            <button
              type="button"
              class="btn btn-frota btn-sm me-1"
              title="Alterar Descritivos das NF fechamentos"
                *ngIf="closure.cobrancas && closure.cobrancas.length > 0"
              (click)="alterarDescritivoNf(i, closure.cobrancas[0].id, closure.tipoFech)"
            >
            <i class="fa-solid fa-calendar-lines-pen fa-fw"></i>
          </button>

            <button
              type="button"
              class="btn btn-warning btn-sm me-1"
              title="Atualizar link boleto e nota"
              *ngIf="
                closure.lenviadofinaceiro == 1 &&
                usuario.idperfil == 2 &&
                closure.pago == 0 &&
                canUpdate
              "
              (click)="atualizarBoletoNota(closure.idfech)"
            >
              <i class="fa-solid fa-rotate-left fa-fw"></i>
            </button>
          </td>
        </tr>
        <ng-container
          *ngIf="!closure.chargesCollapsed && closure.cobrancas.length"
        >
          <tr>
            <td [attr.colspan]="tipoUsuario == 2 ? '13' : '16'">
              <blockquote
                class="border-start border-5 border-warning-subtle mb-0 ms-0 ms-lg-5"
              >
                <app-cobrancas-fechamento
                  [charges]="closure.cobrancas"
                  [module]="module"
                  [lote]="closure.lote"
                ></app-cobrancas-fechamento>
              </blockquote>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
