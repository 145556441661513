<div class="backdrop"></div>
<div class="modall {{ modalSize }} p-4" [ngClass]="{ exit: isleaving }">
  <div class="d-flex flex-row align-items-center pb-3">
    <div class="w-100">
      <span class="m-0 modal-title h3 text-dark">{{ modalTitle }}</span>
      <ng-content select="[modal-header]"> </ng-content>
    </div>
    <button class="btn btn-link" type="button" (click)="onCloseModal()">
      <i class="fa-solid fa-close text-dark"></i>
    </button>
  </div>
  <div class="body">
    <ng-content select="[modal-body]"> </ng-content>
  </div>
  <div class="footer-modal flex-row pt-3">
    <ng-content select="[modal-footer]"> </ng-content>
    <button
      type="button"
      class="btn btn-light btn-shadow ms-2"
      (click)="onCloseModal()"
    >
      Fechar
    </button>
  </div>
</div>
