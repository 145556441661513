import { Component, Input, OnChanges } from "@angular/core";
import { DesempenhoService } from "../../../services/desempenho.service";
import { ClienteService } from "../../../../clientes/cliente.service";
import { Chart } from "angular-highcharts";

@Component({
  selector: "line-char-data-consumo-media-veiculo",
  templateUrl: "./line-char-data.component.html",
})
export class LineChartDataConsumoVeiculoComponent implements OnChanges {
  charta: any;
  graficoAtivo = false;

  @Input() filtros: {
    ano: string;
    placa: string;
    centroDeCusto: string;
  };

  constructor(
    private clienteService: ClienteService,
    private desempenhoService: DesempenhoService
  ) {}

  ngOnChanges(): void {
    this.defineFilterChanger();
  }

  defineFilterChanger(): void {
    this.graficoAtivo = false;

    this.desempenhoService
      .getInformativoClienteConsumoMedioVeiculo({
        idcliente: this.clienteService.getCliente().idcliente,
        ano: this.filtros.ano,
        placa: this.filtros.placa,
        centrocusto: this.filtros.centroDeCusto,
      })
      .subscribe((response) => {
        const data = [];

        for (
          let i = 0;
          i < response.desempenhoTotalConsumoVeiculoData.length;
          i++
        ) {
          data.push({
            name: response.desempenhoTotalConsumoVeiculoLabel[i],
            y: response.desempenhoTotalConsumoVeiculoData[i] * 1,
          });
        }

        this.charta = new Chart(<any>{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 400,
          },
          title: {
            text: "Total consumido por tipo de veiculo no mês atual",
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              colorByPoint: true,
              data: data,
              innerSize: "50%",
            },
          ],
          plotOptions: {
            pie: {
              colors: [
                "rgba(0,124,112,0.7)",
                "rgba(54,148,244,0.7)",
                "rgba(184,244,54,0.7)",
                "rgba(208,57,46,0.7)",
              ],
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          tooltip: {
            formatter: function () {
              return (
                this.key +
                ": R$ " +
                String(this.y.toLocaleString("pt-BR")) +
                " | Representa: " +
                this.percentage.toFixed(2) +
                "% do consumo"
              );
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    enabled: false,
                  },
                },
              },
            ],
          },
          exporting: {
            enabled: false,
          },
        });

        this.graficoAtivo = true;

        setTimeout(() => {
          this.charta.ref.reflow();
        }, 3);
      });
  }

  Popup() {
    this.charta.ref$.subscribe((ref) => {
      const svg = ref.getSVG();

      const mywindow = window.open("", "my div", "height=400,width=700");
      mywindow.document.write(
        "<html><head><title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</title>"
      );
      mywindow.document.write("</head><body>");
      mywindow.document.write("" + svg + "");
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      setTimeout(() => {
        mywindow.print();
        mywindow.close();
      }, 10);
    });
  }
}
