/* src/app/modulos/manutencao/regras/regras.component.css */
.checkbox-inline,
.radio-inline {
  vertical-align: top;
  margin-top: 20px;
  padding-left: 25px;
  font-weight: bold;
}
.toggle-switch-demo .ts-label {
  min-width: 180px;
}
.toggle-componente {
  padding-top: 20px;
}
[hidden] {
  display: none !important;
}
.Janela1 {
  display: inline-block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #B4CEFB;
  margin-top: 10px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -khtml-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -khtml-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  -khtml-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  -khtml-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -khtml-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -khtml-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  -khtml-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  -khtml-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}
img {
  vertical-align: middle;
}
img {
  border: 0;
}
.TelaViewContainer1 {
  margin-bottom: 3px;
}
.TituloSubitem543 {
  font-size: 11px;
  color: #444;
}
.TelaView1 {
  display: inline-block;
  margin-right: 20px;
}
.TituloSubitem543 {
  font-size: 11px;
  color: #444;
}
.SubtituloDestaque2233 {
  font-size: 11px;
  color: #0066CC;
}
.colorOrange {
  color: orange;
}
.colorGreen {
  color: green;
}
.colorRed {
  color: red;
}
.FilterItem {
  padding: 5px 0;
}
.FilterItemLabel {
  text-align: right;
  vertical-align: middle;
  min-height: 40px;
  display: table-cell;
  width: 100px;
  padding-right: 10px;
}
.FilterField2 {
  display: table-cell;
  min-height: 40px;
  padding-right: 10px;
}
.pointer {
  cursor: pointer;
}
.molduraTabela {
  border-radius: 4px;
  background: #cccccc;
  margin-top: 3px;
}
.cabecalhoTabela {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(top, rgba(224, 224, 224, 1) 1%, rgba(204, 204, 204, 1) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgba(224, 224, 224, 1)), color-stop(100%, rgba(204, 204, 204, 1)));
  background: -webkit-linear-gradient(top, rgba(224, 224, 224, 1) 1%, rgba(204, 204, 204, 1) 100%);
  background: -o-linear-gradient(top, rgba(224, 224, 224, 1) 1%, rgba(204, 204, 204, 1) 100%);
  background: -ms-linear-gradient(top, rgba(224, 224, 224, 1) 1%, rgba(204, 204, 204, 1) 100%);
  background:
    linear-gradient(
      to bottom,
      rgba(224, 224, 224, 1) 1%,
      rgba(204, 204, 204, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e0e0e0", endColorstr="#cccccc", GradientType=0);
}
.conteudoTabela {
  background: #fff;
  cursor: pointer;
}
.FilterLabel {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.FilterLabel input[type=checkbox] {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
}
.FilterLabel div {
  display: inline-block;
  vertical-align: middle;
}
.orange {
  color: orange;
}
.smallTitle {
  font-size: 10px;
}
.titleVis {
  margin-bottom: 0;
}
.dadoVis div {
  margin-top: 9px;
}
.colorResultado {
  color: #2196F3;
}
.colorNegativo {
  color: red;
}
.panel-body {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
}
/*# sourceMappingURL=regras.component-LXTSU5MU.css.map */
