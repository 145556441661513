<ng-container *ngIf="!CadastraNovoEndereco">
  <div class="row pb-2 align-items-end">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Estado/UF:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Estado/UF:</label>
      <app-search-select
        value="id"
        [disabled]="desabilitado"
        [arrToFilter]="[
          {
            id: 'AC',
            label: 'AC',
          },
          {
            id: 'AL',
            label: 'AL',
          },
          {
            id: 'AM',
            label: 'AM',
          },
          {
            id: 'AP',
            label: 'AP',
          },
          {
            id: 'BA',
            label: 'BA',
          },
          {
            id: 'CE',
            label: 'CE',
          },
          {
            id: 'DF',
            label: 'DF',
          },
          {
            id: 'ES',
            label: 'ES',
          },
          {
            id: 'GO',
            label: 'GO',
          },
          {
            id: 'MA',
            label: 'MA',
          },
          {
            id: 'MG',
            label: 'MG',
          },
          {
            id: 'MS',
            label: 'MS',
          },
          {
            id: 'MT',
            label: 'MT',
          },
          {
            id: 'PA',
            label: 'PA',
          },
          {
            id: 'PB',
            label: 'PB',
          },
          {
            id: 'PE',
            label: 'PE',
          },
          {
            id: 'PI',
            label: 'PI',
          },
          {
            id: 'PR',
            label: 'PR',
          },
          {
            id: 'RJ',
            label: 'RJ',
          },
          {
            id: 'RN',
            label: 'RN',
          },
          {
            id: 'RO',
            label: 'RO',
          },
          {
            id: 'RR',
            label: 'RR',
          },
          {
            id: 'RS',
            label: 'RS',
          },
          {
            id: 'SC',
            label: 'SC',
          },
          {
            id: 'SE',
            label: 'SE',
          },
          {
            id: 'SP',
            label: 'SP',
          },
          {
            id: 'TO',
            label: 'TO',
          },
        ]"
        [(ngValue)]="inputdados.cuf"
        (ngValueChange)="carregarLocalidade($event)"
      ></app-search-select>
    </div>
    <div class="col">
      <label class="form-label" *ngIf="obrigatorio" requiredInput>CEP:</label>
      <label class="form-label" *ngIf="!obrigatorio">CEP:</label>
      <input
        class="form-control"
        mask="00.000-000"
        [(ngModel)]="inputdados.cep"
        [disabled]="desabilitado"
      />
    </div>
    <div class="col-auto" *ngIf="!desabilitado">
      <button
        class="btn btn-success"
        (click)="buscaCepCorreios(inputdados.cep, true)"
        title="Cadastrar Novo Endereço"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="row pb-2" *ngIf="!conteudoCarregadoLocalidade">
    <div class="col-6 text-center">
      <mat-spinner diameter="40" class="m-auto"></mat-spinner>
    </div>
  </div>
  <div class="row pb-2" [hidden]="!conteudoCarregadoLocalidade">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Localidade:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Localidade:</label>
      <app-search-select
        value="idmun"
        [arrToFilter]="localidades"
        [(ngValue)]="inputdados.idmun"
        [disabled]="desabilitado"
        (ngValueChange)="carregarBairro($event)"
      ></app-search-select>
    </div>
  </div>
  <div class="row pb-2" *ngIf="!conteudoCarregadoBairro">
    <div class="col-6 text-center">
      <mat-spinner diameter="40" class="m-auto"></mat-spinner>
    </div>
  </div>
  <div class="row pb-2" [hidden]="!conteudoCarregadoBairro">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Bairro:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Bairro:</label>
      <app-search-select
        value="idbai"
        [arrToFilter]="bairros"
        [(ngValue)]="inputdados.idbai"
        [disabled]="desabilitado"
        (ngValueChange)="carregarTipoLog($event)"
      ></app-search-select>
    </div>
  </div>
  <div class="row pb-2" *ngIf="!conteudoCarregadoTipoLog">
    <div class="col-6 text-center">
      <mat-spinner diameter="40" class="m-auto"></mat-spinner>
    </div>
  </div>
  <div class="row pb-2" [hidden]="!conteudoCarregadoTipoLog">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Tipo de Logradouro:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Tipo de Logradouro:</label>
      <app-search-select
        value="ctipolog"
        [arrToFilter]="ctipolog"
        [(ngValue)]="inputdados.ctipolog"
        [disabled]="desabilitado"
        (ngValueChange)="carregarLog(inputdados.idbai, $event)"
      ></app-search-select>
    </div>
  </div>
  <div class="row pb-2" *ngIf="!conteudoCarregadoLog">
    <div class="col-6 text-center">
      <mat-spinner diameter="40" class="m-auto"></mat-spinner>
    </div>
  </div>
  <div class="row pb-2" [hidden]="!conteudoCarregadoLog">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Logradouro:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Logradouro:</label>
      <app-search-select
        value="idlogr"
        [arrToFilter]="log"
        [(ngValue)]="inputdados.idlogr"
        [disabled]="desabilitado"
        (ngValueChange)="selecionarCep($event)"
      ></app-search-select>
    </div>
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Número:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Número:</label>
      <input
        class="form-control"
        [(ngModel)]="inputdados.cnumero"
        [disabled]="desabilitado"
      />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label">Complemento:</label>
      <input
        class="form-control"
        [(ngModel)]="inputdados.cend"
        [disabled]="desabilitado"
      />
    </div>
  </div>
  <div class="row" *ngIf="latlog == true">
    <div class="col-3">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Latitude:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Latitude:</label>
      <input
        class="form-control"
        [(ngModel)]="inputdados.clatitude"
        [disabled]="desabilitado"
      />
    </div>
    <div class="col-3">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Longitude:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Longitude:</label>
      <input
        class="form-control"
        [(ngModel)]="inputdados.clongitude"
        [disabled]="desabilitado"
      />
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="
    conteudoCarregadoLocalidade &&
    conteudoCarregadoBairro &&
    conteudoCarregadoTipoLog &&
    conteudoCarregadoLog &&
    conteudoCarregadoCep &&
    CadastraNovoEndereco
  "
>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Estado/UF:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Estado/UF:</label>
      <app-search-select
        value="id"
        [arrToFilter]="[
          {
            id: 'AC',
            label: 'AC',
          },
          {
            id: 'AL',
            label: 'AL',
          },
          {
            id: 'AM',
            label: 'AM',
          },
          {
            id: 'AP',
            label: 'AP',
          },
          {
            id: 'BA',
            label: 'BA',
          },
          {
            id: 'CE',
            label: 'CE',
          },
          {
            id: 'DF',
            label: 'DF',
          },
          {
            id: 'ES',
            label: 'ES',
          },
          {
            id: 'GO',
            label: 'GO',
          },
          {
            id: 'MA',
            label: 'MA',
          },
          {
            id: 'MG',
            label: 'MG',
          },
          {
            id: 'MS',
            label: 'MS',
          },
          {
            id: 'MT',
            label: 'MT',
          },
          {
            id: 'PA',
            label: 'PA',
          },
          {
            id: 'PB',
            label: 'PB',
          },
          {
            id: 'PE',
            label: 'PE',
          },
          {
            id: 'PI',
            label: 'PI',
          },
          {
            id: 'PR',
            label: 'PR',
          },
          {
            id: 'RJ',
            label: 'RJ',
          },
          {
            id: 'RN',
            label: 'RN',
          },
          {
            id: 'RO',
            label: 'RO',
          },
          {
            id: 'RR',
            label: 'RR',
          },
          {
            id: 'RS',
            label: 'RS',
          },
          {
            id: 'SC',
            label: 'SC',
          },
          {
            id: 'SE',
            label: 'SE',
          },
          {
            id: 'SP',
            label: 'SP',
          },
          {
            id: 'TO',
            label: 'TO',
          },
        ]"
        [(ngValue)]="inputdados.cuf"
        (ngValueChange)="carregarLocalidade($event)"
      ></app-search-select>
    </div>
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput>CEP:</label>
      <label class="form-label" *ngIf="!obrigatorio">CEP:</label>
      <input
        class="form-control"
        mask="00.000-000"
        [(ngModel)]="inputdados.cep"
        maxlength="10"
      />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Localidade:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Localidade:</label>
      <input class="form-control" [(ngModel)]="inputdados.municipio" />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Bairro:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Bairro:</label>
      <input class="form-control" [(ngModel)]="inputdados.cbairro" />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Tipo Logradouro:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Tipo Logradouro:</label>
      <input class="form-control" [(ngModel)]="inputdados.ctipolog" />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Logradouro:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Logradouro:</label>
      <input class="form-control" [(ngModel)]="inputdados.logradouro" />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label">Complemento:</label>
      <input class="form-control" [(ngModel)]="inputdados.complemento" />
    </div>
  </div>
  <div class="row pb-2" *ngIf="latlog">
    <div class="col-3">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Latitude:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Latitude:</label>
      <input class="form-control" [(ngModel)]="inputdados.clatitude" />
    </div>
    <div class="col-3">
      <label class="form-label" *ngIf="obrigatorio" requiredInput
        >Longitude:</label
      >
      <label class="form-label" *ngIf="!obrigatorio">Longitude:</label>
      <input class="form-control" [(ngModel)]="inputdados.clongitude" />
    </div>
  </div>
  <div class="row">
    <div class="col text-end">
      <button class="btn btn-success" (click)="salvarNovoEndereco()">
        <i class="fa-solid fa-check"></i> Salvar Novo Endereço
      </button>
      <button class="btn btn-danger ms-1" (click)="cancelarNovoEndereco()">
        <i class="fa-solid fa-close"></i> Cancelar
      </button>
    </div>
  </div>
</ng-container>
