<div class="w-100 ps-3 py-2 fs-6 text-dark fw-semibold">
  Cobranças do fechamento {{ charges[0][fechVar] }}
</div>
<div
  #tableResponsive
  class="table-responsive"
  [ngStyle]="{ minHeight: hasMinHeight ? '300px' : 'unset' }"
>
  <table class="table table-bordered table-striped mb-0">
    <thead>
      <tr>
        <th></th>
        <th class="text-center">Cód. da cobrança</th>
        <th class="text-center text-limited">Total de itens</th>
        <th class="text-center">Cód. centro de custo</th>
        <th class="text-center">Nome do centro de custo</th>
        <th class="text-center">Data do vencimento</th>
        <th class="text-center">Valor da cobrança</th>
        <th class="text-center text-limited">Data do pagamento</th>
        <th class="text-center">Pago</th>
        <th class="text-center" style="min-width: 140px">Ações</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let charge of charges; let i = index">
        <tr>
          <td class="text-end">
            <ng-container *ngIf="charge.itens_cobranca.length">
              <button
                class="btn btn-success btn-sm"
                *ngIf="charge.productsDetailsCollapsed"
                (click)="expandProductDetailsByIndex(i)"
              >
                <i class="fa-solid fa-plus fa-fw"></i>
              </button>
              <button
                class="btn btn-danger btn-sm"
                *ngIf="!charge.productsDetailsCollapsed"
                (click)="expandProductDetailsByIndex(i)"
              >
                <i class="fa-solid fa-minus fa-fw"></i>
              </button>
            </ng-container>
          </td>
          <td class="text-center">{{ charge.id }}</td>
          <td class="text-center">{{ charge.itens_cobranca.length }}</td>
          <td class="text-center">{{ charge.cod_centrocusto }}</td>
          <td class="text-center">{{ charge.nome_centrocusto }}</td>
          <td class="text-center">
            {{ charge.data_vencimento | date: "dd/MM/yyyy" }}
          </td>
          <td class="text-end">
            {{ charge.valor_cobranca | real }}
            <i
              class="fa-solid fa-circle-info"
              placement="bottom"
              triggers="mouseenter:mouseleave"
              [ngbPopover]="fechTotals"
            ></i>
            <ng-template #fechTotals>
              <table class="table table-sm table-striped mb-0">
                <tbody>
                  <tr *ngIf="!isInvoiceManager">
                    <th class="text-end" nowrap>Total de consumo (+)</th>
                    <td class="text-end text-black" nowrap>
                      {{ charge.consumo | real }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-end" nowrap>Total de taxa (+)</th>
                    <td class="text-end text-black" nowrap>
                      {{ charge.total_taxa | real }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-end" nowrap>Total de desconto (-)</th>
                    <td class="text-black text-end" nowrap>
                      {{ charge.total_desconto | real }}
                    </td>
                  </tr>
                  <tr *ngIf="!isInvoiceManager && !isCourses">
                    <th class="text-end" nowrap>Total de impostos (-)</th>
                    <td class="text-end text-black" nowrap>
                      {{ charge.total_impostos | real }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="bg-dark-subtle text-end" nowrap>
                      Total geral (=)
                    </th>
                    <td class="bg-dark-subtle text-end text-black" nowrap>
                      {{ charge.valor_cobranca | real }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </ng-template>
          </td>
          <td class="text-center">
            {{ charge.data_pagamento | date: "dd/MM/yyyy" }}
          </td>
          <td class="text-center">
            <button
              class="btn btn-success rounded-circle btn-shadow btn-icon"
              *ngIf="charge.pago == 1"
              title="Sim"
            >
              <i class="fa-solid fa-s fa-sm"></i>
            </button>
            <button
              class="btn btn-danger rounded-circle btn-shadow btn-icon"
              *ngIf="charge.pago == 0"
              title="Não"
            >
              <i class="fa-solid fa-n fa-sm"></i>
            </button>
          </td>
          <td align="center">
            <app-acoes-cobrancas
              [id]="charge.id"
              [idfech]="charge[fechVar]"
              [lote]="lote"
              [modulo]="module"
              [codCobranca]="charge.codCobranca"
              [link_boleto]="charge.link_boleto"
              [link_nota]="charge.link_nota"
              [pago]="charge.pago"
              [descNf]="charge.descNf"
              (clickedDropdown)="checkScroll()"
            >
            </app-acoes-cobrancas>
          </td>
        </tr>
        <ng-container
          *ngIf="
            !charge.productsDetailsCollapsed && charge.itens_cobranca.length
          "
        >
          <tr>
            <td [attr.colspan]="tipoUsuario == 2 ? '13' : '16'">
              <blockquote
                class="border-start border-5 border-secondary mb-0 ms-0 ms-lg-5"
              >
                <app-products-details
                  *ngIf="isFueling"
                  [productsDetails]="charge.itens_cobranca"
                ></app-products-details>
                <app-parts-details
                  *ngIf="isMaintenance"
                  [partsDetails]="charge.itens_cobranca"
                ></app-parts-details>
                <app-toll-details
                  *ngIf="isToll"
                  [tollsDetails]="charge.itens_cobranca"
                ></app-toll-details>
                <app-nf-details
                  *ngIf="isInvoiceManager"
                  [nfDetails]="charge.itens_cobranca"
                ></app-nf-details>
                <app-courses-details
                  *ngIf="isCourses"
                  [coursesDetails]="charge.itens_cobranca"
                ></app-courses-details>
              </blockquote>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
