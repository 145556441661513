<div class="row" *ngIf="!modal">
  <div class="col text-end">
    <button
      title="Pesquisar Veiculos"
      [routerLink]="['/veiculos']"
      class="btn btn-info btn-shadow btn-sm"
    >
      <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
    </button>
    <button
      title="Cadastrar Veiculos"
      class="btn btn-success btn-shadow ms-1 btn-sm"
      [routerLink]="['/veiculos/cadastrar']"
      *ngIf="alterarDados && gesLimVeic"
    >
      <i class="fa-solid fa-plus fa-fw"></i>
    </button>
    <button
      *ngIf="alterarDados"
      title="Alterar Veiculos"
      class="btn btn-primary btn-shadow btn-sm ms-1"
      [routerLink]="['/veiculos/alterar', idveiculo]"
    >
      <i class="fa-solid fa-pen-to-square fa-fw"></i>
    </button>
  </div>
</div>

<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>f
</div>
<div class="row" [hidden]="!conteudoCarregado">
  <div class="col-3">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Identificação">
        <div class="container-fluid pt-3">
          <div class="card">
            <div class="card-header bg-secondary h5 text-white">
              Informações Principais
            </div>
            <div class="card-body card-padding">
              <div class="row">
                <div class="col-auto">
                  <img
                    src="assets/img/{{ arrVeiculos.imagensVeiculo }}"
                    style="width: 70px"
                  />
                </div>
                <div class="col d-flex flex-column">
                  <small class="fw-normal">Placa/Município/UF:</small>
                  <span class="fw-bold">{{ arrVeiculos.placa }}</span>
                  <span class="fw-bold"
                    >{{ arrVeiculos.cnomemun }}/{{ arrVeiculos.cuf }}</span
                  >
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  Situação:
                  <span class="fw-bold">{{ arrVeiculos.situacaoVeiculo }}</span>
                </div>
                <div class="col">
                  Família:
                  <span class="fw-bold">{{ arrVeiculos.FamiliaNome }}</span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  Marca:
                  <span class="fw-bold">{{ arrVeiculos.MarcaNome }}</span>
                </div>
                <div class="col">
                  Modelo:
                  <span class="fw-bold">{{ arrVeiculos.ModeloNome }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  Ano de Fabricação:
                  <span class="fw-bold">{{ arrVeiculos.anofabr }}</span>
                </div>
                <div class="col">
                  Ano do Modelo:
                  <span class="fw-bold">{{ arrVeiculos.ano }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header bg-secondary h5 text-white">
              Outras Informações
            </div>
            <div class="card-body card-padding">
              <div class="row mb-1">
                <div class="col">
                  Cor:
                  <span class="fw-bold">{{ arrVeiculos.VeiculoCor }}</span>
                </div>
                <div class="col">
                  Renavam:
                  <span class="fw-bold">{{ arrVeiculos.renavam }}</span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  Chassi:<span class="fw-bold">{{ arrVeiculos.chassi }}</span>
                </div>
                <div class="col">
                  Vencimento IPVA:
                  <span class="fw-bold">{{
                    arrVeiculos.mesvenctoipva
                      ? arrVeiculos.mesvenctoipva
                      : arrVeiculos.dvenctoipva
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  Tipo de Frota:
                  <span class="fw-bold">{{ arrVeiculos.FrotaTipoNome }}</span>
                </div>
                <div class="col">
                  Código do Veículo:
                  <span class="fw-bold">{{ arrVeiculos.codveiculo }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header bg-secondary h5 text-white">Empresa</div>
            <div class="card-body card-padding">
              <div class="row mb-1">
                <div class="col">
                  Centro de Custo:
                  <span class="fw-bold">{{ arrVeiculos.CentroCustoNome }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  Departamento:
                  <span class="fw-bold">{{ arrVeiculos.SetorNome }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="col-9">
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      dynamicHeight
    >
      <mat-tab label="Informativo Veículo">
        <div class="container-fluid pt-3">
          <informativo-veiculo
            [sistema]="sistema"
            [GNVMark]="GNVMark"
            [idveiculo]="idveiculo"
            [arrVeiculos]="arrVeiculos"
            [arrCartaoVeiculos]="arrCartbg - secondaryos"
            [veiculoEvolucaoConsumo]="veiculoEvolucaoConsumo"
            [arrCartaoVeiculosManutencao]="arrCartaoVeiculosManutencao"
            *ngIf="conteudoCarregado"
          ></informativo-veiculo>
        </div>
      </mat-tab>
      <mat-tab label="Configurações de Veículo">
        <div class="container-fluid pt-3">
          <configuracoes-veiculo
            [sistema]="sistema"
            [arrVeiculos]="arrVeiculos"
            [arrCartaoVeiculos]="arrCartaoVeiculos"
            [arrHistoricoVeiculos]="arrHistoricoVeiculos"
            [arrCartaoVeiculosManutencao]="arrCartaoVeiculosManutencao"
            *ngIf="conteudoCarregado"
          ></configuracoes-veiculo>
        </div>
      </mat-tab>
      <mat-tab label="Tanques e Combustíveis">
        <div class="container-fluid pt-3">
          <tanques-combustiveis
            [idveiculo]="idveiculo"
            [arrVeiculos]="arrVeiculos"
            *ngIf="conteudoCarregado"
          ></tanques-combustiveis>
        </div>
      </mat-tab>
      <mat-tab label="Histórico de Transações">
        <div class="container-fluid pt-3">
          <historico-transacoes
            [idveiculo]="idveiculo"
            *ngIf="conteudoCarregado"
          ></historico-transacoes>
        </div>
      </mat-tab>
      <mat-tab
        label="Histórico de Manutenção"
        *ngIf="arrVeiculoManutencao.statusModulo"
      >
        <div class="container-fluid pt-3">
          <historico-manutencao
            [arrVeiculoManutencao]="arrVeiculoManutencao"
            *ngIf="conteudoCarregado"
          ></historico-manutencao>
        </div>
      </mat-tab>
      <mat-tab label="Log Ajuste de Odometro">
        <div class="container-fluid pt-3">
          <log-ajuste-odometro
            [arrVeiculos]="arrVeiculos"
            *ngIf="conteudoCarregado"
          ></log-ajuste-odometro>
        </div>
      </mat-tab>
      <mat-tab label="Log Alteração de Veículo">
        <div class="container-fluid pt-3">
          <log-alteracoes *ngIf="conteudoCarregado"></log-alteracoes>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="card mt-3">
      <div class="card-header bg-secondary h5 text-white">
        PARAMETROS DO VEICULO / TROCA DE ÓLEO
      </div>
      <div class="card-body card-padding">
        <div class="w-100 table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Odometro</th>
                <th>Data</th>
                <th class="text-end">Qtde</th>
                <th class="text-end">Total</th>
                <th>Motivo</th>
                <th>Local</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of arrTrocaOleo">
                <td>{{ row.odometroOleo }}</td>
                <td>{{ row.dataOleo }}</td>
                <td class="text-end">{{ row.qtde }}</td>
                <td class="text-end">{{ row.valor }}</td>
                <td>{{ row.motivo }}</td>
                <td>{{ row.local }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-1" *ngIf="!modal">
  <div class="col text-end">
    <button class="btn btn-danger btn-shadow" (click)="handleBack()">
      <i class="fa-solid fa-arrow-left"></i>
      Voltar
    </button>
  </div>
</div>
