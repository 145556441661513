<div class="w-100 ps-3 py-2 fs-6 text-dark fw-semibold">
  Detalhamento da cobrança {{ productsDetails[0].idcobranca }}
</div>
<div class="w-100 table-responsive">
  <table class="table table-bordered table-striped mb-0 border-secondary">
    <thead>
      <tr>
        <th class="text-center border-top-0 border-start-0">Produto</th>
        <th class="text-center border-top-0">Quantidade</th>
        <th class="text-center border-top-0">Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detalhe of productsDetails">
        <td class="border-start-0">
          {{ detalhe.descricao }}
        </td>
        <td class="text-end">
          {{ detalhe.nqtditens | number : "1.2-2" }}
        </td>
        <td class="text-end">
          R$ {{ detalhe.totalgeral | number : "1.2-2" }}
          <i
            class="fa-solid fa-circle-info"
            placement="left"
            triggers="mouseenter:mouseleave"
            [ngbPopover]="groupTotals"
          ></i>
          <ng-template #groupTotals>
            <table class="table table-sm table-striped mb-0">
              <tbody>
                <tr>
                  <th class="text-end" nowrap>Total de consumo (+)</th>
                  <td class="text-end text-black" nowrap>
                    R$ {{ detalhe.nvalor | number : "1.2-2" }}
                  </td>
                </tr>
                <tr>
                  <th class="text-end" nowrap>Total de desconto (-)</th>
                  <td class="text-black text-end" nowrap>
                    R$ {{ detalhe.totaldescontos | number : "1.2-2" }}
                  </td>
                </tr>
                <tr>
                  <th class="text-end" nowrap>Total de impostos (-)</th>
                  <td class="text-end text-black" nowrap>
                    R$ {{ detalhe.totalimpostos | number : "1.2-2" }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="bg-dark-subtle text-end" nowrap>
                    Total geral (=)
                  </th>
                  <td class="bg-dark-subtle text-end text-black" nowrap>
                    R$ {{ detalhe.totalgeral | number : "1.2-2" }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
