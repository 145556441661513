<div class="row pb-2">
  <div class="col text-end">
    <button
      (click)="pesquisar()"
      title="Pesquisar Perfil Regras"
      class="btn btn-info btn-shadow ms-1 btn-sm"
    >
      <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
    </button>
    <button
      (click)="cadastrar()"
      title="Cadastrar Perfil Regras"
      class="btn btn-success btn-shadow ms-1 btn-sm"
      *ngIf="!telaCadastrar && alterarDados"
    >
      <i class="fa-solid fa-plus fa-fw"></i>
    </button>
  </div>
</div>

<pesquisar-regras-perfil
  [evento]="evento"
  [hidden]="!telaPesquisa"
  [idcliente]="idcliente"
  (editar)="editar($event)"
  (editarRegras)="editarRegras($event)"
  (carregado)="carregadoPesquisa($event)"
></pesquisar-regras-perfil>

<alterar-regras-perfil
  [id]="id"
  *ngIf="telaAlterar"
  (cancelar)="cancelarTela($event)"
></alterar-regras-perfil>

<cadastrar-regras-perfil
  *ngIf="telaCadastrar"
  (cancelar)="cancelarTela($event)"
></cadastrar-regras-perfil>

<regras
  [regranivel]="7"
  [idcliente]="idcliente"
  *ngIf="telaAlterarRegras"
  [centrocusto]="centrocusto"
  [idrelacionado]="idrelacionado"
></regras>
