<app-base-modal [modalTitle]="'Busca de Clientes'" (closeModal)="closeModal()">
  <ng-container modal-body>
    <div class="row mb-3">
      <div class="col">
        <label requiredInput class="form-label"
          >Buscar Clientes: <br />
          <small>Informe Nome, ou Código Telenet</small>
        </label>
        <input class="form-control" #dataCliente [formControl]="inputControl" />
      </div>
    </div>

    <div class="row" *ngIf="carregarConteudo">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div class="row">
      <div class="col" [hidden]="carregarConteudo">
        <div class="w-100 table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center">Codigo Telenet</th>
                <th class="text-center">Cliente</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let loc of arrClientes">
                <td class="text-center">
                  {{ loc.codtn }}
                </td>
                <td class="text-center">
                  {{ loc.fantasia }}
                </td>
                <td>
                  <button
                    type="button"
                    (click)="selecionarCliente(loc.codtn)"
                    class="btn btn-success rounded-circle btn-shadow btn-icon"
                    title="Selecionar Cliente"
                  >
                    <i class="fa-solid fa-check fa-sm"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</app-base-modal>
