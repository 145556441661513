<app-base-modal [modalTitle]="'Busca de placa'" (closeModal)="onCloseModal()">
  <ng-container modal-body>
    <div class="row col">
      <div class="row">
        <div class="col pb-2 text-end">
          <button
            type="button"
            class="btn btn-success text-uppercase btn-shadow btn-sm"
            (click)="filters.visible = !filters.visible; filters.toggle()"
          >
            <i class="fa-solid fa-filter"></i>
            &nbsp; Filtros
          </button>
          <button class="btn btn-info btn-shadow ms-1 btn-sm" (click)="busca()">
            <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
            Pesquisar
          </button>
        </div>
      </div>

      <div class="row pb-2" #filters="cCollapse" cCollapse (keydown.enter)="busca()">
        <div class="col">
          <label class="form-label">Código do Veiculo:</label>
          <input [(ngModel)]="filtros.codigo" class="form-control" />
        </div>
        <div class="col">
          <label class="form-label">Modelo:</label>
          <input [(ngModel)]="filtros.modelo" class="form-control" />
        </div>
        <div class="col">
          <label class="form-label">Marca:</label>
          <input [(ngModel)]="filtros.marca" class="form-control" />
        </div>
        <div class="col">
          <label class="form-label">Tipo de Veiculo:</label>
          <input [(ngModel)]="filtros.tipoVeiculo" class="form-control" />
        </div>
      </div>

      <div class="row" *ngIf="carregarConteudo">
        <div class="col text-center">
          <mat-spinner diameter="40" class="m-auto"></mat-spinner>
        </div>
      </div>

      <div class="col" [hidden]="carregarConteudo">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Codigo</th>
              <th>Placa</th>
              <th>Tipo</th>
              <th>Marca</th>
              <th>Modelo</th>
              <th class="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let loc of arrVeiculos">
              <td>
                {{ loc.codigo }}
              </td>
              <td>
                {{ loc.placa }}
              </td>
              <td class="text-success">
                {{ loc.familia }}
              </td>
              <td class="text-success">
                {{ loc.marca }}
              </td>
              <td class="text-success">
                {{ loc.modelo }}
              </td>
              <td>
                <button
                  type="button"
                  (click)="selecionar(loc.placa)"
                  class="btn btn-success btn-sm"
                  title="Selecionar"
                >
                  <i class="fa-solid fa-check fa-fw"></i> Selecionar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</app-base-modal>
