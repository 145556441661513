<div class="row" *ngIf="!carregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="carregado">
  <div class="col-4">
    <div class="col-12" style="margin-bottom: 10px">
      <img
        src="assets/img/icon_system/budget_icon.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>Orçamentos Pendentes Oficinas</b>
      </div>
    </div>

    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="let tr of informativo.OrcAguardandoResposta"
      >
        <span class="TituloSubitem543"
          ><b>{{ tr.dcriacao | formatDate : "DD/MM/YYYY STR" }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ tr.cnome }} - Veículo {{ tr.placa }}</b>
        </span>
      </div>
    </div>

    <div class="col-12" style="margin-top: 30px; margin-bottom: 10px">
      <img
        src="assets/img/icon_system/budget_icon.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>Orçamentos Recebidos Oficinas</b>
      </div>
    </div>

    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="let tr of informativo.OrcRespondido"
      >
        <span class="TituloSubitem543"
          ><b>{{ tr.dcriacao | formatDate : "DD/MM/YYYY STR" }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ tr.cnome }} - Veículo {{ tr.placa }}</b>
        </span>
      </div>
    </div>

    <div class="col-12" style="margin-top: 30px; margin-bottom: 10px">
      <img
        src="assets/img/icon_system/budget_icon.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>Orçamentos Aprovados</b>
      </div>
    </div>

    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="
          let tr of informativo.OrcAprovado | slice : 0 : 5;
          let i = index
        "
      >
        <span class="TituloSubitem543"
          ><b>{{ tr.dcriacao | formatDate : "DD/MM/YYYY STR" }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ tr.cnome }} - Veículo {{ tr.placa }}</b>
        </span>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="col-12" style="margin-bottom: 10px">
      <img
        src="assets/img/icon_system/osopen.jpg"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>OS Previstas</b>
        <button
          class="btn btn-danger btn-xs"
          *ngIf="informativo.listaOSPrevistas.length != 0"
          (click)="abrirModalOSPrevistas()"
        >
          <i class="fa-solid fa-invert-colors"></i>
          {{ informativo.listaOSPrevistas.length }}
        </button>
      </div>
    </div>
    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngIf="informativo.listaOSPrevistas.length != 0"
      >
        <span class="TituloSubitem543 text-primary"
          ><b>Você tem Manutenções Previstas</b></span
        >
      </div>
      <div
        style="margin: 5px 0 15px 35px; color: red"
        *ngIf="informativo.listaOSPrevistas.length == 0"
      >
        Nenhuma Manutenção Prevista
      </div>
    </div>
    <!-- -->
    <div class="col-12" style="margin-bottom: 10px">
      <img
        src="assets/img/icon_system/osopen.jpg"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>OS Abertas</b>
      </div>
    </div>
    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="let os of informativo.listaOSAbertas"
      >
        <span class="TituloSubitem543"
          ><b>{{ os.dcriacao | formatDate : "DD/MM/YYYY STR" }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ os.cnome }} - Veículo {{ os.placa | placa }}</b>
        </span>
      </div>
      <div
        style="margin: 5px 0 15px 35px; color: red"
        *ngIf="informativo.listaOSAbertas.length == 0"
      >
        Nenhuma OS Aberta
      </div>
    </div>

    <div class="col-12" style="margin-top: 30px; margin-bottom: 10px">
      <img
        src="assets/img/icon_system/osclose.jpg"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>OS Fechadas</b>
      </div>
    </div>
    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="let os of informativo.listaOSFechadas"
      >
        <span class="TituloSubitem543"
          ><b>{{ os.dcriacao | formatDate : "DD/MM/YYYY STR" }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ os.cnome }} - Veículo {{ os.placa | placa }}</b>
        </span>
      </div>
      <div
        style="margin: 5px 0 15px 35px; color: red"
        *ngIf="informativo.listaOSFechadas.length == 0"
      >
        Nenhuma OS Fechada
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="col-12" style="margin-bottom: 10px">
      <img
        src="assets/img/icon_system/veiculomanut.jpg"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>Veiculos Em Manutenção</b>
      </div>
    </div>

    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="let VM of informativo.listaVeiculosEmManutencao"
      >
        <span class="TituloSubitem543"
          ><b>{{ VM.placa | placa }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ VM.marca }} {{ VM.modelo }}</b>
        </span>
      </div>
      <div
        style="margin: 5px 0 15px 35px; color: red"
        *ngIf="informativo.listaVeiculosEmManutencao.length == 0"
      >
        Nenhuma Veículo em Manutencão
      </div>
    </div>

    <div class="col-12" style="margin-top: 30px; margin-bottom: 10px">
      <img
        src="assets/img/icon_system/veiculoliberado.jpg"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div style="display: inline-block; vertical-align: middle">
        <b>Veiculos Liberados Entrega</b>
      </div>
    </div>

    <div class="col-12">
      <div
        style="margin: 5px 0 15px 35px"
        *ngFor="let VE of informativo.listaVeiculosEntregues"
      >
        <span class="TituloSubitem543"
          ><b>{{ VE.placa | placa }}</b></span
        >
        <br />
        <span class="SubtituloDestaque2233 text-primary">
          <b>{{ VE.marca }} {{ VE.modelo }}</b>
        </span>
      </div>
      <div
        style="margin: 5px 0 15px 35px; color: red"
        *ngIf="informativo.listaVeiculosEntregues.length == 0"
      >
        Nenhuma Veiculos Liberados Entrega
      </div>
    </div>
  </div>
</div>

<app-base-modal
  modalTitle="Manutenções Previstas"
  modalSize="xl"
  (closeModal)="showModalAlertaManutencao = false"
  *ngIf="showModalAlertaManutencao"
>
  <ng-container modal-body>
    <div class="row table-responsive">
      <table class="table table-responsive">
        <thead>
          <tr>
            <th>Placa</th>
            <th>Veiculo</th>
            <th>Odometro</th>
            <th>Quantidade de OS Prevista</th>
            <th>Verificar OS</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let osp of informativo.listaOSPrevistas">
            <td>{{ osp.veiculo.placa | placa }}</td>
            <td>{{ osp.veiculo.marca }} {{ osp.veiculo.modelo }}</td>
            <td>
              {{ osp.veiculo.ncontador | number : "1.0-0" }}
            </td>
            <td>
              {{ osp.itens.length | number : "1.0-0" }}
            </td>
            <td>
              <button
                type="button"
                title="Visualizar OS Prevista"
                class="btn btn-info"
                (click)="visItens(osp)"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Itens da Manutenções Previstas -
  {{ itemSel.veiculo.placa | placa }} - {{ itemSel.veiculo.marca }}
  {{ itemSel.veiculo.modelo }}"
  modalSize="xl"
  (closeModal)="showModalAlertaManutencaoItem = false"
  *ngIf="showModalAlertaManutencaoItem"
>
  <ng-container modal-body>
    <div class="row table-responsive">
      <table class="table table-responsive">
        <thead>
          <tr>
            <th>Odometro</th>
            <th>Sistema</th>
            <th>Produto/Serviço</th>
            <th>Ocorrencia</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let osp of itemSel.itens">
            <td>
              {{ itemSel.veiculo.ncontador | number : "1.0-0" }}
            </td>
            <td>{{ osp.setting.sistema }}</td>
            <td>{{ osp.setting.prodserv }}</td>
            <td>{{ osp.msg }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</app-base-modal>
