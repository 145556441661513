import { Component, ElementRef, ViewChild } from "@angular/core";
import { ConsultaService } from "src/app/modulos/shared/services/consulta.service";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";
import Swal from "sweetalert2";
import { UserService } from "../../services/user.service";
import { AvisoService } from "../../services/avisos.service";

@Component({
  selector: "app-historico-avisos",
  templateUrl: "./historico-avisos.component.html",
})
export class HistoricoAvisosComponent {
  carregado = false;

  visibleFilters = false;

  arrListaCentroCusto: any[] = [];
  arrAvisos: any[] = [];
  filtro = {
    idavisolog: "",
    idcliente: 0,
    datade: "1900-01-01",
    dataate: "2100-01-01",
    centrocusto: 0,
    tipoaviso: 0,
  };

  ccEmailString: string;
  ccEmailAssunto: string;
  ccEmailConteudo: string;
  detalhesTodos = false;
  checkTodos = false;

  arrTipoAvisos = [
    { id: 0, label: "TODOS" },
    { id: 2, label: "Inconsistencia" },
    { id: 3, label: "Restrições" },
    { id: 4, label: "Documentos Veiculos" },
    { id: 5, label: "Documentos Motoristas" },
    { id: 6, label: "Liberações Restrições" },
    { id: 7, label: "Alterações Limites" },
    { id: 8, label: "Inclusão Novos Veículos" },
    { id: 9, label: "Limite Global" },
    { id: 10, label: "Troca de Óleo" },
    { id: 11, label: "Troca de Filtro" },
    { id: 12, label: "Liberação de OS" },
    { id: 13, label: "Limite de Veiculo" },
    { id: 14, label: "Bloqueio de Tag" },
    { id: 15, label: "Limite de Tag" },
  ];

  modalInputEmail = "";
  showModalSendToEmail = false;

  totallog = {
    mailtotalcnh: 0,
    mailtotalipva: 0,
    mailtotaltrocaoleo: 0,
    mailtotaltrocafiltro: 0,
  };

  centroDeCustoEmail = 0;
  hasMaintenceModule = false;

  @ViewChild("tabelaImprimir", { read: ElementRef, static: true })
  tabelaImprimir: ElementRef;

  constructor(
    private consultaService: ConsultaService,
    private avisosService: AvisoService,
    private clienteService: ClienteService,
    private userService: UserService
  ) {
    this.carregado = false;

    this.ccEmailString = "";

    this.filtro.idcliente = this.clienteService.getCliente().idcliente;
  }

  ngOnInit() {
    this.prepararConsulta();
    this.filtrarDataGridLocal(this.filtro);
  }

  filtrarDataGridLocal(filtros: any): void {
    this.carregado = false;

    this.avisosService.getAvisosLog(filtros).subscribe((response) => {
      if (response.success) {
        this.arrAvisos = response.grupocc_avisolog;
        this.totallog = response.totallog;

        this.carregado = true;
      } else {
        Swal.fire({
          title: "Aviso(s) não encontrado(s)!",
          text: response.message,
          icon: "error",
        }).then(() => {
          this.carregado = true;
        });
      }
    });
  }

  limparFiltros(): void {
    this.filtro.centrocusto = 0;

    if (this.userService.usuario.centrocusto != 0) {
      this.filtro.centrocusto = this.userService.usuario.centrocusto;
      this.centroDeCustoEmail = this.filtro.centrocusto;
    }

    this.filtro.tipoaviso = 0;
    this.filtro.datade = "";
    this.filtro.dataate = "";
  }

  prepararConsulta(): void {
    setTimeout(() => {
      const idcliente = this.filtro.idcliente;

      this.consultaService.prepararConsulta(idcliente).subscribe((response) => {
        if (response.success) {
          this.arrListaCentroCusto = response.CentroCusto;
          this.arrListaCentroCusto.forEach((centrodecusto) => {
            centrodecusto.label = centrodecusto.cnome;
          });

          if (this.userService.usuario.centrocusto != 0) {
            this.filtro.centrocusto = this.userService.usuario.centrocusto;
            this.centroDeCustoEmail = this.filtro.centrocusto;
          }
        }
      });
    }, 1);
  }

  selectCentroCustoEmail(event: any) {
    const email = event.replace("undefined", "");
    if (email == "") {
      Swal.fire(
        "Centro de Custo!",
        "Centro de Custo não possue um email registrado",
        "error"
      );
    } else if (email != 0 && email != "") {
      this.ccEmailString += email.replace(",", ";") + "; ";
    }
  }

  excel(): void {
    const dt = new Date();
    const day = dt.getDate();
    const month = dt.getMonth() + 1;
    const year = dt.getFullYear();
    const hour = dt.getHours();
    const mins = dt.getMinutes();
    const postfix = day + "." + month + "." + year + "_" + hour + "." + mins;

    const a: any = document.createElement("a");

    const data_type = "data:application/vnd.ms-excel";
    const table_div = this.tabelaImprimir.nativeElement;

    let table_html = table_div.outerHTML.replace(/ /g, "%20");
    table_html = table_html.replace(/%20%20/g, "");

    table_html = table_html.replace(/ã/g, "a");
    table_html = table_html.replace(/Ã/g, "A");
    table_html = table_html.replace(/á/g, "a");
    table_html = table_html.replace(/Á/g, "A");
    table_html = table_html.replace(/à/g, "a");
    table_html = table_html.replace(/À/g, "A");
    table_html = table_html.replace(/ä/g, "a");
    table_html = table_html.replace(/Ä/g, "A");
    table_html = table_html.replace(/â/g, "a");
    table_html = table_html.replace(/Â/g, "A");

    table_html = table_html.replace(/é/g, "e");
    table_html = table_html.replace(/É/g, "E");
    table_html = table_html.replace(/è/g, "e");
    table_html = table_html.replace(/È/g, "E");
    table_html = table_html.replace(/ê/g, "e");
    table_html = table_html.replace(/Ê/g, "E");
    table_html = table_html.replace(/ë/g, "e");
    table_html = table_html.replace(/Ë/g, "E");

    table_html = table_html.replace(/í/g, "i");
    table_html = table_html.replace(/Í/g, "I");
    table_html = table_html.replace(/ì/g, "i");
    table_html = table_html.replace(/Ì/g, "I");
    table_html = table_html.replace(/î/g, "i");
    table_html = table_html.replace(/Î/g, "I");
    table_html = table_html.replace(/ï/g, "i");
    table_html = table_html.replace(/Ï/g, "I");

    table_html = table_html.replace(/õ/g, "o");
    table_html = table_html.replace(/Ô/g, "O");
    table_html = table_html.replace(/ó/g, "o");
    table_html = table_html.replace(/Ó/g, "O");
    table_html = table_html.replace(/ò/g, "o");
    table_html = table_html.replace(/Ò/g, "O");
    table_html = table_html.replace(/ô/g, "o");
    table_html = table_html.replace(/Ô/g, "O");
    table_html = table_html.replace(/ö/g, "o");
    table_html = table_html.replace(/Ö/g, "O");

    table_html = table_html.replace(/ú/g, "u");
    table_html = table_html.replace(/Ú/g, "U");
    table_html = table_html.replace(/ù/g, "u");
    table_html = table_html.replace(/Ù/g, "U");
    table_html = table_html.replace(/û/g, "u");
    table_html = table_html.replace(/Û/g, "U");
    table_html = table_html.replace(/ü/g, "u");
    table_html = table_html.replace(/Ü/g, "U");

    table_html = table_html.replace(/ç/g, "c");
    table_html = table_html.replace(/Ç/g, "C");
    a.href = data_type + ", " + table_html;
    a.download = "exported_table_" + postfix + ".xls";
    a.click();
  }

  openModalSendToEmail(): void {
    this.showModalSendToEmail = true;
  }

  SendEmail(): void {
    const parametros: any = {};
    parametros.html = this.tabelaImprimir.nativeElement.innerHTML;

    if (this.modalInputEmail == "") {
      Swal.fire("", "Entre com o E-mail", "error");
      return;
    }

    if (
      this.modalInputEmail.indexOf("@") == -1 &&
      this.modalInputEmail.indexOf(".") == -1
    ) {
      Swal.fire("", "Entre com o E-mail válido", "error");
      return;
    }

    parametros.email = this.modalInputEmail;
    this.avisosService
      .enviarHtmlPorEmail(parametros)
      .subscribe((response: any) => {
        if (response.success) {
          Swal.fire({
            title: "E-MAIL ENVIADO",
            text:
              "Dentro de poucos minutos o e-mail: !" +
              this.modalInputEmail +
              " estará recebendo essa consulta",
            icon: "success",
          }).then(() => {
            this.modalInputEmail = "";
            this.showModalSendToEmail = false;
          });
        } else {
          Swal.fire(
            "E-MAIL NÃO ENVIADO",
            "Não pode ser enviado o e-mail",
            "error"
          );
        }
      });
  }

  SendEmailSMS(): void {
    const parametros: any = {};
    parametros.emails = this.ccEmailString;
    parametros.assunto = this.ccEmailAssunto;
    parametros.conteudo = this.ccEmailConteudo;

    this.avisosService
      .enviarSMSPorEmail(parametros)
      .subscribe((response: any) => {
        if (response.success) {
          Swal.fire(
            "E-MAIL ENVIADO",
            "Dentro de poucos minutos o e-mail: !" +
              response.message +
              " estará recebendo essa consulta",
            "success"
          );
          this.ccEmailString = "";
          this.ccEmailAssunto = "";
          this.ccEmailConteudo = "";
        } else {
          Swal.fire(
            "E-MAIL NÃO ENVIADO",
            "Não pode ser enviado o e-mail" + response.message,
            "error"
          );
        }
      });
  }

  setDetalhes(): void {
    this.detalhesTodos = !this.detalhesTodos;
    for (let i = 0; i < this.arrAvisos.length; i++) {
      this.arrAvisos[i].detalhes = this.detalhesTodos;
    }
  }

  setCheckTodos(): void {
    for (let i = 0; i < this.arrAvisos.length; i++) {
      this.arrAvisos[i].reenvio = this.checkTodos;

      this.setReenvio(this.arrAvisos[i]);
    }
  }

  setReenvio(param: any): void {
    for (let i = 0; i < param.avisoslog.length; i++) {
      param.avisoslog[i].reenvio = param.reenvio;
    }
  }

  rebootSelected(): void {
    let validado = false;
    for (let i = 0; i < this.arrAvisos.length; i++) {
      for (let j = 0; j < this.arrAvisos[i].avisoslog.length; j++) {
        if (this.arrAvisos[i].avisoslog[j].reenvio) {
          validado = true;
          break;
        }
      }

      if (validado) {
        break;
      }
    }
    if (validado) {
      const parametros: any = {};
      parametros.idcliente = this.filtro.idcliente;
      parametros.opcoes = this.arrAvisos;
      this.avisosService
        .reenviarAvisosPorEmail(parametros)
        .subscribe((response: any) => {
          if (response.success) {
            Swal.fire(
              "E-MAIL ENVIADO",
              "Dentro de poucos minutos o e-mail: ! Estará recebendo os avisos selecionados",
              "success"
            );
          } else {
            Swal.fire(
              "E-MAIL NÃO ENVIADO",
              "E-mail não pode ser enviado. " + response.message,
              "error"
            );
          }
        });
    } else {
      Swal.fire(
        "E-MAIL NÃO ENVIADO",
        "Você deve selecionar pelo menos um aviso",
        "error"
      );
    }
  }
}
