<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="card" *ngIf="conteudoCarregado">
  <div class="card-header bg-secondary h5 text-white">
      Histórico de Ajuste de Odomentro
  </div>
  <div class="card-body card-padding">
    <div class="w-100 table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Cód. Transação Unico</th>
            <th>Data da Transação</th>
            <th>Cód. Motorista</th>
            <th>Nome Motorista</th>
            <th align="right">Odometro Informado</th>
            <th align="right">Odometro Anterior</th>
            <th align="right">Odometro Ajustado</th>
            <th>Autorizado por:</th>
            <th>Data do Ajuste</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lao of arrLogAjusteOdometro">
            <td>{{ lao.idtransunico }}</td>
            <td>{{ lao.dcadastrans }}</td>
            <td>{{ lao.codmotorista }}</td>
            <td>{{ lao.nomemot }}</td>
            <td align="right">{{ lao.tr_odometrotrans }}</td>
            <td align="right">{{ lao.tr_odometroantes }}</td>
            <td align="right">{{ lao.tr_odometronovo }}</td>
            <td>{{ lao.nomeusuario }}</td>
            <td>{{ lao.dcadas }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
