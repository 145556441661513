/* src/app/modulos/shared/core/transacoes/pesquisar/pesquisar-transacoes.component.scss */
.linhaCancelada {
  background-color: #ffebee;
  color: #000;
  opacity: 1;
}
.linhaPendente {
  background-color: #ffe594;
  color: #000;
  opacity: 1;
}
/*# sourceMappingURL=pesquisar-transacoes.component-LCEWC5PL.css.map */
