<div class="w-100 ps-3 py-2 fs-6 text-dark fw-semibold">
  Detalhamento da cobrança {{ partsDetails()[0].idcobranca }}
</div>
<div class="w-100 table-responsive">
  <table class="table table-bordered table-striped mb-0 border-secondary">
    <thead>
      <tr>
        <th class="text-center border-top-0 border-start-0">Descrição</th>
        <th class="text-center border-top-0">Ordem de Serviço</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detalhe of partsDetails(); let i = index">
        <td class="border-start-0">
          {{
            (detalhe.codveiculo ? detalhe.codveiculo + " - " : "") +
              detalhe.placa +
              (detalhe?.descricao ? " - " + detalhe.descricao : "")
          }}
        </td>
        <td class="text-center">
          <a
            href="/manutencao/os/visualizar/{{ detalhe?.idos }}"
            target="_blank"
            class="link-primary"
            *ngIf="detalhe?.idos"
          >
            <i class="fa-solid fa-link fa-fw"></i>{{ detalhe?.idos }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
