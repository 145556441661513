<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="card mt-3" *ngIf="conteudoCarregado">
  <div class="card-header bg-secondary h5 text-white">
    Informações Gerais dos Tanques
  </div>
  <div class="card-body card-padding">
    <div class="row">
      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; É Bi-Combustível?:
        <span class="fw-bold" *ngIf="arrVeiculos.lbicombustivel == '0'"
          >NAO</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.lbicombustivel == '1'"
          >SIM</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Nº de Tanques:
        <span class="text-danger">{{ arrVeiculos.ntanques }}</span>
      </div>
    </div>
  </div>
</div>
<!--  INFORMACOES PERTINENTES AO TANK 1-->
<div class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">
    Informações Referentes ao Tanque 1
  </div>
  <div class="card-body card-padding">
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Capacidade do Tanque
        Principal:
        <span class="fw-bold"
          >{{ arrVeiculos.ncapacidade | number : "1.2-2" }}L</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Tolerância para o Tanque
        Principal:
        <span class="fw-bold"
          >{{ arrVeiculos.tolerancia | number : "1.2-2" }}%</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Grupo do Combustível
        Principal:
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel1 == '1'"
          >GASOLINA</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel1 == '2'"
          >ETANOL</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel1 == '3'"
          >DIESEL</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel1 == '4'"
          >GNV</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel1 == '5'"
          >GASOLINA + ETANOL</span
        >
      </div>
    </div>
    <div class="row">
      <div *ngFor="let combu1 of arrVeiculoCombustivel" class="col">
        <div *ngIf="combu1.lprincipal == '1'">
          <i class="fa-solid fa-gas-pump"></i> &nbsp; Combustível:
          <span class="fw-bold"> {{ combu1.cnome }} - {{ combu1.cnome }} </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  INFORMACOES PERTINENTES AO TANK 2-->
<div *ngIf="arrVeiculos.ntanques == '2'" class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">
    Informações Referentes ao Tanque 2
  </div>
  <div class="card-body card-padding">
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Capacidade do Tanque
        Secundário:
        <span class="fw-bold"
          >{{ arrVeiculos.ncapacidade2 | number : "1.2-2" }}L</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Tolerância para o Tanque
        Secundário:
        <span class="fw-bold"
          >{{ arrVeiculos.tolerancia2 | number : "1.2-2" }}%</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Grupo do Combustível
        Secundários:
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel2 == '1'"
          >GASOLINA</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel2 == '2'"
          >ETANOL</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel2 == '3'"
          >DIESEL</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel2 == '4'"
          >GNV</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivel2 == '5'"
          >GASOLINA + ETANOL</span
        >
      </div>
    </div>
    <div class="row">
      <div *ngFor="let combu2 of arrVeiculoCombustivel" class="col-12">
        <div *ngIf="combu2.lprincipal == '2'">
          <i class="fa-solid fa-gas-pump"></i> &nbsp; Combustível:
          <span class="fw-bold"> {{ combu2.cnome }} - {{ combu2.cnome }} </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">
    Informações Referentes ao Reservatorio
  </div>
  <div class="card-body card-padding">
    <div class="row">
      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Capacidade do Reservatorio:
        <span class="fw-bold"
          >{{ arrVeiculos.ncapreserv | number : "1.2-2" }}L</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Tolerância para o
        Reservatorio:
        <span class="fw-bold"
          >{{ arrVeiculos.ntoleranreserv | number : "1.2-2" }}%</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-gas-pump"></i> &nbsp; Grupo do Combustível do
        Reservatorio:
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivelreserv == '1'"
          >GASOLINA</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivelreserv == '2'"
          >ETANOL</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivelreserv == '3'"
          >DIESEL</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivelreserv == '4'"
          >GNV</span
        >
        <span class="fw-bold" *ngIf="arrVeiculos.idtipocombustivelreserv == '5'"
          >GASOLINA + ETANOL</span
        >
      </div>
    </div>
  </div>
</div>
