<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="col-auto">
    <img src="assets/img/user-photo-default.png" style="width: 100px" />
  </div>
  <div class="col">
    <div class="row">
      <div class="col">
        Nome:
        <span class="text-primary fw-bold"> {{ usuario.nome }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        Situação:
        <span class="text-success fw-bold" *ngIf="usuario.status == 1">
          Ativo</span
        >
        <span class="text-danger fw-bold" *ngIf="usuario.status != 1">
          Bloqueado</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        CPF/CNPJ:
        <span class="text-primary fw-bold"> {{ usuario.cgc | cgc }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        E-mail:
        <span class="text-primary fw-bold" *ngIf="usuario.email != ''">
          {{ usuario.email }}</span
        >
        <span class="text-danger fw-bold" *ngIf="usuario.email == ''">
          N/I</span
        >
      </div>
    </div>
    <div class="row" *ngIf="usuario.centrocusto && usuario.centrocusto != ''">
      <div class="col">
        Centro de Custo:
        <span class="text-primary fw-bold"> {{ usuario.centrocusto }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        Perfil de acesso:
        <span class="text-primary fw-bold"> {{ usuario.perfil }}</span>
      </div>
    </div>
  </div>
</div>
