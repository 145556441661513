import { saveAs } from "file-saver";
import { ElementRef, Injectable } from "@angular/core";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

@Injectable()
export class DataExportService {
  static exportToCSV(CSVFile: Record<string, any>[], fileName: string) {
    let CSVString = "";
    CSVFile.forEach((obj) => {
      CSVString +=
        Object.entries(obj)
          .map(([key, value]) => value)
          .join(";") + "\n";
    });

    const blob = new Blob([CSVString], { type: "text/csv;charset=utf-8" });
    saveAs(blob, fileName + ".csv", { separator: ";", decimalSeparator: "," });
  }

  static exportToPDF(contentDiv: ElementRef, fileName: string) {
    const data = contentDiv.nativeElement;
    html2canvas(data, { allowTaint: true }).then((canvas) => {
      const margin = 15;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const pdfWidth = canvasWidth + margin * 2;
      const pdfHeight = pdfWidth * 1.5 + margin * 2;
      const totalPDFPages = Math.ceil(canvasHeight / pdfHeight) - 1;
      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);

      // Adiciona a primeira página
      pdf.addImage(imgData, "JPG", margin, margin, canvasWidth, canvasHeight);

      // Adiciona as páginas restantes, se houver
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage();
        pdf.addImage(
          imgData,
          "JPG",
          margin,
          -(pdfHeight * i) + margin * 4,
          canvasWidth,
          canvasHeight
        );
      }

      // Salva o PDF
      pdf.save(fileName + ".pdf");
    });
  }
}
