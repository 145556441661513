import { Component, OnInit, Input } from "@angular/core";
import { MotoristaService } from "../../../../../shared/services/motorista.service";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";
import Swal from "sweetalert2";

@Component({
  selector: "visualizar-motoristas",
  templateUrl: "./visualizar-motoristas.component.html",
})
export class VisualizarMotoristasComponent implements OnInit {
  motorista: any = {};
  conteudoCarregado = false;

  arrTransacoesMotoristas: any = [];

  idEsp: number;
  tipoEsp: string;

  creationDetails: {
    dataCadastro: '',
    nomeUsuario: '',
    observacaoCadastro: '',
  }

  logs: any = [];

  vizualizarTransacao = false;

  driverFields = {
    cnome: { label: "Nome" },
    status: { label: "Status" },
    sexo: { label: "Sexo" },
    estcivil: { label: "Estado Civil" },
    motivoBloqueio: { label: "Motivo do Bloqueio" },
    dnasc: { label: "Data de Nascimento" },
    codmotorista: { label: "Cód. Motorista" },
    cemail: { label: "Email" },
    ccelular: { label: "Celular" },
    lveiculosvinculados: { label: "Ver Todos os Veículos App" },
    lsemsenha: {
      label: "Validar Senha na Transação",
      options: { 0: "Sim", 1: "Não" },
    },
    dadmissao: { label: "Data de Admissão" },
    ddemissao: { label: "Data de Demissão" },
    lcargaperigosa: {
      label: "Dirige Carga Perigosa",
      options: { 1: "Sim", 0: "Não" },
    },
    centrocusto: { label: "Centro de Custo" },
    ccartmot: { label: "Número CNH" },
    classe: { label: "Categoria CNH" },
    dcartmot: { label: "Data de Emissão CNH" },
    dcartmotvencto: { label: "Data de Validade CNH" },
    ccartmotespelho: { label: "Espelho CNH" },
    drgemissao: { label: "Data de Emissão RG" },
    crg: { label: "RG" },
    crgorgao: { label: "Orgão Emissor RG" },
    nrcbo: { label: "CBO NR" },
    nrcategoria: { label: "Categoria NR" },
    nrdocidentidade: { label: "Identidade NR" },
    nrdvalidade: { label: "Data de Validade NR" },
  };

  dateFields = [
    "dnasc",
    "dadmissao",
    "ddemissao",
    "dcartmot",
    "dcartmotvencto",
    "drgemissao",
    "nrdvalidade",
  ];

  @Input() id: string;
  @Input() idcliente: string;

  showModal = false;

  constructor(
    private motoristaService: MotoristaService,
    private clienteService: ClienteService
  ) {}

  ngOnInit(): any {
    this.motoristaService
      .getMotoristas({ id: this.id, idcliente: this.idcliente })
      .subscribe((response) => {
        if (response.success) {
          this.motorista = response.motorista;
          if (
            this.motorista.nrdvalidade != "" &&
            this.motorista.nrdvalidade != null
          ) {
            const dateformat =
              this.motorista.nrdvalidade.substring(8, 10) +
              "/" +
              this.motorista.nrdvalidade.substring(5, 7) +
              "/" +
              this.motorista.nrdvalidade.substring(0, 4);
            this.motorista.nrdvalidadeC = dateformat;
          }
        }

        this.conteudoCarregado = true;
      });

    this.motoristaService.getTransacoesMotorista({ idmotorista: this.id }).subscribe((response) => {
      if (response.success) {
        this.arrTransacoesMotoristas = response.transacoes;
      }
    });

    this.motoristaService.getLogsAlteracoes(this.clienteService.getCliente().idcliente, this.id).subscribe((response) => {
      if (response.dados) {
        this.logs = response.dados;
      }
    });

    this.motoristaService.getLogCadastro(+this.clienteService.getCliente().idcliente, +this.id).subscribe({
      next: (response) => {
        if (response.success) {
          this.creationDetails = response.data;
        } else {
          Swal.fire('Aviso!', response.message, 'error');
        }
      },
      error: (response) => {
        Swal.fire('Aviso!', response.message, 'error');
      }
    });
  }

  convertBlob(arquivos_dado, arquivos_type): any {
    const binary = atob(arquivos_dado.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: arquivos_type });
    return URL.createObjectURL(blob);
  }

  abrirAnexo(titulo, arquivos_dado, arquivos_type) {
    const downloadLink = document.createElement("a");
    const fileName = titulo;

    downloadLink.setAttribute("type", "hidden");

    downloadLink.href = this.convertBlob(arquivos_dado, arquivos_type);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);

    downloadLink.click();
    downloadLink.remove();
  }

  abrirModalTransacao(id: number, tipo: string): void {
    this.idEsp = id;

    switch (tipo) {
      case "1":
        tipo = "A";
        break;
      case "2":
        tipo = "I";
        break;
      case "3":
        tipo = "L";
        break;
      case "4":
        tipo = "R";
        break;
    }

    this.tipoEsp = tipo;

    this.vizualizarTransacao = true;

    this.showModal = true;
  }

  isDateField(field: string): boolean {
    return this.dateFields.includes(field);
  }
}
