<div class="card">
  <div class="card-header bg-secondary h5 text-white">
    Histórico de Manutenção
  </div>
  <div class="card-body card-padding">
    <div class="w-100 table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>N OS</th>
            <th align="center">Situação</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Data OS</th>
            <th align="right">Data Finalização</th>
            <th align="right">Desconto</th>
            <th align="right">KM Guincho</th>
            <th align="right">Valor Guincho:</th>
            <th align="right">Valor Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let lao of arrVeiculoManutencao.historico">
            <td>{{ lao.idos }}</td>
            <td align="center">
              <button
                title="OS Aberta"
                *ngIf="lao.lstatus == '1'"
                class="btn btn-default rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                *ngIf="lao.lstatus == '2'"
                title="OS Finalizada"
                class="btn btn-success rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-f fa-sm"></i>
              </button>
              <button
                *ngIf="lao.lstatus == '3'"
                title="Os Iniciada"
                class="btn btn-primary rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-i fa-sm"></i>
              </button>
              <button
                *ngIf="lao.lstatus == '4'"
                title="OS Cancelada"
                class="btn btn-danger rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-c fa-sm"></i>
              </button>
              <button
                *ngIf="lao.lstatus == '5'"
                title="OS Liberada para Pagamento"
                class="btn btn-info rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-p fa-sm"></i>
              </button>
              <button
                *ngIf="lao.lstatus == '6'"
                title="OS Encerrada"
                class="btn btn-success rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-e fa-sm"></i>
              </button>
            </td>
            <td>{{ lao.cnome }}</td>
            <td>{{ lao.descricao }}</td>
            <td>
              {{ lao.dcriacao | formatDate : "DD/MM/YYYY HH:mm STR" }}
            </td>
            <td align="right">
              {{ lao.dfinalizacaoos | formatDate : "DD/MM/YYYY HH:mm STR" }}
            </td>
            <td align="right">{{ lao.desconto | real }}</td>
            <td align="right">{{ lao.kmguincho }}</td>
            <td align="right">{{ lao.valorquincho | real }}</td>
            <td align="right">{{ lao.valortotal | real }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
