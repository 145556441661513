<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="col">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Adicionar Desconto">
        <div class="container-fluid pt-3">
          <div class="row pb-2">
            <div class="col text-start">
              <label class="form-label text-dark"
                >Valor para dar desconto:</label
              >
              <input
                type="text"
                class="form-control"
                currencyMask
                [options]="{
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                align: 'left'
              }"
                [(ngModel)]="valorDisconto"
              />
            </div>
          </div>
          <div class="row pb-2">
            <div class="col text-start">
              <label class="form-label text-dark">Motivo:</label>
              <textarea
                class="form-control"
                rows="10"
                title="motivo"
                [(ngModel)]="motivoDisconto"
              ></textarea>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col text-end">
              <button
                class="btn btn-success btn-shadow"
                (click)="addDesconto()"
              >
                <i class="fa-solid fa-check"></i> Dar desconto
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Histórico de Descontos">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col">
              <div class="w-100 table-responsive">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th class="text-center">Data</th>
                      <th class="text-center">Valor</th>
                      <th class="text-center">Motivo</th>
                      <th class="text-center">Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dado of dados">
                      <td class="text-center">
                        {{ dado.dcadas | formatDate:'DD/MM/YYYY HH:mm STR' }}
                      </td>
                      <td class="text-center">{{ dado.valor | real }}</td>
                      <td class="text-center">{{ dado.descricao }}</td>
                      <td class="text-center">{{ dado.usuario }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
