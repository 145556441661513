<div class="row" *ngIf="!carregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="carregado">
  <div class="col d-flex flex-column">
    <button class="btn btn-info" (click)="abrirModalBuscarVeiculo()">
      <i class="fa-solid fa-search"></i>
      Buscar Veículo
    </button>

    <div class="w-100 mb-2 mt-3">
      <img
        src="assets/img/transacao3.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Transações Inconsistentes
      </div>
    </div>

    <div
      class="w-100 fw-bold mb-2 d-flex flex-column"
      *ngFor="let tr of informativo.transInc"
    >
      {{ tr.dcadas | formatDate : "DD/MM/YYYY HH:mm STR" }}
      <span class="text-primary"
        >{{ tr.idtrans }} - Veículo {{ tr.placa }}</span
      >
    </div>

    <div class="w-100 mb-2 mt-3">
      <img
        src="assets/img/transacao3.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Transações Restritas
      </div>
    </div>

    <div
      class="w-100 fw-bold mb-2 d-flex flex-column"
      *ngFor="let tr of informativo.TransRej"
    >
      {{ tr.dcadas | formatDate : "DD/MM/YYYY HH:mm STR" }}
      <span class="text-primary"
        >{{ tr.idtrans }} - Veículo {{ tr.placa }}</span
      >
    </div>

    <div class="w-100 mb-2 mt-3">
      <img
        src="assets/img/financeiro1.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Informativo do Limite
      </div>
    </div>

    <div class="w-100">
      Saldo Disponível:
      <span class="text-primary fw-bold">
        <ng-container *ngIf="completo">
          <ng-container *ngIf="!saldoConsultado"
            >Clique no botão ao lado para mostrar os valores</ng-container
          >
          <ng-container *ngIf="saldoConsultado">{{
            cliente.SaldoAtual | real
          }}</ng-container>
          <i
            class="fa-solid fa-refresh pointer ps-2"
            style="font-size: 20px"
            (click)="attSaldo()"
          ></i>
        </ng-container>
        <ng-container *ngIf="!completo">Carregando...</ng-container>
      </span>
    </div>

    <div class="w-100">
      Limite utilizado:
      <span class="text-primary fw-bold">
        <ng-container *ngIf="completo"
          >{{ cliente.CalcLimitUtilizado | number : "1.2-2" }} %</ng-container
        >
        <ng-container *ngIf="!completo">Carregando...</ng-container>
      </span>
    </div>

    <div class="w-100">
      Limite disponível:
      <span class="text-primary fw-bold">
        <ng-container *ngIf="completo"
          >{{ cliente.CalcLimitDisponivel | number : "1.2-2" }} %</ng-container
        >
        <ng-container *ngIf="!completo">Carregando...</ng-container>
      </span>
    </div>
  </div>
  <div class="col d-flex flex-column">
    <div class="w-100 mb-2">
      <img
        src="assets/img/manutencao2.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Trocas de Óleo
        <button
          class="btn btn-danger btn-sm"
          *ngIf="informativo.TotalTrocaOleo != 0"
          (click)="abrirModalAlertaOleo()"
        >
          <i class="fa-solid fa-droplet"></i>
          {{ informativo.TotalTrocaOleo }}
        </button>
      </div>
    </div>

    <div class="w-100 py-2">Ultimas trocas:</div>

    <ng-container *ngIf="informativo.UltTrocaOleo.length > 0">
      <div
        class="w-100 text-primary fw-bold"
        *ngFor="let trocaOleo of informativo.UltTrocaOleo"
      >
        {{ trocaOleo.placa }}
        -
        {{ trocaOleo.modelo | cortarLabel : 20 }}
      </div>
    </ng-container>
    <ng-container *ngIf="informativo.UltTrocaOleo.length == 0">
      <div class="w-100 text-danger">Nenhuma troca feita.</div>
    </ng-container>

    <div class="w-100 mb-2 mt-4">
      <img
        src="assets/img/manutencao2.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Trocas de Filtro
        <button
          class="btn btn-danger btn-sm"
          *ngIf="informativo.TotalTrocaFiltro != 0"
          (click)="abrirModalAlertaFiltro()"
        >
          <i class="fa-solid fa-filter"></i>
          {{ informativo.TotalTrocaFiltro }}
        </button>
      </div>
    </div>

    <div class="w-100 py-2">Ultimas trocas:</div>

    <ng-container *ngIf="informativo.UltTrocaFiltro.length > 0">
      <div
        class="w-100 text-primary fw-bold"
        *ngFor="let trocaOleo of informativo.UltTrocaFiltro"
      >
        {{ trocaOleo.placa }}
        -
        {{ trocaOleo.modelo | cortarLabel : 20 }}
      </div>
    </ng-container>
    <ng-container *ngIf="informativo.UltTrocaFiltro.length == 0">
      <div class="w-100 text-danger">Nenhuma troca feita.</div>
    </ng-container>

    <div class="w-100 mb-2 mt-4">
      <img
        src="assets/img/documento1.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Vencimento de CNH
        <button
          class="btn btn-danger btn-sm"
          *ngIf="informativo.TotalVencCnh > 3"
          (click)="abrirModalAlertaCnh()"
        >
          <i class="fa-solid fa-address-card"></i>
          {{ informativo.TotalVencCnh }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="informativo.VencCnh.length > 0">
      <div
        class="w-100 pb-3"
        *ngFor="let CNH of informativo.VencCnh; let i = index"
      >
        <ng-container *ngIf="i < 3">
          CNH:
          <span class="text-primary fw-bold">{{ CNH.ccartmot }}</span>
          <br />
          Motorista:
          <span class="text-primary fw-bold">{{
            CNH.cnome | cortarLabel : 20
          }}</span>
          <br />
          Vencimento:
          <span class="text-primary fw-bold">{{
            CNH.data | formatDate : "DD/MM/YYYY STR"
          }}</span>
          <br />
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="informativo.VencCnh.length == 0">
      <div class="w-100 text-danger">Nenhuma CNH a vencer.</div>
    </ng-container>

    <div class="w-100 mb-2 mt-4">
      <img
        src="assets/img/documento1.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Vencimento de IPVA
        <button
          class="btn btn-danger btn-sm"
          *ngIf="informativo.TotalVencIpva > 3"
          (click)="abrirModalAlertaIpva()"
        >
          <i class="fa-solid fa-car"></i>
          {{ informativo.TotalVencIpva }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="informativo.VencIpva.length > 0">
      <ng-container *ngFor="let IPVA of informativo.VencIpva; let i = index">
        <div class="w-100" *ngIf="i < 3">
          Veículo:
          <span class="text-primary fw-bold">{{ IPVA.placa }}</span>
          / Mês Vencimento:
          <span class="text-primary fw-bold">{{ IPVA.mes }}</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="informativo.VencIpva.length == 0">
      <div class="w-100 text-danger">Nenhum vencimento previsto.</div>
    </ng-container>
  </div>
  <div class="col d-flex flex-column">
    <div class="w-100 mb-2">
      <img
        src="assets/img/posto-gasolina-2.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Menor Preço do Combustível
      </div>
    </div>

    <div class="w-100">
      Gasolina Comum:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[100].valor,
            informativo.MenorPrecoCombustivel[100].idtrans,
            informativo.MenorPrecoCombustivel[100].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[100].valor | real }}</span
      >
    </div>
    <div class="w-100">
      Gasolina Aditivada:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[101].valor,
            informativo.MenorPrecoCombustivel[101].idtrans,
            informativo.MenorPrecoCombustivel[101].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[101].valor | real }}</span
      >
    </div>
    <div class="w-100">
      Etanol:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[103].valor,
            informativo.MenorPrecoCombustivel[103].idtrans,
            informativo.MenorPrecoCombustivel[103].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[103].valor | real }}</span
      >
    </div>
    <div class="w-100">
      Diesel:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[105].valor,
            informativo.MenorPrecoCombustivel[105].idtrans,
            informativo.MenorPrecoCombustivel[105].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[105].valor | real }}</span
      >
    </div>
    <div class="w-100">
      Diesel S10:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[106].valor,
            informativo.MenorPrecoCombustivel[106].idtrans,
            informativo.MenorPrecoCombustivel[106].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[106].valor | real }}</span
      >
    </div>
    <div class="w-100">
      GNV:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[107].valor,
            informativo.MenorPrecoCombustivel[107].idtrans,
            informativo.MenorPrecoCombustivel[107].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[107].valor | real }}</span
      >
    </div>
    <div class="w-100">
      Arla 32:
      <span
        class="fw-bold text-primary pointer"
        (click)="
          abrirModalTransacao(
            informativo.MenorPrecoCombustivel[109].valor,
            informativo.MenorPrecoCombustivel[109].idtrans,
            informativo.MenorPrecoCombustivel[109].idtipo
          )
        "
        >{{ informativo.MenorPrecoCombustivel[109].valor | real }}</span
      >
    </div>

    <div class="w-100 mb-2 mt-4">
      <img
        src="assets/img/grafico1.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Estatística
      </div>
    </div>

    <div class="w-100">
      Total de Veículos Ativos:
      <span class="fw-bold text-primary">{{ informativo.TotalVeiculos }}</span>
    </div>
    <div class="w-100">
      Total de Motoristas Ativos:
      <span class="fw-bold text-primary">{{
        informativo.TotalMotoristas
      }}</span>
    </div>
    <div class="w-100">
      Consumo Médio da Empresa:
      <span class="fw-bold text-primary">{{
        informativo.ConsumoMedio | real
      }}</span>
    </div>

    <div class="w-100 mb-2 mt-4">
      <img
        src="assets/img/mechanical.png"
        width="26"
        style="display: inline-block; vertical-align: middle; margin-right: 5px"
      />
      <div
        class="fw-bold"
        style="display: inline-block; vertical-align: middle"
      >
        Ocorrências
        <button
          class="btn btn-danger btn-sm"
          *ngIf="informativo.ocorrenciaTotais != 0"
        >
          <i class="fa-regular fa-circle-exclamation"></i>
          {{ informativo.ocorrenciaTotais }}
        </button>
      </div>
    </div>

    <div class="w-100 py-2">Ultimas ocorrências:</div>

    <ng-container *ngIf="informativo.UltOcorrencias.length > 0">
      <div
        class="w-100 text-primary fw-bold"
        *ngFor="let ocorrencia of informativo.UltOcorrencias"
      >
        {{ ocorrencia.placa }}
        -
        {{ ocorrencia.motorista | cortarLabel : 20 }}
      </div>
    </ng-container>
    <ng-container *ngIf="informativo.UltOcorrencias.length == 0">
      <div class="w-100 text-danger">Nenhuma ocorrência reportada.</div>
    </ng-container>
  </div>
</div>

<app-base-modal
  modalTitle="Dados da Transação"
  modalSize="xl"
  (closeModal)="showModalTransacao = false"
  *ngIf="showModalTransacao"
>
  <ng-container modal-body>
    <visualizar-transacoes
      *ngIf="vizualizarTransacao"
      [modal]="1"
      [id]="idEsp"
      [tipo]="tipoEsp"
    ></visualizar-transacoes>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Troca de Oleos"
  modalSize="xl"
  (closeModal)="showModalAlertaOleo = false"
  *ngIf="showModalAlertaOleo"
>
  <ng-container modal-body>
    <div class="row" *ngIf="!carregadoOleo">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div class="row" *ngIf="carregadoOleo">
      <div classs="col">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-start">Placa / Modelo</th>
              <th class="text-start">Centro de Custo</th>
              <th class="text-start">Ultima Troca</th>
              <th class="text-start">Intervalo Km</th>
              <th class="text-start">Tolerância</th>
              <th class="text-start">Odometro Atual</th>
              <th class="text-start">Próxima Troca</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!informativo.TrocaOleoStatus">
              <tr *ngFor="let oleo of informativo.TrocaOleo; let i = index">
                <td class="text-start">{{ oleo.placa }} {{ oleo.modelo }}</td>
                <td class="text-start">{{ oleo.centroCusto }}</td>
                <td class="text-start">{{ oleo.ultimaTroca }}</td>
                <td class="text-start">{{ oleo.intervaloKm }}</td>
                <td class="text-start">
                  De {{ oleo.intervKmRegraMin }} A {{ oleo.intervkmRegraMax }}
                </td>
                <td class="text-start">
                  {{ oleo.odometroAtual }}
                </td>
                <td class="text-start">
                  De {{ oleo.trocaPermitidaMin }} A
                  {{ oleo.trocaPermitidaMax }}
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="informativo.TrocaOleoStatus">
              <td class="text-danger text-start" colspan="7">
                {{ informativo.TrocaOleo[0] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Troca de Filtro"
  modalSize="xl"
  (closeModal)="showModalAlertaFiltro = false"
  *ngIf="showModalAlertaFiltro"
>
  <ng-container modal-body>
    <div class="row" *ngIf="!carregadoFiltro">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div class="row" *ngIf="carregadoFiltro">
      <div classs="col">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-start">Placa / Modelo</th>
              <th class="text-start">Centro de Custo</th>
              <th class="text-start">Ultima Troca</th>
              <th class="text-start">Intervalo Km</th>
              <th class="text-start">Tolerância</th>
              <th class="text-start">Odometro Atual</th>
              <th class="text-start">Próxima Troca</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!informativo.TrocaOleoStatus">
              <tr *ngFor="let filtro of informativo.TrocaFiltro; let i = index">
                <td class="text-start">
                  {{ filtro.placa }} {{ filtro.modelo }}
                </td>
                <td class="text-start">
                  {{ filtro.centroCusto }}
                </td>
                <td class="text-start">
                  {{ filtro.ultimaTroca }}
                </td>
                <td class="text-start">
                  {{ filtro.intervaloKm }}
                </td>
                <td class="text-start">
                  De {{ filtro.intervKmRegraMin }} A
                  {{ filtro.intervkmRegraMax }}
                </td>
                <td class="text-start">
                  {{ filtro.odometroAtual }}
                </td>
                <td class="text-start">
                  De {{ filtro.trocaPermitidaMin }} A
                  {{ filtro.trocaPermitidaMax }}
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="informativo.TrocaOleoStatus">
              <td class="text-danger text-start" colspan="7">
                {{ informativo.TrocaFiltro[0] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Vencimento do CNH"
  modalSize="xl"
  (closeModal)="showModalAlertaCnh = false"
  *ngIf="showModalAlertaCnh"
>
  <ng-container modal-body>
    <div class="row" *ngIf="!carregadoCnh">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div class="row" *ngIf="carregadoCnh">
      <div classs="col">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-start">Motorista</th>
              <th class="text-start">Carteira Motorista (CNH)</th>
              <th class="text-start">Data Vencimento</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!informativo.VencCnhStatus">
              <tr *ngFor="let cnh of informativo.VencCnhA; let i = index">
                <td class="text-start">{{ cnh.cnome }}</td>
                <td class="text-start">{{ cnh.ccartmot }}</td>
                <td class="text-start">
                  {{ cnh.data | formatDate : "DD/MM/YYYY STR" }}
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="informativo.VencCnhStatus">
              <td class="text-danger text-start" colspan="3">
                {{ informativo.VencCnhA[0] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Vencimento do IPVA"
  modalSize="xl"
  (closeModal)="showModalAlertaIpva = false"
  *ngIf="showModalAlertaIpva"
>
  <ng-container modal-body>
    <div class="row" *ngIf="!carregadoIpva">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div class="row" *ngIf="carregadoIpva">
      <div classs="col">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-start">Placa do Veículo</th>
              <th class="text-start">Mês Vencimento</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!informativo.VencIpvaStatus">
              <tr *ngFor="let ipva of informativo.VencIpvaA; let i = index">
                <td class="text-start">{{ ipva.placa }}</td>
                <td class="text-start">{{ ipva.mes }}</td>
              </tr>
            </ng-container>
            <tr *ngIf="informativo.VencIpvaStatus">
              <td class="text-danger text-start" colspan="2">
                {{ informativo.VencIpvaA[0] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Buscar Veículo"
  modalSize="xl"
  (closeModal)="showModalBuscarVeiculo = false"
  *ngIf="showModalBuscarVeiculo"
>
  <ng-container modal-body>
    <div class="row align-items-end">
      <div class="col">
        <label class="form-label">PLACA</label>
        <input
          type="text"
          [style.textTransform]="'uppercase'"
          class="form-control"
          mask="SSS-AAAA"
          [(ngModel)]="veiculoPlaca"
        />
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          (click)="buscarVeiculoPorPlaca()"
          [disabled]="veiculoPlaca?.replace(regexPlaca, '').length !== 7"
        >
          <i class="fa-solid fa-search"></i> Buscar
        </button>
      </div>
    </div>

    <div class="row mt-3" *ngIf="!carregadoVeiculo">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div class="row mt-3" *ngIf="carregadoVeiculo && veiculo">
      <div class="col">
        <table class="table table-bordered table-hover" *ngIf="veiculo">
          <thead>
            <tr>
              <th class="text-start">Placa</th>
              <th class="text-start">Marca</th>
              <th class="text-start">Modelo</th>
              <th class="text-start">Família</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-start">{{ veiculo.placa }}</td>
              <td class="text-start">{{ veiculo.marca }}</td>
              <td class="text-start">{{ veiculo.modelo }}</td>
              <td class="text-start">{{ veiculo.familia }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" *ngIf="carregadoVeiculo && veiculo">
      <div class="col text-end">
        <button
          type="button"
          title="Visualizar Veículo"
          class="btn btn-frota me-1"
          (click)="openModalVisualizarVeiculo(veiculo.idveiculo)"
        >
          Visualizar
        </button>
        <button
          type="button"
          title="Especificações do Veículo"
          class="btn btn-info"
          (click)="openModalEspecificacoesVeiculo(veiculo.placa)"
          *ngIf="[1, 2, 3, 4, 5, 6, 23].indexOf(veiculo.idfamilia) > -1"
        >
          Especificações
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Visualização do veículo"
  modalSize="xl"
  (closeModal)="showModalVeiculos = false"
  *ngIf="showModalVeiculos"
>
  <ng-container modal-body>
    <visualizar-veiculos
      *ngIf="idveiculoShow"
      [modal]="true"
      [idveiculo]="idveiculoShow"
    ></visualizar-veiculos>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Especificações do veículo"
  modalSize="xl"
  (closeModal)="showModalEspecificacoes = false"
  *ngIf="showModalEspecificacoes"
>
  <ng-container modal-body>
    <detalhes-veiculo
      *ngIf="showModalEspecificacoes"
      [modal]="true"
      [placaVeiculo]="placa"
    ></detalhes-veiculo>
  </ng-container>
</app-base-modal>
