import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import {
  ApiDomainService,
  USE_NEW_API_DOMAIN_HEADER,
} from "./api-domain.service";

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiDomainService = new ApiDomainService(request.headers);

    request = request.clone({
      headers: request.headers.delete(USE_NEW_API_DOMAIN_HEADER),
    });

    const isExternalReq = this.isExternalRequest(request);

    const willSaveToken = !this.isPublicRoute(request) && !isExternalReq;

    if (!isExternalReq) {
      const SERVER_URL = apiDomainService.getApiDomain();
      request = request.clone({
        url: SERVER_URL + `${request.url}`,
      });
    }

    if (willSaveToken) {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json",
          [apiDomainService.getTokenHeader()]:
            apiDomainService.getTokenFromStorage(),
        },
      });
    }

    return next.handle(request).pipe(
      tap((res) => {
        if (res.type === 0) return;
        if (res instanceof HttpResponse && willSaveToken) {
          const TOKEN_HEADER = apiDomainService.getTokenHeader();
          if (res.headers.get(TOKEN_HEADER)) {
            localStorage.setItem("token", res.headers.get(TOKEN_HEADER));
          }
        }
      }),
      catchError((err) => {
        if (err.status === 401 && !isExternalReq) {
          const login = localStorage.getItem("login");
          const version = localStorage.getItem("version");
          localStorage.clear();
          localStorage.setItem("version", version);

          if (login) localStorage.setItem("login", login);

          Swal.fire(
            "Sessão Expirada!",
            "Por favor, entre novamente no sistema.",
            "error"
          ).then(() => {
            this.router.navigate(["login"]);
          });
        }

        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }

  private isPublicRoute(request: HttpRequest<any>): boolean {
    return (
      request.url.split("/")[0] === "login" ||
      request.url.split("/")[0] === "publico"
    );
  }

  private isExternalRequest(request: HttpRequest<any>): boolean {
    return request.url.substring(0, 4) == "http";
  }
}
