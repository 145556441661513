<div class="row" *ngIf="!contentLoaded">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row py-2" *ngIf="contentLoaded">
  <div class="col text-end">
    <button
      class="btn btn-success text-uppercase btn-shadow btn-sm"
      (click)="filtersVisible = !filtersVisible"
    >
      <i class="fa-solid fa-filter"></i>
      &nbsp; Filtros
    </button>
    <button
      class="btn btn-secondary text-uppercase btn-shadow ms-1 btn-sm"
      (click)="clearFilters()"
    >
      <i class="fa-solid fa-filter-slash"></i>
      &nbsp; Limpar Filtros
    </button>
    <button
      *ngIf="idrelated && userType == 1"
      class="btn btn-secondary text-uppercase btn-shadow ms-1 btn-sm"
      (click)="blockInBatch()"
    >
      <i class="fa-solid fa-lock"></i> &nbsp; Bloquear em Lote
    </button>
  </div>
</div>

<div
  class="row pb-2"
  cCollapse
  [visible]="filtersVisible"
  (keydown.enter)="filterDataGridLocal(filter)"
  [hidden]="!contentLoaded"
>
  <div class="col-4">
    <div class="row pb-2">
      <div class="col">
        <label class="form-label">CPF/CNPJ: </label>
        <input
          class="form-control"
          [(ngModel)]="filter.cgc"
          mask="000.000.000-00||00.000.000/0000-00"
        />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col">
        <label class="form-label">Nome:</label>
        <input class="form-control" [(ngModel)]="filter.nome" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label me-2">Status: </label>
        <mat-checkbox
          color="primary"
          value=""
          [(ngModel)]="filter.statusAtivo"
          class="text-success me-2"
        >
          Ativo
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          value=""
          [(ngModel)]="filter.statusBloqueado"
          class="text-success me-2"
        >
          Bloqueado
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          value=""
          [(ngModel)]="filter.statusCancelado"
          class="text-success"
        >
          Cancelado
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="col d-flex flex-column">
    <span class="form-label">Perfis:</span>
    <mat-checkbox
      color="primary"
      class="text-success me-2"
      *ngFor="let dados of arrProfiles"
      checked
      (change)="updateCheckedOptions(dados.id, $event)"
    >
      {{ dados.nome }}
    </mat-checkbox>
  </div>
</div>

<div class="row" *ngIf="contentLoaded">
  <div class="col">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="text-center" *ngIf="idrelated && userType == 1">
            <mat-checkbox color="primary" (change)="markAll()"> </mat-checkbox>
          </th>
          <th class="text-center" *ngIf="!idrelated">Cód. Telenet</th>
          <th
            class="text-center"
            (click)="sortColun(0)"
            [ngClass]="getClassHeadTable(0)"
          >
            Nome
          </th>
          <th
            class="text-center"
            (click)="sortColun(1)"
            [ngClass]="getClassHeadTable(1)"
          >
            CPF/CNPJ
          </th>
          <th class="text-center" *ngIf="type == 1">Centro de Custo</th>
          <th
            class="text-center"
            (click)="sortColun(2)"
            [ngClass]="getClassHeadTable(2)"
          >
            Perfil de Acesso
          </th>
          <th
            class="text-center"
            (click)="sortColun(3)"
            [ngClass]="getClassHeadTable(3)"
          >
            Status
          </th>
          <th style="min-width: 115px">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of arrUsers">
          <td align="center" *ngIf="idrelated && userType == 1">
            <mat-checkbox
              color="primary"
              [disabled]="user.status !== 1"
              [(ngModel)]="user.selected"
            >
            </mat-checkbox>
          </td>
          <td align="center" *ngIf="!idrelated">
            {{ user.codtn }}
          </td>
          <td align="center">
            {{ user.nome }}
          </td>
          <td align="center">
            {{ user.cgc | cgc }}
          </td>
          <td align="center" *ngIf="type == 1">
            {{ user.centrocusto }}
          </td>
          <td align="center">
            {{ user.perfil }}
          </td>
          <td align="center">
            <span class="text-success" *ngIf="user.status == 1">Ativo</span>
            <span class="text-warning" *ngIf="user.status == 2">Bloqueado</span>
            <span class="text-danger" *ngIf="user.status == 3">Cancelado</span>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-info btn-sm me-1"
              title="Visualizar"
              (click)="onView(user.id)"
            >
              <i class="fa-solid fa-eye fa-fw"></i>
            </button>
            <button
              type="button"
              class="btn btn-warning btn-sm"
              title="Alterar"
              *ngIf="canUpdate"
              (click)="onUpdate(user.id)"
            >
              <i class="fa-solid fa-edit fa-fw"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" [hidden]="!contentLoaded">
  <div class="col d-flex justify-content-end">
    <ngb-pagination
      [(page)]="filter.paginaAtual"
      [pageSize]="filter.limite"
      [collectionSize]="filter.quantidade"
      [boundaryLinks]="true"
      [maxSize]="8"
      [rotate]="true"
      (pageChange)="pageChange($event)"
    ></ngb-pagination>
  </div>
</div>
