<div class="card">
  <div class="card-header bg-primary d-flex flex-column">
    <span class="h5 text-white">Ficha Técnica</span>
    <small class="text-white">
      Informações referentes ao manual técnico do veículo
    </small>
  </div>
  <div class="card-body card-padding">
    <div class="row d-flex flex-column mt-3" *ngFor="let item of specsData">
      <div class="col mb-2">
        <span class="pb-2 border-bottom border-primary w-100 d-block fw-bold">
          {{ item.description }}
        </span>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-4 mb-2" *ngFor="let spec of item.specs">
            {{ spec.property }}:
            <span class="text-primary">
              {{ spec.value ?? "Não Informado" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
