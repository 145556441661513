<app-base-report
  [reportData]="dadosRelatorio"
  [reportPath]="caminhoRelatorio"
  [contentLoaded]="conteudoCarregado"
  [loadedReport]="carregaRelatorio"
  (onGoBack)="voltar()"
  (onQueryData)="consultarDados()"
>
  <ng-container title>RELATÓRIO DE FECHAMENTOS</ng-container>
  <ng-container filters>
    <div class="row">
      <div class="col">
        <div class="row pb-2 align-items-end">
          <div
            class="col"
            [hidden]="tipoUsuario != 3 || usuario.qntdCentralizadora == 0"
            *ngIf="tipoUsuario == 3 && usuario.qntdCentralizadora > 0"
          >
            <label class="form-label">Credenciado:</label>
            <input
              class="form-control"
              mask="000000"
              [(ngModel)]="filtro.codtn"
            />
          </div>
          <div class="col">
            <label class="form-label">Tipo de data:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                { id: 'datapgto', label: 'Data de Pagamento' },
                { id: 'fim', label: 'Data de Corte' }
              ]"
              [(ngValue)]="filtro.tipoData"
            ></app-search-select>
          </div>
          <div class="col">
            <label class="form-label">Período de:</label>
            <input
              class="form-control"
              type="date"
              [(ngModel)]="filtro.datade"
            />
          </div>
          <div class="col">
            <label class="form-label">Período até:</label>
            <input
              class="form-control"
              type="date"
              [(ngModel)]="filtro.dataate"
            />
          </div>
          <div class="col">
            <label class="form-label">Desconto IR:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                { id: '', label: 'Todos' },
                { id: '1', label: 'Com desconto' },
                { id: '2', label: 'Sem desconto' }
              ]"
              [(ngValue)]="filtro.retencaoIR"
            ></app-search-select>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-base-report>
