<div class="row">
  <div class="col">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Data de Criação</th>
          <th>Criado Por</th>
          <th>Observação</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ creationDetails.dataCadastro | date : "dd/MM/YYYY HH:mm:ss" }}</td>
          <td>{{ creationDetails.nome }}</td>
          <td>{{ creationDetails.observacaoCadastro }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="deletionDetails.nome !== ''">
  <div class="col">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Data de Cancelamento</th>
          <th>Cancelado Por</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ deletionDetails.dataCancelamento | date : "dd/MM/YYYY HH:mm:ss"}}</td>
          <td>{{ deletionDetails.nome }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="changes.length > 0">
  <div class="col">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="text-start">Data da alteração</th>
          <th class="text-start">Alteração</th>
          <th class="text-start">Observação</th>
          <th class="text-start">Alterado por:</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let change of changes">
          <td>
            {{ change.dcadas | date : "dd/MM/YYYY HH:mm:ss" }}
          </td>
          <td>
            <div class="row">
              <div class="col">
                <table
                  class="table table-bordered table-hover"
                  *ngIf="change.diferencas.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="text-start">Campo</th>
                      <th class="text-start">Antes</th>
                      <th class="text-start">Depois</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dif of change.diferencas">
                      <td>
                        {{ vehicleFields[dif.campo]?.label }}
                      </td>
                      <ng-container
                        *ngIf="!exclusiveFields.includes(dif.campo)"
                      >
                        <td>
                          <ng-container
                            *ngIf="vehicleFields[dif.campo]?.options"
                          >
                            {{ vehicleFields[dif.campo].options[dif.antes] }}
                          </ng-container>
                          <ng-container
                            *ngIf="!vehicleFields[dif.campo]?.options"
                          >
                            {{
                              isDateField(dif.campo)
                                ? (dif.antes | date : "dd/MM/yyyy")
                                : doIsNaN(dif.antes) != false
                                ? dif.antes
                                : (dif.antes | number : "1.2-2")
                            }}
                          </ng-container>
                        </td>
                        <td>
                          <ng-container
                            *ngIf="vehicleFields[dif.campo]?.options"
                          >
                            {{ vehicleFields[dif.campo].options[dif.depois] }}
                          </ng-container>
                          <ng-container
                            *ngIf="!vehicleFields[dif.campo]?.options"
                          >
                            {{
                              isDateField(dif.campo)
                                ? (dif.depois | date : "dd/MM/yyyy")
                                : doIsNaN(dif.depois) != false
                                ? dif.depois
                                : (dif.depois | number : "1.2-2")
                            }}
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          dif.campo == 'motorista' ||
                          dif.campo == 'grupo_motorista' ||
                          dif.campo == 'combustivel'
                        "
                      >
                        <td>
                          <ng-container *ngFor="let motorista of dif.antes">
                            {{ motorista.cnome }}<br />
                            <hr
                              style="
                                margin: 10px 0;
                                height: 1px;
                                background-color: #2196f3;
                              "
                              *ngIf="i != dif.antes.length - 1"
                            />
                          </ng-container>
                        </td>
                        <td>
                          <ng-container *ngFor="let motorista of dif.depois">
                            {{ motorista.cnome }}<br />
                            <hr
                              style="
                                margin: 10px 0;
                                height: 1px;
                                background-color: #2196f3;
                              "
                              *ngIf="i != dif.depois.length - 1"
                            />
                          </ng-container>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
          <td>
            {{ change.observacao }}
          </td>
          <td>
            {{ change.usuario }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
