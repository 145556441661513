import { DatePipe, DecimalPipe } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";
import { TIPO_PERFIL_USUARIO } from "src/app/modulos/shared/enums/tipo-perfil-usuario.enum";
import { CgcPipe } from "src/app/modulos/shared/pipes/cgc.pipe";
import { DataExportService } from "src/app/modulos/shared/services/data-export.service";
import { FechamentoService } from "src/app/modulos/shared/services/fechamento.service";
import { UserService } from "src/app/modulos/shared/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-acoes-cobrancas",
  templateUrl: "./acoes-cobrancas.component.html",
})
export class AcoesCobrancasComponent implements OnDestroy {
  idperfil!: number;

  showModalTaxa = false;
  showModalDescontos = false;
  showModalRemoveTaxa = false;

  abaModalTaxas = 0;

  @Input() id!: number;
  @Input() idfech!: string;
  @Input() lote!: number;
  @Input() modulo!: number;
  @Input() codCobranca!: number;
  @Input() link_boleto!: string;
  @Input() link_nota!: string;
  @Input() pago!: number;
  @Input() descNf!: number;
  @Output() clickedDropdown = new EventEmitter();

  private clienteService = inject(ClienteService);
  private userService = inject(UserService);
  private fechamentoService = inject(FechamentoService);
  private datePipe = inject(DatePipe);
  private cgcPipe = inject(CgcPipe);
  private decimalPipe = inject(DecimalPipe);

  historicoTaxas = [];
  historicoTaxasRemocao = [];

  taxLabels = {
    consumo: "Taxa de Consumo",
    segvia: "Segunda Via Cartão",
    embosso: "Embossing de Cartões",
    embossocoringa: "Embossing de Cartões Coringa",
    valcartao: "Taxa Cartões",
    valcartaoativo: "Taxa Cartões Ativos",
    nf: "Taxa de NF",
    outros: "Cobrança Fixa",
    manutencao: "Taxa de Licença de uso de Software",
    semmov: "Taxa Sem Movimentação",
    semlimmin: "Taxa Sem Atingir Faturamento Mínimo",
  };

  taxes = [];
  taxesToRemove = [];

  taxaSelecionada = {
    total: 0,
    perc: 0,
    taxa: 0,
    valor: 0,
    motivo: "",
  };

  @ViewChild("dropdownMenu") dropdownMenu!: ElementRef;

  tipoUsuario: number;

  canUpdate = false;

  ngOnInit(): void {
    this.canUpdate = this.userService.alterarDados;
    this.idperfil = this.userService.usuario.idperfil;
    this.tipoUsuario = this.userService.tipoUsuario;
    this.setTaxas();
  }

  openAddTaxesModal() {
    this.showModalTaxa = true;
    this.getHistoricoTaxas();
  }

  getTaxes() {
    this.fechamentoService.getTaxas(this.id).subscribe((response) => {
      if (response.success) {
        this.taxesToRemove = response?.taxas;
      } else {
        Swal.fire("Erro ao buscar taxas", response.message, "error");
      }
    });
  }

  setTaxas() {
    this.clienteService.getTipoTaxas().subscribe({
      next: (response) => {
        if (response.success) {
          response.dados.forEach((tipoTaxa) => {
            this.taxes.push({
              ...tipoTaxa,
              ntipoconta: tipoTaxa.tipo,
              label: tipoTaxa.cnome,
              nvalor: "",
              dvencto_rec: "",
              dcobrar_ate: "",
              nvalorcaren: "",
              carencia: "2",
            });
          });
        }
      },
    });
  }

  getHistoricoTaxas(): void {
    this.fechamentoService
      .getHistoricoTaxas(this.idfech, this.modulo, this.id)
      .subscribe((response) => {
        if (response.success) {
          this.historicoTaxas = response.dados;
        }
      });
  }

  openRemoveTaxesModal() {
    this.showModalRemoveTaxa = true;
    this.getTaxes();
    this.getHistoricoTaxasRemocao();
  }

  getHistoricoTaxasRemocao(): void {
    this.fechamentoService
      .getHistoricoTaxasRemocao(this.idfech, this.modulo, this.id)
      .subscribe((response) => {
        if (response.success) {
          this.historicoTaxasRemocao = response.dados;
        }
      });
  }

  adicionarTaxa(): void {
    if (!this.taxaSelecionada.taxa) {
      Swal.fire("Erro ao adicionar taxa", "Selecione uma taxa", "error");
      return;
    }

    if (!this.taxaSelecionada.valor) {
      Swal.fire("Erro ao adicionar taxa", "Informe um valor", "error");
      return;
    }
    if (!this.taxaSelecionada.total) {
      Swal.fire("Erro ao adicionar taxa", "Informe um valor total", "error");
      return;
    }

    if (!this.taxaSelecionada.motivo) {
      Swal.fire("Erro ao adicionar taxa", "Informe um motivo", "error");
      return;
    }

    Swal.fire({
      title: "Adicionar taxa",
      icon: "question",
      text: "Deseja adicionar a taxa?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      preConfirm: () => {
        return this.fechamentoService.setTaxa({
          idfech: this.idfech,
          taxa: this.taxaSelecionada.taxa,
          valor: this.taxaSelecionada.valor,
          motivo: this.taxaSelecionada.motivo,
          modulo: this.modulo,
          total: this.taxaSelecionada.total,
          idcobranca: this.id,
        });
      },
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }

      if (result.value.success) {
        Swal.fire("Sucesso!", "", "success").then(() => {
          this.fechamentoService.dataChangedEvent.next();
          this.showModalTaxa = false;
        });
        this.taxaSelecionada = {
          total: 0,
          perc: 0,
          taxa: 0,
          valor: 0,
          motivo: "",
        };
        this.showModalTaxa = false;
      } else {
        Swal.fire("Erro ao adicionar taxa!", result.value.message, "error");
      }
    });
  }

  removeTaxa(idtipotaxa: number, valorTaxa: number): void {
    Swal.fire({
      title: "Remover taxa",
      icon: "question",
      input: "text",
      inputLabel: "Motivo da remoção:",
      inputPlaceholder: "Informe o motivo que levou a remoção da taxa",
      showCancelButton: true,
      confirmButtonText: "Remover",
      cancelButtonText: "Cancelar",
      preConfirm: (motivo) => {
        if (!motivo) {
          Swal.showValidationMessage("Informe o motivo da remoção");
          return;
        }
        return this.fechamentoService.removeTaxa({
          idfech: this.idfech,
          taxa: idtipotaxa,
          valor: valorTaxa,
          motivo: motivo,
          modulo: this.modulo,
          idcobranca: this.id,
        });
      },
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }

      if (result.value.success) {
        Swal.fire("Sucesso!", "", "success").then(() => {
          this.fechamentoService.dataChangedEvent.next();
          this.showModalRemoveTaxa = false;
        });
        this.showModalRemoveTaxa = false;
      } else {
        Swal.fire("Erro ao remover taxa!", result.value.message, "error");
      }
    });
  }

  addTaxa(modulo: number, taxa: number): void {
    const element = this.taxes.find(
      (e) =>
        e.ntipoconta === taxa &&
        e.modulos.split(",").includes(modulo.toString())
    );
    this.taxaSelecionada.perc = element.perc;
  }

  getNomeTaxaById(id: string): string {
    return this.taxes.find((e) => e.idtipotaxa == id).cnome;
  }

  atualizarBoletoNota() {
    this.fechamentoService
      .atualizarBoletoNota(this.id, Number(this.idfech), this.modulo)
      .subscribe({
        next: (response) => {
          if (response.success) {
            Swal.fire("", "Links atualizados com sucesso!", "success").then(
              () => {
                this.fechamentoService.dataChangedEvent.next();
              }
            );
          } else {
            Swal.fire("", "Erro ao atualizar os links!", "error");
          }
        },
      });
  }

  async getInvoicePreviewAsPdf() {
    const previewNfData = await this.getInvoicePreview(this.id);
    if (previewNfData == null) return;
    const previewNfElement = this.getInvoicePreviewElement(previewNfData);
    DataExportService.exportToPDF(previewNfElement, "previa-nota-fiscal");
  }

  async getInvoicePreview(id: number) {
    const response = await firstValueFrom(
      this.fechamentoService.getInvoicePreview(id, this.modulo)
    );
    if (response.success) {
      return response.data;
    } else {
      Swal.fire("Erro", response.message, "error");
      return null;
    }
  }

  getInvoicePreviewElement(previewNfData): ElementRef {
    const div = document.createElement("div");
    div.style.width = "100vw";
    div.style.padding = "10px";
    div.style.position = "absolute";
    div.style.top = "0";
    div.style.left = "0";
    div.style.zIndex = "-1";
    div.style.fontFamily = "Roboto, sans-serif";
    div.innerHTML = `
    <div class="border border-2 border-dark text-dark w-100 overflow-hidden position-relative">
      <span style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: red;
        opacity: 0.1;
        font-size: 6rem;
        font-weight: bold;
      ">SEM VALOR FISCAL</span>
      <header class="row border-bottom border-dark border-2">
        <div class="col-8 p-2">
          <h1 class="m-0 text-center">Modelo de Pré-visualização de Nota Fiscal</h1>
        </div>
        <div class="col-4 border-start border-dark border-2">
          <div class="row d-flex flex-column">
            <div class="col p-2">
              <p class="text-center m-0 fs-6">
                Competência
              </p>
              <p class="text-center fw-bold m-0 fs-5">
                ${this.datePipe.transform(
      previewNfData.competencia,
      "dd/MM/yyyy"
    )}
              </p>
            </div>
          </div>
        </div>
      </header>
      <section class="row d-flex flex-column border-bottom border-dark border-2 p-2">
        <div class="col">
          <h2 class="m-0 fs-4 fw-bold text-center">PRESTADOR DE SERVIÇOS</h2>
        </div>
        <div class="col-10 align-self-center">
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                CPF/CNPJ:
              </p>
            </div>
            <div class="col-4">
              <p class="m-0 fs-4 fw-bold">
                09.687.900/0002-04
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4">
                Inscrição Municipal: <span class="fw-bold">177865</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Nome fantasia:
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4 fw-bold">
                PERSONAL NET TECNOLOGIA DE INFORMACAO LTDA
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Nome empresarial:
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4 fw-bold">
                PERSONAL NET TECNOLOGIA DE INFORMACAO LTDA
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Endereço:
              </p>
            </div>
            <div class="col-6">
              <p class="m-0 fs-4 fw-bold">
                BLUMENAU, 178 - LOJA 02-
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4">
                CEP: <span class="fw-bold">89204-250</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Bairro:
              </p>
            </div>
            <div class="col-6">
              <p class="m-0 fs-4 fw-bold">
                AMERICA
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Município:
              </p>
            </div>
            <div class="col-6">
              <p class="m-0 fs-4 fw-bold">
                JOINVILLE
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4">
                UF: <span class="fw-bold">SC</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="row d-flex flex-column border-bottom border-dark border-2 p-2">
        <div class="col">
          <h2 class="fs-4 fw-bold text-center">TOMADOR DE SERVIÇOS</h2>
        </div>
        <div class="col-10 align-self-center">
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                CPF/CNPJ:
              </p>
            </div>
            <div class="col-4">
              <p class="m-0 fs-4 fw-bold">
                ${this.cgcPipe.transform(previewNfData.cpf_cnpj)}
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4">
                Inscrição Municipal: <span class="fw-bold">${previewNfData.inscricao_municipal
      }</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Nome fantasia:
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4 fw-bold">
                ${previewNfData.nome_fantasia}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Nome empresarial:
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4 fw-bold">
              ${previewNfData.razao_social}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Endereço:
              </p>
            </div>
            <div class="col-6">
              <p class="m-0 fs-4 fw-bold">
              ${previewNfData.endereco}
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4">
                CEP: <span class="fw-bold">${previewNfData.cep}</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Bairro:
              </p>
            </div>
            <div class="col-6">
              <p class="m-0 fs-4 fw-bold">
              ${previewNfData.bairro}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="m-0 fs-4">
                Município:
              </p>
            </div>
            <div class="col-6">
              <p class="m-0 fs-4 fw-bold">
              ${previewNfData.municipio}
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0 fs-4">
                UF: <span class="fw-bold">${previewNfData.uf}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="row d-flex flex-column border-bottom border-dark border-2 p-2">
        <div class="col border-bottom border-dark">
          <h2 class="fs-4 fw-bold text-center">DISCRIMINAÇÃO DOS SERVIÇOS</h2>
        </div>
        <div class="col">
          <pre class="m-0 fs-4" style="
          overflow: hidden;
          max-width: 100%;
          text-wrap: auto;
          ">
           ${previewNfData.descricao_nf}
          </pre>
        </div>
      </section>
      <article class="row border-bottom border-dark border-2 p-2">
        <div class="col">
          <h4 class="m-0 text-center fw-bold">VALOR TOTAL DO SERVIÇO: R$ ${this.decimalPipe.transform(
        previewNfData.valor_bruto,
        "1.2-2"
      )}</h4>
        </div>
      </article>
    </div>
    `;

    document.body.appendChild(div);
    setTimeout(() => {
      div.remove();
    }, 1);

    return new ElementRef(div);
  }

  moveDropdownToBody() {
    const dropdown = this.dropdownMenu.nativeElement;
    document.body.appendChild(dropdown);
  }

  alterarDescritivoNf() {
    Swal.fire({
      title: "Alterar Descritivo da NF",
      input: "textarea",
      inputAttributes: {
        rows: "5",
      },
      inputValue: this.descNf ?? "",
      text: "Informe o novo descritivo da nota fiscal",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Alterar",
      denyButtonText: "Atualizar com cadastro",
      cancelButtonText: "Cancelar",
      customClass: {
        denyButton: "bg-primary",
      },
      showLoaderOnConfirm: true,
      preConfirm: (descritivo) => {
        if (!descritivo) {
          Swal.showValidationMessage("Informe o descritivo da nota fiscal");
          return;
        }
        return this.fechamentoService.updateInvoiceDescriptive(
          this.id,
          descritivo
        );
      },
      preDeny: () => {
        return this.fechamentoService.reloadInvoiceDescriptive(
          this.id,
          this.modulo
        );
      },
    }).then((result) => {
      if (result.isDismissed) {
        return;
      }

      if (result.value.success) {
        Swal.fire("Sucesso!", "", "success").then(() =>
          this.fechamentoService.dataChangedEvent.next()
        );
      } else {
        Swal.fire("Erro ao alterar descritivo!", result.value.message, "error");
      }
    });
  }

  get isAdm() {
    return this.idperfil === TIPO_PERFIL_USUARIO.ADMINISTRADOR_SISTEMA;
  }

  changePaymentStatus() {
    Swal.fire({
      title: "Alterar status de pagamento",
      text: "Deseja alterar o status de pagamento?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      preConfirm: () => {
        return this.fechamentoService.markAsPaid(this.id);
      },
    }).then((result) => {
      if (result.isDismissed) return;
      if (result.value.success) {
        Swal.fire("Sucesso!", "", "success").then(() => {
          this.fechamentoService.dataChangedEvent.next();
        });
      } else {
        Swal.fire(
          "Erro ao alterar status de pagamento",
          result.value.message,
          "error"
        );
      }
    });
  }

  ngOnDestroy(): void {
    const dropdown = this.dropdownMenu.nativeElement;
    if (dropdown.parentElement === document.body) {
      document.body.removeChild(dropdown);
    }
  }
}
