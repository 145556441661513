import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, ViewChild, ElementRef, OnInit } from "@angular/core";

import { UserService } from "../../shared/services/user.service";
import { VeiculoService } from "../veiculo.service";

const VEICULO_IMAGE: { [key: string]: string } = {
  "AUTOMOVEIS E SUVs": "icon_carro_topo.png",
  "CAMINHÕES E CARRETAS": "truck_green.png",
  MOTO: "motocycle.png",
  "ÔNIBUS E MICROÔNIBUS": "onibus.png",
  "MOTOR-CASA": "motohome.png",
  BARCOS: "cruise_ship.png",
  "CAMIONETAS E UTILITARIOS DE CARGA": "truck_red.png",
  "MÁQUINAS EQUIPAMENTOS": "gear.png",
  EMPILHADEIRA: "forklift.png",
  "JET SKI": "ship.png",
  TRATORES: "tractor_red.png",
  default: "veiculo-default.png",
};

@Component({
  selector: "visualizar-veiculos",
  templateUrl: "./visualizar-veiculos.component.html",
  styleUrls: ["./visualizar-veiculos.component.css"],
})
export class VisualizarVeiculosComponent implements OnInit {
  @Input("idveiculo") idveiculo: number;
  @Input() modal = false;
  @ViewChild("graficoEvolucao") graficoEvolucao: ElementRef;

  private sub: any;
  GNVMark = 0;
  sistema: any = 1;
  arrVeiculos: any;
  alterarDados = true;
  temModuloManut = true;
  tipoHistorico = "0";
  arrHistoricoVeiculos: any;
  arrCartaoVeiculos: any;
  arrCartaoVeiculosManutencao: any;
  arrGrupoMotorista: any;
  arrMotorista: any;
  arrTrocaOleo: any;
  arrVeiculoManutencao: any;
  veiculoEvolucaoConsumo: any[] = [];
  conteudoCarregado = true;
  gesLimVeic = false;
  constructor(
    private router: Router,
    private veiculoService: VeiculoService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.arrVeiculos = [];
    this.alterarDados = this.userService.alterarDados;

    this.arrHistoricoVeiculos = [];
    this.gesLimVeic = this.userService.tipoUsuario == 6;
    if( this.gesLimVeic === true ){
      this.alterarDados = false;
    }
    if (!this.userService.temModulo(2)) {
      this.temModuloManut = false;
    }

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.idveiculo = params["id"];
      }

      this.carregarVeiculo(this.idveiculo);
    });
  }


  carregarVeiculo(veiculoid: any): any {
    this.conteudoCarregado = false;
    this.arrVeiculos = [];
    this.arrHistoricoVeiculos = [];
    this.arrVeiculoManutencao = {};
    this.arrCartaoVeiculos = {
      cnumero: "",
      ident: "",
      validade: "",
      StatusCartao: "",
      dcadas: "",
      dretirada: "",
      dretorno: "",
      scoringa: "",
    };
    this.arrCartaoVeiculosManutencao = {
      cnumero: "",
      ident: "",
      validade: "",
      StatusCartao: "",
      dcadas: "",
      dretirada: "",
      dretorno: "",
      scoringa: "",
    };
    this.arrTrocaOleo = [];

    this.veiculoService
      .getView({ idveiculo: veiculoid, idcliente: "" })
      .subscribe((response) => {
        if (response.success) {
          this.sistema = response.sistema;

          response.veiculo[0].imagensVeiculo =
            VEICULO_IMAGE[response.veiculo[0].FamiliaNome] ||
            VEICULO_IMAGE.default;

          response.veiculo[0].situacaoVeiculo =
            response.veiculo[0].lbloqueado === 0 ? "Ativo" : "Bloqueado";

          this.arrVeiculos = response.veiculo[0];
          this.arrHistoricoVeiculos = response.historicoVeiculo;

          if (response.VeiculoCartao.length > 0) {
            this.arrCartaoVeiculos = response.VeiculoCartao[0];
          }

          if (response.VeiculoCartaoManutencao.length > 0) {
            this.arrCartaoVeiculosManutencao =
              response.VeiculoCartaoManutencao[0];
          }

          this.GNVMark = response.GNV;

          this.conteudoCarregado = true;

          this.arrTrocaOleo = response.paramTrocaOleo;
          this.arrVeiculoManutencao = response.veiculoManutencao;
          this.veiculoEvolucaoConsumo = response.VeiculoEvolucaoConsumo;
        } else {
          this.conteudoCarregado = true;
        }
      });
  }

  handleBack() {
    this.router.navigate(["veiculos/lista"]);
  }
}
