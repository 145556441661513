import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-courses-details",
  templateUrl: "./courses-details.component.html",
  standalone: true,
  imports: [CommonModule, NgbPopoverModule],
})
export class CoursesDetailsComponent {
  @Input() coursesDetails;
}
