<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="col">
    <div class="row">
      <div class="col">
        <div class="w-100 border border-primary p-1">
          <div class="row">
            <div class="col-auto">
              <img src="assets/img/transacao6.png" width="90" />
            </div>
            <div class="col d-flex">
              <div class="row">
                <div class="col d-flex flex-column">
                  <div class="mb-1">
                    <div class="me-3">
                      <small
                        >Sub-rede:
                        <span class="text-primary"
                          >{{ transacao.cnomeemp }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="d-flex">
                      <small class="me-3"
                        >Código da Transação:
                        <span class="text-primary">{{
                          transacao.idtransunico
                        }}</span>
                      </small>
                      <small
                        >Doc:
                        <span class="text-primary">{{
                          transacao.idTransAGI
                        }}</span>
                      </small>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="d-flex" *ngIf="tipoUsuario != 3">
                      <small class="me-3"
                        >Tipo:
                        <span class="text-primary">
                          <span
                            [ngClass]="{
                              'text-success': transacao.idtipo == 1,
                              'text-warning': transacao.idtipo == 2,
                              'text-danger':
                                transacao.idtipo == 3 || transacao.idtipo == 4
                            }"
                          >
                            {{ transacao.categoria }}
                          </span>
                        </span>
                      </small>
                      <small
                        >Situação:
                        <span class="text-primary">
                          {{ transacao.csituacao }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="d-flex">
                      <small class="me-3"
                        >Data:
                        <span class="text-primary">
                          {{ transacao.datatrans }}
                        </span>
                      </small>
                      <small
                        >Hora:
                        <span class="text-primary">
                          {{ transacao.horatrans }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="d-flex">
                      <small class="me-3"
                        >Cartão:
                        <span class="text-primary">
                          {{ transacao.tr_ncartao | cartao : tipoUsuario }}
                        </span>
                      </small>
                      <small
                        >Valor:
                        <span class="text-primary">{{
                          transacao.valortrans | real
                        }}</span>
                      </small>
                    </div>
                    <div *ngIf="transacao?.nparcelas">
                      <small
                        >Parcela:
                        <span class="text-primary"
                          >{{ transacao.parcela }} de
                          {{ transacao.nparcelas }}</span
                        >
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col ms-3">
                  <div *ngIf="transacao.idtipo != 1">
                    <div class="mb-1">
                      <div class="me-3">
                        <small
                          >Motivo da Inconsistência:
                          <div class="text-danger" style="max-width: 250px">
                            {{ transacao.descr_especifica }}
                          </div>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="mb-1"
                    *ngIf="
                      transacao.idtipo == 4 &&
                      transacao.liberavel == 1 &&
                      tipoUsuario < 3 &&
                      alterarDados
                    "
                  >
                    <button
                      class="btn btn-warning"
                      (click)="liberarRestricao(transacao)"
                    >
                      <i class="fa-solid fa-check"></i> Liberar Restrição
                    </button>
                  </div>
                  <div
                    *ngIf="transacao.csituacao == 'CANCELADA' && tipoUsuario == 1"
                  >
                    <div class="mb-1">
                      <button class="btn btn-danger" (click)="descancelar()">
                        <i class="fa-solid fa-check"></i> Descancelar Transação
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Credenciado">
            <div class="container-fluid pt-3">
              <div class="row">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">
                    <ng-container *ngIf="transacao.codtn_cred != '607249'"
                      >Código:</ng-container
                    >
                    <ng-container *ngIf="transacao.codtn_cred == '607249'"
                      >CNPJ:</ng-container
                    >
                  </h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    <ng-container *ngIf="transacao.codtn_cred != '607249'">{{
                      transacao.codtn_cred
                    }}</ng-container>
                    <ng-container *ngIf="transacao.codtn_cred == '607249'">{{
                      transacao.goodCNPJ
                    }}</ng-container>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">
                    <ng-container
                      *ngIf="
                        transacao.codtn_cred !== '607249' && transacao.cpfcnpj
                      "
                      >CNPJ:</ng-container
                    >
                  </h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    <ng-container
                      class=""
                      *ngIf="
                        transacao.codtn_cred !== '607249' && transacao.cpfcnpj
                      "
                      >{{ transacao.cpfcnpj | cgc }}</ng-container
                    >
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="transacao.codtn_cred != '607249'">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Razão social:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.credenciado }}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">
                    Nome
                    <ng-container *ngIf="transacao.codtn_cred != '607249'"
                      >Fantasia</ng-container
                    >:
                  </h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    <ng-container *ngIf="transacao.codtn_cred != '607249'">{{
                      transacao.nome_fantasia
                    }}</ng-container>
                    <ng-container *ngIf="transacao.codtn_cred == '607249'">{{
                      transacao.goodNOME
                    }}</ng-container>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Endereço:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    <ng-container *ngIf="transacao.codtn_cred != '607249'">{{
                      transacao.endcre
                    }}</ng-container>
                    <ng-container *ngIf="transacao.codtn_cred == '607249'"
                      >{{ transacao.goodCEP | cep }} -
                      {{ transacao.goodCIDADE }}/{{
                        transacao.goodESTADO
                      }}</ng-container
                    >
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="col-6">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Empresa">
            <div class="container-fluid pt-3">
              <div class="row">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Código da Empresa:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.codtn_cliente }}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Razão social:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.cliente }}
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="tipoUsuario != 3">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Código do Motorista:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.codmotorista }}
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="tipoUsuario != 3">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Nome do Motorista:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.nomemot }}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Veículo:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.placa }}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Odômetro informado:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.tr_odometro }}
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="tipoUsuario != 3">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Odômetro anterior:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.tr_odometroAntesSempre }}
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="tipoUsuario != 3">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Media KM/L da transação:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.mediakml }}
                  </div>
                </div>
              </div>
              <div class="row mt-2" *ngIf="tipoUsuario != 3">
                <div class="col-4 text-end">
                  <h5 class="mb-3 fs-6 fw-normal">Media KM/L GNV:</h5>
                </div>
                <div class="col-8 text-primary fs-6">
                  <div>
                    {{ transacao.mediakmlGnv | number : "1.2-2" }}
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="col">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Produtos">
            <div class="container-fluid pt-3">
              <div class="row">
                <div class="col mt-2">
                  <div class="w-100 table-responsive">
                    <table class="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th class="text-center">Código</th>
                          <th class="text-center">Produto</th>
                          <th class="text-center">Quantidade</th>
                          <th class="text-center">Preço por L/Und</th>
                          <th class="text-center">Valor</th>
                          <th class="text-center">Desconto</th>
                          <th class="text-center">Total</th>
                          <th aling="center" width="90px" [hidden]="altatributos">
                            Ajustar Transação
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let trans of transacoesItens">
                          <td align="center">
                            {{ trans.tr_prod }}
                          </td>
                          <td align="center">
                            {{ trans.cnome }}
                          </td>
                          <td align="center">
                            {{ trans.nqtd | number : "1.2-2" }}
                          </td>
                          <td align="center">
                            {{ trans.nvalor / trans.nqtd | real }}
                          </td>
                          <td align="center">
                            {{ trans.nvalor | real }}
                          </td>
                          <td align="center">
                            {{ trans.desconto | real }}
                          </td>
                          <td align="center">
                            {{ trans.nvalor - trans.desconto | real }}
                          </td>
                          <td aling="center" width="50px" [hidden]="altatributos">
                            <button
                              type="button"
                              *ngIf="trans.tipoproduto"
                              class="btn btn-danger"
                              title="Ajustar Transação"
                              (click)="abrirModalAjustarItem(trans)"
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td align="center">
                            <b>{{ transacoesItensTotais.cnome }}</b>
                          </td>
                          <td align="center">
                            <b>{{ transacoesItensTotais.nvalor | real }}</b>
                          </td>
                          <td align="center">
                            <b>{{ transacoesItensTotais.desconto | real }}</b>
                          </td>
                          <td align="center">
                            <b>{{ transacoesItensTotais.total | real }}</b>
                          </td>
                          <td
                            aling="center"
                            width="50px"
                            [hidden]="altatributos"
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="row">
      <div class="col text-end pt-3">
        <button
          class="btn btn-danger btn-shadow me-1"
          [routerLink]="['../../../']"
          *ngIf="modal == 0"
        >
          <i class="fa-solid fa-arrow-left"></i> Voltar
        </button>
        <button
          class="btn btn-info btn-shadow me-1"
          (click)="abrirModalAjustarOdometro()"
          *ngIf="
            transacao.ultimaTransacao == 'S' &&
            (tipoUsuario == 1 || tipoUsuario == 2) &&
            alterarDados
          "
        >
          <i class="fa-solid fa-code-simple"></i> Ajustar Odometro
        </button>
        <button class="btn btn-light btn-shadow" (click)="PrintElem()">
          <i class="fa-solid fa-print"></i> Imprimir
        </button>
      </div>
    </div>
  </div>
</div>

<app-base-modal
  modalTitle="Ajuste de Transação"
  (closeModal)="showModalTrans = false"
  *ngIf="showModalTrans"
>
  <ng-container modal-body>
    <div class="row">
      <div class="col">
        <label requiredInput class="form-label">Quantidade:</label>
        <input
          class="form-control"
          [(ngModel)]="valoresQtdeTrans"
          currencyMask
          [options]="{
            prefix: ' ',
            thousands: '.',
            decimal: ',',
            align: 'left'
          }"
          (keyup)="calculaSubTotal()"
        />
      </div>
      <div class="col">
        <label requiredInput class="form-label">Preço por L/Und</label>
        <input
          class="form-control"
          [(ngModel)]="valoresMonetarioTrans"
          currencyMask
          [options]="{
            prefix: 'R$ ',
            thousands: '.',
            decimal: ',',
            align: 'left'
          }"
          (keyup)="calculaSubTotal()"
        />
      </div>
      <div class="col">
        <label class="form-label">Total</label><br />
        <label class="fs-5 text-danger"> R$ {{ totalResult }} </label>
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <button
      class="btn btn-info btn-shadow me-1"
      (click)="openModalAlteraTrans()"
    >
      <i class="fa-solid fa-check"></i> Ajustar valor da transação ?
    </button>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalSize="sm"
  modalTitle="Ajuste de Odometro"
  (closeModal)="showModalOdometro = false"
  *ngIf="showModalOdometro"
>
  <ng-container modal-body>
    <div class="row d-flex flex-column">
      <div class="col">
        <div class="row">
          <span class="col text-end">Data Transação:</span>
          <span class="col">
            {{ transacao.dcadas | formatDate : "DD/MM/YYYY HH:mm STR" }}
          </span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Código da Transação:</span>
          <span class="col">{{ transacao.idtransunico }}</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Código do Motorista:</span>
          <span class="col">{{ transacao.codmotorista }}</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Nome do Motorista:</span>
          <span class="col">{{ transacao.nomemot }}</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Placa Veículo:</span>
          <span class="col">{{ transacao.placa }}</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Odômetro Informado:</span>
          <span class="col">{{ transacao.tr_odometro }}</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Odômetro Anterior:</span>
          <span class="col">{{ transacao.tr_odometroAntesSempre }}</span>
        </div>
      </div>
      <div class="col mt-3 mb-3">
        <div class="row">
          <label class="col text-end">Novo Odometro:</label>
          <div class="col">
            <input
              class="form-control text-primary"
              mask="0*"
              title="Novo Odometro"
              style="width: 200px"
              [(ngModel)]="transacao.tr_odometroNovo"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <span class="col text-end">Autorizado Por:</span>
          <span class="col">{{ dataUsuario.nome }}</span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <button
      class="btn btn-info btn-shadow me-1"
      (click)="alterarOdomentroTransacao(transacao)"
    >
      <i class="fa-solid fa-check"></i> Ajustar Odometro ?
    </button>
  </ng-container>
</app-base-modal>

<!-- APENAS PARA IMPRESSÃO -->
<div #transacaoImprimir style="display: none" *ngIf="conteudoCarregado">
  <div style="padding: 0 5px 3px 5px; vertical-align: top">
    <div style="font-size: 11px">
      <div>
        <div>
          <div>
            <div style="padding: 5px 10px 5px 10px">
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-right: 15px;
                "
              >
                <img src="assets/img/transacao6.png" width="90" />
              </div>
              <div
                style="
                  display: inline-block;
                  vertical-align: top;
                  margin-right: 25px;
                "
              >
                <div>
                  <div>
                    <small
                      >Sub-rede:
                      <span>{{ transacao.cnomeemp }} </span>
                    </small>
                  </div>
                </div>
                <div>
                  <div>
                    <small
                      >Código da Transação:
                      <span>{{ transacao.idtransunico }}</span>
                    </small>
                  </div>
                  <div>
                    <small
                      >Doc:
                      <span>{{ transacao.idTransAGI }}</span>
                    </small>
                  </div>
                </div>
                <div>
                  <div *ngIf="tipoUsuario != 3">
                    <small
                      >Tipo:
                      <span>{{ transacao.categoria }}</span>
                    </small>
                  </div>
                  <div>
                    <small
                      >Situação:
                      <span>{{ transacao.csituacao }}</span>
                    </small>
                  </div>
                </div>
                <div>
                  <div>
                    <small
                      >Data:
                      <span>{{ transacao.datatrans }}</span>
                    </small>
                  </div>
                  <div>
                    <small
                      >Hora:
                      <span>{{ transacao.horatrans }}</span>
                    </small>
                  </div>
                </div>
                <div>
                  <div>
                    <small
                      >Cartão:
                      <span>{{ transacao.tr_ncartao | cartao : 3 }}</span>
                    </small>
                  </div>

                  <div>
                    <small
                      >Valor:
                      <span>{{ transacao.valortrans | real }}</span>
                    </small>
                  </div>
                </div>
              </div>
              <div
                style="display: inline-block; vertical-align: top"
                *ngIf="transacao.idtipo != 1"
              >
                <div>
                  <div>
                    <div>Motivo da Restrição:</div>
                    <div
                      style="
                        max-width: 250px;
                        white-space: normal;
                        margin-top: 3px;
                        color: red;
                      "
                    >
                      {{ transacao.descr_especifica }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="text-align: left; vertical-align: top; margin-top: 5px">
    <div>
      <div style="font-size: 11px">
        <ul>
          <li tabindex="0" aria-controls="transacao-1">
            <a tabindex="-1">Informações</a>
          </li>
        </ul>

        <div aria-hidden="false">
          <div style="padding: 5px 10px 0 10px">
            <div
              style="
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
              "
            >
              <div style="display: block; min-width: 300px">
                <div>
                  <b>Credenciado</b>
                </div>
                <div style="padding: 10px">
                  <div>
                    <div>
                      <small
                        >Código:
                        <span>
                          {{ transacao.codtn_cred }}
                        </span>
                      </small>
                    </div>
                    <div>
                      <small
                        >Razão social:
                        <span>
                          {{ transacao.credenciado }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div>
                    <div>
                      <small
                        >Nome Fantasia:
                        <span>
                          {{ transacao.nome_fantasia }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div>
                    <div>
                      <small
                        >Endereço:
                        <span>
                          {{ transacao.endcre }}
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div style="display: block">
                <div>
                  <b>Empresa</b>
                </div>

                <div style="padding: 10px">
                  <div>
                    <div>
                      <small
                        >Razão social:
                        <span>
                          {{ transacao.cliente }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div>
                    <div>
                      <small
                        >Nome do Motorista:
                        <span>
                          {{ transacao.nomemot }}
                        </span>
                      </small>
                    </div>
                  </div>
                  <div>
                    <div>
                      <small
                        >Veículo:
                        <span>
                          {{ transacao.placa }}
                        </span>
                      </small>
                    </div>
                    <div>
                      <small
                        >Odômetro informado:
                        <span>
                          {{ transacao.tr_odometro }}
                        </span>
                      </small>
                    </div>
                    <div>
                      <small
                        >Odômetro anterior:
                        <span>
                          {{ transacao.tr_odometroAntes }}
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div style="display: block">
                <div>
                  <b>Produtos</b>
                </div>

                <div style="padding: 10px">
                  <div>
                    <table
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style="font-size: 11px"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <table
                              align="center"
                              border="0"
                              cellpadding="0"
                              cellspacing="1"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td align="center">Código</td>
                                  <td align="center">Produto</td>
                                  <td align="center">Quantidade</td>
                                  <td align="center">Preço por L/Und</td>
                                  <td align="center">Valor</td>
                                  <td align="center">Desconto</td>
                                  <td align="center">Total</td>
                                </tr>
                                <tr *ngFor="let trans of transacoesItens">
                                  <td align="center">
                                    {{ trans.tr_prod }}
                                  </td>
                                  <td align="center">
                                    {{ trans.cnome }}
                                  </td>
                                  <td align="center">
                                    {{ trans.nqtd | number : "1.2-2" }}
                                  </td>
                                  <td align="center">
                                    {{ trans.nvalor / trans.nqtd | real }}
                                  </td>
                                  <td align="center">
                                    {{ trans.nvalor | real }}
                                  </td>
                                  <td align="center">
                                    {{ trans.desconto | real }}
                                  </td>
                                  <td align="center">
                                    {{ trans.nvalor - trans.desconto | real }}
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td>&nbsp;</td>
                                  <td>&nbsp;</td>
                                  <td>
                                    <b>{{ transacoesItensTotais.cnome }}</b>
                                  </td>
                                  <td>&nbsp;</td>
                                  <td align="center">
                                    <b>{{
                                      transacoesItensTotais.nvalor | real
                                    }}</b>
                                  </td>
                                  <td align="center">
                                    <b>{{
                                      transacoesItensTotais.desconto | real
                                    }}</b>
                                  </td>
                                  <td align="center">
                                    <b>{{
                                      transacoesItensTotais.total | real
                                    }}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-base-modal
  modalSize="xs"
  modalTitle="Ajustar valores dessa transação?"
  (closeModal)="showModalAjustarTrans = false"
  *ngIf="showModalAjustarTrans"
>
  <ng-container modal-body>
    <div class="row mb-3">
      <div class="col">
        <h4>Você está prestes a ajustar o valor desta transação!</h4>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label">Por favor informe sua senha:</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="inputAlteraTransValue"
        />
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <button
      class="btn btn-success btn-shadow me-1"
      (click)="salvarNovoValorTrans()"
    >
      Confirmar Senha!
    </button>
  </ng-container>
</app-base-modal>
