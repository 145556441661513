/* src/app/modulos/shared/components/base-modal/base-modal.component.scss */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.425);
}
.modall {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1055;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  transition: all 0.3s ease-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  overflow-y: auto;
  animation: fade 0.3s ease-out;
}
.modall.xl {
  width: 90vw;
}
.modall.lg {
  width: 80vw;
}
.modall.sm {
  width: 70vw;
}
.modall.xs {
  width: 50vw;
}
.modall.exit {
  animation: fade-out 0.3s ease-out;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal-title {
  float: left;
  margin-right: 10px !important;
}
.body {
  overflow-y: auto;
  overflow-x: hidden;
}
.footer-modal {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}
.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08), 0 2px 10px rgba(0, 0, 0, 0.04);
}
.btn-close {
  font-size: 20px;
}
/*# sourceMappingURL=base-modal.component-S4NJRA23.css.map */
