<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>
<ng-container *ngIf="conteudoCarregado"></ng-container>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Identificação">
    <div class="container-fluid pt-3">
      <div class="row pb-2">
        <div class="col">
          <label class="form-label" requiredInput>Nome do Perfil:</label>
          <input class="form-control" [(ngModel)]="dados.cnome" />
        </div>
      </div>
      <div class="row pb-2">
        <div class="col">
          <label class="form-label" requiredInput>Situação do Perfil:</label>
          <app-search-select
            value="id"
            [arrToFilter]="[
              {
                id: '1',
                label: 'Ativo'
              },
              {
                id: '0',
                label: 'Bloqueado'
              }
            ]"
            [(ngValue)]="dados.lativo"
          ></app-search-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="form-label">Centro de Custo:</label>
          <app-search-select
            value="idregional"
            [arrToFilter]="regionais"
            [(ngValue)]="dados.idregional"
          ></app-search-select>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Veiculos">
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col">
          <dual-list
            [source]="dados.veiculos"
            display="label"
            key="idveiculo"
            height="312px"
            [filter]="true"
            [format]="format"
            [(destination)]="dados.veiculosSelec"
          ></dual-list>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="row pt-2" *ngIf="conteudoCarregado">
  <div class="col text-end">
    <button class="btn btn-success" (click)="salvar(dados)">
      <i class="fa-solid fa-check"></i>
      Salvar Alterações
    </button>
    <button class="btn btn-danger ms-2" (click)="voltarPesquisa()">
      <i class="fa-solid fa-close"></i>
      Cancelar
    </button>
  </div>
</div>
