<div class="row" *ngIf="!carregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<ng-container *ngIf="carregado">
  <div class="row pb-2">
    <div class="col-3" data-bs-theme="dark">
      <div
        class="w-100 bg-success text-white d-flex flex-row align-items-center"
      >
        <div class="p-4 bg-success-subtle">
          <i class="fa-solid fa-id-card fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate">
            Total de emails enviados sobre vencimento CNH
          </small>
          <span class="h2 m-0">{{ totallog.mailtotalcnh }}</span>
        </div>
      </div>
    </div>
    <div class="col-3" data-bs-theme="dark">
      <div class="w-100 bg-info text-white d-flex flex-row align-items-center">
        <div class="p-4 bg-info-subtle">
          <i class="fa-solid fa-car fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate">
            Total de emails enviados sobre vencimento IPVA
          </small>
          <span class="h2 m-0">{{ totallog.mailtotalipva }}</span>
        </div>
      </div>
    </div>
    <div class="col-3" data-bs-theme="dark">
      <div class="w-100 bg-frota text-white d-flex flex-row align-items-center">
        <div class="p-4 bg-frota-subtle">
          <i class="fa-solid fa-droplet fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate">
            Total de emails enviados sobre Troca de Oléo
          </small>
          <span class="h2 m-0">{{ totallog.mailtotaltrocaoleo }}</span>
        </div>
      </div>
    </div>
    <div class="col-3" data-bs-theme="dark">
      <div
        class="w-100 bg-secondary text-white d-flex flex-row align-items-center"
      >
        <div class="p-4 bg-secondary-subtle">
          <i class="fa-solid fa-filter fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate"> Total de emails Troca de Filtro </small>
          <span class="h2 m-0">{{ totallog.mailtotaltrocafiltro }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row" *ngIf="hasMaintenceModule">
    <div class="col-3" data-bs-theme="dark">
      <div
        class="w-100 bg-success text-white d-flex flex-row align-items-center"
      >
        <div class="p-4 bg-success-subtle">
          <i class="fa-solid fa-book-open-reader fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate"> Manutenção Preventiva </small>
          <span class="h2 m-0">{{ totallog.mailtotalPreventiva }}</span>
        </div>
      </div>
    </div>
    <div class="col-3" data-bs-theme="dark">
      <div class="w-100 bg-info text-white d-flex flex-row align-items-center">
        <div class="p-4 bg-info-subtle">
          <i class="fa-solid fa-square-user fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate"> Manutenção Preditiva </small>
          <span class="h2 m-0">{{ totallog.mailtotalPreditiva }}</span>
        </div>
      </div>
    </div>
    <div class="col-3" data-bs-theme="dark">
      <div class="w-100 bg-frota text-white d-flex flex-row align-items-center">
        <div class="p-4 bg-frota-subtle">
          <i class="fa-solid fa-calendar-check fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate"> OS Encerradas </small>
          <span class="h2 m-0">{{ totallog.mailtotalOSFechadas }}</span>
        </div>
      </div>
    </div>
    <div class="col-3" data-bs-theme="dark">
      <div
        class="w-100 bg-secondary text-white d-flex flex-row align-items-center"
      >
        <div class="p-4 bg-secondary-subtle">
          <i class="fa-solid fa-calendar-star fa-fw h3 m-0"></i>
        </div>
        <div class="flex-grow-1 d-flex flex-column px-2">
          <small class="text-truncate">Garantias próximo à expirar</small>
          <span class="h2 m-0">{{ totallog.mailtotalGarantiaExpirada }}</span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row pt-3">
    <div class="col">
      <!-- Recent Items -->
      <div class="card">
        <div class="card-header bg-secondary text-white h5 d-flex flex-row">
          <div class="flex-grow-1 d-flex flex-column">
            Recentes Envios
            <span class="h6 m-0">
              Confira os ultimos avisos enviados pelo sistema
            </span>
          </div>
          <c-dropdown>
            <button class="btn btn-frota btn-float shadow-sm" cDropdownToggle>
              <i class="fa-solid fa-bars text-white"></i>
            </button>

            <div
              class="rounded-0 shadow p-0"
              style="width: 300px"
              cDropdownMenu
            >
              <div
                class="d-flex flex-row align-items-center p-3 pointer hover"
                cDropdownItem
                (click)="rebootSelected()"
              >
                <i class="fa-solid fa-paper-plane-top fa-fw fs-5"></i>
                <div class="d-flex flex-column w-100 ps-3">
                  <span class="pb-1 fs-6 fw-light"
                    >Reenviar selecionado(s)</span
                  >
                </div>
              </div>
              <div
                class="d-flex flex-row align-items-center p-3 pointer hover"
                cDropdownItem
                (click)="excel()"
              >
                <i class="fa-solid fa-file-excel fa-fw fs-5"></i>
                <div class="d-flex flex-column w-100 ps-3">
                  <span class="pb-1 fs-6 fw-light">
                    Download lista de logs em excel
                  </span>
                </div>
              </div>
              <div
                class="d-flex flex-row align-items-center p-3 pointer hover"
                cDropdownItem
                (click)="openModalSendToEmail()"
              >
                <i class="fa-solid fa-envelope fa-fw fs-5"></i>
                <div class="d-flex flex-column w-100 ps-3">
                  <span class="pb-1 fs-6 fw-light">
                    Enviar lista de logs por e-mail
                  </span>
                </div>
              </div>
            </div>
          </c-dropdown>
        </div>
        <div class="card-body pt-4">
          <div class="row">
            <div class="col text-end">
              <button
                type="button"
                class="btn btn-success text-uppercase btn-shadow btn-sm"
                (click)="visibleFilters = !visibleFilters"
              >
                <i class="fa-solid fa-filter"></i>
                &nbsp; Filtros
              </button>
              <button
                type="button"
                class="btn btn-secondary text-uppercase btn-shadow ms-1 btn-sm"
                (click)="limparFiltros()"
              >
                <i class="fa-solid fa-filter-slash"></i>
                &nbsp; Limpar Filtros
              </button>
              <button
                title="Pesquisar"
                class="btn btn-info btn-shadow ms-1 btn-sm"
                (click)="filtrarDataGridLocal(filtro)"
              >
                <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
              </button>
            </div>
          </div>
          <div
            class="row pt-2"
            cCollapse
            [visible]="visibleFilters"
            (keydown.enter)="filtrarDataGridLocal(filtro, true)"
          >
            <div class="col">
              <div class="row">
                <div class="col">
                  <label class="form-label">Data de:</label>
                  <input
                    class="form-control"
                    type="date"
                    [(ngModel)]="filtro.datade"
                  />
                </div>
                <div class="col">
                  <label class="form-label">Data ate:</label>
                  <input
                    class="form-control"
                    type="date"
                    [(ngModel)]="filtro.dataate"
                  />
                </div>
              </div>
              <div class="row pt-2">
                <div class="col">
                  <label class="form-label">Tipo de Avisos</label>
                  <app-search-select
                    value="id"
                    [arrToFilter]="arrTipoAvisos"
                    [(ngValue)]="filtro.tipoaviso"
                  ></app-search-select>
                </div>
                <div class="col">
                  <label class="form-label">Centro de Custo:</label>
                  <app-search-select
                    value="idregional"
                    [arrToFilter]="arrListaCentroCusto"
                    [(ngValue)]="filtro.centrocusto"
                  ></app-search-select>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <div class="w-100 table-responsive">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th align="center">
                        <button
                          class="btn btn-success btn-sm"
                          (click)="setDetalhes()"
                        >
                          <i
                            class="fa-solid fa-minus"
                            *ngIf="detalhesTodos"
                          ></i>
                          <i
                            class="fa-solid fa-plus"
                            *ngIf="!detalhesTodos"
                          ></i>
                        </button>
                      </th>
                      <th align="center">
                        <mat-checkbox
                          color="primary"
                          [(ngModel)]="checkTodos"
                          (click)="setCheckTodos()"
                        >
                        </mat-checkbox>
                      </th>
                      <th class="text-center">Centro de Custo</th>
                      <th class="text-center">Tipo de Aviso</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let loc of arrAvisos">
                      <tr>
                        <td align="center">
                          <button
                            class="btn btn-success btn-sm"
                            (click)="loc.detalhes = !loc.detalhes"
                          >
                            <i
                              class="fa-solid fa-plus"
                              *ngIf="!loc.detalhes"
                            ></i>
                            <i
                              class="fa-solid fa-minus"
                              *ngIf="loc.detalhes"
                            ></i>
                          </button>
                        </td>
                        <td align="center">
                          <mat-checkbox
                            color="primary"
                            [value]="loc.idavisolog"
                            [(ngModel)]="loc.reenvio"
                            (click)="setReenvio(loc)"
                          >
                          </mat-checkbox>
                        </td>
                        <td align="center" class="col-6">
                          {{ loc.ccentrocusto }}
                        </td>
                        <td align="center" class="col-5">
                          {{ loc.ctipoaviso }}
                        </td>
                      </tr>
                      <tr *ngIf="loc.detalhes">
                        <td colspan="4">
                          <table class="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th></th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso != 0 &&
                                    loc.avisoslog[0].padr_emailCentroCusto
                                  "
                                >
                                  Email Centro Custo
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                  "
                                >
                                  Placa
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                  "
                                >
                                  Modelo
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                  "
                                >
                                  Ultima Troca
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                  "
                                >
                                  Odometro Atual
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                  "
                                >
                                  Troca Permitida Mim
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                  "
                                >
                                  Troca Permitida Max
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 4"
                                >
                                  Veículo
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 4"
                                >
                                  Placa
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 4"
                                >
                                  Mês Vencimento
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 5"
                                >
                                  Motorista
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 5"
                                >
                                  carteira Motorista
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 5"
                                >
                                  Vencimento
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="
                                    loc.idtipoaviso >= 13 &&
                                    loc.idtipoaviso <= 15
                                  "
                                >
                                  Placa
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 13"
                                >
                                  Porcentagem do Limite
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 14"
                                >
                                  Modelo
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 14"
                                >
                                  Numero da TAG
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso == 17"
                                >
                                  Data do Bloqueio
                                </th>
                                <th
                                  class="text-center"
                                  *ngIf="loc.idtipoaviso != 0"
                                >
                                  Data Envio
                                </th>
                                <th class="text-center">Situação</th>
                              </tr>
                            </thead>
                            <tr *ngFor="let locs of loc.avisoslog">
                              <td>
                                <mat-checkbox
                                  color="primary"
                                  [value]="locs.idavisolog"
                                  [(ngModel)]="locs.reenvio"
                                >
                                </mat-checkbox>
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso != 0 &&
                                  loc.avisoslog[0].padr_emailCentroCusto
                                "
                              >
                                {{ locs.padr_emailCentroCusto }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                {{ locs.troc_placa }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                {{ locs.troc_modelo }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                {{ locs.troc_ultimaTroca }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                {{ locs.troc_odometroAtual }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                {{ locs.troc_trocaPermitidaMin }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                {{ locs.troc_trocaPermitidaMax }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 4"
                              >
                                {{ locs.ipva_cnome }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 4"
                              >
                                {{ locs.ipva_placa }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 4"
                              >
                                {{ locs.ipva_MesVencimento }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 5"
                              >
                                {{ locs.cnh_cnome }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 5"
                              >
                                {{ locs.cnh_ccartmot }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 5"
                              >
                                {{ locs.cnh_data }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso >= 13 && loc.idtipoaviso <= 15
                                "
                              >
                                {{ locs.troc_placa }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 13"
                              >
                                {{ locs.porcentagemLimite }} %
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 14"
                              >
                                {{ locs.troc_modelo }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 14"
                              >
                                {{ locs.tnumero }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 17"
                              >
                                {{ locs.dbloqueio | date : "dd/MM/yyyy" }}
                              </td>
                              <td
                                class="text-center"
                                *ngIf="loc.idtipoaviso != 0"
                              >
                                {{ locs.dcadas | date : "dd/MM/yyyy" }}
                              </td>
                              <td class="text-center">
                                <div>
                                  <button
                                    class="btn btn-success btn-sm"
                                    *ngIf="locs.nstatus == 1"
                                  >
                                    E
                                  </button>
                                  <button
                                    *ngIf="locs.nstatus == 2"
                                    class="btn btn-primary btn-sm"
                                  >
                                    R
                                  </button>
                                  <button
                                    *ngIf="locs.nstatus == 3"
                                    class="btn btn-danger btn-sm"
                                  >
                                    N
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="5">
                        <div class="col mt-2">
                          <div class="row mb-2">
                            <div class="col">
                              <button class="btn btn-success btn-sm">E</button>
                              <span class="ms-2">Email enviado.</span>
                            </div>
                            <div class="col">
                              <button class="btn btn-primary btn-sm">R</button>
                              <span class="ms-2">Email reenviado.</span>
                            </div>
                            <div class="col">
                              <button class="btn btn-danger btn-sm">N</button>
                              <span class="ms-2">Email não enviado.</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col" [hidden]="true" #tabelaImprimir>
              <table
                class="table table-bordered table-hover"
                style="
                  font-family: Verdana, Arial, sans-serif;
                  font-size: 9px;
                  width: 100%;
                "
              >
                <thead>
                  <tr>
                    <th align="left" class="col-6">Centro de Custo</th>
                    <th align="center" class="col-5">Tipo de Aviso</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template ngFor let-loc [ngForOf]="arrAvisos">
                    <tr>
                      <td align="left" class="col-6">
                        {{ loc.ccentrocusto }}
                      </td>
                      <td align="center" class="col-5">
                        {{ loc.ctipoaviso }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="padding: 0 !important">
                        <table
                          class="table table-bordered table-hover"
                          style="
                            font-family: Verdana, Arial, sans-serif;
                            font-size: 9px;
                            width: 100%;
                          "
                        >
                          <thead>
                            <tr>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso != 0 &&
                                  loc.avisoslog[0].padr_emailCentroCusto
                                "
                              >
                                Email Centro Custo
                              </th>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                Placa
                              </th>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                Modelo
                              </th>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                Ultima Troca
                              </th>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                Odometro Atual
                              </th>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                Troca Permitida Mim
                              </th>
                              <th
                                class="text-center"
                                *ngIf="
                                  loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                                "
                              >
                                Troca Permitida Max
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 4"
                              >
                                Veículo
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 4"
                              >
                                Placa
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 4"
                              >
                                Mês Vencimento
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 5"
                              >
                                Motorista
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 5"
                              >
                                carteira Motorista
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 5"
                              >
                                Vencimento
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 13"
                              >
                                Placa
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 13"
                              >
                                Porcentagem do Limite
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso == 17"
                              >
                                Data do Bloqueio
                              </th>
                              <th
                                class="text-center"
                                *ngIf="loc.idtipoaviso != 0"
                              >
                                Data Envio
                              </th>

                              <th class="text-center">Situação</th>
                            </tr>
                          </thead>
                          <tr *ngFor="let locs of loc.avisoslog">
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso != 0 &&
                                loc.avisoslog[0].padr_emailCentroCusto
                              "
                            >
                              {{ locs.padr_emailCentroCusto }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                              "
                            >
                              {{ locs.troc_placa }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                              "
                            >
                              {{ locs.troc_modelo }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                              "
                            >
                              {{ locs.troc_ultimaTroca }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                              "
                            >
                              {{ locs.troc_odometroAtual }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                              "
                            >
                              {{ locs.troc_trocaPermitidaMin }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="
                                loc.idtipoaviso > 9 && loc.idtipoaviso < 12
                              "
                            >
                              {{ locs.troc_trocaPermitidaMax }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 4"
                            >
                              {{ locs.ipva_cnome }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 4"
                            >
                              {{ locs.ipva_placa }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 4"
                            >
                              {{ locs.ipva_MesVencimento }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 5"
                            >
                              {{ locs.cnh_cnome }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 5"
                            >
                              {{ locs.cnh_ccartmot }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 5"
                            >
                              {{ locs.cnh_data }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 13"
                            >
                              {{ locs.troc_placa }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 13"
                            >
                              {{ locs.porcentagemLimite }} %
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso == 17"
                            >
                              {{ locs.dbloqueio | date : "dd/MM/yyyy" }}
                            </td>
                            <td
                              class="text-center"
                              *ngIf="loc.idtipoaviso != 0"
                            >
                              {{ locs.dcadas | date : "dd/MM/yyyy" }}
                            </td>
                            <td class="text-center">
                              <button
                                *ngIf="locs.nstatus == 1"
                                class="btn btn-success btn-sm"
                              >
                                E
                              </button>
                              <button
                                *ngIf="locs.nstatus == 2"
                                class="btn btn-primary btn-sm"
                              >
                                R
                              </button>
                              <button
                                *ngIf="locs.nstatus == 3"
                                class="btn btn-danger btn-sm"
                              >
                                N
                              </button>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <div class="col mt-2">
                        <div class="row mb-2">
                          <div class="col-4">
                            E <span class="ms-2">Email enviado.</span>
                          </div>
                          <div class="col-4">
                            R <span class="ms-2">Email reenviado.</span>
                          </div>
                          <div class="col-4">
                            N <span class="ms-2">Email não enviado.</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-header bg-secondary text-white h5 d-flex flex-row">
          <div class="flex-grow-1 d-flex flex-column">
            Envio de Email
            <span class="h6 m-0">
              Separe os e-mails por ponto e virgula ( ; )
            </span>
          </div>
          <div class="position-relative">
            <button
              class="btn btn-primary btn-float shadow-sm"
              (click)="SendEmailSMS()"
            >
              <i class="fa-solid fa-paper-plane-top text-white"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <label class="form-label">Centro de custo</label>
              <app-search-select
                value="cemail"
                [arrToFilter]="arrListaCentroCusto"
                [(ngValue)]="centroDeCustoEmail"
                (ngValueChange)="selectCentroCustoEmail($event)"
              ></app-search-select>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <label class="form-label">Email de destino</label>
              <input class="form-control" [(ngModel)]="ccEmailString" />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <label class="form-label">Assunto</label>
              <input class="form-control" [(ngModel)]="ccEmailAssunto" />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <label class="form-label">Conteúdo</label>
              <textarea
                class="form-control"
                rows="5"
                [(ngModel)]="ccEmailConteudo"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-base-modal
  modalSize="xs"
  modalTitle="Enviar relatorio por e-mail"
  (closeModal)="showModalSendToEmail = false; this.modalInputEmail = ''"
  *ngIf="showModalSendToEmail"
>
  <ng-container modal-body>
    <div class="row">
      <div class="col">
        <label class="form-label">
          Digite abaixo o e-mail que ira receber esse relatório
        </label>
        <input
          type="email"
          class="form-control"
          [(ngModel)]="modalInputEmail"
        />
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer>
    <button class="btn btn-success" (click)="SendEmail()">Enviar</button>
  </ng-container>
</app-base-modal>
