<c-dropdown alignment="end" (visibleChange)="moveDropdownToBody()">
  <a
    href="javascript:void(0)"
    class="btn btn-secondary text-uppercase btn-shadow btn-sm ms-1 dropdown-toggle"
    cDropdownToggle
  >
    <i class="fa-solid fa-ellipsis-vertical"></i>
    Opções
    <b class="caret"></b>
  </a>
  <ul
    class="dropdown-menu icons-right dropdown-menu-left"
    cDropdownMenu
    #dropdownMenu
  >
    <li cDropdownItem>
      <a href="javascript:void(0)" (click)="getInvoicePreviewAsPdf()"
        >Baixar Prévia da Nota Fiscal</a
      >
    </li>
    <li cDropdownItem *ngIf="tipoUsuario === 1">
      <a href="javascript:void(0)" (click)="alterarDescritivoNf()"
        >Alterar Descritivo da NF</a
      >
    </li>
    <li cDropdownItem *ngIf="canUpdate">
      <a href="javascript:void(0)" (click)="atualizarBoletoNota()"
        >Atualizar Link Boleto e Nota</a
      >
    </li>
    <li cDropdownItem *ngIf="link_boleto && pago == 0">
      <a [href]="link_boleto" target="_blank">Abrir boleto</a>
    </li>
    <li cDropdownItem *ngIf="link_nota">
      <a [href]="link_nota" target="_blank">Abrir nota</a>
    </li>
    <li cDropdownItem *ngIf="isAdm">
      <a href="javascript:void(0)" (click)="showModalDescontos = true"
        >Adicionar Descontos Avulsos</a
      >
    </li>
    <li cDropdownItem *ngIf="isAdm">
      <a href="javascript:void(0)" (click)="openAddTaxesModal()"
        >Adicionar Taxa Avulsa</a
      >
    </li>
    <li cDropdownItem *ngIf="isAdm">
      <a href="javascript:void(0)" (click)="openRemoveTaxesModal()"
        >Remover Taxa</a
      >
    </li>
    <li cDropdownItem *ngIf="isAdm && pago == 0">
      <a href="javascript:void(0)" (click)="changePaymentStatus()"
        >Marcar como pago</a
      >
    </li>
  </ul>
</c-dropdown>

<app-base-modal
  modalTitle="Adicionar Taxa"
  modalSize="xs"
  (closeModal)="showModalTaxa = false"
  *ngIf="showModalTaxa"
>
  <ng-container modal-body>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [dynamicHeight]="true"
      (selectedIndexChange)="abaModalTaxas = $event"
    >
      <mat-tab label="Adicionar Taxa">
        <div class="container-fluid pt-3">
          <ng-container *ngIf="taxes.length > 0; else loadingTaxes">
            <div class="row pb-2">
              <div class="col text-start">
                <label class="form-label text-dark">Tipo de Taxa: </label>
                <app-search-select
                  value="idtipotaxa"
                  [arrToFilter]="taxes | filterTaxaModulos: modulo : 0 : true"
                  [(ngValue)]="taxaSelecionada.taxa"
                  (ngValueChange)="addTaxa(modulo, taxaSelecionada.taxa)"
                ></app-search-select>
              </div>
            </div>
            <div class="row pb-2">
              <div class="col text-start">
                <label class="form-label text-dark">Motivo da Taxa:</label>
                <textarea
                  [(ngModel)]="taxaSelecionada.motivo"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col text-start">
                <label class="form-label text-dark">Valor da Taxa:</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="taxaSelecionada.valor"
                  currencyMask
                  [options]="
                    taxaSelecionada.perc
                      ? {
                          prefix: '',
                          thousands: '.',
                          decimal: ',',
                          suffix: '%',
                          align: 'left',
                          allowNegative: true,
                        }
                      : {
                          prefix: 'R$ ',
                          thousands: '.',
                          decimal: ',',
                          align: 'left',
                          allowNegative: true,
                        }
                  "
                />
              </div>
              <div class="col text-start">
                <label class="form-label text-dark">Valor total:</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="taxaSelecionada.total"
                  currencyMask
                  [options]="{
                    prefix: 'R$ ',
                    thousands: '.',
                    decimal: ',',
                    align: 'left',
                    allowNegative: true,
                  }"
                />
              </div>
            </div>
          </ng-container>
          <ng-template #loadingTaxes>
            <div class="row pb-2">
              <div class="col text-center">
                <mat-spinner diameter="40" class="m-auto"></mat-spinner>
              </div>
            </div>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab label="Histórico">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col">
              <div class="w-100 table-responsive">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th class="text-center">Data</th>
                      <th class="text-center">Tipo de Taxa</th>
                      <th class="text-center">Motivo da Taxa</th>
                      <th class="text-center">Valor da Taxa</th>
                      <th class="text-center">Valor total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let taxa of historicoTaxas">
                      <td class="text-center">
                        {{ taxa.dcadas | date: "dd/MM/yyyy hh:mm" }}
                      </td>
                      <td class="text-center">
                        {{ getNomeTaxaById(taxa.taxa) }}
                      </td>
                      <td class="text-center">{{ taxa.descricao }}</td>
                      <td class="text-center">
                        {{ taxa.valor | number: "1.2-2" }}
                      </td>
                      <td class="text-center">
                        {{ taxa.total | number: "1.2-2" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container modal-footer>
    <button
      class="btn btn-success btn-shadow"
      *ngIf="abaModalTaxas == 0"
      (click)="adicionarTaxa()"
    >
      Adicionar
    </button>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Remover Taxa"
  modalSize="xs"
  (closeModal)="showModalRemoveTaxa = false"
  *ngIf="showModalRemoveTaxa"
>
  <ng-container modal-body>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [dynamicHeight]="true"
      (selectedIndexChange)="abaModalTaxas = $event"
    >
      <mat-tab label="Remover Taxa">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col">
              <div class="w-100 table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr class="fw-bold">
                      <td class="text-black text-center">Tipo de Taxas</td>
                      <td class="text-black text-center">Total</td>
                      <td class="text-black text-center">Ação</td>
                    </tr>
                    <tr *ngFor="let tax of taxesToRemove">
                      <td class="text-center">
                        {{ getNomeTaxaById(tax.idtipotaxa) }}
                      </td>
                      <td class="text-center">
                        {{ tax.valor | real }}
                      </td>
                      <td class="text-center">
                        <button
                          class="btn btn-danger btn-sm"
                          (click)="removeTaxa(tax.idtipotaxa, tax.valor)"
                        >
                          Remover Taxa
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Histórico">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col">
              <div class="w-100 table-responsive">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th class="text-center">Data</th>
                      <th class="text-center">Tipo de Taxa</th>
                      <th class="text-center">Motivo da remoção</th>
                      <th class="text-center">Valor da Taxa removida</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let taxa of historicoTaxasRemocao">
                      <td class="text-center">
                        {{ taxa.dcadas | date: "dd/MM/yyyy hh:mm" }}
                      </td>
                      <td class="text-center">
                        {{ taxLabels[taxa.taxa.toLocaleLowerCase()] }}
                      </td>
                      <td class="text-center">{{ taxa.descricao }}</td>
                      <td class="text-center">
                        {{ taxa.valor | number: "1.2-2" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Descontos avulsos no Fechamento"
  (closeModal)="showModalDescontos = false"
  *ngIf="showModalDescontos"
>
  <ng-container modal-body>
    <desconto-fechamentos [id]="idfech" [idcobranca]="id" [modulo]="modulo">
    </desconto-fechamentos>
  </ng-container>
</app-base-modal>
