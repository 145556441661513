<div class="row" *ngIf="carregado" (keydown.enter)="aplicarFiltros()">
  <div class="col pb-5">
    <label class="form-label">Ano:</label>
    <app-search-select
      [arrToFilter]="anos"
      [(ngValue)]="filtro.ano"
    ></app-search-select>
  </div>
  <div class="col pb-5">
    <label class="form-label">Placa:</label>
    <input [(ngModel)]="filtro.placa" class="form-control" />
  </div>
  <div class="col pb-5">
    <label class="form-label">Centro de custo:</label>
    <app-search-select
      value="idregional"
      [arrToFilter]="listaDeCentrosDeCusto"
      [(ngValue)]="filtro.centroDeCusto"
    ></app-search-select>
  </div>
  <div class="col-auto d-flex align-items-end pb-5">
    <button
        class="btn btn-info"
        (click)="aplicarFiltros()">
        <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i> Pesquisar
      </button>
  </div>
</div>
<div class="row pb-3" *ngIf="carregado">
  <div class="col-6">
    <line-char-data-geral [filtros]="filtroDesempenho"></line-char-data-geral>
  </div>
  <div class="col-6">
    <line-char-data-preco-medio [filtros]="filtroDesempenho"></line-char-data-preco-medio>
  </div>
  <div class="col-6">
    <line-char-data-consumo-media-veiculo [filtros]="filtroDesempenho"></line-char-data-consumo-media-veiculo>
  </div>
  <div class="col-6">
    <line-char-data-km-media-veiculo [filtros]="filtroDesempenho"></line-char-data-km-media-veiculo>
  </div>
</div>
