<div class="card">
  <div class="card-header bg-secondary h5 text-white">Contador</div>
  <div class="card-body card-padding">
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Controle de Contador:
        <span class="fw-bold">{{
          arrVeiculos.lcontador_naorestringe == 0
            ? "Sim (*abastecimentos restringidos)"
            : "Não (*abastecimentos não restringidos)"
        }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Histórico de Acertos do
        Odômetro:
        <span class="text-danger fw-bold"
          >Nenhum histórico de acerto no odômetro</span
        >
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-6">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Odômetro:
        <span class="fw-bold">{{
          arrVeiculos.lodometro == 1 ? "Sim" : "Não"
        }}</span>
      </div>

      <div class="col-6">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Horímetro:
        <span class="text-danger fw-bold">{{
          arrVeiculos.lhorimetro == 1 ? "Sim" : "Não"
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Contagem Atual:
        <span class="fw-bold"
          >{{ arrVeiculos.ncontador }}
          {{ arrVeiculos.lhorimetro == 1 ? "Hrs" : "Kms" }}</span
        >
      </div>

      <div class="col-6">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Cilindradas:
        <span class="fw-bold">{{ arrVeiculos.ncinlindradas }}</span>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">
    {{sistema == 1 ? "Limites" : "Saldo"}}
  </div>
  <div class="card-body card-padding">
    <div class="row">
      <div *ngIf="sistema == 1" class="col">
        <i class="fa-solid fa-box-dollar"></i> &nbsp; Limite Atual:
        <span class="fw-bold"
          >R$ {{ arrCartaoVeiculos.limiteatual | number : "1.2-2" }}</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-box-dollar"></i> &nbsp; Saldo Atual:
        <span class="fw-bold"
          >R$ {{ arrCartaoVeiculos.saldocartao | number : "1.2-2" }}</span
        >
      </div>
    </div>
  </div>
</div>
<div *ngIf="sistema == 1" class="card mt-3" [hidden]="temModuloManut">
  <div class="card-header bg-secondary h5 text-white">Limites Manutenção</div>
  <div class="card-body card-padding">
    <div class="row">
      <div class="col">
        <i class="fa-solid fa-box-dollar"></i> &nbsp; Limite Atual:
        <span class="fw-bold"
          >R$
          {{ arrCartaoVeiculosManutencao.limiteatual | number : "1.2-2" }}</span
        >
      </div>

      <div class="col">
        <i class="fa-solid fa-box-dollar"></i> &nbsp; Saldo Atual:
        <span class="fw-bold"
          >R$
          {{ arrCartaoVeiculosManutencao.saldocartao | number : "1.2-2" }}</span
        >
      </div>
    </div>
  </div>
</div>
<div *ngIf="sistema == 1" class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">Histórico de Limites</div>
  <div class="card-body card-padding">
    <div class="row">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Data</th>
            <th class="text-end">Valor</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let hist of arrHistoricoVeiculos">
            <td>{{ hist.dcadasformat }}</td>
            <td class="text-end">R$ {{ hist.nvalor | number : "1.2-2" }}</td>
            <td>{{ hist.cusuario }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">
    Cartão Vinculado ao Veículo
  </div>
  <div class="card-body card-padding">
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Nº:
        <span class="fw-bold">{{ arrCartaoVeiculos.cnumero }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Placa:
        <span class="fw-bold">{{ arrCartaoVeiculos.ident }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Validade:
        <span class="fw-bold">{{ arrCartaoVeiculos.validade }}</span>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Situação:
        <span class="fw-bold">{{ arrCartaoVeiculos.StatusCartao }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Data do Cadastro:
        <span class="fw-bold">{{ arrCartaoVeiculos.dcadas }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Data da Retirada: <span class="fw-bold"></span>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Data do Retorno:
        <span class="fw-bold">{{ arrCartaoVeiculos.dretorno }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        É Cartão Curinga?:
        <span class="fw-bold">{{ arrCartaoVeiculos.scoringa }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-credit-card"></i> &nbsp;
        Autorizador: <span class="fw-bold"></span>
      </div>
    </div>
  </div>
</div>
