import { Component, OnInit, Input } from "@angular/core";
import Swal from "sweetalert2";
import { FechamentoService } from "../../../services/fechamento.service";

@Component({
  selector: "desconto-fechamentos",
  templateUrl: "./desconto-fechamentos.html",
})
export class DescontoFechamentosComponent implements OnInit {
  dados = [];
  conteudoCarregado = false;

  @Input() id: string;
  @Input() idcobranca: number;
  @Input() modulo;

  valorDisconto = 0;
  motivoDisconto = "";

  constructor(private fechamentoService: FechamentoService) {}

  ngOnInit() {
    this.atualizarDados();
  }

  atualizarDados(): void {
    this.conteudoCarregado = false;

    this.fechamentoService
      .getFechamentoDesconto({
        id: this.id,
        modulo: this.modulo,
        idcobranca: this.idcobranca,
      })
      .subscribe((response) => {
        if (response.success) {
          this.dados = response.dados;
        } else {
          Swal.fire("", response.message, "error");
        }

        this.conteudoCarregado = true;
      });
  }

  addDesconto(): void {
    const dados = {
      valor: this.valorDisconto,
      motivo: this.motivoDisconto,
      id: this.id,
      modulo: this.modulo,
      idcobranca: this.idcobranca,
    };

    if (!dados.valor || dados.valor <= 0) {
      Swal.fire(
        "Desconto Avulso",
        "Informe um valor para dar de desconto",
        "error"
      );
    }

    if (dados.motivo == "") {
      Swal.fire("Desconto Avulso", "Informe o motivo desse desconto", "error");
    }

    Swal.fire({
      title: "Desconto Avulso",
      text: "Adicionar desconto avulso no fechamento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, dar desconto!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.fechamentoService.addDesconto(dados);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire(
            "Desconto Avulso",
            "Desconto adicionado com sucesso!",
            "success"
          ).then(() => {
            this.fechamentoService.dataChangedEvent.next();
            this.atualizarDados();
            this.valorDisconto = 0;
            this.motivoDisconto = "";
          });
        } else {
          Swal.fire("Desconto Avulso", result.value.message, "error");
        }
      }
    });
  }
}
