<div class="row" *ngIf="!graficoAtivo">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" [hidden]="!graficoAtivo">
  <div class="col d-flex flex-column mb-3">
    <div class="mb-5">
      <div class="w-100" [chart]="charta"></div>
    </div>
  </div>
</div>
