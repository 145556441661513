import { Component, Input, OnChanges } from "@angular/core";
import { LocalidadeService } from "../../services/localidade.service";
import Swal from "sweetalert2";

@Component({
  selector: "localidade-cadastro-combo",
  templateUrl: "./localidade-cadastro.component.html",
})
export class LocalidadeCadastroComponent implements OnChanges {
  localidades = [];
  bairros = [];
  ctipolog = [];
  log = [];

  conteudoCarregadoLocalidade = false;
  conteudoCarregadoBairro = false;
  conteudoCarregadoTipoLog = false;
  conteudoCarregadoLog = false;
  conteudoCarregadoCep = true;

  CadastraNovoEndereco = false;

  cepAntigo = "";

  @Input() inputdados: any;
  @Input() obrigatorio = false;
  @Input() latlog = false;
  @Input() credenciado = false;
  @Input() desabilitado = false;

  constructor(private localidadeService: LocalidadeService) {}

  ngOnChanges(): any {
    if (this.inputdados.cuf && this.inputdados.cuf != "") {
      if (this.credenciado) {
        this.getIdsLocalizacao(
          this.inputdados.cuf?.trim(),
          this.inputdados.municipio?.trim(),
          this.inputdados.cbairro?.trim(),
          this.inputdados.endereco?.trim()
        );
      } else {
        this.getLocalidade(this.inputdados.cuf);
        this.getBairro(this.inputdados.idmun);
        this.getTipoLog(this.inputdados.idbai);
        this.getLog(this.inputdados.idbai, this.inputdados.ctipolog);
      }
    } else if (
      this.inputdados.idlogr &&
      this.inputdados.idlogr != "" &&
      this.inputdados.idlogr != "0"
    ) {
      this.getLoge(this.inputdados.idlogr);
    } else {
      this.inputdados.cuf = "";
      this.inputdados.idmun = "0";
      this.inputdados.idbai = "0";
      this.inputdados.ctipolog = "0";
      this.inputdados.idlogr = "0";

      this.conteudoCarregadoLocalidade = true;
      this.conteudoCarregadoBairro = true;
      this.conteudoCarregadoTipoLog = true;
      this.conteudoCarregadoLog = true;
      this.conteudoCarregadoCep = true;
    }
  }

  buscaCepCorreios(cepCorreios: string, status: boolean): void {
    this.cepAntigo = this.inputdados.cep;
    this.inputdados.complemento = "";

    if (
      (this.inputdados.ctipolog == "" || this.inputdados.ctipolog == "0") &&
      this.inputdados.endereco &&
      this.inputdados.endereco != ""
    ) {
      let TipoLogr = this.inputdados.endereco.split(" ")[0];

      if (TipoLogr.substr(0, 2) == "RO") {
        TipoLogr = "RODOVIA";
      }

      if (TipoLogr.substr(0, 2) == "AV") {
        TipoLogr = "AVENIDA";
      }

      if (TipoLogr.substr(0, 2) == "RU") {
        TipoLogr = "RUA";
      }

      if (TipoLogr.substr(0, 2) == "PR") {
        TipoLogr = "PRACA";
      }

      if (TipoLogr.substr(0, 2) == "SE") {
        TipoLogr = "SERVIDAO";
      }

      if (TipoLogr.substr(0, 2) == "TR") {
        TipoLogr = "TRAVESSA";
      }

      this.inputdados.ctipolog = TipoLogr;
    }

    if (
      (this.inputdados.logradouro == "" || this.inputdados.logradouro == "0") &&
      this.inputdados.endereco &&
      this.inputdados.endereco != ""
    ) {
      const FiltraLogr = this.inputdados.endereco.split(", ")[0];
      const Logradouro = FiltraLogr.split(" ");
      let LogradouroLimpo = "";

      for (let i = 0; i < Logradouro.length; i++) {
        if (i != 0) {
          LogradouroLimpo += Logradouro[i] + " ";
        }
      }

      this.inputdados.logradouro = LogradouroLimpo;
    }

    this.CadastraNovoEndereco = status;
  }

  cancelarNovoEndereco() {
    this.CadastraNovoEndereco = false;
    this.inputdados.cep = this.cepAntigo;
  }

  salvarNovoEndereco() {
    const LOCALIDADE = {
      uf: this.inputdados.cuf,
      localidade: this.inputdados.municipio,
      bairro: this.inputdados.cbairro,
      ctipolog: this.inputdados.ctipolog,
      logradouro: this.inputdados.logradouro,
      cep: this.inputdados.cep,
      complemento: this.inputdados.complemento,
    };

    if (LOCALIDADE.uf == "") {
      Swal.fire("Novo endereço", "Por favor, informe um estado.", "error");
      return;
    }

    if (LOCALIDADE.localidade == "") {
      Swal.fire("Novo endereço", "Por favor, informe um municipio.", "error");
      return;
    }

    if (LOCALIDADE.bairro == "") {
      Swal.fire("Novo endereço", "Por favor, informe um bairro.", "error");
      return;
    }

    if (LOCALIDADE.ctipolog == "") {
      Swal.fire(
        "Novo endereço",
        "Por favor, informe um tipo de logradouro.",
        "error"
      );
      return;
    }

    if (LOCALIDADE.logradouro == "") {
      Swal.fire("Novo endereço", "Por favor, informe um logradouro.", "error");
      return;
    }

    if (LOCALIDADE.cep == "") {
      Swal.fire("Novo endereço", "Por favor, informe um CEP.", "error");
      return;
    }

    Swal.fire({
      title: "Novo endereço",
      text: "Cadastrar o novo endereço?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, cadastrar!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.localidadeService.setNovoEndereco(LOCALIDADE);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          if (result.value.success) {
            this.inputdados.cuf = result.value.localidade.cuf;
            this.inputdados.idmun = result.value.localidade.idmun;
            this.inputdados.idbai = result.value.localidade.idbai;
            this.inputdados.ctipolog = result.value.localidade.ctipolog;
            this.inputdados.idlogr = result.value.localidade.idlogr;

            Swal.fire(
              "Novo endereço",
              "Endereço cadastrado com sucesso!",
              "success"
            ).then(() => {
              this.CadastraNovoEndereco = false;

              this.conteudoCarregadoLocalidade = true;
              this.conteudoCarregadoBairro = false;
              this.conteudoCarregadoTipoLog = false;
              this.conteudoCarregadoLog = false;
              this.conteudoCarregadoCep = true;

              this.getLocalidade(this.inputdados.cuf);
              this.getBairro(this.inputdados.idmun);
              this.getTipoLog(this.inputdados.idbai);
              this.getLog(this.inputdados.idbai, this.inputdados.ctipolog);
            });
          } else {
            Swal.fire("Novo endereço", result.value.message, "error");
          }
        }
      }
    });
  }

  carregarLocalidade(estado: any): void {
    this.inputdados.idmun = "0";

    this.inputdados.idbai = "0";
    this.inputdados.ctipolog = "0";
    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";
    this.inputdados.cuf = estado;

    this.bairros = [];
    this.ctipolog = [];
    this.log = [];

    if (estado != "0") {
      this.getLocalidade(estado);
    } else {
      this.localidades = [];
    }
  }

  carregarBairro(localidade: any): void {
    this.inputdados.idbai = "0";

    this.inputdados.ctipolog = "0";
    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";

    this.ctipolog = [];
    this.log = [];

    if (localidade != "0") {
      this.getBairro(localidade);
    } else {
      this.bairros = [];
    }
  }

  carregarTipoLog(bairro: any): void {
    this.inputdados.ctipolog = "0";

    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";

    this.log = [];

    if (bairro != "0") {
      this.getTipoLog(bairro);
    } else {
      this.ctipolog = [];
    }
  }

  carregarLog(bairro: any, tipolog: any): void {
    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";

    if (bairro != "0" && tipolog != "0") {
      this.getLog(bairro, tipolog);
    } else {
      this.log = [];
    }
  }

  selecionarCep(log: any): void {
    this.inputdados.cep = "";

    if (log != "") {
      this.getCep(log);
    }
  }

  getLocalidade(estado: any): void {
    this.conteudoCarregadoLocalidade = false;

    this.localidadeService.getLocalidades(estado).subscribe((response) => {
      if (response.success) {
        this.localidades = response.localidades;
        this.localidades.forEach((element) => {
          element.label = element.cnomemun;
        });
      }

      this.conteudoCarregadoLocalidade = true;
    });
  }

  getBairro(localidade: any): void {
    this.conteudoCarregadoBairro = false;

    this.localidadeService.getBairros(localidade).subscribe((response) => {
      if (response.success) {
        this.bairros = response.bairros;
        this.bairros.forEach((element) => {
          element.label = element.cnomebai;
        });
      }

      this.conteudoCarregadoBairro = true;
    });
  }

  getTipoLog(bairro: any): void {
    this.conteudoCarregadoTipoLog = false;

    this.localidadeService.getTiposLog(bairro).subscribe((response) => {
      if (response.success) {
        this.ctipolog = response.ctipolog;
        this.ctipolog.forEach((element) => {
          element.label = element.ctipolog;
        });
      }

      this.conteudoCarregadoTipoLog = true;
    });
  }

  getLog(bairro: any, tipolog: any): void {
    this.conteudoCarregadoLog = false;

    this.localidadeService.getLog(bairro, tipolog).subscribe((response) => {
      if (response.success) {
        this.log = response.log;
        this.log.forEach((element) => {
          element.label = element.cnomecompleto;
        });
      }

      this.conteudoCarregadoLog = true;
    });
  }

  getLoge(idlogr: any): void {
    this.conteudoCarregadoLog = false;

    this.localidadeService.getLoge(idlogr).subscribe((response) => {
      if (response.success) {
        this.inputdados.cuf = response.log.cuf;
        this.inputdados.idmun = response.log.idmun;
        this.inputdados.idbai = response.log.idbai;
        this.inputdados.ctipolog = response.log.ctipolog.trim();
        this.inputdados.cep = response.log.cep;

        this.getLocalidade(this.inputdados.cuf);
        this.getBairro(this.inputdados.idmun);
        this.getTipoLog(this.inputdados.idbai);
        this.getLog(this.inputdados.idbai, this.inputdados.ctipolog);
        // this.getGeoLocalizacao(response.log.cuf, response.log.cnomemun, response.log.cnomebai, response.log.ctipolog + '+' + response.log.cnomelog);
      }
    });
  }

  getCep(log: any): void {
    this.conteudoCarregadoCep = false;

    this.localidadeService.getCep(log).subscribe((response) => {
      if (response.success) {
        this.inputdados.cep = response.cep[0].cep;
      }

      this.conteudoCarregadoCep = true;
    });
  }

  getIdsLocalizacao(UF: any, LOCALIDADE: any, BAIRRO: any, ENDERECO: any) {
    this.getLocalidade(UF);

    this.localidadeService
      .getMunicipioNome(LOCALIDADE, UF)
      .subscribe((response) => {
        if (response.localidades.length > 0) {
          this.inputdados.idmun = response.localidades[0].idmun;

          this.localidadeService
            .getBairros(this.inputdados.idmun)
            .subscribe((response1) => {
              if (response1.success) {
                this.bairros = response1.bairros;
                this.bairros.forEach((element) => {
                  element.label = element.cnomebai;
                });

                this.conteudoCarregadoBairro = true;

                if (response1.bairros.length > 0) {
                  const bairro = this.bairros.filter(
                    (item) => item.cnomebai.trim() == BAIRRO.toString().trim()
                  );

                  if (bairro && bairro.length > 0) {
                    this.inputdados.idbai = bairro[0].idbai;
                  } else {
                    this.inputdados.idbai = response1.bairros[0].idbai;
                  }

                  this.getTipoLog(this.inputdados.idbai);

                  let TipoLogr = ENDERECO.split(" ")[0];

                  if (TipoLogr.substr(0, 2) == "RO") {
                    TipoLogr = "RODOVIA";
                  }

                  if (TipoLogr.substr(0, 2) == "AV") {
                    TipoLogr = "AVENIDA";
                  }

                  if (TipoLogr.substr(0, 2) == "RU") {
                    TipoLogr = "RUA";
                  }

                  if (TipoLogr.substr(0, 2) == "PR") {
                    TipoLogr = "PRACA";
                  }

                  if (TipoLogr.substr(0, 2) == "SE") {
                    TipoLogr = "SERVIDAO";
                  }

                  if (TipoLogr.substr(0, 2) == "TR") {
                    TipoLogr = "TRAVESSA";
                  }

                  this.inputdados.ctipolog = TipoLogr;
                  this.getLog(this.inputdados.idbai, TipoLogr);

                  const FiltraLogr = ENDERECO.split(", ")[0];
                  const Logradouro = FiltraLogr.split(" ");
                  let LogradouroLimpo = "";

                  for (let i = 0; i < Logradouro.length; i++) {
                    if (i != 0) {
                      LogradouroLimpo += Logradouro[i] + " ";
                    }
                  }

                  this.inputdados.logradouro = LogradouroLimpo;

                  const Datalog = {
                    logr: LogradouroLimpo,
                    idbai: this.inputdados.idbai,
                    cep: this.inputdados.cep,
                  };

                  this.localidadeService
                    .getLoge(Datalog)
                    .subscribe((response2) => {
                      if (response2.success) {
                        this.inputdados.idlogr = response2.log.idlogr;
                      }
                    });
                } else {
                  this.inputdados.idbai = "0";
                  this.inputdados.ctipolog = "0";
                  this.inputdados.idlogr = "0";

                  this.conteudoCarregadoTipoLog = true;
                  this.conteudoCarregadoLog = true;
                }
              } else {
                this.inputdados.idbai = "0";
                this.inputdados.ctipolog = "0";
                this.inputdados.idlogr = "0";
              }
            });
        } else {
          this.inputdados.idmun = "0";
          this.inputdados.idbai = "0";
          this.inputdados.ctipolog = "0";
          this.inputdados.idlogr = "0";
          this.conteudoCarregadoBairro = true;
          this.conteudoCarregadoTipoLog = true;
          this.conteudoCarregadoLog = true;
        }
      });
  }
}
