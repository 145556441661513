import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filtrarVeiculos",
})
export class FiltrarVeiculosPipe implements PipeTransform {
  transform(items: any[], args: any, args2: any): any {
    return items.filter((item: any, key: any) => {
      if (args != "") {
        const argsUpper = args.toUpperCase();
        const minhaPlaca = item.placa.toUpperCase();
        return minhaPlaca.indexOf(argsUpper) != -1;
      }

      if (args2 != "") {
        return item.lrastreio == (args2 == 0 ? true : false);
      }

      return item;
    });
  }
}
