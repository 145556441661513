<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="col-3">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Informações Principais">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col-auto">
              <img src="assets/img/user-photo-default.png" />
            </div>
            <div class="col d-flex flex-column">
              <span class="h6">Nome:</span>
              <span class="text-primary fw-bold h6">{{ motorista.cnome }}</span>
            </div>
          </div>
          <div class="row pt-3 pb-2">
            <div class="col text-end">
              <span class="h6">Situação:</span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-success': motorista.status == 'Ativo',
                'text-danger': motorista.status != 'Ativo'
              }"
            >
              <span class="h6 fw-bold">{{ motorista.status }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Código:</span>
            </div>
            <div class="col">
              <span class="h6 fw-bold">{{ motorista.codmotorista }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">CPF:</span>
            </div>
            <div class="col">
              <span class="h6 fw-bold">{{ motorista.cpf | cgc }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Sexo:</span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-primary': motorista.sexo != 'N/I',
                'text-danger': motorista.sexo == 'N/I' || !motorista.sexo
              }"
            >
              <span class="h6 fw-bold">{{
                motorista.sexo ? motorista.sexo : "N/I"
              }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Estado civil:</span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-primary': motorista.estcivil != 'N/I',
                'text-danger':
                  motorista.estcivil == 'N/I' || !motorista.estcivil
              }"
            >
              <span class="h6 fw-bold">{{
                motorista.estcivil ? motorista.estcivil : "N/I"
              }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Data de Nascimento:</span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-primary': motorista.nascimento != 'N/I',
                'text-danger':
                  motorista.nascimento == 'N/I' || !motorista.nascimento
              }"
            >
              <span class="h6 fw-bold">{{
                motorista.nascimento ? motorista.nascimento : "N/I"
              }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Email:</span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-primary': motorista.cemail != 'N/I',
                'text-danger': motorista.cemail == 'N/I' || !motorista.cemail
              }"
            >
              <span class="h6 fw-bold">{{
                motorista.cemail ? motorista.cemail : "N/I"
              }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Celular:</span>
            </div>
            <div
              class="col"
              [ngClass]="{
                'text-primary': motorista.ccelular != 'N/I',
                'text-danger':
                  motorista.ccelular == 'N/I' || !motorista.ccelular
              }"
            >
              <span class="h6 fw-bold">{{
                motorista.ccelular
                  ? (motorista.ccelular
                    | mask : "(00) 0000-0000||(00) 0 0000-0000")
                  : "N/I"
              }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Dirige Carga Perigosa?</span>
            </div>
            <div class="col text-primary">
              <span class="h6 fw-bold">{{ motorista.cargaperigosa }}</span>
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">
                Ver todos veiculos não vinculados no app?
              </span>
            </div>
            <div class="col text-primary">
              <span
                class="h6 fw-bold"
                *ngIf="motorista.lveiculosvinculados == '0'"
                >Sim</span
              >
              <span
                class="h6 fw-bold"
                *ngIf="motorista.lveiculosvinculados == '1'"
                >Não</span
              >
            </div>
          </div>
          <div class="row pb-2 align-items-center">
            <div class="col text-end">
              <span class="h6">Centro de Custo</span>
            </div>
            <div class="col">
              <span
                class="text-primary fw-bold h6"
                [hidden]="
                  motorista.centrocusto == '' || motorista.centrocusto == null
                "
              >
                {{ motorista.centrocusto }}
              </span>
              <span
                class="text-danger fw-bold h6"
                [hidden]="
                  motorista.centrocusto != '' && motorista.centrocusto != null
                "
              >
                N/I
              </span>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="col">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Documentos">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-header bg-secondary text-white h5">CNH</div>
                <div class="card-body">
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Número:</span>
                    </div>
                    <div class="col text-primary">
                      <span class="h6 fw-bold">{{ motorista.ccartmot }}</span>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Categoria:</span>
                    </div>
                    <div class="col text-primary">
                      <span class="h6 fw-bold">{{ motorista.classe }}</span>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Espelho:</span>
                    </div>
                    <div class="col">
                      <span
                        class="h6 fw-bold text-primary"
                        [hidden]="
                          motorista.ccartmotespelho == '' ||
                          motorista.ccartmotespelho == null
                        "
                      >
                        {{ motorista.ccartmotespelho }}
                      </span>
                      <div
                        class="text-danger fw-bold h6"
                        [hidden]="
                          motorista.ccartmotespelho != '' &&
                          motorista.ccartmotespelho != null
                        "
                      >
                        N/I
                      </div>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Data de Expedição:</span>
                    </div>
                    <div class="col text-primary">
                      <span class="h6 fw-bold">{{ motorista.dcartmotC }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-end">
                      <span class="h6">Data de Validade:</span>
                    </div>
                    <div class="col text-primary">
                      <span class="h6 fw-bold">{{
                        motorista.dcartmotvenctoC
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="motorista.fileData && motorista.fileName != ''"
                  >
                    <div class="col text-end">
                      <span class="h6">Download Documento</span>
                    </div>
                    <div class="col">
                      <button
                        class="btn btn-success"
                        (click)="
                          abrirAnexo(motorista.fileName, motorista.fileData, '')
                        "
                      >
                        CNH
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-header bg-secondary text-white h5">RG</div>

                <div class="card-body">
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Número:</span>
                    </div>
                    <div class="col">
                      <span
                        class="h6 fw-bold text-primary"
                        [hidden]="motorista.crg == '' || motorista.crg == null"
                      >
                        {{ motorista.crg }}
                      </span>
                      <span
                        class="h6 fw-bold text-danger"
                        [hidden]="motorista.crg != '' && motorista.crg != null"
                      >
                        N/I
                      </span>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Órgão Emissor:</span>
                    </div>
                    <div class="col">
                      <span
                        class="h6 fw-bold text-primary"
                        [hidden]="
                          motorista.crgorgao == '' || motorista.crgorgao == null
                        "
                      >
                        {{ motorista.crgorgao }}
                      </span>
                      <span
                        class="h6 fw-bold text-danger"
                        [hidden]="
                          motorista.crgorgao != '' && motorista.crgorgao != null
                        "
                      >
                        N/I
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-end">
                      <span class="h6">Data de Emissão:</span>
                    </div>
                    <div
                      class="col"
                      [ngClass]="{
                        'text-primary': motorista.rgemissao != 'N/I',
                        'text-danger':
                          motorista.rgemissao == 'N/I' || !motorista.rgemissao
                      }"
                    >
                      <span class="h6 fw-bold">{{
                        motorista.rgemissao ? motorista.rgemissao : "N/I"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-6">
              <div class="card">
                <div class="card-header bg-secondary text-white h5">CBO</div>

                <div class="card-body">
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Número CBO:</span>
                    </div>
                    <div class="col">
                      <span
                        class="h6 fw-bold text-primary"
                        [hidden]="
                          motorista.nrcbo == '' || motorista.nrcbo == null
                        "
                      >
                        {{ motorista.nrcbo }}
                      </span>
                      <span
                        class="h6 fw-bold text-danger"
                        [hidden]="
                          motorista.nrcbo != '' && motorista.nrcbo != null
                        "
                      >
                        N/I
                      </span>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Categoria:</span>
                    </div>
                    <div class="col">
                      <span
                        class="text-primary fw-bold h6"
                        [hidden]="
                          motorista.nrcategoria == '' ||
                          motorista.nrcategoria == null
                        "
                      >
                        {{ motorista.nrcategoria }}
                      </span>
                      <span
                        class="text-danger fw-bold h6"
                        [hidden]="
                          motorista.nrcategoria != '' &&
                          motorista.nrcategoria != null
                        "
                      >
                        N/I
                      </span>
                    </div>
                  </div>
                  <div class="row pb-2">
                    <div class="col text-end">
                      <span class="h6">Doc. Identidade:</span>
                    </div>
                    <div
                      class="col"
                      [ngClass]="{
                        'text-primary': motorista.nrdocidentidade != 'N/I',
                        'text-danger':
                          motorista.nrdocidentidade == 'N/I' ||
                          !motorista.nrdocidentidade
                      }"
                    >
                      <span class="fw-bold h6">{{
                        motorista.nrdocidentidade
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-end">
                      <span class="h6">Validade:</span>
                    </div>
                    <div
                      class="col"
                      [ngClass]="{
                        'text-primary': motorista.nrdvalidade != 'N/I',
                        'text-danger':
                          motorista.nrdvalidade == 'N/I' ||
                          !motorista.nrdvalidade
                      }"
                    >
                      <span class="fw-bold h6">
                        {{
                          motorista.nrdvalidade | formatDate : "DD/MM/YYYY STR"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Transações">
        <div class="container-fluid pt-3">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th class="text-center">Data</th>
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Produto</th>
                  <th class="text-center">Qtde</th>
                  <th class="text-center">Valor</th>
                  <th class="text-center">Veiculo</th>
                  <th class="text-center">Cartão</th>
                  <th class="text-center">Credenciado</th>
                  <th class="text-center">Endereço</th>
                  <th class="text-center">Transação</th>
                  <th class="text-center">Odometro</th>
                  <th class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trans2 of arrTransacoesMotoristas">
                  <td align="center">
                    {{ trans2.dcadasFormat }}
                  </td>
                  <td align="center">
                    <button
                      class="btn btn-success rounded-circle btn-shadow btn-icon"
                      *ngIf="trans2.idtipo == 1"
                      title="Aceita sem Inconsistencia"
                    >
                      A
                    </button>
                    <button
                      class="btn btn-primary rounded-circle btn-shadow btn-icon"
                      *ngIf="trans2.idtipo == 2"
                      title="Aceita com Inconsistencia"
                    >
                      A
                    </button>
                    <button
                      class="btn btn-warning rounded-circle btn-shadow btn-icon"
                      *ngIf="trans2.idtipo == 3"
                      title="Aceita com liberação de restrição"
                    >
                      A
                    </button>
                    <button
                      class="btn btn-danger rounded-circle btn-shadow btn-icon"
                      *ngIf="trans2.idtipo == 4"
                      title="Rejeitada"
                    >
                      R
                    </button>
                  </td>
                  <td align="center">
                    {{ trans2.cproduto }}
                  </td>
                  <td align="center">
                    {{ trans2.nqtd | number : "1.2-2" }}
                  </td>
                  <td align="center">
                    {{ trans2.valortrans | real }}
                  </td>
                  <td align="center">
                    {{ trans2.placa }}
                  </td>
                  <td align="center">
                    {{ trans2.tr_ncartao | cartao : 2 }}
                  </td>
                  <td align="center">
                    {{ trans2.credenciado }}
                  </td>
                  <td align="center">
                    {{ trans2.endcre }}
                  </td>
                  <td align="center">
                    {{ trans2.idtrans }}
                  </td>
                  <td align="center">
                    {{ trans2.tr_odometro }}
                  </td>
                  <td align="center">
                    <button
                      type="button"
                      class="btn btn-info btn-sm me-1"
                      title="Visualizar"
                      (click)="
                        abrirModalTransacao(trans2.idtrans, trans2.idtipo)
                      "
                    >
                      <i class="fa-solid fa-eye fa-fw"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Logs de alteração">
        <div class="container-fluid pt-3">
          <div class="row">
            <div class="col">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Data de Criação</th>
                    <th>Criado Por</th>
                    <th>Observações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ creationDetails.dataCadastro | date : "dd/MM/YYYY HH:mm:ss" }}</td>
                    <td>{{ creationDetails.nomeUsuario }}</td>
                    <td>{{ creationDetails.observacaoCadastro }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="text-start">Dada da alteração</th>
                    <th class="text-start">Alteração</th>
                    <th class="text-start">Observação</th>
                    <th class="text-start">Alterado por:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let log of logs">
                    <td>
                      {{ log.dcadas | formatDate : "DD/MM/YYYY HH:mm STR" }}
                    </td>
                    <td>
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th class="text-start">Campo</th>
                            <th class="text-start">Antes</th>
                            <th class="text-start">Depois</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let dado of log.diferencas">
                            <td>
                              {{
                                driverFields[dado.campo]?.label ?? dado.campo
                              }}
                            </td>
                            <ng-container
                              *ngIf="driverFields[dado.campo]?.options"
                            >
                              <td>
                                {{
                                  driverFields[dado.campo].options[
                                    dado.antes
                                  ] ?? ""
                                }}
                              </td>
                              <td>
                                {{
                                  driverFields[dado.campo].options[
                                    dado.depois
                                  ] ?? ""
                                }}
                              </td>
                            </ng-container>
                            <ng-container
                              *ngIf="!driverFields[dado.campo]?.options"
                            >
                              <td>
                                {{
                                  isDateField(dado.campo)
                                    ? (dado.antes | date : "dd/MM/yyyy")
                                    : dado.antes
                                }}
                              </td>
                              <td>
                                {{
                                  isDateField(dado.campo)
                                    ? (dado.depois | date : "dd/MM/yyyy")
                                    : dado.depois
                                }}
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      {{ log.observacao }}
                    </td>
                    <td>
                      {{ log.nome }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<app-base-modal
  modalTitle="Dados da Transação"
  (closeModal)="showModal = false"
  *ngIf="showModal"
>
  <ng-container modal-body>
    <visualizar-transacoes
      *ngIf="vizualizarTransacao"
      [modal]="1"
      [id]="idEsp"
      [tipo]="tipoEsp"
    ></visualizar-transacoes>
  </ng-container>
</app-base-modal>
