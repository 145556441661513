<app-base-report
  [reportData]="dadosRelatorio"
  [reportPath]="caminhoRelatorio"
  [contentLoaded]="conteudoCarregado"
  [loadedReport]="carregaRelatorio"
  (onGoBack)="voltar()"
  (onQueryData)="consultarDados()"
>
  <ng-container title>RELATÓRIO DE CARTA DE CIRCULARIZAÇÃO</ng-container>
  <ng-container filters>
    <div class="row pb-2">
      <div class="col">
        <label class="form-label">Período da Transação de:</label>
        <input type="date" class="form-control" [(ngModel)]="filtro.dataDe" />
      </div>
      <div class="col">
        <label class="form-label">Período da Transação até:</label>
        <input type="date" class="form-control" [(ngModel)]="filtro.dataAte" />
      </div>
      <div class="col">
        <label class="form-label">Tipo de Data:</label>
        <app-search-select
          value="id"
          [arrToFilter]="[
            { id: 0, label: 'Data de Pagamento' },
            { id: 1, label: 'Data de Fechamento' }
          ]"
          [(ngValue)]="filtro.tipoData"
        ></app-search-select>
      </div>
    </div>
    <div class="row">
      <div class="col" *ngIf="this.tipoUsuario == 1">
        <label class="form-label">Módulo:</label>
        <app-search-select
          value="id"
          [arrToFilter]="[
            { id: 1, label: 'Abastecimento' },
            { id: 2, label: 'Manutenção' }
          ]"
          [(ngValue)]="filtro.modulo"
        ></app-search-select>
      </div>
      <div
        class="col"
        *ngIf="usuario.qntdCentralizadora != 0 || tipoUsuario == 1"
      >
        <label class="form-label">Tipo de Consulta Centralizadora:</label>
        <app-search-select
          value="id"
          [arrToFilter]="[
            { id: 0, label: 'Não' },
            { id: 1, label: 'Sim' }
          ]"
          [(ngValue)]="filtro.tipoCentralizadora"
        ></app-search-select>
      </div>
      <div
        class="col"
        *ngIf="usuario.qntdCentralizadora != 0 || tipoUsuario == 1"
      >
        <label class="form-label">Cód.TN Credenciado:</label>
        <input
          type="text"
          class="form-control"
          mask="000000"
          [disabled]="filtro.tipoCentralizadora"
          [(ngModel)]="filtro.codigoCentralizadoEscolhido"
        />
      </div>
    </div>
  </ng-container>
</app-base-report>
