<div class="row">
  <div class="col-6 m-b-15">
    <line-char-data-cliente [modulo]="modulo"></line-char-data-cliente>
  </div>
  <div class="col-6 m-b-15">
    <line-char-data-credenciado [modulo]="modulo"></line-char-data-credenciado>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 m-b-15">
    <line-char-data-veiculos [modulo]="modulo"></line-char-data-veiculos>
  </div>
  <div class="col-lg-6 m-b-15">
    <line-char-data-transacao [modulo]="modulo"></line-char-data-transacao>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 m-b-15">
    <line-char-data-consumo [modulo]="modulo"></line-char-data-consumo>
  </div>
  <div class="col-lg-6 m-b-15">
    <line-char-data-consveiculo [modulo]="modulo"></line-char-data-consveiculo>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 m-b-15">
    <line-char-data-taxa [modulo]="modulo"></line-char-data-taxa>
  </div>
  <div class="col-lg-6 m-b-15">
    <line-char-data-tgeral [modulo]="modulo"></line-char-data-tgeral>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 m-b-15">
    <line-char-data-qntdveiculos></line-char-data-qntdveiculos>
  </div>
  <div class="col-lg-6 m-b-15">
    <line-char-data-ticketmedio></line-char-data-ticketmedio>
  </div>
</div>
