<div class="card">
  <div class="card-header bg-primary d-flex flex-column">
    <span class="h5 text-white">Ficha Cadastral</span>
    <small class="text-white">
      Informações Cadastrais do Documento do Veículo
    </small>
  </div>
  <div class="card-body card-padding">
    <div class="row mb-2">
      <div class="col">
        Placa:
        <span class="text-primary">
          {{ vehicleData.plate ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        VIN:
        <span class="text-primary">
          {{ vehicleData.chassis ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Número do Motor:
        <span class="text-primary">
          {{ vehicleData.engineNumber ?? "Não Informado" }}
        </span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        Número da Cx. Câmbio:
        <span class="text-primary">
          {{ vehicleData.gearBoxNumber ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Cilindradas:
        <span class="text-primary">
          {{ vehicleData.cc ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Combustível:
        <span class="text-primary">
          {{ vehicleData.fuel ?? "Não Informado" }}
        </span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        Segmento:
        <span class="text-primary">
          {{ vehicleData.type ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Subsegmento:
        <span class="text-primary">
          {{ vehicleData.species ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Carroceria:
        <span class="text-primary">
          {{ vehicleData.bodywork ?? "Não Informado" }}
        </span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        Potência:
        <span class="text-primary">
          {{ vehicleData.power ? vehicleData.power + " CV" : "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Quantidade de Acentos:
        <span class="text-primary">
          {{ vehicleData.seatCount ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Procedência:
        <span class="text-primary">
          {{ vehicleData.isNational === true ? "Nacional" : "Importado" }}
        </span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        Peso bruto:
        <span class="text-primary">
          {{
            vehicleData.totalGrossWeight
              ? vehicleData.totalGrossWeight + " Km"
              : "Não Informado"
          }}
        </span>
      </div>
      <div class="col">
        Eixo Dianteiro:
        <span class="text-primary">
          {{ vehicleData.axisCount ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Eixo Traseiro:
        <span class="text-primary">
          {{ vehicleData.backAxisCount ?? "Não Informado" }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        Capacidade de Carga:
        <span class="text-primary">
          {{
            vehicleData.loadCapacity
              ? vehicleData.loadCapacity + " Kg"
              : "Não Informado"
          }}
        </span>
      </div>
      <div class="col-8">
        Eixos Auxiliares:
        <span class="text-primary">
          {{ vehicleData.auxAxisCount ?? "Não Informado" }}
        </span>
      </div>
    </div>
  </div>
</div>
