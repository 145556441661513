<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" [hidden]="!conteudoCarregado">
  <!-- INFORMAÇÕES DO VEICULO QUANDO O NIVEL É O DELE -->
  <div *ngIf="isVehicleRules" class="col-3">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Identificação">
        <div class="container-fluid pt-3">
          <div class="card">
            <div class="card-header bg-secondary h5 text-white">
              Informações Principais
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  <img
                    src="assets/img/{{ veiculo.imagensVeiculo }}"
                    style="width: 70px"
                  />
                </div>
                <div class="col fw-bold">
                  <small class="fw-normal">Placa/Município/UF:</small>
                  <br />
                  {{ veiculo.placa }} <br />
                  {{ veiculo.cnomemun }}/{{ veiculo.cuf }}
                </div>
              </div>
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Situação: </small>
                  {{ veiculo.situacaoVeiculo }}
                </div>
                <div class="col fw-bold">
                  <small class="fw-normal">Família: </small>
                  {{ veiculo.FamiliaNome }}
                </div>
              </div>
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Marca: </small>
                  {{ veiculo.MarcaNome }}
                </div>
                <div class="col fw-bold">
                  <small class="fw-normal">Modelo: </small>
                  {{ veiculo.ModeloNome }}
                </div>
              </div>
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Ano de Fabricação: </small>
                  {{ veiculo.anofabr }}
                </div>
                <div class="col fw-bold">
                  <small class="fw-normal">Ano do Modelo: </small
                  >{{ veiculo.ano }}
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header bg-secondary h5 text-white">
              Outras Informações
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Cor: </small>
                  {{ veiculo.VeiculoCor }}
                </div>
                <div class="col fw-bold">
                  <small class="fw-normal">Renavam: </small>
                  {{ veiculo.renavam }}
                </div>
              </div>
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Chassi: </small>{{ veiculo.chassi }}
                </div>
                <div class="col fw-bold">
                  <small class="fw-normal">Vencimento IPVA: </small
                  >{{
                    veiculo.mesvenctoipva
                      ? veiculo.mesvenctoipva
                      : veiculo.dvenctoipva
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Tipo de Frota: </small
                  >{{ veiculo.FrotaTipoNome }}
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header bg-secondary h5 text-white">Empresa</div>
            <div class="card-body">
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Centro de Custo: </small
                  >{{ veiculo.CentroCustoNome }}
                </div>
              </div>
              <div class="row">
                <div class="col fw-bold">
                  <small class="fw-normal">Departamento: </small
                  >{{ veiculo.SetorNome }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <!-- FIM INFORMAÇÕES -->
  <div
    [ngClass]="{
      'col-9': isVehicleRules,
      col: !isVehicleRules,
    }"
  >
    <div class="row">
      <div class="col">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
          <mat-tab label="Regras de Produtos">
            <div class="container-fluid pt-3">
              <div class="card">
                <div class="card-header bg-secondary h5 text-white">
                  Grupo: Combustíveis
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-auto d-flex flex-column align-items-center">
                      <span class="h5 mb-3">Combustivel</span>
                      <img
                        src="assets/img/gasolina1.png"
                        width="50"
                        class="mb-2"
                      />
                      <a
                        href="javascript:void(0)"
                        (click)="ativarTodosCombustiveis(lTodosCombustiveis)"
                        *ngIf="alterarDados"
                        class="btn btn-light"
                      >
                        Marcar todos
                      </a>
                    </div>
                    <div class="col">
                      <div class="row pt-2">
                        <div
                          class="col-3 pt-2"
                          *ngFor="let prod of arrProdutos | filterProdutos: '1'"
                        >
                          <mat-checkbox
                            color="primary"
                            [(ngModel)]="prod.status"
                            class="me-2"
                            [disabled]="prod.desable || !alterarDados"
                          >
                            {{ prod.codtn }} - {{ prod.cnome }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-3">
                <div class="card-header bg-secondary h5 text-white">
                  Grupo: Não Combustíveis
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-auto d-flex flex-column align-items-center">
                      <span class="h5 mb-3">Óleos</span>
                      <img src="assets/img/oleo4.jpg" width="50" class="mb-2" />
                      <a
                        href="javascript:void(0)"
                        (click)="ativarTodosOleo(lTodosOleo)"
                        *ngIf="alterarDados"
                        class="btn btn-light"
                      >
                        Marcar todos
                      </a>
                    </div>
                    <div class="col">
                      <div
                        class="row pt-2"
                        *ngFor="let prod of arrProdutos | filterProdutos: '2'"
                      >
                        <div class="col">
                          <mat-checkbox
                            color="primary"
                            [(ngModel)]="prod.status"
                            class="me-2"
                            [disabled]="prod.desable || !alterarDados"
                          >
                            {{ prod.codtn }} - {{ prod.cnome }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-flex flex-column align-items-center">
                      <span class="h5 mb-3">Filtros</span>
                      <img
                        src="assets/img/filtro1.jpg"
                        width="50"
                        class="mb-2"
                      />
                      <a
                        href="javascript:void(0)"
                        (click)="ativarTodosFiltro(lTodosFiltro)"
                        *ngIf="alterarDados"
                        class="btn btn-light"
                      >
                        Marcar todos
                      </a>
                    </div>
                    <div class="col">
                      <div
                        class="row pt-2"
                        *ngFor="let prod of arrProdutos | filterProdutos: '3'"
                      >
                        <div class="col">
                          <mat-checkbox
                            color="primary"
                            [(ngModel)]="prod.status"
                            class="me-2"
                            [disabled]="prod.desable || !alterarDados"
                          >
                            {{ prod.codtn }} - {{ prod.cnome }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-5">
                    <div class="col-auto d-flex flex-column align-items-center">
                      <span class="h5 mb-3">Outros</span>
                      <img
                        src="assets/img/oficina2.jpg"
                        width="50"
                        class="mb-2"
                      />
                      <a
                        href="javascript:void(0)"
                        (click)="ativarTodosOutros(lTodosOutros)"
                        *ngIf="alterarDados"
                        class="btn btn-light"
                      >
                        Marcar todos
                      </a>
                    </div>
                    <div class="col">
                      <div
                        class="row pt-2"
                        *ngFor="let prod of arrProdutos | filterProdutos: '4'"
                      >
                        <div class="col">
                          <mat-checkbox
                            color="primary"
                            [(ngModel)]="prod.status"
                            class="me-2"
                            [disabled]="prod.desable || !alterarDados"
                          >
                            {{ prod.codtn }} - {{ prod.cnome }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-flex flex-column align-items-center">
                      <span class="h5 mb-3">Serviços</span>
                      <img
                        src="assets/img/oficina2.jpg"
                        width="50"
                        class="mb-2"
                      />
                      <a
                        href="javascript:void(0)"
                        (click)="ativarTodosServicos(lTodosServicos)"
                        *ngIf="alterarDados"
                        class="btn btn-light"
                      >
                        Marcar todos
                      </a>
                    </div>
                    <div class="col">
                      <div
                        class="row pt-2"
                        *ngFor="let prod of arrProdutos | filterProdutos: '5'"
                      >
                        <div class="col">
                          <mat-checkbox
                            color="primary"
                            [(ngModel)]="prod.status"
                            class="me-2"
                            [disabled]="prod.desable || !alterarDados"
                          >
                            {{ prod.codtn }} - {{ prod.cnome }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-3">
                <div class="card-header bg-secondary h5 text-white">
                  Grupo: Serviços Diversos
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-auto d-flex flex-column align-items-center">
                      <span class="h5 mb-3">Serviços Diversos</span>
                      <img src="assets/img/oleo4.jpg" width="50" class="mb-2" />
                      <a
                        href="javascript:void(0)"
                        (click)="ativarTodosServicosDiversos(lTodosServiDiv)"
                        *ngIf="alterarDados"
                        class="btn btn-light"
                      >
                        Marcar todos
                      </a>
                    </div>
                    <div class="col">
                      <div
                        class="row pt-2"
                        *ngFor="let prod of arrProdutos | filterProdutos: '8'"
                      >
                        <div class="col">
                          <mat-checkbox
                            color="primary"
                            [(ngModel)]="prod.status"
                            class="me-2"
                            [disabled]="prod.desable || !alterarDados"
                          >
                            {{ prod.codtn }} - {{ prod.cnome }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Regras de Localização">
            <div class="container-fluid pt-3">
              <div class="card">
                <div class="card-header bg-secondary h5 text-white">
                  Regras de Localização
                </div>
                <div class="card-body">
                  <div class="row pb-2" *ngIf="alterarDados">
                    <div class="col-6" [hidden]="preloaderEstado">
                      <label class="form-label">Estados:</label>
                      <app-search-select
                        value="cuf"
                        [arrToFilter]="regrasCliente.LocalidadeRegraComboCuf"
                        [(ngValue)]="localizacao.cuf"
                        (ngValueChange)="carregarMunicipio($event)"
                      ></app-search-select>
                    </div>
                    <div class="col-6" *ngIf="preloaderEstado">
                      <mat-spinner diameter="40" class="m-auto"></mat-spinner>
                    </div>
                  </div>
                  <div class="row align-items-end" *ngIf="alterarDados">
                    <div class="col-6" [hidden]="preloaderMunicipio">
                      <label class="form-label">Municipios:</label>
                      <app-search-select
                        value="idmun"
                        [arrToFilter]="regrasCliente.ComboLocalizacaoMun"
                        [(ngValue)]="localizacao.idmun"
                      ></app-search-select>
                    </div>
                    <div class="col-6" [hidden]="preloaderMunicipio">
                      <button
                        class="btn btn-success"
                        [disabled]="localizacao.cuf == '0'"
                        (click)="verificaAddLocalidadeCliente(localizacao)"
                      >
                        <i class="fa-solid fa-check"></i>
                        Adicionar Localidade
                      </button>
                    </div>

                    <div class="col-6" *ngIf="preloaderMunicipio">
                      <mat-spinner diameter="40" class="m-auto"></mat-spinner>
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th class="text-center">Estado</th>
                            <th class="text-center">Municipio ID</th>
                            <th class="text-center">Municipio Nome</th>
                            <th style="min-width: 115px">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let loc of regrasCliente.Localizacao;
                              let i = index
                            "
                          >
                            <td align="center">
                              {{ loc.estado }}
                            </td>
                            <td align="center">
                              {{ loc.municipioId }}
                            </td>
                            <td align="center">
                              {{ loc.municipiocnome }}
                            </td>
                            <td>
                              <button
                                type="button"
                                *ngIf="alterarDados"
                                (click)="removerRegraLocalidade(i)"
                                class="btn btn-danger btn-sm me-1"
                                title="Remover Regra de Localização"
                              >
                                <i class="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Regras de Rede Por Credenciado">
            <div class="container-fluid pt-3">
              <div class="card">
                <div class="card-header bg-secondary h5 text-white">
                  Regras de Rede Por Credenciado
                </div>
                <div class="card-body">
                  <div *ngIf="isGlobalRules && alterarDados" class="row">
                    <div class="col">
                      <button
                        [disabled]="
                          (!isGlobalRules && inverterregra == '1') ||
                          !alterarDados
                        "
                        *ngIf="inverterregra == '1' && alterarDados"
                        class="btn btn-success"
                        (click)="inverterRegra(0)"
                      >
                        <i class="fa-solid fa-check"></i> RESTAURAR INVERSAO DAS
                        REGRAS DE CREDENCIADO
                      </button>

                      <button
                        [disabled]="
                          (!isGlobalRules && inverterregra == '1') ||
                          !alterarDados
                        "
                        *ngIf="inverterregra == '0' && alterarDados"
                        class="btn btn-danger"
                        (click)="inverterRegra(1)"
                      >
                        <i class="fa-solid fa-check"></i> INVERTER REGRAS DE
                        CREDENCIADO
                      </button>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      !isGlobalRules && inverterregra == '1' && alterarDados
                    "
                  >
                    <label class="text-danger"
                      >Regra de Credenciado Bloqueada devido a inversão da regra
                      nas Regras Globais.</label
                    >
                  </div>
                  <div class="row pt-2 align-items-end" *ngIf="alterarDados">
                    <div class="col-6">
                      <label class="form-label">Credenciados:</label>
                      <app-search-select
                        [disabled]="!isGlobalRules && inverterregra == '1'"
                        value="idcred"
                        [arrToFilter]="regrasCliente.LocalidadeRegraComboCred"
                        [(ngValue)]="credenciado"
                      ></app-search-select>
                    </div>
                    <div class="col-6">
                      <button
                        [disabled]="!isGlobalRules && inverterregra == '1'"
                        class="btn btn-success"
                        *ngIf="credenciado != 0"
                        (click)="adicionarCredenciado(credenciado)"
                      >
                        <i class="fa-solid fa-check"></i> Adicionar Credenciados
                      </button>
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th class="text-center">Cod. Telenet</th>
                            <th class="text-center">Credenciado</th>
                            <th style="min-width: 115px">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let cre of regrasCliente.Credenciado;
                              let p = index
                            "
                          >
                            <td align="center">
                              {{ cre.codtln }}
                            </td>
                            <td align="center">
                              {{ cre.credenciadocnome }}
                            </td>
                            <td>
                              <button
                                [disabled]="
                                  (!isGlobalRules && inverterregra == '1') ||
                                  !alterarDados
                                "
                                *ngIf="alterarDados"
                                type="button"
                                (click)="removerCredenciado(p)"
                                class="btn btn-danger btn-sm me-1"
                                title="Remover Regra de Credenciados"
                              >
                                <i class="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Regras de Restrição" *ngIf="!isClientRules">
            <div class="container-fluid pt-3">
              <div class="card">
                <div class="card-header bg-secondary h5 text-white">
                  Regras de Restrição
                </div>
                <div class="card-body">
                  <div
                    class="row pb-2"
                    [hidden]="lcadastrandoRestricao"
                    *ngIf="alterarDados"
                  >
                    <div class="col">
                      <button
                        class="btn btn-success"
                        (click)="adicionarRegraRestricaoCliente()"
                      >
                        <i class="fa-solid fa-plus"></i> Adicionar Restrição
                      </button>
                    </div>
                  </div>
                  <div class="row" [hidden]="lcadastrandoRestricao">
                    <div class="col">
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th class="text-center">Tipo Produto</th>
                            <th class="text-center">Produto</th>
                            <th class="text-center">Descrição da Regra</th>
                            <th class="text-center">Restrição Estabelecida</th>
                            <th class="text-center">Tolerância</th>
                            <th class="text-center">Restrigir</th>
                            <th style="min-width: 115px" *ngIf="alterarDados">
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let reg of regrasCliente.Restricao;
                              let p = index
                            "
                          >
                            <td align="center">
                              {{ reg.tipoProdNome }}
                            </td>
                            <td align="center">
                              {{ reg.produtoName }}
                            </td>
                            <td align="center">
                              {{ reg.DescricaoRegra }}
                            </td>
                            <td align="center">
                              <span *ngIf="reg.RegraId == 6">R$</span>
                              {{ reg.restricaoDescricao }}
                            </td>
                            <td align="center">
                              {{ reg.tolerancia }}
                              <span *ngIf="reg.RegraId == 6">%</span>
                            </td>
                            <td align="center">
                              {{ reg.restringirDescricao }}
                            </td>
                            <td *ngIf="alterarDados">
                              <button
                                type="button"
                                (click)="removerRestricao(p)"
                                title="Remover Regra de Restrição"
                                class="btn btn-danger btn-sm me-1"
                              >
                                <i class="fa-solid fa-trash fa-fw"></i>
                              </button>
                              <button
                                type="button"
                                (click)="editarRestricao(p)"
                                title="Editar Regra de Restrição"
                                class="btn btn-warning btn-sm me-1"
                              >
                                <i class="fa-solid fa-edit fa-fw"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ng-container *ngIf="lcadastrandoRestricao">
                    <div class="row pb-2">
                      <div class="col-6">
                        <label class="form-label">Tipo de produto:</label>
                        <app-search-select
                          value="id"
                          [arrToFilter]="
                            regrasCliente.ComboRestricaoTipoProdutoCliente
                          "
                          [(ngValue)]="
                            regrasCliente.RestricaoTipoProdutoCliente
                          "
                          (ngValueChange)="filtrarProdutos($event)"
                        ></app-search-select>
                      </div>
                    </div>
                    <div class="row pb-2">
                      <div class="col-6">
                        <label class="form-label">Produto:</label>
                        <app-search-select
                          value="idpfprod"
                          [arrToFilter]="
                            regrasCliente.ComboRestricaoProdutosCliente
                              | filtrarProdutosCliente
                                : regrasCliente.RestricaoTipoProdutoCliente
                          "
                          [(ngValue)]="regrasCliente.RestricaoProdutoCliente"
                          (ngValueChange)="handleAnpRestriction()"
                        ></app-search-select>
                      </div>
                    </div>
                    <div class="row pb-2">
                      <div class="col-6">
                        <label class="form-label">Regra:</label>
                        <app-search-select
                          value="valor"
                          [arrToFilter]="
                            regrasCliente.ComboRestricaoTipoCliente
                          "
                          [(ngValue)]="regrasCliente.RestricaoTipoCliente"
                          (ngValueChange)="controlaRegrasCliente($event)"
                        ></app-search-select>
                      </div>
                    </div>
                  </ng-container>
                  <div
                    class="row pb-2"
                    [hidden]="!lcadastrandoRestricao || permi == 0"
                  >
                    <div class="col">
                      <div class="card">
                        <div class="card-header bg-secondary h5 text-white">
                          {{ tituloRestricao }}
                        </div>
                        <div class="card-body">
                          <ng-container *ngIf="permi == 1">
                            <div class="row">
                              <div class="col d-flex flex-column">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao01.rodovia"
                                >
                                  Em RODOVIA
                                </mat-checkbox>
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao01.urbano"
                                >
                                  URBANO
                                </mat-checkbox>
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao01.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 2">
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <ng-container
                                  *ngFor="let dia of permissao02.dias"
                                >
                                  <mat-checkbox
                                    color="primary"
                                    value="1"
                                    [(ngModel)]="dia.value"
                                  >
                                    {{ dia.label }}
                                  </mat-checkbox>
                                  <ng-container *ngIf="dia.value">
                                    <label class="form-label pt-2"
                                      >Hora inicial</label
                                    >
                                    <input
                                      type="time"
                                      class="form-control mb-2"
                                      [(ngModel)]="dia.horaini"
                                    />
                                    <label class="form-label">Hora final</label>
                                    <input
                                      [(ngModel)]="dia.horafim"
                                      type="time"
                                      class="form-control mb-2"
                                    />
                                  </ng-container>
                                </ng-container>
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao02.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 3">
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">KM:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao03.km"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Tolerância Pra Baixo:</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao03.tolerancia2"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Tolerância Pra Cima</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao03.tolerancia"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao03.restringir"
                                >
                                  Restringir pra baixo
                                </mat-checkbox>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao03.restringirmax"
                                >
                                  Restringir pra cima
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 4">
                            <div class="row pb-2">
                              <div class="col-6" [hidden]="preloaderEstado">
                                <label class="form-label">Tempo:</label>
                                <app-search-select
                                  value="id"
                                  [arrToFilter]="[
                                    { id: '1', label: 'Meses' },
                                    { id: '2', label: 'Dias' },
                                    { id: '3', label: 'Horas' },
                                  ]"
                                  [(ngValue)]="permissao04.tempo"
                                ></app-search-select>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Quantidade:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao04.quantidade"
                                  mask="000000"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Tolerância:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao04.tolerancia"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao04.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 5">
                            <div class="row">
                              <div class="col-6">
                                <div class="h4 text-danger">
                                  <b>AVISO: </b>
                                  Em breve essa regra deixará de existir,
                                  recomendamos utilizar "Dias da semana
                                  permitido".
                                </div>

                                <label class="form-label">Hora inicial</label>
                                <input
                                  [(ngModel)]="permissao05.horaini"
                                  type="time"
                                  class="form-control mb-2"
                                />

                                <label class="form-label">Hora final</label>
                                <input
                                  [(ngModel)]="permissao05.horafim"
                                  type="time"
                                  class="form-control mb-2"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao05.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 6">
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Valor Limite:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao06.valorlimite"
                                  currencyMask
                                  [options]="{
                                    prefix: 'R$ ',
                                    thousands: '.',
                                    decimal: ',',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Tolerância:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao06.tolerancia"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div
                                class="col-3"
                                [hidden]="preloaderLocalidades"
                              >
                                <label class="form-label">Estados:</label>
                                <app-search-select
                                  value="id"
                                  [arrToFilter]="[
                                    { id: '-1', label: 'Todos' },
                                    { id: 'AC', label: 'AC' },
                                    { id: 'AL', label: 'AL' },
                                    { id: 'AM', label: 'AM' },
                                    { id: 'AP', label: 'AP' },
                                    { id: 'BA', label: 'BA' },
                                    { id: 'CE', label: 'CE' },
                                    { id: 'DF', label: 'DF' },
                                    { id: 'ES', label: 'ES' },
                                    { id: 'GO', label: 'GO' },
                                    { id: 'MA', label: 'MA' },
                                    { id: 'MG', label: 'MG' },
                                    { id: 'MS', label: 'MS' },
                                    { id: 'MT', label: 'MT' },
                                    { id: 'PA', label: 'PA' },
                                    { id: 'PB', label: 'PB' },
                                    { id: 'PE', label: 'PE' },
                                    { id: 'PI', label: 'PI' },
                                    { id: 'PR', label: 'PR' },
                                    { id: 'RJ', label: 'RJ' },
                                    { id: 'RN', label: 'RN' },
                                    { id: 'RO', label: 'RO' },
                                    { id: 'RR', label: 'RR' },
                                    { id: 'RS', label: 'RS' },
                                    { id: 'SC', label: 'SC' },
                                    { id: 'SE', label: 'SE' },
                                    { id: 'SP', label: 'SP' },
                                    { id: 'TO', label: 'TO' },
                                  ]"
                                  [(ngValue)]="permissao06.cuf"
                                  (ngValueChange)="getMunicipios($event)"
                                ></app-search-select>
                              </div>
                              <div
                                class="col-3"
                                [hidden]="preloaderLocalidades"
                              >
                                <label class="form-label">Municipios:</label>
                                <app-search-select
                                  value="idmun"
                                  [arrToFilter]="compoMunicipios"
                                  [(ngValue)]="permissao06.idmun"
                                  (ngValueChange)="checkAllCitiesSelected()"
                                  [multiple]="true"
                                ></app-search-select>
                              </div>
                              <div class="col-6" *ngIf="preloaderLocalidades">
                                <mat-spinner
                                  diameter="40"
                                  class="m-auto"
                                ></mat-spinner>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao06.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 7">
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Quantidade (UN):</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao07.quantidade"
                                  mask="000000"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Tolerância Pra Baixo:</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao07.tolerancia"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao07.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 8">
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Quantidade (UN):</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao08.quantidade"
                                  mask="000000"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Tolerância Pra Baixo:</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao08.tolerancia"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao08.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 9">
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Tempo:</label>
                                <app-search-select
                                  value="id"
                                  [arrToFilter]="[
                                    { id: '1', label: 'Mês' },
                                    { id: '2', label: 'Quinzena' },
                                    { id: '3', label: 'Semana' },
                                  ]"
                                  [(ngValue)]="permissao09.tempo"
                                ></app-search-select>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Quantidade:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao09.quantidade"
                                  mask="000000"
                                />
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label">Tolerância:</label>
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao09.tolerancia"
                                  currencyMask
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao09.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 10">
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Intervalo de KM:</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao10.quantidade"
                                  mask="000000"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao10.restringir"
                                >
                                  Restringir pra baixo
                                </mat-checkbox>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Tolerância Pra Baixo:</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao10.tolerancia2"
                                  currencyMask
                                  disabled="{{ !permissao10.restringir }}"
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao10.restringirmax"
                                >
                                  Restringir pra cima
                                </mat-checkbox>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-6">
                                <label class="form-label"
                                  >Tolerância Pra Cima:</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao10.tolerancia"
                                  currencyMask
                                  disabled="{{ !permissao10.restringirmax }}"
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 11">
                            <div class="row">
                              <div class="col d-flex flex-column">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao11.cnh_vencida"
                                >
                                  CNH Vencida
                                </mat-checkbox>
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao11.restringir"
                                >
                                  Restringir
                                </mat-checkbox>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="permi == 12">
                            <div class="row">
                              <div class="col-6 d-flex flex-column">
                                <mat-checkbox
                                  color="primary"
                                  value="1"
                                  [(ngModel)]="permissao12.restringir"
                                  (change)="permissao12.tolerancia = 0.0"
                                >
                                  Restringir
                                </mat-checkbox>
                                <label class="form-label pt-2"
                                  >Tolerância Pra Cima</label
                                >
                                <input
                                  class="form-control"
                                  [(ngModel)]="permissao12.tolerancia"
                                  currencyMask
                                  [disabled]="!permissao12.restringir"
                                  [options]="{
                                    prefix: '',
                                    thousands: '.',
                                    decimal: ',',
                                    suffix: '%',
                                    align: 'left',
                                  }"
                                />
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" [hidden]="!lcadastrandoRestricao">
                    <div class="col">
                      <button
                        [hidden]="permi == 0"
                        class="btn btn-success me-2"
                        (click)="salvarRegrasRestricao()"
                      >
                        <i class="fa-solid fa-check"></i> Salvar Restrição
                      </button>

                      <button
                        class="btn btn-danger"
                        (click)="cancelarRegrasRestricaoCliente()"
                      >
                        <i class="fa-solid fa-check"></i>
                        Cancelar Restrição
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Logs de Alteração">
            <app-log-alteracoes-regras></app-log-alteracoes-regras>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col text-end">
        <button
          *ngIf="alterarDados"
          (click)="salvarAsRegras(regrasCliente)"
          class="btn btn-success btn-shadow me-1"
        >
          <i class="fa-solid fa-check"></i> Salvar Regras
          <ng-container *ngIf="isGlobalRules"> Globais </ng-container>
          <ng-container *ngIf="isVehicleRules"> de Veiculos </ng-container>
          <ng-container *ngIf="isProfileRules"> de Perfil </ng-container>
        </button>
        <button
          *ngIf="voltar"
          (click)="goBack()"
          class="btn btn-danger btn-shadow"
        >
          <i class="fa-solid fa-close"></i>
          Voltar
        </button>
      </div>
    </div>
  </div>
</div>
