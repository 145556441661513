<div class="container-fluid pt-3">
  <div class="card">
    <div class="card-header bg-secondary h5 text-white">
      Logs de Alterações de Regras
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-start">Data da alteração</th>
                <th class="text-start">Alteração</th>
                <th class="text-start">Alterado por:</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dados of arrDados">
                <td>
                  {{ dados.dcadas | formatDate: "DD/MM/YYYY HH:mm STR" }}
                </td>
                <td>
                  <div class="row">
                    <div class="col">
                      <table
                        class="table table-bordered table-hover"
                        *ngIf="dados?.diferencas?.length > 0"
                      >
                        <thead>
                          <tr>
                            <th class="text-start">Campo</th>
                            <th class="text-start">Antes</th>
                            <th class="text-start">Depois</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let dif of dados.diferencas">
                            <ng-container *ngIf="dif.campo == 'produtos2'">
                              <td>
                                {{ camposRegras[dif.campo]?.label }}
                              </td>
                              <td>
                                <ng-container *ngFor="let produto of dif.antes">
                                  {{ produto.cnome }}<br />
                                  <hr
                                    style="
                                      margin: 10px 0;
                                      height: 1px;
                                      background-color: #2196f3;
                                    "
                                    *ngIf="i != dif.antes.length - 1"
                                  />
                                </ng-container>
                              </td>
                              <td>
                                <ng-container
                                  *ngFor="let produto of dif.depois"
                                >
                                  {{ produto.cnome }}<br />
                                  <hr
                                    style="
                                      margin: 10px 0;
                                      height: 1px;
                                      background-color: #2196f3;
                                    "
                                    *ngIf="i != dif.depois.length - 1"
                                  />
                                </ng-container>
                              </td>
                            </ng-container>
                            <ng-container *ngIf="dif.campo == 'credenciados'">
                              <td>
                                {{ camposRegras[dif.campo]?.label }}
                              </td>
                              <td>
                                <ng-container *ngFor="let cred of dif.antes">
                                  {{ cred.codtn + " - " + cred.cfantasia
                                  }}<br />
                                  <hr
                                    style="
                                      margin: 10px 0;
                                      height: 1px;
                                      background-color: #2196f3;
                                    "
                                    *ngIf="i != dif.antes.length - 1"
                                  />
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngFor="let cred of dif.depois">
                                  {{ cred.codtn + " - " + cred.cfantasia
                                  }}<br />
                                  <hr
                                    style="
                                      margin: 10px 0;
                                      height: 1px;
                                      background-color: #2196f3;
                                    "
                                    *ngIf="i != dif.depois.length - 1"
                                  />
                                </ng-container>
                              </td>
                            </ng-container>
                            <ng-container *ngIf="dif.campo == 'localidade'">
                              <td>
                                {{ camposRegras[dif.campo]?.label }}
                              </td>
                              <td>
                                <ng-container *ngFor="let local of dif.antes">
                                  {{ local.cnomemun + " , " + local.cuf }}<br />
                                  <hr
                                    style="
                                      margin: 10px 0;
                                      height: 1px;
                                      background-color: #2196f3;
                                    "
                                    *ngIf="i != dif.antes.length - 1"
                                  />
                                </ng-container>
                              </td>
                              <td>
                                <ng-container *ngFor="let local of dif.depois">
                                  {{ local.cnomemun + " , " + local.cuf }}<br />
                                  <hr
                                    style="
                                      margin: 10px 0;
                                      height: 1px;
                                      background-color: #2196f3;
                                    "
                                    *ngIf="i != dif.depois.length - 1"
                                  />
                                </ng-container>
                              </td>
                            </ng-container>
                            <ng-container *ngIf="dif.campo == 'restricoes'">
                              <td>{{ camposRegras[dif.campo]?.label }}</td>
                              <td>
                                <table class="table table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th class="text-start">Tipo Produto</th>
                                      <th class="text-start">Produto</th>
                                      <th class="text-start">
                                        Descrição da Regra
                                      </th>
                                      <th class="text-start">Restrigir</th>
                                      <th class="text-start">Tolerância(%)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let restricao of dif.antes">
                                      <td class="text-start">
                                        {{ restricao.descricaoTipoCombustivel }}
                                      </td>
                                      <td class="text-start">
                                        {{ restricao.produto }}
                                      </td>
                                      <td class="text-start">
                                        {{ restricao.DescricaoRegra }}
                                      </td>
                                      <td class="text-start">
                                        {{
                                          restricao.restringir2
                                            ? "Baixo: " +
                                              restricao.restringir +
                                              " - Cima: " +
                                              restricao.restringir2
                                            : restricao.restringir
                                        }}
                                      </td>
                                      <td class="text-start">
                                        {{
                                          restricao.tolerancia2
                                            ? "Baixo: " +
                                              (restricao.tolerancia2
                                                | number: "1.2-2") +
                                              " - Cima: " +
                                              (restricao.tolerancia
                                                | number: "1.2-2")
                                            : (restricao.tolerancia
                                              | number: "1.2-2")
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>
                                <table class="table table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th class="text-start">Tipo Produto</th>
                                      <th class="text-start">Produto</th>
                                      <th class="text-start">
                                        Descrição da Regra
                                      </th>
                                      <th class="text-start">Restrigir</th>
                                      <th class="text-start">Tolerância(%)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let restricao of dif.depois">
                                      <td class="text-start">
                                        {{ restricao.descricaoTipoCombustivel }}
                                      </td>
                                      <td class="text-start">
                                        {{ restricao.produto }}
                                      </td>
                                      <td class="text-start">
                                        {{ restricao.DescricaoRegra }}
                                      </td>
                                      <td class="text-start">
                                        {{
                                          restricao.restringir2
                                            ? "Baixo: " +
                                              restricao.restringir +
                                              " - Cima: " +
                                              restricao.restringir2
                                            : restricao.restringir
                                        }}
                                      </td>
                                      <td class="text-start">
                                        {{
                                          restricao.tolerancia2
                                            ? "Baixo: " +
                                              (restricao.tolerancia2
                                                | number: "1.2-2") +
                                              " - Cima: " +
                                              (restricao.tolerancia
                                                | number: "1.2-2")
                                            : (restricao.tolerancia
                                              | number: "1.2-2")
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        class="table table-bordered table-hover"
                        *ngIf="dados?.observacao?.length > 1"
                      >
                        <thead>
                          <tr>
                            <th class="text-start">Observação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ dados.observacao }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
                <td>
                  {{ dados.usuario }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
