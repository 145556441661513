<div *ngIf="tipo == '1'">
  <div class="row">
    <div class="col-3">
      <div
        class="w-100 bg-primary py-2 px-3 text-white d-flex flex-column rounded"
      >
        <div class="row">
          <div class="col-3 d-flex justify-content-center align-items-center">
            <i class="fa-sharp fa-solid fa-arrow-down-to-line fs-2"></i>
          </div>
          <div class="col d-flex flex-column">
            <small>MANUAL GESTOR DE FROTA</small>
            <span class="h4 m-0">
              <a
                class="text-white"
                href="/assets/download/ManualPlusfrotaWEB.PDF"
                target="_blank"
              >
                Baixar Agora
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="tipo == '2'">
  <div class="row">
    <div class="col-3">
      <div
        class="w-100 bg-primary py-2 px-3 text-white d-flex flex-column rounded"
      >
        <div class="row">
          <div class="col-3 d-flex justify-content-center align-items-center">
            <i class="fa-sharp fa-solid fa-arrow-down-to-line fs-2"></i>
          </div>
          <div class="col d-flex flex-column">
            <small>MANUAL DO CREDENCIADO</small>
            <span class="h4 m-0">
              <a
                class="text-white"
                href="/assets/download/ManualPlusfrotaCredenciadoWEB.PDF"
                target="_blank"
              >
                Baixar Agora
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
