<div class="row" *ngIf="!contentLoaded">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row py-2" *ngIf="contentLoaded">
  <div class="col text-end">
    <button
      class="btn btn-success text-uppercase btn-shadow btn-sm"
      (click)="filtersVisible = !filtersVisible"
    >
      <i class="fa-solid fa-filter"></i>
      &nbsp; Filtros
    </button>
    <button
      class="btn btn-secondary text-uppercase btn-shadow ms-1 btn-sm"
      (click)="clearFilters()"
    >
      <i class="fa-solid fa-filter-slash"></i>
      &nbsp; Limpar Filtros
    </button>
    <button
      title="Pesquisar"
      class="btn btn-info btn-shadow btn-sm ms-1"
      (click)="filterDataGridLocal()"
    >
      <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
    </button>
  </div>
</div>

<div
  class="row pb-2"
  cCollapse
  [visible]="filtersVisible"
  (keydown.enter)="filterDataGridLocal()"
  [hidden]="!contentLoaded"
>
  <div class="col">
    <div class="row pb-2">
      <div class="col">
        <label class="form-label">Código do Usuario: </label>
        <input class="form-control" [(ngModel)]="filter.idusu" />
      </div>
      <div class="col">
        <label class="form-label">Nome:</label>
        <input class="form-control" [(ngModel)]="filter.nome" />
      </div>
      <div class="col">
        <label class="form-label">Data de:</label>
        <input class="form-control" type="date" [(ngModel)]="filter.datade" />
      </div>
      <div class="col">
        <label class="form-label">Data até:</label>
        <input class="form-control" type="date" [(ngModel)]="filter.dataate" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label">Rota:</label>
        <input class="form-control" [(ngModel)]="filter.rota" />
      </div>
      <div class="col">
        <label class="form-label">IP:</label>
        <input class="form-control" [(ngModel)]="filter.ip" />
      </div>
      <div class="col">
        <label class="form-label">Navegador:</label>
        <input class="form-control" [(ngModel)]="filter.navegador" />
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="contentLoaded">
  <div class="col">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Cód. Usuario</th>
          <th>Nome</th>
          <th>Perfil</th>
          <th>Data/Hora</th>
          <th>Rota</th>
          <th>IP</th>
          <th>Navegador</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of arrUsers">
          <td>
            {{ user.idusu }}
          </td>
          <td>
            {{ user.nome }}
          </td>
          <td>
            {{ user.perfil }}
          </td>
          <td>
            {{ user.dcadas | formatDate : "DD/MM/YYYY HH:mm STR" }}
          </td>
          <td>
            {{ user.rota }}
          </td>
          <td>
            {{ user.ip }}
          </td>
          <td>
            {{ user.navegador }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" [hidden]="!contentLoaded">
  <div class="col d-flex justify-content-end">
    <ngb-pagination
      [(page)]="filter.paginaAtual"
      [pageSize]="20"
      [collectionSize]="filter.quantidade"
      [boundaryLinks]="true"
      [maxSize]="8"
      [rotate]="true"
      (pageChange)="pageChange($event)"
    ></ngb-pagination>
  </div>
</div>
