<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-header bg-secondary p-3">
        <h2 class="text-white fs-6 m-0">
          CONSULTAS INTELIGENTES PLUS FROTA CREDENCIADO
        </h2>
        <small class="text-white"
          >Consultas personalizadas para gerênciar seus clientes</small
        >
      </div>
      <div class="row card-body ps-4">
        <div class="row">
          <div class="col-6">
            <label class="form-label">SELECIONE O TIPO DE CONSULTA:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                {
                  id: './' + ROTAS_CONSULTAS_CRED.DESCONTO_CLIENTE,
                  label: 'DESCONTO POR CLIENTE'
                },
                {
                  id: './' + ROTAS_CONSULTAS_CRED.CARTA_CIRCULARIZACAO,
                  label: 'CARTA DE CIRCULARIZAÇÃO'
                },
                {
                  id: './' + ROTAS_CONSULTAS_CRED.CONSULTA_FECHAMENTOS,
                  label: 'FECHAMENTOS'
                },
                {
                  id: './' + ROTAS_CONSULTAS_CRED.TRANSACOES_CREDENCIADOS,
                  label: 'TRANSAÇÕES'
                }
              ]"
              [(ngValue)]="ComboTipoConsulta"
              (ngValueChange)="gerarConsulta($event)"
            ></app-search-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
