<div class="card">
  <div class="card-header bg-primary d-flex flex-column">
    <span class="h4 text-white">Fipe</span>
    <small class="text-white"> Informações referentes à tabela FIPE </small>
  </div>
  <div class="card-body card-padding">
    <div class="row mb-2">
      <div class="col">
        Montadora:
        <span class="text-primary">
          {{ fipeData.makerDescription ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Valor Atual:
        <span class="text-primary">
          {{
            fipeData.currentValue
              ? (fipeData.currentValue | currency : "BRL")
              : "Não Informado"
          }}
        </span>
      </div>
      <div class="col" *ngIf="ipvaData">
        IPVA atual (estimado):
        <span class="text-primary">
          {{
            ipvaData.value
              ? (ipvaData.value | currency : "BRL")
              : "Não Informado"
          }}
        </span>
      </div>
      <div class="col">
        Modelo:
        <span class="text-primary">
          {{ fipeData.modelDescription ?? "Não Informado" }}
        </span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        Código FIPE:
        <span class="text-primary">
          {{ fipeData.fipeId ?? "Não Informado" }}
        </span>
      </div>
      <div class="col" *ngIf="ipvaData">
        UF referência:
        <span class="text-primary">
          {{ ipvaData.state ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Versão:
        <span class="text-primary">
          {{ fipeData.versionDescription ?? "Não Informado" }}
        </span>
      </div>
      <div class="col">
        Combustível:
        <span class="text-primary">
          {{ fipeData.fuel ?? "Não Informado" }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col w-100 d-flex justify-content-center">
        <div class="chart-container" [chart]="chart"></div>
      </div>
    </div>
  </div>
</div>
