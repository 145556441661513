import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { RegraService } from "./regras.service";
import { VeiculoService } from "../../veiculos/veiculo.service";
import { MessageEventFrota } from "./messageevent.service";
import { LocalidadeService } from "../../shared/services/localidade.service";
import Swal from "sweetalert2";
import { NIVEL_REGRAS } from "../../shared/enums/nivel-regras.enum";
import { UserService } from "../../shared/services/user.service";

const VEICULO_IMAGE: { [key: string]: string } = {
  AUTOMOVEIS: "icon_carro_topo.png",
  CAMINHÕES: "veiculo-default.png",
  MOTO: "motocycle.png",
  "ÔNIBUS E MICROÔNIBUS": "onibus.png",
  "MOTOR-CASA": "motohome.png",
  default: "veiculo-default.png",
};

@Component({
  selector: "regras",
  templateUrl: "./regras.component.html",
})
export class RegrasComponent implements OnInit {
  conteudoCarregado = false;
  conteudoCarregadoV = false;
  conteudoCarregadoR = false;
  conteudoCarregadoP = false;

  lTodosCombustiveis = true;
  lTodosOleo = true;
  lTodosFiltro = true;
  lTodosOutros = true;
  lTodosServicos = true;
  lTodosServiDiv = true;

  preloaderEstado = false;
  preloaderMunicipio = false;
  arrProdutos = [];
  lcadastrandoRestricao = false;
  inverterregra = "";

  permi = 0;
  credenciado = 0;
  alterarDados = false;

  permissao01 = {
    rodovia: false,
    urbano: false,
    restringir: false,
    restringirmax: false,
  };
  permissao02 = {
    dias: [
      {
        label: "DOMINGO",
        value: false,
        horaini: "",
        horafim: "",
        column: "ldom",
      },
      {
        label: "SEGUNDA",
        value: false,
        horaini: "",
        horafim: "",
        column: "lseg",
      },
      {
        label: "TERÇA",
        value: false,
        horaini: "",
        horafim: "",
        column: "lter",
      },
      {
        label: "QUARTA",
        value: false,
        horaini: "",
        horafim: "",
        column: "lqua",
      },
      {
        label: "QUINTA",
        value: false,
        horaini: "",
        horafim: "",
        column: "lqui",
      },
      {
        label: "SEXTA",
        value: false,
        horaini: "",
        horafim: "",
        column: "lsex",
      },
      {
        label: "SÁBADO",
        value: false,
        horaini: "",
        horafim: "",
        column: "lsab",
      },
    ],
    restringir: false,
    restringirmax: false,
  };
  permissao03 = {
    km: 0.0,
    tolerancia: 0.0,
    tolerancia2: 0.0,
    restringir: false,
    restringirmax: false,
  };
  permissao04 = {
    tempo: "1",
    quantidade: 0.0,
    tolerancia: 0.0,
    restringir: false,
    restringirmax: false,
  };
  permissao05 = {
    horaini: "",
    horafim: "",
    restringir: false,
    restringirmax: false,
  };
  permissao06 = {
    valorlimite: 0.0,
    tolerancia: 0.0,
    restringir: false,
    restringirmax: false,
    cuf: "",
    idmun: [],
  };
  permissao07 = {
    quantidade: 0.0,
    tolerancia: 0.0,
    restringir: false,
    restringirmax: false,
  };
  permissao08 = {
    quantidade: 0.0,
    tolerancia: 0.0,
    restringir: false,
    restringirmax: false,
  };
  permissao09 = {
    tempo: "1",
    quantidade: 0.0,
    tolerancia: 0.0,
    restringir: false,
    restringirmax: false,
  };
  permissao10 = {
    quantidade: 0.0,
    tolerancia: 0.0,
    tolerancia2: 0.0,
    restringir: false,
    restringirmax: false,
  };
  permissao11 = {
    cnh_vencida: false,
    restringir: false,
  };
  permissao12 = {
    tolerancia: 0.0,
    restringir: false,
  };

  localizacao = {
    cuf: "0",
    idmun: "0",
  };
  localizacaoRestricao = {
    cuf: "",
    idmun: "0",
  };

  regras = {
    Localizacao: [],
    Credenciado: [],
    CredenciadoRegra: [],
    CredenciadoAntigo: [],
    Restricao: [],
    idcliente: "",
    idempresa: "",
    idveiculo: "",
  };

  regrasCliente = {
    Localizacao: [],
    Credenciado: [],
    CredenciadoRegra: [],
    CredenciadoAntigo: [],
    Restricao: [],
    idcliente: "",
    idempresa: "",
    idveiculo: "",
    ComboRestricaoTipoCliente: [
      { label: "Selecione", name: "Selecione", valor: 0 },
      {
        label: "Permissão sobre tipo de Credenciados",
        name: "Permissão sobre tipo de Credenciados",
        valor: 1,
      },
      {
        label: "Dias da Semana Permitido",
        name: "Dias da Semana Permitido",
        valor: 2,
      },
      { label: "Km/Litro(M3)", name: "Km/Litro(M3)", valor: 3 },
      {
        label: "Tempo entre Abastecimento",
        name: "Tempo entre Abastecimento",
        valor: 4,
      },
      { label: "Horário Permitido", name: "Horário Permitido", valor: 5 },
      { label: "Valor do Litro(M3)", name: "Valor do Litro(M3)", valor: 6 },
      {
        label: "Quantidade de Vezes no Mês",
        name: "Quantidade de Vezes no Mês",
        valor: 7,
      },
      {
        label: "Quantidade de Compra por Vez",
        name: "Quantidade de Compra por Vez",
        valor: 8,
      },
      { label: "Intervalo de KM", name: "Intervalo de KM", valor: 10 },
      { label: "CNH Vencida", name: "CNH Vencida", valor: 11 },
    ],
    RestricaoTipoCliente: "0",
    RestricaoProdutoCliente: "0",
    RestricaoAnterior: [],
    ComboLocalizacaoRestricaoCuf: [],
    ComboLocalizacaoRestricaoMun: [],
    ComboLocalizacaoCuf: [],
    ComboLocalizacaoMun: [],
    ComboRestricaoTipoProdutoCliente: [],
    ComboRestricaoProdutosCliente: [],
    LocalidadeRegraComboCuf: [],
    LocalidadeRegraComboCred: [],
    RestricaoTipoProdutoCliente: "",
  };

  veiculo;
  tituloRestricao = "Selecione a regra";
  editarRegra = false;
  editarRegraI: number;

  voltar = false;

  compoMunicipios = [];
  preloaderLocalidades = false;
  filtro = {
    centrocusto: "0",
    modelo: "",
    tipocontrole: "",
    combustivel: "",
    ordenacao: "",
    motorista: "",
    placa: "",
    nfrota: "",
    cartao: "",
    limite: "",
    percentualUtilizacao: "",
    produto: "0",
    tiporelatorio: "1",
    tipocredenciado: "-1",
    estado: "-1",
    cidade: "0",
    bairro: "0",
    oleo: true,
    filtros: true,
    outros: true,
    servicos: true,
    tipoproduto: "0",
    tipotransacao: "",
    quantidade: "",
    opcoes: "",
    periodo: "",
    kmvencido: false,
    kmavencer: false,
    kmtroca: false,
    codtn: "0",
  };

  @Input("idrelacionado") idrelacionado = "0";
  @Input("idcliente") idcliente = "0";

  @Input("regranivel") regranivel: number;
  @Input("centrocusto") centrocusto = "0";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private regraService: RegraService,
    private localidadeService: LocalidadeService,
    private veiculoService: VeiculoService,
    private messageEventService: MessageEventFrota,
    private userService: UserService
  ) {
    this.alterarDados = this.userService.alterarDados;
    if (
      this.route.snapshot.data["regranivel"] ||
      this.route.snapshot.data["regranivel"] == 0
    ) {
      this.regranivel = this.route.snapshot.data["regranivel"];
    }

    if (this.route.snapshot.data["voltar"]) {
      this.voltar = true;
    }
  }


  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params["idcliente"] && params["idrelacionado"]) {
        this.idcliente = params["idcliente"];
        this.idrelacionado = params["idrelacionado"];
      }

      this.getTodosProdutosRegras(); // setar arrProdutos
      this.carregarDadosRegras(); // getVeiculo
      this.registerTypeBroadcast();
    });
  }

  get isClientRules(): boolean {
    return this.regranivel == NIVEL_REGRAS.CLIENTE;
  }

  get isGlobalRules(): boolean {
    return this.regranivel == NIVEL_REGRAS.GLOBAL;
  }

  get isCenterCostRules(): boolean {
    return this.regranivel == NIVEL_REGRAS.CENTRO_CUSTO;
  }

  get isVehicleRules(): boolean {
    return this.regranivel == NIVEL_REGRAS.VEICULO;
  }

  get isProfileRules(): boolean {
    return this.regranivel == NIVEL_REGRAS.PERFIL;
  }

  getTodosProdutosRegras(): void {
    this.conteudoCarregadoP = false;

    this.regraService
      .getTodosProdutosRegras({ modulo: 1 })
      .subscribe((response) => {
        if (response.success) {
          this.arrProdutos = response.produtos;

          this.conteudoCarregadoP = true;
          this.verificarCarregado();
        }
      });
  }

  carregarDadosRegras(): void {
    // GetRegras do Nivel, setar o que pode e não pode com base no nivel anterior, carregar os dados do nivel atual
    this.carregarDadosRegrasEmpresaSetarPrimeiro();

    if (this.regranivel == 3) {
      this.veiculoService
        .getView({ idveiculo: this.idrelacionado, idcliente: this.idcliente })
        .subscribe((response) => {
          if (response.success) {
            response.veiculo[0].imagensVeiculo =
              VEICULO_IMAGE[response.veiculo[0].FamiliaNome] ||
              VEICULO_IMAGE.default;

            response.veiculo[0].situacaoVeiculo = "Bloqueado";

            if (response.veiculo[0].lbloqueado == 0) {
              response.veiculo[0].situacaoVeiculo = "Ativo";
            }

            this.veiculo = response.veiculo[0];

            this.conteudoCarregadoV = true;
            this.verificarCarregado();
          }
        });
    } else {
      this.conteudoCarregadoV = true;
      this.verificarCarregado();
    }
  }

  carregarDadosRegrasEmpresaSetarPrimeiro(): void {
    this.regraService
      .getRegrasNivel({
        idcliente: this.idcliente,
        idrelacionado: this.idrelacionado,
        tipo: this.regranivel,
        centrocusto: this.centrocusto,
      })
      .subscribe((response) => {
        this.setarProdutosEmpresa(response.nivelAnterior.ProdutoHabilitado); // Desabilitar itens que não estão no nivel anterior

        // Setar Credenciados que podem ir para as regras
        if (response.nivelAnterior.CredenciadoRegra.length > 0) {
          this.regras.Credenciado = [];
          this.regras.CredenciadoAntigo = [];

          for (
            let x = 0;
            x < response.nivelAnterior.CredenciadoRegra.length;
            x++
          ) {
            this.regras.Credenciado.push({
              codtln: response.nivelAnterior.CredenciadoRegra[x].codtn,
              credenciadocnome:
                response.nivelAnterior.CredenciadoRegra[x].cfantasia,
              idcred: response.nivelAnterior.CredenciadoRegra[x].idcred,
            });
          }

          this.regras.CredenciadoAntigo = this.regras.Credenciado;
        }

        // Setar localidades que podem ir para as regras
        if (response.nivelAnterior.LocalidadeRegra.length > 0) {
          this.regras.Localizacao = [];

          for (
            let x = 0;
            x < response.nivelAnterior.LocalidadeRegra.length;
            x++
          ) {
            this.regras.Localizacao.push({
              estado: response.nivelAnterior.LocalidadeRegra[x].cuf,
              municipioId: response.nivelAnterior.LocalidadeRegra[x].idmun,
              municipiocnome:
                response.nivelAnterior.LocalidadeRegra[x].idmun == 0
                  ? "Todos"
                  : response.nivelAnterior.LocalidadeRegra[x].cnomemun,
            });
          }
        }

        this.regrasCliente.LocalidadeRegraComboCuf =
          response.nivelAnterior.LocalidadeRegraComboCuf;
        this.regrasCliente.LocalidadeRegraComboCuf.forEach((item) => {
          item.label = item.cuf;
        });
        this.regrasCliente.LocalidadeRegraComboCuf.unshift({
          label: "Selecione",
          cuf: "0",
        });

        this.regrasCliente.LocalidadeRegraComboCred =
          response.nivelAnterior.LocalidadeRegraComboCred;

        this.regrasCliente.LocalidadeRegraComboCred.forEach((item) => {
          item.label = item.codtn + " - " + item.cfantasia;
        });
        this.regrasCliente.LocalidadeRegraComboCred.unshift({
          label: "Selecione / Todos",
          idcred: "0",
        });

        this.regrasCliente.RestricaoAnterior = response.nivelAnterior.Restricao;

        this.inverterregra = response.nivelAtual.inversao;

        this.carregarDadosRegrasCliente(response.nivelAtual); // Setar os dados do cliente que estão no nivel atual

        this.conteudoCarregadoR = true;
        this.verificarCarregado();
      });
  }

  setarProdutosEmpresa(arrProdutos): void {
    if (arrProdutos.length == 0) {
      for (let i = 0; i < this.arrProdutos.length; i++) {
        this.arrProdutos[i].idpfprod = this.arrProdutos[i].idpfprod.toString();
        this.arrProdutos[i].idtipoproduto =
          this.arrProdutos[i].idtipoproduto.toString();
        this.arrProdutos[i].modulo = this.arrProdutos[i].modulo.toString();
        this.arrProdutos[i].idusu = this.arrProdutos[i].idusu.toString();
        this.arrProdutos[i].idusucad = this.arrProdutos[i].idusucad.toString();
        this.arrProdutos[i].desable = false;
      }
    } else {
      for (let i = 0; i < this.arrProdutos.length; i++) {
        this.arrProdutos[i].idpfprod = this.arrProdutos[i].idpfprod.toString();
        this.arrProdutos[i].idtipoproduto =
          this.arrProdutos[i].idtipoproduto.toString();
        this.arrProdutos[i].modulo = this.arrProdutos[i].modulo.toString();
        this.arrProdutos[i].idusu = this.arrProdutos[i].idusu.toString();
        this.arrProdutos[i].idusucad = this.arrProdutos[i].idusucad.toString();
        this.arrProdutos[i].status = false;
        this.arrProdutos[i].desable = true;
      }

      for (let i = 0; i < this.arrProdutos.length; i++) {
        const prod = arrProdutos.find(
          (item) => item.idpfprod == this.arrProdutos[i].idpfprod
        );

        if (prod != undefined) {
          this.arrProdutos[i].desable = false;
        }
      }
    }
  }

  carregarDadosRegrasCliente(nivelAtual): void {
    this.regrasCliente.Localizacao = []; // Inicializa a Localizacao do grid

    // seta checked para os produtos que estão habilitados do nivel atual
    for (let i = 0; i < nivelAtual.ProdutoHabilitado.length; i++) {
      const index = this.arrProdutos.indexOf(
        this.arrProdutos.find(
          (item) => item.codtn == nivelAtual.ProdutoHabilitado[i].codtn
        )
      );

      if (index > -1) {
        this.arrProdutos[index].status = true;
        this.arrProdutos[index].desable = false;
      }
    }

    // seta array dos credenciados que estão selecionados do nivel atual
    if (nivelAtual.CredenciadoRegra.length > 0) {
      this.regrasCliente.Credenciado = [];

      for (let x = 0; x < nivelAtual.CredenciadoRegra.length; x++) {
        this.regrasCliente.Credenciado.push({
          codtln: nivelAtual.CredenciadoRegra[x].codtn,
          credenciadocnome: nivelAtual.CredenciadoRegra[x].cfantasia,
          idcred: nivelAtual.CredenciadoRegra[x].idcred,
        });
      }
    }

    // seta array de localidade que estão selecionados do nivel atual
    if (nivelAtual.LocalidadeRegra.length > 0) {
      for (let x = 0; x < nivelAtual.LocalidadeRegra.length; x++) {
        this.regrasCliente.Localizacao.push({
          estado: nivelAtual.LocalidadeRegra[x].cuf,
          municipioId: nivelAtual.LocalidadeRegra[x].idmun,
          municipiocnome:
            nivelAtual.LocalidadeRegra[x].idmun == 0
              ? "Todos"
              : nivelAtual.LocalidadeRegra[x].cnomemun,
        });
      }
    }

    // seta array de restrições que estão selecionados do nivel atual
    this.regrasCliente.Restricao = []; // Inicializa a tela de regras com as regras já registradas.
    this.regrasCliente.Restricao = nivelAtual.Restricao;

    // Coloca o nome dos produtos nas restrições
    for (let x = 0; x < this.regrasCliente.Restricao.length; x++) {
      this.regrasCliente.Restricao[x].produtoName = "";
      if (this.regrasCliente.Restricao[x].RegraId === 12) {
        this.regrasCliente.Restricao[x].produtoName = "Todos";
        this.regrasCliente.Restricao[x].tolerancia =
          `até ${this.regrasCliente.Restricao[x].restricao.tolerancia}% acima`;
      }

      if (
        this.arrProdutos.find(
          (item) => item.idpfprod == this.regrasCliente.Restricao[x].produtoId
        )
      ) {
        this.regrasCliente.Restricao[x].produtoName = this.arrProdutos.find(
          (item) => item.idpfprod == this.regrasCliente.Restricao[x].produtoId
        ).cnome;
      }
    }
  }

  registerTypeBroadcast() {
    this.messageEventService.on().subscribe(() => {
      this.ngOnInit();
    });
  }

  verificarCarregado(): void {
    if (
      this.conteudoCarregadoV == true &&
      this.conteudoCarregadoR == true &&
      this.conteudoCarregadoP == true
    ) {
      this.conteudoCarregado = true;
    }
  }

  // Usados no template

  inverterRegra(inversao) {
    let titulo = "RESTAURAR INVERSAO DAS REGRAS DOS CREDENCIADO ?";
    let texto =
      "Deseja realmente RESTAURAR INVERSAO DAS REGRAS DOS CREDENCIADO, isso vai acarretar na remoção de todas as regras de credenciados em todo sistema ?";
    let cor = "#4CAF50";
    let confirmarButao = "Sim, RESTAURAR!";

    if (inversao == 1) {
      titulo = "INVERTER AS REGRAS DOS CREDENCIADO ?";
      texto =
        "Deseja realmente INVERTER AS REGRAS DOS CREDENCIADO, isso vai acarretar na remoção de todas as regras de credenciados em todo sistema ?";
      cor = "#DD6B55";
      confirmarButao = "Sim, INVERTER!";
    }

    Swal.fire({
      title: titulo,
      text: texto,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: cor,
      confirmButtonText: confirmarButao,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.conteudoCarregado = true;

        return this.regraService.setInversao({
          idcliente: this.idcliente,
          inverterregra: inversao,
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire(
            "Troca de Regras",
            "Troca de Regras do Credenciado com Sucesso!",
            "success"
          );
          this.inverterregra = inversao;
        } else {
          Swal.fire(
            "ERRO",
            "Não foi possivel fazer a Troca de Regras do Credenciado",
            "error"
          );
        }

        this.conteudoCarregado = true;
        this.emitTypeBroadcast();
      }
    });
  }

  emitTypeBroadcast() {
    this.messageEventService.fire("Message from " + this.regranivel);
  }

  verificaAddLocalidadeCliente(localizacao) {
    if (this.regranivel == 1 && this.inverterregra == "1") {
      Swal.fire({
        title: "Adicionar Localização ?",
        text: " Como as regras estão INVERTIDAS a inclusão ou remoção das localizações, IRA REMOVER todas as regras de credenciados desse nivel e dos niveis abaixo, Deseja realmente fazer isso ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, Adicionar!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.adicionarLocalizacao(localizacao);
        }
      });
    } else {
      this.adicionarLocalizacao(localizacao);
    }
  }

  adicionarLocalizacao(localizacao) {
    if (localizacao.cuf == "0" && localizacao.idmun == "0") {
      Swal.fire(
        "Selecione um Estado",
        "Você precisa pelo menos selecionar um estado",
        "error"
      );
    }

    let cnomemun = "Todos";
    // descobre o nome do campo selecionado. caso não ache nenhum coloca TODOS

    const itemCombo = this.regrasCliente.ComboLocalizacaoMun.find(
      (item) => item.idmun == localizacao.idmun
    );
    if (itemCombo != undefined) {
      cnomemun = itemCombo.cnomemun;
    }

    if (
      this.regrasCliente.Localizacao.length == 1 &&
      this.regrasCliente.Localizacao[0].estado == localizacao.cuf &&
      (this.regrasCliente.Localizacao[0].municipioId == 0 ||
        localizacao.idmun == 0)
    ) {
      // So existe 1 item dentro

      this.regrasCliente.Localizacao.splice(0, 1);
      this.regrasCliente.Localizacao.push({
        estado: localizacao.cuf,
        municipioId: localizacao.idmun,
        municipiocnome: cnomemun,
      });
      this.findCredenciadoLocalizacao(); // checa se e Invertido na posicao 1 e a magia acontece
    } else {
      if (localizacao.idmun == "0") {
        // Se for igual a zero primeiro verifica se existe algum estado cadastrado
        // Verifica se já existe um estado
        if (
          this.regrasCliente.Localizacao.find(
            (item) => item.estado == localizacao.cuf
          ) != undefined
        ) {
          Swal.fire(
            "Regra já existente",
            "Você não pode colocar uma regra para todos os municipios caso já exista para um ou todos os municipios",
            "error"
          );
          return;
        }
      }

      const verificaEstado = this.regrasCliente.Localizacao.find(
        (item) => item.estado == localizacao.cuf && item.municipioId == 0
      ); // Verifica se já existe um estado com municipio = 0;

      if (verificaEstado == undefined) {
        const result = this.regrasCliente.Localizacao.find(
          (item) =>
            item.estado == localizacao.cuf &&
            item.municipioId == localizacao.idmun
        );

        if (result == undefined) {
          this.regrasCliente.Localizacao.push({
            estado: localizacao.cuf,
            municipioId: localizacao.idmun,
            municipiocnome: cnomemun,
          });
          this.findCredenciadoLocalizacao(); // checa se e Invertido na posicao 1 e a magia acontece
        } else {
          Swal.fire(
            "Regra já existente",
            "Essa regra já existe na listagem de regras",
            "error"
          );
        }
      } else {
        // Se ele entrou aqui verificar se já existe somente o estado
        Swal.fire(
          "Regra já existente",
          "Você não pode colocar uma regra de Municipio caso exista uma regra de Estado para TODOS os municipios",
          "error"
        );
      }
    }
  }

  findCredenciadoLocalizacao() {
    // Se for invert
    if (this.regranivel == 1 && this.inverterregra == "1") {
      // rotina que vai listar SOMENTE os credenciados que existem nessas localizações.
      this.regraService
        .chamarCredenciadosCertos({
          idcliente: this.idcliente,
          tipo: this.regranivel,
          localidade: this.regrasCliente.Localizacao,
        })
        .subscribe((response) => {
          if (response.success) {
            this.regrasCliente.LocalidadeRegraComboCred =
              response.LocalidadeRegraComboCred;
          }
        });
    }
  }

  removerCredenciado(indice): void {
    Swal.fire({
      title: "REMOVER Credenciado ?",
      text: "Deseja realmente REMOVER esse Credenciado ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, Remover!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.regrasCliente.Credenciado.splice(indice, 1);
      }
    });
  }

  adicionarCredenciado(credenciado): void {
    const result = this.regrasCliente.Credenciado.find(
      (item) => item.idcred == credenciado
    );

    if (result == undefined) {
      let cnome = "";
      let codtln = "";
      let idcred = "";

      // descobre o nome do campo selecionado.
      const locRegComCred = this.regrasCliente.LocalidadeRegraComboCred.find(
        (item) => item.idcred == credenciado
      );
      if (locRegComCred != undefined) {
        cnome = locRegComCred.cfantasia;
        codtln = locRegComCred.codtn;
        idcred = locRegComCred.idcred;
      }

      this.regrasCliente.Credenciado.push({
        codtln: codtln,
        credenciadocnome: cnome,
        idcred: idcred,
      });
    } else {
      Swal.fire(
        "Regra já existente",
        "Esse Credenciado já se encontra cadastro nas regras",
        "error"
      );
    }
  }

  removerRegraLocalidade(indice): void {
    let complemento = "";

    if (this.regranivel == 1 && this.inverterregra == "1") {
      complemento =
        " Como as regras de Credenciados estão Invertidas isso fará com que os credenciados cadastrados sejam excluidos.";
    }

    Swal.fire({
      title: "REMOVER Localidade ?",
      text: "Deseja realmente remover essa localidade ?" + complemento,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, Remover!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.regrasCliente.Localizacao.splice(indice, 1);
        this.findCredenciadoLocalizacao();
      }
    });
  }

  carregarMunicipio(localidade): void {
    if (localidade != "" && localidade != "0") {
      this.preloaderMunicipio = true;

      this.regraService
        .carregarLocalizacao({
          idcliente: this.idcliente,
          tipo: this.regranivel,
          pesquisa: 2,
          cuf: localidade,
        })
        .subscribe((response) => {
          if (response.success) {
            this.regrasCliente.ComboLocalizacaoMun =
              response.LocalidadeRegraMunicipio;

            this.regrasCliente.ComboLocalizacaoMun.forEach((item) => {
              item.label = item.cnomemun;
            });
            this.regrasCliente.ComboLocalizacaoMun.unshift({
              label: "Selecione / Todos",
              idmun: "0",
            });

            this.localizacao.idmun = "0";
          }
          this.preloaderMunicipio = false;
        });
    } else {
      this.regrasCliente.ComboLocalizacaoMun = [];
      this.localizacao.idmun = "0";
    }
  }

  ativarTodosCombustiveis(lTodosCombustiveis): void {
    this.ativarTodos(lTodosCombustiveis, "1");
    this.lTodosCombustiveis = !lTodosCombustiveis;
  }

  ativarTodosOleo(lTodosOleo): void {
    this.ativarTodos(lTodosOleo, "2");
    this.lTodosOleo = !lTodosOleo;
  }

  ativarTodosFiltro(lTodosFiltro): void {
    this.ativarTodos(lTodosFiltro, "3");
    this.lTodosFiltro = !lTodosFiltro;
  }

  ativarTodosOutros(lTodosOutros): void {
    this.ativarTodos(lTodosOutros, "4");
    this.lTodosOutros = !lTodosOutros;
  }

  ativarTodosServicos(lTodosServicos): void {
    this.ativarTodos(lTodosServicos, "5");
    this.lTodosServicos = !lTodosServicos;
  }

  ativarTodosServicosDiversos(lTodosServiDiv): void {
    this.ativarTodos(lTodosServiDiv, "8");
    this.lTodosServiDiv = !lTodosServiDiv;
  }

  ativarTodos(status: boolean, tipo: string): void {
    for (let i = 0; i < this.arrProdutos.length; i++) {
      if (
        this.arrProdutos[i].idtipoproduto == tipo &&
        !this.arrProdutos[i].desable
      ) {
        this.arrProdutos[i].status = status;
      }
    }
  }

  adicionarRegraRestricaoCliente() {
    this.lcadastrandoRestricao = true;
    this.alimentarComboTipoProdutoCliente(); // Carrega os combos para selecionar

    this.permi = 0;
  }

  editarRestricao(indice) {
    this.editarRegra = true;
    this.editarRegraI = indice;

    this.alimentarComboTipoProdutoCliente();

    this.regrasCliente.RestricaoTipoProdutoCliente =
      this.regrasCliente.Restricao[indice].tipoProdId.toString();
    this.regrasCliente.RestricaoProdutoCliente =
      this.regrasCliente.Restricao[indice].produtoId.toString();

    const permi = this.regrasCliente.Restricao[indice].RegraId;

    this.regrasCliente.RestricaoTipoCliente = permi;

    this.permi = -1;

    this.permissao01 = {
      rodovia: false,
      urbano: false,
      restringir: false,
      restringirmax: false,
    };
    this.permissao03 = {
      km: 0.0,
      tolerancia: 0.0,
      tolerancia2: 0.0,
      restringir: false,
      restringirmax: false,
    };
    this.permissao04 = {
      tempo: "1",
      quantidade: 0.0,
      tolerancia: 0.0,
      restringir: false,
      restringirmax: false,
    };
    this.permissao05 = {
      horaini: "",
      horafim: "",
      restringir: false,
      restringirmax: false,
    };
    this.permissao06 = {
      valorlimite: 0.0,
      tolerancia: 0.0,
      restringir: false,
      restringirmax: false,
      cuf: "",
      idmun: [],
    };
    this.permissao07 = {
      quantidade: 0.0,
      tolerancia: 0.0,
      restringir: false,
      restringirmax: false,
    };
    this.permissao08 = {
      quantidade: 0.0,
      tolerancia: 0.0,
      restringir: false,
      restringirmax: false,
    };
    this.permissao09 = {
      tempo: "1",
      quantidade: 0,
      tolerancia: 0.0,
      restringir: false,
      restringirmax: false,
    };
    this.permissao10 = {
      quantidade: 0.0,
      tolerancia: 0.0,
      tolerancia2: 0.0,
      restringir: false,
      restringirmax: false,
    };
    this.permissao11 = {
      cnh_vencida: false,
      restringir: false,
    };
    this.permissao12 = {
      tolerancia: 0.0,
      restringir: false,
    };
    switch (permi) {
      case 1:
        this.permi = 1;

        this.permissao01 = {
          rodovia: this.regrasCliente.Restricao[indice].restricao.rodovia,
          urbano: this.regrasCliente.Restricao[indice].restricao.urbano,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 2:
        this.permi = 2;

        this.permissao02 = {
          dias: this.regrasCliente.Restricao[indice].restricao.dias,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 3:
        this.permi = 3;

        this.permissao03 = {
          km: this.regrasCliente.Restricao[indice].restricao.km,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          tolerancia2:
            this.regrasCliente.Restricao[indice].restricao.tolerancia2,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax:
            this.regrasCliente.Restricao[indice].restricao.restringirmax,
        };
        break;
      case 4:
        this.permi = 4;

        this.permissao04 = {
          tempo: this.regrasCliente.Restricao[indice].restricao.tempo,
          quantidade: this.regrasCliente.Restricao[indice].restricao.quantidade,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 5:
        this.permi = 5;

        this.permissao05 = {
          horaini: this.regrasCliente.Restricao[indice].restricao.horaini,
          horafim: this.regrasCliente.Restricao[indice].restricao.horafim,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 6:
        this.permi = 6;
        console.log(this.regrasCliente.Restricao[indice]);
        this.permissao06 = {
          valorlimite:
            this.regrasCliente.Restricao[indice].restricao.valorlimite,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
          cuf:
            this.regrasCliente.Restricao[indice].cuf ??
            this.regrasCliente.Restricao[indice].restricao.cuf,
          idmun: this.regrasCliente.Restricao[indice]?.idmun
            ? this.regrasCliente.Restricao[indice]?.idmun.split(",")
            : this.regrasCliente.Restricao[indice]?.restricao.idmun?.split(","),
        };

        if (this.permissao06.cuf) {
          this.getMunicipios(this.permissao06.cuf, false);
        }
        break;
      case 7:
        this.permi = 7;

        this.permissao07 = {
          quantidade: this.regrasCliente.Restricao[indice].restricao.quantidade,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 8:
        this.permi = 8;

        this.permissao08 = {
          quantidade: this.regrasCliente.Restricao[indice].restricao.quantidade,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 9:
        this.permi = 9;

        this.permissao09 = {
          tempo: this.regrasCliente.Restricao[indice].restricao.tempo,
          quantidade: parseInt(
            this.regrasCliente.Restricao[indice].restricao.quantidade
          ),
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax: false,
        };
        break;
      case 10:
        this.permi = 10;
        this.permissao10 = {
          quantidade: this.regrasCliente.Restricao[indice].restricao.quantidade,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
          tolerancia2:
            this.regrasCliente.Restricao[indice].restricao.tolerancia2,
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          restringirmax:
            this.regrasCliente.Restricao[indice].restricao.restringirmax,
        };
        break;
      case 11:
        this.permi = 11;

        this.permissao11 = {
          restringir: false,
          cnh_vencida: false,
        };
        break;
      case 12:
        this.permi = 12;

        this.permissao12 = {
          restringir: this.regrasCliente.Restricao[indice].restricao.restringir,
          tolerancia: this.regrasCliente.Restricao[indice].restricao.tolerancia,
        };
        break;
    }

    this.lcadastrandoRestricao = true;

    switch (this.regrasCliente.RestricaoTipoProdutoCliente) {
      case "1":
        {
          const index = this.regrasCliente.ComboRestricaoTipoCliente.indexOf(
            this.regrasCliente.ComboRestricaoTipoCliente.find(
              (item) => item.valor?.toString() == "9"
            )
          );
          if (index == -1) {
            this.regrasCliente.ComboRestricaoTipoCliente.push({
              name: "Litragem máxima",
              label: "Litragem máxima",
              valor: 9,
            });
          }
        }
        break;
      case "2":
        this.regrasCliente.ComboRestricaoTipoCliente[3].name =
          "Km para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[4].name =
          "Tempo para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[6].name =
          "Valor do Litro(M3)";

        if (this.regrasCliente.ComboRestricaoTipoCliente.length > 9) {
          this.regrasCliente.ComboRestricaoTipoCliente.pop();
        }
        break;
      case "3":
        this.regrasCliente.ComboRestricaoTipoCliente[3].name =
          "Km para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[4].name =
          "Tempo para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[6].name =
          "Valor da unidade";

        if (this.regrasCliente.ComboRestricaoTipoCliente.length > 9) {
          this.regrasCliente.ComboRestricaoTipoCliente.pop();
        }
        break;
      default:
        this.regrasCliente.ComboRestricaoTipoCliente[3].name = "Km/Litro(M3)";
        this.regrasCliente.ComboRestricaoTipoCliente[4].name =
          "Tempo entre Abastecimento";
        this.regrasCliente.ComboRestricaoTipoCliente[6].name =
          "Valor do Litro(M3)";

        if (this.regrasCliente.ComboRestricaoTipoCliente.length > 9) {
          this.regrasCliente.ComboRestricaoTipoCliente.pop();
        }
        break;
    }
  }

  alimentarComboTipoProdutoCliente(): void {
    this.regrasCliente.ComboRestricaoTipoProdutoCliente = [];
    this.regrasCliente.ComboRestricaoProdutosCliente = [];

    const produtosPermitidos = this.arrProdutos.filter(
      (item) => item.status == true
    );

    produtosPermitidos.forEach((produto) => {
      const tiposDeProduto = {
        "1": "COMBUSTÍVEIS",
        "2": "ÓLEOS",
        "3": "FILTROS",
        "4": "OUTROS",
        "5": "SERVIÇOS",
      };

      const nome = tiposDeProduto[produto.idtipoproduto] ?? "";

      const index = this.regrasCliente.ComboRestricaoTipoProdutoCliente.indexOf(
        this.regrasCliente.ComboRestricaoTipoProdutoCliente.find(
          (item) => item.name == nome
        )
      );

      if (index == -1 && nome != "") {
        this.regrasCliente.ComboRestricaoTipoProdutoCliente.push({
          name: nome,
          label: nome,
          id: produto.idtipoproduto,
        });
      }

      produto.label = produto.cnome;
      this.regrasCliente.ComboRestricaoProdutosCliente.push(produto);
    });

    this.regrasCliente.ComboRestricaoTipoProdutoCliente.unshift({
      label: "Selecione",
      id: "0",
    });

    this.regrasCliente.ComboRestricaoProdutosCliente.unshift({
      label: "Selecione",
      idpfprod: "0",
    });
    this.regrasCliente.ComboRestricaoProdutosCliente.unshift({
      label: "Todos",
      idtipoproduto: "1",
      idpfprod: "",
    });

    this.regrasCliente.RestricaoTipoProdutoCliente = "0";
    this.regrasCliente.RestricaoTipoCliente = "0";
    this.regrasCliente.RestricaoProdutoCliente = "0";
    this.regrasCliente.ComboLocalizacaoRestricaoCuf = [];
    this.regrasCliente.ComboLocalizacaoRestricaoMun = [];

    // regrasCliente.Localizacao
    // Configurar o combo do Estado, e quando pesquisar o estado puxar os municipios corretos.
    this.regrasCliente.Localizacao.forEach((localizacao) => {
      const verificaEstadoTodos =
        this.regrasCliente.ComboLocalizacaoRestricaoCuf.find(
          (item) => item.cuf == localizacao.estado
        );

      if (verificaEstadoTodos == undefined) {
        this.regrasCliente.ComboLocalizacaoRestricaoCuf.push({
          cuf: localizacao.estado,
          municipioId: localizacao.municipioId,
        });
      }
    });

    this.localizacaoRestricao.cuf = "0";
    this.localizacaoRestricao.idmun = "0";

    if (this.regrasCliente.ComboLocalizacaoRestricaoCuf.length == 1) {
      this.localizacaoRestricao.idmun =
        this.regrasCliente.ComboLocalizacaoRestricaoCuf[0].municipioId;
    }
  }

  controlaRegrasCliente(permi): void {
    this.permi = permi;

    switch (permi) {
      case "0":
        this.tituloRestricao = "Selecione a regra";
        break;
      case "1":
        this.tituloRestricao = "Permissão Sobre Tipo de Credenciado";

        this.permissao01.rodovia = false;
        this.permissao01.urbano = false;
        this.permissao01.restringir = false;
        this.permissao01.restringirmax = false;
        break;
      case "2":
        {
          this.tituloRestricao = "Dias da Semana Permitido";
          const diaPermissao02Padrao = {
            value: false,
            horaini: "",
            horafim: "",
          };
          this.permissao02.dias = [
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[0].label,
              column: this.permissao02.dias[0].column,
            },
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[1].label,
              column: this.permissao02.dias[1].column,
            },
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[2].label,
              column: this.permissao02.dias[2].column,
            },
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[3].label,
              column: this.permissao02.dias[3].column,
            },
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[4].label,
              column: this.permissao02.dias[4].column,
            },
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[5].label,
              column: this.permissao02.dias[5].column,
            },
            {
              ...diaPermissao02Padrao,
              label: this.permissao02.dias[6].label,
              column: this.permissao02.dias[6].column,
            },
          ];
          this.permissao02.restringir = false;
          this.permissao02.restringirmax = false;
        }
        break;
      case "3":
        this.tituloRestricao =
          this.regrasCliente.ComboRestricaoTipoCliente[3].name;
        this.permissao03.km = 0.0;
        this.permissao03.tolerancia = 0.0;
        this.permissao03.tolerancia2 = 0.0;
        this.permissao03.restringir = false;
        this.permissao03.restringirmax = false;
        break;
      case "4":
        this.tituloRestricao =
          this.regrasCliente.ComboRestricaoTipoCliente[4].name;
        this.permissao04.tempo = "1";
        this.permissao04.quantidade = 0.0;
        this.permissao04.tolerancia = 0.0;
        this.permissao04.restringir = false;
        this.permissao04.restringirmax = false;
        break;
      case "5":
        this.tituloRestricao = "Horário Permitido para Abastecimento";
        this.permissao05.horaini = "";
        this.permissao05.horafim = "";
        this.permissao05.restringir = false;
        this.permissao05.restringirmax = false;
        break;
      case "6":
        this.tituloRestricao =
          this.regrasCliente.ComboRestricaoTipoCliente[6].name;
        this.permissao06.valorlimite = 0.0;
        this.permissao06.tolerancia = 0.0;
        this.permissao06.restringir = false;
        this.permissao06.restringirmax = false;
        this.permissao06.cuf = "";
        this.permissao06.idmun = [];
        break;
      case "7":
        this.tituloRestricao = "Quantidade de Vezes no Mês";
        this.permissao07.quantidade = 0.0;
        this.permissao07.tolerancia = 0.0;
        this.permissao07.restringir = false;
        this.permissao07.restringirmax = false;
        break;
      case "8":
        this.tituloRestricao = "Quantidade por Vez";
        this.permissao08.quantidade = 0.0;
        this.permissao08.tolerancia = 0.0;
        this.permissao08.restringir = false;
        this.permissao08.restringirmax = false;
        break;
      case "9":
        {
          const regra = this.regrasCliente.ComboRestricaoTipoCliente.find(
            (item) => item.valor == 9
          );

          this.tituloRestricao = regra.name;
          this.permissao04.tempo = "1";
          this.permissao04.quantidade = 0.0;
          this.permissao04.tolerancia = 0.0;
          this.permissao04.restringir = false;
          this.permissao04.restringirmax = false;
        }
        break;
      case "10":
        this.tituloRestricao = "Intervalo de KM";
        this.permissao10.quantidade = 0.0;
        this.permissao10.tolerancia = 0.0;
        this.permissao10.tolerancia2 = 0.0;
        this.permissao10.restringir = false;
        this.permissao10.restringirmax = false;
        this.regrasCliente.RestricaoProdutoCliente = "1";
        this.regrasCliente.RestricaoTipoProdutoCliente = "1";
        break;
      case "11":
        this.tituloRestricao = "Abastecimento para motorista com CNH vencida";
        this.permissao11.cnh_vencida = false;
        this.permissao11.restringir = false;
        break;
      case "12":
        this.tituloRestricao = "Restrição de Valor ANP";
        this.permissao12.tolerancia = 0.0;
        this.permissao12.restringir = false;
        this.regrasCliente.RestricaoProdutoCliente = "0";
        break;
    }
  }

  carregarMunicipioRestricao(localidade) {
    if (localidade != "" && localidade != "0") {
      // Toda vez que eu pesquisar os estados eu preciso saber se o municipio e 0,
      // se for zero eu puxo tudo se não puxo somente o que tem no array de localidade do cliente

      const verificaEstado = this.regrasCliente.Localizacao.find(
        (item) => item.estado == localidade && item.municipioId == "0"
      ); // Verifica se já existe um estado com municipio = 0;

      if (verificaEstado != undefined) {
        // Achou o estado e são todos.
        this.regraService
          .carregarLocalizacao({
            idcliente: this.idcliente,
            tipo: this.regranivel,
            pesquisa: 2,
            cuf: localidade,
          })
          .subscribe((response) => {
            if (response.success) {
              this.regrasCliente.ComboLocalizacaoRestricaoMun =
                response.LocalidadeRegraMunicipio;
              this.localizacaoRestricao.idmun = "0";
            }
          });
      } else {
        // pesquisou o estado e nao tem TODOS os municipios então devo pegar de todos.
        this.regrasCliente.ComboLocalizacaoRestricaoMun = [];

        for (let i = 0; i < this.regrasCliente.Localizacao.length; i++) {
          if (this.regrasCliente.Localizacao[i].estado == localidade) {
            this.regrasCliente.ComboLocalizacaoRestricaoMun.push({
              cuf: localidade,
              idmun: this.regrasCliente.Localizacao[i].municipioId,
              cnomemun: this.regrasCliente.Localizacao[i].municipiocnome,
            });
          }
        }

        this.localizacaoRestricao.idmun = "0";
      }
    } else {
      this.regrasCliente.ComboLocalizacaoMun = [];
      this.localizacao.idmun = "0";
    }
  }

  getMunicipios(cuf, zerarMun = true): void {
    this.compoMunicipios = [];

    this.preloaderLocalidades = true;

    if (cuf == "-1") {
      this.preloaderLocalidades = false;
    } else {
      this.localidadeService.getLocalidades(cuf).subscribe((response) => {
        if (response.success) {
          this.compoMunicipios = response.localidades;
          this.compoMunicipios.forEach((item) => {
            item.label = item.cnomemun;
            item.idmun = item.idmun.toString();
          });
          this.compoMunicipios.unshift({
            label: "Todos",
            idmun: "",
          });

          if (zerarMun) this.permissao06.idmun = [];
        }
        this.preloaderLocalidades = false;
      });
    }
  }

  checkAllCitiesSelected(): void {
    if (this.permissao06.idmun.includes("")) {
      this.permissao06.idmun = [""];
    }
  }

  removerRestricao(indice): void {
    Swal.fire({
      title: "REMOVER Restrição ?",
      text: "Deseja realmente REMOVER essa Restrição ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, Remover!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.regrasCliente.Restricao.splice(indice, 1);
      }
    });
  }

  substituirRestricao(indice, restricao): void {
    this.regrasCliente.Restricao[indice] = restricao;
  }

  filtrarProdutos(id): void {
    this.removeAnpRestriction();
    this.regrasCliente.RestricaoTipoProdutoCliente = id;
    switch (this.regrasCliente.RestricaoTipoProdutoCliente) {
      case "1":
        {
          const index = this.regrasCliente.ComboRestricaoTipoCliente.indexOf(
            this.regrasCliente.ComboRestricaoTipoCliente.find(
              (item) => item.valor == 9
            )
          );
          if (index == -1) {
            this.regrasCliente.ComboRestricaoTipoCliente.push({
              name: "Litragem máxima",
              label: "Litragem máxima",
              valor: 9,
            });
          }
        }
        break;
      case "2":
        this.regrasCliente.ComboRestricaoTipoCliente[3].name =
          "Km para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[4].name =
          "Tempo para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[6].name =
          "Valor do Litro(M3)";

        if (this.regrasCliente.ComboRestricaoTipoCliente.length > 9) {
          this.regrasCliente.ComboRestricaoTipoCliente.pop();
        }
        break;
      case "3":
        this.regrasCliente.ComboRestricaoTipoCliente[3].name =
          "Km para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[4].name =
          "Tempo para próxima troca";
        this.regrasCliente.ComboRestricaoTipoCliente[6].name =
          "Valor da unidade";

        if (this.regrasCliente.ComboRestricaoTipoCliente.length > 9) {
          this.regrasCliente.ComboRestricaoTipoCliente.pop();
        }
        break;
      default:
        this.regrasCliente.ComboRestricaoTipoCliente[3].name = "Km/Litro(M3)";
        this.regrasCliente.ComboRestricaoTipoCliente[4].name =
          "Tempo entre Abastecimento";
        this.regrasCliente.ComboRestricaoTipoCliente[6].name =
          "Valor do Litro(M3)";

        if (this.regrasCliente.ComboRestricaoTipoCliente.length > 9) {
          this.regrasCliente.ComboRestricaoTipoCliente.pop();
        }
        break;
    }
  }

  removeAnpRestriction(): void {
    this.regrasCliente.ComboRestricaoTipoCliente =
      this.regrasCliente.ComboRestricaoTipoCliente.filter(
        (item) => item.valor !== 12
      );

    this.permi = 0;

    this.regrasCliente.RestricaoTipoCliente = "0";
  }

  salvarRegrasRestricao() {
    const tipoProd = this.regrasCliente.RestricaoTipoProdutoCliente;
    const Prod = this.regrasCliente.RestricaoProdutoCliente;

    const cnomeTipo =
      this.regrasCliente.ComboRestricaoTipoProdutoCliente.find(
        (tProd) => tProd.id == tipoProd
      ).name ?? "";
    const cvalueTipo = tipoProd;

    let cnomeProduto =
      this.regrasCliente.ComboRestricaoProdutosCliente.find(
        (prod) => prod.idpfprod == Prod
      ).cnome ?? "";
    let cvalueProduto: number | string = Prod;

    let descricao = "";
    let DescricaoRegra = "";
    let descricaoTolerancia = "";
    let descricaoTolerancia2 = "";

    if (tipoProd != "0") {
      if (Prod == "0") {
        cnomeProduto = "Todos";
        cvalueProduto = 0;
      }

      const tolerancia = "";

      let permi: {
        restringir?;
        restringirmax?;
        km?;
        tolerancia?;
        tolerancia2?;
        tempo?;
        quantidade?;
        horaini?;
        horafim?;
        valorlimite?;
        cuf?;
        idmun?;
        rodovia?;
        urbano?;
      } = {};

      switch (this.permi) {
        case 1:
          permi = this.permissao01;

          DescricaoRegra = "Permissão sobre tipo de Credenciados";

          if (permi.rodovia) {
            descricao += " EM RODOVIA";
          }

          if (permi.urbano) {
            if (descricao != "") {
              descricao += ", ";
            }

            descricao += " URBANO";
          }

          break;
        case 2:
          permi = this.permissao02;

          try {
            DescricaoRegra = "Dias da Semana Permitido";

            const diasPermitidos = this.permissao02.dias.filter(
              (dia) => dia.value
            );

            diasPermitidos.forEach((dia) => {
              descricao += descricao.length > 0 ? ", " : " ";
              descricao += dia.label.substring(0, 3);

              dia.horaini = dia.horaini.replace(/\D/g, "");
              dia.horafim = dia.horafim.replace(/\D/g, "");

              const horaInicial =
                dia.horaini.substring(0, 2) +
                ":" +
                (dia.horaini.substring(2, 4) || "00");
              const horaFinal =
                dia.horafim.substring(0, 2) +
                ":" +
                (dia.horafim.substring(2, 4) || "00");

              if (horaInicial.length < 1 || horaFinal.length < 1) {
                throw new Error("Os Horários não podem estar zerados!");
              }

              descricao += horaInicial;

              descricao += " - " + horaFinal;

              dia.horaini = horaInicial;
              dia.horafim = horaFinal;
            });
          } catch (error) {
            Swal.fire("ERRO", error.message, "error");
            return;
          }
          break;
        case 3:
          permi = this.permissao03;

          DescricaoRegra = "Km para próxima troca";

          if (tipoProd.toString() == "1") {
            DescricaoRegra = "Km/Litro(M3)";
          }

          if (permi.km == "" || permi.km == 0) {
            Swal.fire("ERRO", "O valor deve ser maior do que Zero", "error");
            return;
          }

          descricao = permi.km;

          if (parseFloat(permi.tolerancia) > 0) {
            descricaoTolerancia += permi.tolerancia + "% Cima - ";
          }

          if (permi.tolerancia2 > 0) {
            descricaoTolerancia += permi.tolerancia2 + "% Baixo ";
          }

          descricaoTolerancia2 = permi.tolerancia2;

          break;
        case 4:
          {
            permi = this.permissao04;

            DescricaoRegra = "Tempo para próxima troca";

            if (tipoProd.toString() == "1") {
              DescricaoRegra = "Tempo entre Abastecimento";
            }

            if (permi.quantidade == "" || permi.quantidade == 0) {
              Swal.fire("ERRO", "O valor deve ser maior do que Zero", "error");
              return;
            }

            descricao = permi.quantidade;
            descricaoTolerancia = permi.tolerancia;

            const tempo = permi.tempo;

            switch (tempo.toString()) {
              case "1":
                descricao = descricao + " Mes(es)";
                break;
              case "2":
                descricao = descricao + " Dia(s)";
                break;
              case "3":
                descricao = descricao + " Hora(s)";
                break;
            }
          }

          break;
        case 5:
          permi = this.permissao05;

          DescricaoRegra = "Horário Permitido";

          if (!this.permissao05.horaini) {
            Swal.fire("ERRO", "Não pode ter uma hora INICIAL zerada", "error");
            return;
          }

          descricao = this.permissao05.horaini;

          if (!this.permissao05.horafim) {
            Swal.fire("ERRO", "Não pode ter uma hora FINAL zerada", "error");
            return;
          }

          descricao += " - " + this.permissao05.horafim;

          permi.horaini = "2016-03-04 " + this.permissao05.horafim + ":00";
          permi.horafim = "2016-03-04 " + this.permissao05.horafim + ":00";

          break;
        case 6:
          {
            permi = {
              ...this.permissao06,
              idmun: this.permissao06.idmun.join(","),
            };

            DescricaoRegra = "Valor do Litro(M3)";

            if (permi.valorlimite == "" || permi.valorlimite == 0) {
              Swal.fire(
                "ERRO",
                "A Valor Limite deve ser maior do que Zero",
                "error"
              );
              return;
            }

            descricao = permi.valorlimite;
            descricaoTolerancia = permi.tolerancia;

            if (permi.cuf != "" && permi.cuf != "0") {
              descricao += "  / UF: " + permi.cuf;
            }

            let cnomeCidade = "";

            if (!this.permissao06.idmun.includes("")) {
              this.permissao06.idmun.forEach((idmun: string, index: number) => {
                cnomeCidade += this.compoMunicipios.find(
                  (mun) => mun.idmun == idmun
                )?.cnomemun;
                if (index < this.permissao06.idmun.length - 1) {
                  cnomeCidade += ", ";
                }
              });
            } else {
              cnomeCidade = "Todos";
            }

            descricao += " / Municipio: " + cnomeCidade;
          }
          break;
        case 7:
          permi = this.permissao07;

          DescricaoRegra = "Quantidade de Vezes no Mês";

          if (permi.quantidade == "" || permi.quantidade == 0) {
            Swal.fire("ERRO", "O valor deve ser maior do que Zero", "error");
            return;
          }

          descricao = permi.quantidade;
          descricaoTolerancia = permi.tolerancia;

          break;
        case 8:
          permi = this.permissao08;

          DescricaoRegra = "Quantidade de Compra por Vez";

          if (permi.quantidade == "" || permi.quantidade == 0) {
            Swal.fire("ERRO", "O valor deve ser maior do que Zero", "error");
          }

          descricao = permi.quantidade;
          descricaoTolerancia = permi.tolerancia;

          break;
        case 9:
          {
            permi = this.permissao09;

            DescricaoRegra = "Litragem máxima";

            if (permi.quantidade == "" || permi.quantidade == 0) {
              Swal.fire("ERRO", "O valor deve ser maior do que Zero", "error");
              return;
            }

            descricao = permi.quantidade;
            descricaoTolerancia = permi.tolerancia + " %";

            const tempo9 = permi.tempo;

            switch (tempo9.toString()) {
              case "1":
                descricao = descricao + " Litro(s) Mensal(is)";
                break;
              case "2":
                descricao = descricao + " Litro(s) Quinzenal(is)";
                break;
              case "3":
                descricao = descricao + " Litro(s) Semanal(is)";
                break;
            }
          }

          break;
        case 10:
          permi = this.permissao10;

          DescricaoRegra = "Intervalo de KM";

          if (permi.quantidade == "" || permi.quantidade == 0) {
            Swal.fire("ERRO", "O valor deve ser maior do que Zero", "error");
            return;
          }

          descricao = permi.quantidade + " Intervalo de KM";

          if (permi.tolerancia > 0) {
            descricaoTolerancia += permi.tolerancia + "% Cima - ";
          }

          if (permi.tolerancia2 > 0) {
            descricaoTolerancia += permi.tolerancia2 + "% Baixo ";
          }

          descricaoTolerancia2 = permi.tolerancia2;

          break;
        case 11:
          permi = this.permissao11;

          DescricaoRegra = "CNH Vencida";

          descricao = "Restrição se CNH está vencida";

          break;
        case 12:
          permi = this.permissao12;

          descricaoTolerancia = `até ${permi.tolerancia}% acima`;
          DescricaoRegra = "Valor ANP";
          if (Prod == "") {
            cnomeProduto = "Todos";
          }

          descricao =
            "Restrição de abastecimento se o valor do combustível estiver acima do valor ANP";

          break;
      }

      const restricao = {
        tipoProdId: cvalueTipo,
        tipoProdNome: cnomeTipo,
        produtoId: cvalueProduto,
        produtoName: cnomeProduto,
        RegraId: this.permi,
        DescricaoRegra: DescricaoRegra,
        restricao: permi,
        restricaoDescricao: descricao,
        tolerancia: descricaoTolerancia,
        tolerancia2: descricaoTolerancia2,
        restringir: permi.restringir,
        restringirmax: permi.restringirmax,
        restringirDescricao:
          permi.restringir || permi.restringirmax ? "SIM" : "NAO",
      };

      if (this.editarRegra) {
        this.substituirRestricao(this.editarRegraI, restricao);
      } else {
        const verificaregraAnterior = this.regrasCliente.RestricaoAnterior.find(
          (item) =>
            item.RegraId == this.permi &&
            item.produtoId == cvalueProduto &&
            item.tipoProdId == cvalueTipo
        );
        if (verificaregraAnterior != undefined) {
          // Significa que ele achou
          // Agora verificar se tem restricao
          if (verificaregraAnterior.restringir) {
            // nao tem restricao deixa cadastrar
            let valQuantAnterior;
            let valTolerAnterior;
            let quantidadeAtual;

            switch (this.permi) {
              case 3:
                // Agora verifica se tem uma restricao que e superior a que esta sendo Colocada.
                valQuantAnterior = Number(verificaregraAnterior.restricao.km);
                valTolerAnterior = Number(
                  verificaregraAnterior.restricao.tolerancia
                );

                valQuantAnterior =
                  valQuantAnterior +
                  (valQuantAnterior * valTolerAnterior) / 100;

                quantidadeAtual = parseFloat(permi.km);

                if (quantidadeAtual >= valQuantAnterior) {
                  Swal.fire(
                    "ERRO",
                    "Você não pode colocar essa regra, pois já existe uma Global que restringe",
                    "error"
                  );
                  return;
                }

                break;
              case 4:
              case 7:
              case 8:
              case 10:
              case 9:
                valQuantAnterior = Number(
                  verificaregraAnterior.restricao.quantidade
                );
                valTolerAnterior = Number(
                  verificaregraAnterior.restricao.tolerancia
                );

                valQuantAnterior =
                  valQuantAnterior +
                  (valQuantAnterior * valTolerAnterior) / 100;

                quantidadeAtual = parseFloat(permi.quantidade);

                if (quantidadeAtual >= valQuantAnterior) {
                  Swal.fire(
                    "ERRO",
                    "Você não pode colocar essa regra, pois já existe uma Global que restringe",
                    "error"
                  );
                  return;
                }

                break;
              case 6:
                valQuantAnterior = Number(
                  verificaregraAnterior.restricao.valorlimite
                );
                valTolerAnterior = Number(
                  verificaregraAnterior.restricao.tolerancia
                );

                valQuantAnterior =
                  valQuantAnterior +
                  (valQuantAnterior * valTolerAnterior) / 100;

                quantidadeAtual = parseFloat(permi.valorlimite);

                if (
                  quantidadeAtual >= valQuantAnterior &&
                  verificaregraAnterior.estado == permi.cuf &&
                  verificaregraAnterior.cidade == permi.idmun
                ) {
                  Swal.fire(
                    "ERRO",
                    "Você não pode colocar essa regra, pois já existe uma Global que restringe",
                    "error"
                  );
                  return false;
                }

                break;
              default:
                Swal.fire(
                  "ERRO",
                  "Você não pode colocar essa regra, pois já existe uma Global que restringe",
                  "error"
                );
                return;
            }
          }
        }

        const verificaCombustivel = this.regrasCliente.Restricao.find(
          (item) => {
            const isSameRule =
              item.RegraId == this.permi &&
              item.produtoId == cvalueProduto &&
              item.tipoProdId == cvalueTipo;

            if (!isSameRule) return false;

            if (this.permi == 6) {
              const previousCities = (
                item.idmun ?? item.restricao.idmun
              )?.split(",");
              const newCities = permi.idmun?.split(",");
              const previousState = item.cuf ?? item.restricao.cuf;
              const newState = permi.cuf;
              if (previousState != newState) return false;
              if (
                this.allCitiesAreSelected(previousCities) ||
                this.allCitiesAreSelected(newCities)
              )
                return true;

              return this.hasRepeatedCities(previousCities, newCities);
            }
            return true;
          }
        );
        if (verificaCombustivel == undefined) {
          console.log(restricao);
          this.regrasCliente.Restricao.push(restricao);
        } else {
          Swal.fire(
            "ERRO",
            "Já Existe cadastro de restrição para este TIPO DE PRODUTO + PRODUTO + REGRA" +
            (this.permi == 6 ? " + MUNICÍPIO(S)" : ""),
            "error"
          );
        }
      }
    } else {
      Swal.fire("ERRO", "Você deve selecionar um TIPO DE PRODUTO", "error");
    }

    this.lcadastrandoRestricao = false;
    this.permi = -1;
  }

  hasRepeatedCities(previousCities, newCities): boolean {
    return newCities.some(
      (city) => previousCities.includes(city) && city != ""
    );
  }

  allCitiesAreSelected(cities): boolean {
    return cities.includes("");
  }

  cancelarRegrasRestricaoCliente(): void {
    this.editarRegra = false;
    this.lcadastrandoRestricao = false;
    this.permi = -1;
  }

  salvarAsRegras(regras): void {
    const Parametros: {
      tipoproduto?;
      Credenciado?;
      Localizacao?;
      idcliente?;
      idrelacionado?;
      tipo?;
      Restricao?;
    } = {};
    Parametros.tipoproduto = [];

    const prodHabilitados = this.arrProdutos.filter((item) => item.status);

    for (let i = 0; i < prodHabilitados.length; i++) {
      Parametros.tipoproduto.push({
        valor: prodHabilitados[i].idpfprod,
        tipoproduto: prodHabilitados[i].idtipoproduto,
      });
    }

    Parametros.Credenciado = regras.Credenciado;
    Parametros.Localizacao = regras.Localizacao;
    Parametros.idcliente = this.idcliente;
    Parametros.idrelacionado = this.idrelacionado;
    Parametros.tipo = this.regranivel;
    Parametros.Restricao = regras.Restricao;

    Swal.fire({
      title: "SALVAR REGRAS?",
      text: "Você realmente deseja salvar as regras atuais?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, salvar!",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => {
        this.conteudoCarregado = false;
        return this.regraService.salvarRegras(Parametros);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire("Sucesso", "Regras salvas com sucesso!", "success").then(
            () => this.ngOnInit()
          );
        } else {
          Swal.fire("Erro", "Não foi possivel salvar as regras", "error");
        }

        this.conteudoCarregado = true;
      }
    });
  }

  handleAnpRestriction(): void {
    if (
      this.regrasCliente.RestricaoProdutoCliente === "" &&
      this.regrasCliente.RestricaoTipoProdutoCliente == "1"
    ) {
      this.regrasCliente.ComboRestricaoTipoCliente.push({
        label: "Restrição de Valor ANP",
        name: "Restrição de Valor ANP",
        valor: 12,
      });
    } else {
      this.removeAnpRestriction();
    }
  }

  /*  */
  goBack(): void {
    this.router.navigate(["../../../"], { relativeTo: this.route });
  }
}
