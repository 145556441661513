<div class="card">
  <div class="card-header bg-secondary h5 text-white">Avisos e Alertas</div>
  <div class="card-body card-padding">
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Média Km/L da Última
        Transação:
        <span class="fw-bold">{{
          arrVeiculos.medkmlulttrans | number : "1.2-2"
        }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Média Km/m3 GNV Geral:
        <span class="fw-bold">{{ GNVMark | number : "1.2-2" }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Média Km/L Geral:
        <span class="fw-bold">{{
          arrVeiculos.medkmlger | number : "1.2-2"
        }}</span>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Última troca de óleo:
        <span class="fw-bold">{{ arrVeiculos.nKmUltTrocaOleo }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Km da próxima troca:
        <span class="fw-bold">{{ arrVeiculos.nKmProxTrocaOleo }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Última troca de extintor:
        <span class="fw-bold">{{ arrVeiculos.dUltTrocaExt }}</span>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Data para a próxima
        troca:
        <span class="fw-bold">{{ arrVeiculos.dProxTrocaExt }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Última troca de Filtro
        Óleo:
        <span class="fw-bold">{{ arrVeiculos.nKmUltTrocaFiltroOleo }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Km para a próxima troca:
        <span class="fw-bold">{{ arrVeiculos.nKmProxTrocaFiltroOleo }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Última troca de filtro
        combustível:
        <span class="fw-bold">{{ arrVeiculos.nKmUltTrocaFiltroComb }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Km para a próxima troca:
        <span class="fw-bold">{{ arrVeiculos.nKmProxTrocaFiltroComb }}</span>
      </div>

      <div class="col">
        <i class="fa-solid fa-alarm-clock"></i> &nbsp; Dias para Vencimento do
        IPVA:
        <span class="fw-bold">{{ arrVeiculos.diasvctoipva }}</span>
      </div>
    </div>
  </div>
</div>

<div class="card mt-3">
  <div class="card-header bg-secondary h5 text-white">Evolução do Consumo</div>
  <div class="card-body card-padding">
    <div [chart]="chart" style="height: 400px"></div>
  </div>
</div>
