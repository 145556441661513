import {
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "app-cobrancas-fechamento",
  templateUrl: "./cobrancas-fechamento.component.html",
})
export class CobrancasFechamentoComponent implements OnInit {
  @Input() charges;
  @Input() lote: number;
  @Input() module: number;
  tipoUsuario: number;
  usuario;

  fechManager = {
    1: "idabastecimento",
    2: "idmanutencao",
    6: "idgnf",
    7: "idpedagio",
    9: "idcurso",
  };

  hasMinHeight = false;

  private userService = inject(UserService);

  @ViewChild("tableResponsive") tableResponsive!: ElementRef;

  ngOnInit(): void {
    this.tipoUsuario = this.userService.tipoUsuario;
    this.usuario = this.userService.usuario;
  }

  expandProductDetailsByIndex(indexCharge: number) {
    this.charges[indexCharge].productsDetailsCollapsed =
      !this.charges[indexCharge].productsDetailsCollapsed;
  }

  get isFueling() {
    return this.module === 1;
  }

  get isMaintenance() {
    return this.module === 2;
  }

  get isToll() {
    return this.module === 7;
  }

  get isInvoiceManager() {
    return this.module === 6;
  }

  get isCourses() {
    return this.module === 9;
  }

  get fechVar() {
    return this.fechManager[this.module];
  }

  checkScroll() {
    const div = this.tableResponsive.nativeElement;
    setTimeout(() => {
      if (div.scrollHeight > div.clientHeight) {
        this.hasMinHeight = true;
      } else {
        this.hasMinHeight = false;
      }
    }, 100);
  }
}
