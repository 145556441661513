<div class="row mb-3" *ngIf="tipoUsuario == 1">
  <div class="col pt-2">
    <div class="card">
      <div class="card-header bg-secondary mb-1 p-3">
        <h2 class="text-white fs-6 m-0">ACESSO ADMINISTRADOR/MASTER</h2>
      </div>
      <div class="card-body ps-4">
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">INFORME CODIGO TELENET DO CLIENTE:</label>
            <div class="input-group">
              <input class="form-control" [formControl]="codtnControl" />
              <button
                class="btn btn-info btn-shadow"
                type="button"
                (click)="showModalBuscaCliente = true"
              >
                <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
              </button>
            </div>
          </div>
          <div class="col d-flex align-items-end">
            <div class="row">
              <ng-content select="[extra-buttons]"> </ng-content>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="clientData.idcliente !== 0">
          <h4 class="fs-6 m-0">
            Cliente Selecionado:
            <span class="text-success">{{ clientData.ccliente }}</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
  <busca-clientes
    (closeBusca)="showModalBuscaCliente = false"
    (codtn)="setClienteCodtn($event)"
    *ngIf="showModalBuscaCliente"
  ></busca-clientes>
</div>
