<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="card" *ngIf="conteudoCarregado">
  <div class="card-header bg-secondary h5 text-white">
    <label class="form-label">Tipo de produto:</label>
    <div class="w-50">
      <app-search-select
        value="id"
        [arrToFilter]="[
          { id: 0, label: 'Todos' },
          { id: 1, label: 'Combustiveis' },
          { id: 2, label: 'Não combustiveis' }
        ]"
        [(ngValue)]="tipoHistorico"
        (ngValueChange)="trocarHistorico($event)"
      ></app-search-select>
    </div>
  </div>
  <div class="card-body card-padding">
    <div class="w-100 table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Produto</th>
            <th>Qtde</th>
            <th>Valor</th>
            <th>Motorista</th>
            <th>Cartão</th>
            <th>Credenciado</th>
            <th>Endereço</th>
            <th>Transação</th>
            <th>Odometro</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trans2 of arrTransacoesVeiculos">
            <td align="center">
              {{ trans2.dcadas | formatDate : "DD/MM/YYYY" }}
            </td>
            <td align="center">
              <button
                *ngIf="trans2.idtipo == 1"
                title="Aceita sem Inconsistencia"
                class="btn btn-success rounded-circle btn-shadow btn-icon"
              >
                <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                *ngIf="trans2.idtipo == 2"
                title="Aceita com Inconsistencia"
                class="btn btn-primary rounded-circle btn-shadow btn-icon"
              >
                <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                *ngIf="trans2.idtipo == 3"
                title="Aceita com liberação de restrição"
                class="btn btn-warning rounded-circle btn-shadow btn-icon"
              >
                <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                title="Rejeitada"
                *ngIf="trans2.idtipo == 4"
                class="btn btn-danger rounded-circle btn-shadow btn-icon"
              >
              <i class="fa-solid fa-r fa-sm"></i>
              </button>
            </td>
            <td align="center">{{ trans2.cproduto }}</td>
            <td align="center">{{ trans2.nqtd | number : "1.2-2" }}</td>
            <td align="center">{{ trans2.valortrans | real }}</td>
            <td align="center">{{ trans2.cmotorista }}</td>
            <td align="center">{{ trans2.tr_ncartao | cartao : 2 }}</td>
            <td align="center">{{ trans2.credenciado }}</td>
            <td align="center">{{ trans2.municipio }} - {{ trans2.uf }}</td>
            <td align="center">{{ trans2.idtrans }}</td>
            <td align="center">{{ trans2.tr_odometro }}</td>
            <td align="center">
              <button
                type="button"
                (click)="abrirModalTransacao(trans2.idtrans, trans2.idtipo)"
                class="btn btn-info btn-sm"
              >
                <i class="fa-solid fa-eye fa-fw"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-base-modal
  modalTitle="Dados da Transação"
  (closeModal)="visualizarTransacao = false"
  *ngIf="visualizarTransacao"
>
  <ng-container modal-body>
    <visualizar-transacoes
      *ngIf="visualizarTransacao"
      [modal]="1"
      [id]="idEsp"
      [tipo]="tipoEsp"
    ></visualizar-transacoes>
  </ng-container>
</app-base-modal>
