import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";

@Component({
  selector: "app-parts-details",
  templateUrl: "./parts-details.component.html",
  standalone: true,
  imports: [CommonModule],
})
export class PartsDetailsComponent {
  partsDetails = input();
}
