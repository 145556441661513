<div class="card">
  <div class="card-header bg-primary d-flex flex-column">
    <span class="h5 text-white">Planos de Revisão</span>
    <small class="text-white">
      Lista de revisões, peças trocadas e inspecionadas de acordo com a
      recomendação da montadora
    </small>
  </div>
  <div class="card-body card-padding">
    <div
      class="row d-flex flex-column mt-3"
      *ngFor="let item of revisionData; let i = index"
    >
      <div class="col mb-2">
        <span class="pb-2 border-bottom border-primary w-100 d-block fw-bold">
          Revisão dos {{ item.kilometers }}Km (ou {{ item.months }} meses)
        </span>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            Valor aproximado da revisão:
            <span class="text-primary">
              {{ item.fullPrice | currency : "BRL" }}
            </span>
          </div>
          <div class="col">
            Peças trocadas:
            <span class="text-primary">
              {{ item.changedParts.length }}
            </span>
            <div class="row" [visible]="visiblePart[i]" cCollapse>
              <ul>
                <li *ngFor="let changedPart of item.changedParts">
                  {{ changedPart.description }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            Itens inspecionados:
            <span class="text-primary">
              {{ item.inspections.length }}
            </span>
            <div class="row" [visible]="visibleInspection[i]" cCollapse>
              <ul>
                <li *ngFor="let inspection of item.inspections">
                  {{ inspection }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <button
              class="btn btn-success btn-shadow btn-sm text-uppercase"
              (click)="expandirRevisao(i)"
            >
              Revisão Completa <i class="fa-solid fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
