import { Component, Input, OnChanges } from "@angular/core";
import { DesempenhoService } from "../../../services/desempenho.service";
import { ClienteService } from "../../../../clientes/cliente.service";
import { Chart } from "angular-highcharts";

@Component({
  selector: "line-char-data-km-media-veiculo",
  templateUrl: "./line-char-data.component.html",
})
export class LineChartDataMediaVeiculoComponent implements OnChanges {
  charta = new Chart(<any>{
    chart: {
      type: "column",
      height: 400,
    },
    title: {
      text: "Média KM/L por tipo de veiculo",
    },
    credits: {
      enabled: false,
    },
    series: [],
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "top",
      x: 40,
      y: 0,
      floating: true,
      borderWidth: 1,
      itemStyle: {
        "font-size": "9px",
      },
    },
    plotOptions: {
      area: {
        fillOpacity: 0.2,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: [
        "Dez - Ano Anter.",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              enabled: false,
            },
          },
        },
      ],
    },
    // exporting: {
    //   enabled: false
    // },
    tooltip: {
      formatter: function () {
        return (
          "Média de " +
          this.series.name +
          ": " +
          String(this.y.toLocaleString("pt-BR")) +
          " Km/L no mês"
        );
      },
    },
  });

  lineChartColors = [
    "rgba(0,124,112,1)",
    "rgba(54,148,244,1)",
    "rgba(184,244,54,1)",
    "rgba(208,57,46,1)",
  ];

  graficoAtivo = false;
  @Input() filtros: {
    ano: string;
    placa: string;
    centroDeCusto: string;
  };

  constructor(
    private clienteService: ClienteService,
    private desempenhoService: DesempenhoService
  ) {}

  ngOnChanges(): void {
    this.defineFilterChanger();
  }

  defineFilterChanger(): void {
    this.graficoAtivo = false;

    this.desempenhoService
      .getInformativoClienteMediaKmLitroVeiculo({
        idcliente: this.clienteService.getCliente().idcliente,
        ano: this.filtros.ano,
        placa: this.filtros.placa,
        centrocusto: this.filtros.centroDeCusto,
      })
      .subscribe((response) => {
        this.removeAllSerie();

        if (response.desempenhoMediaKmLitro.length > 0) {
          for (let i = 0; i < response.desempenhoMediaKmLitro.length; i++) {
            for (
              let j = 0;
              j < response.desempenhoMediaKmLitro[i].data.length;
              j++
            ) {
              response.desempenhoMediaKmLitro[i].data[j] *= 1;
            }

            this.charta.addSeries(
              {
                name: response.desempenhoMediaKmLitro[i].label,
                data: response.desempenhoMediaKmLitro[i].data,
                color: this.lineChartColors[i],
                type: "column",
              },
              true,
              false
            );
          }
        }

        this.graficoAtivo = true;

        setTimeout(() => {
          this.charta.ref.reflow();
        }, 3);
      });
  }

  removeAllSerie() {
    const quant = this.charta.ref.series.length;

    for (let i = 0; i < quant; i++) {
      this.charta.removeSeries(0);
    }
  }
}
