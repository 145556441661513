<div class="row">
  <div class="col pb-2 text-end">
    <button
      class="btn btn-success text-uppercase btn-shadow btn-sm"
      (click)="filtrosAbrir = !filtrosAbrir"
    >
      <i class="fa-solid fa-filter"></i> &nbsp; Filtros
    </button>
    <button
      class="btn btn-secondary text-uppercase btn-shadow ms-1 btn-sm"
      (click)="limparFiltros()"
    >
      <i class="fa-solid fa-filter-slash"></i> &nbsp; Limpar Filtros
    </button>
    <button
      title="Pesquisar Transações"
      class="btn btn-info btn-shadow ms-1 btn-sm"
      (click)="filtrarDataGridLocal(true)"
    >
      <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
    </button>
  </div>
</div>

<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div
  class="row"
  cCollapse
  [visible]="filtrosAbrir"
  (keydown.enter)="filtrarDataGridLocal(true)"
  [hidden]="!conteudoCarregado"
>
  <div class="col">
    <div class="row pb-2" *ngIf="tipoUsuario == 1">
      <div class="col d-flex flex-column">
        <label class="form-label"> Rede: </label>
        <div class="w-100">
          <mat-checkbox
            *ngFor="let empresa of filtro.subredes"
            color="primary"
            [value]="empresa.idempresa"
            [(ngModel)]="empresa.selecionado"
            class="me-2"
          >
            {{ empresa.cnomeemp }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row pb-2" *ngIf="tipoUsuario != 3">
      <div class="col d-flex flex-column">
        <label class="form-label"> Tipo: </label>
        <div class="w-100">
          <mat-checkbox
            color="primary"
            value=""
            [(ngModel)]="filtro.campos[0].dado"
            class="me-2"
          >
            <span class="text-success">Aceita</span>
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            value=""
            [(ngModel)]="filtro.campos[1].dado"
            class="me-2"
          >
            <span class="text-primary">Aceita COM Inconsistência</span>
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            value=""
            [(ngModel)]="filtro.campos[2].dado"
            class="me-2"
          >
            <span class="text-warning">Aceita COM Liberação de Restrição</span>
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            value=""
            [(ngModel)]="filtro.campos[3].dado"
            class="me-2"
          >
            <span class="text-danger">Rejeitada</span>
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            value=""
            [(ngModel)]="filtro.campos[13].dado"
            class="me-2"
          >
            Canceladas
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row pb-2">
      <div class="col">
        <label class="form-label">Código de Autorização: </label>
        <input
          class="form-control"
          mask="000000AA"
          [(ngModel)]="filtro.campos[4].dado"
        />
      </div>
      <div class="col">
        <label class="form-label">Número do Cartão:</label>
        <input
          class="form-control"
          mask="0000 0000 0000 0000 0"
          [(ngModel)]="filtro.campos[5].dado"
        />
      </div>
      <div class="col">
        <label class="form-label">Placa do Veículo:</label>
        <input
          class="form-control"
          mask="SSS-AAAA"
          [(ngModel)]="filtro.campos[6].dado"
        />
      </div>
      <div class="col" *ngIf="tipoUsuario != 3">
        <label class="form-label">Codigo do Veículo:</label>
        <input class="form-control" [(ngModel)]="filtro.codveiculo" />
      </div>
    </div>
    <div class="row pb-2">
      <div class="col" *ngIf="tipoUsuario != 3">
        <label class="form-label">Cidade:</label>
        <input class="form-control" [(ngModel)]="filtro.campos[9].dado" />
      </div>
      <div class="col" *ngIf="tipoUsuario != 3">
        <label class="form-label">UF:</label>
        <app-search-select
          value="id"
          [arrToFilter]="[
            { id: 'AC', label: 'AC' },
            { id: 'AL', label: 'AL' },
            { id: 'AM', label: 'AM' },
            { id: 'AP', label: 'AP' },
            { id: 'BA', label: 'BA' },
            { id: 'CE', label: 'CE' },
            { id: 'DF', label: 'DF' },
            { id: 'ES', label: 'ES' },
            { id: 'GO', label: 'GO' },
            { id: 'MA', label: 'MA' },
            { id: 'MG', label: 'MG' },
            { id: 'MS', label: 'MS' },
            { id: 'MT', label: 'MT' },
            { id: 'PA', label: 'PA' },
            { id: 'PB', label: 'PB' },
            { id: 'PE', label: 'PE' },
            { id: 'PI', label: 'PI' },
            { id: 'PR', label: 'PR' },
            { id: 'RJ', label: 'RJ' },
            { id: 'RN', label: 'RN' },
            { id: 'RO', label: 'RO' },
            { id: 'RR', label: 'RR' },
            { id: 'RS', label: 'RS' },
            { id: 'SC', label: 'SC' },
            { id: 'SE', label: 'SE' },
            { id: 'SP', label: 'SP' },
            { id: 'TO', label: 'TO' }
          ]"
          [(ngValue)]="filtro.campos[10].dado"
        ></app-search-select>
      </div>
      <div class="col">
        <label class="form-label">Período da Transação de:</label>
        <input
          type="date"
          class="form-control"
          [(ngModel)]="this.filtro.campos[7].dado"
        />
      </div>
      <div class="col">
        <label class="form-label">Período da Transação até:</label>
        <input
          type="date"
          class="form-control"
          [(ngModel)]="this.filtro.campos[8].dado"
        />
      </div>
      <div class="col" *ngIf="tipoUsuario == 3">
        <label class="form-label">Razão ou Fantasia:</label>
        <input class="form-control" [(ngModel)]="filtro.cliente" />
      </div>
    </div>
    <div class="row align-items-end pb-2">
      <div class="col" *ngIf="tipoUsuario != 3 && tipoUsuario != 2">
        <label class="form-label">Cliente:</label>
        <div class="input-group">
          <input
            class="form-control"
            mask="000000"
            [(ngModel)]="filtro.campos[11].dado"
            (ngModelChange)="handleCentrosCusto()"
          />
          <button
            class="btn btn-info btn-shadow"
            type="button"
            (click)="abrirModalBuscaCliente()"
          >
            <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
          </button>
        </div>
      </div>
      <div class="col" *ngIf="tipoUsuario != 3 && !isCenterCostManager">
        <label class="form-label">Centro de Custo:</label>
        <app-search-select
          value="idregional"
          [arrToFilter]="arrCentrosCusto"
          [(ngValue)]="filtro.idregional"
        ></app-search-select>
      </div>
      <div class="col" *ngIf="tipoUsuario != 3">
        <label class="form-label">Credenciado:</label>
        <div class="input-group">
          <input
            class="form-control"
            mask="000000"
            [(ngModel)]="filtro.campos[12].dado"
          />
          <button
            class="btn btn-info btn-shadow"
            type="button"
            (click)="abrirModalBuscaCredenciado()"
          >
            <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
          </button>
        </div>
      </div>
      <div class="col">
        <label class="form-label">Cód. NF:</label>
        <input class="form-control" [(ngModel)]="filtro.idnf" />
      </div>
      <div class="col">
        <div class="form-group">
          <label class="form-label">Mostrar:</label>
          <app-search-select
            [arrToFilter]="['5', '10', '20', '30', '40', '50', '100']"
            [(ngValue)]="filtro.limite"
            (ngValueChange)="changeLimitePesquisa($event)"
          ></app-search-select>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="col">
    <div class="w-100 table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center" *ngIf="tipoUsuario == 1">Status Telenet</th>
            <th
              class="text-center"
              (click)="sortColun(0)"
              [ngClass]="getClassHeadTable(0)"
            >
              Cód. de Autorização
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario == 3"
              (click)="sortColun(13)"
              [ngClass]="getClassHeadTable(13)"
            >
              Situação
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario == 3"
              (click)="sortColun(14)"
              [ngClass]="getClassHeadTable(14)"
            >
              Razão Social
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario == 3"
              (click)="sortColun(14)"
              [ngClass]="getClassHeadTable(14)"
            >
              Nome Fantasia
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3"
              (click)="sortColun(1)"
              [ngClass]="getClassHeadTable(1)"
            >
              Tipo
            </th>
            <th
              class="text-center"
              (click)="sortColun(2)"
              [ngClass]="getClassHeadTable(2)"
            >
              Data e Hora
            </th>
            <th
              class="text-center"
              (click)="sortColun(3)"
              [ngClass]="getClassHeadTable(3)"
            >
              Cartão
            </th>
            <th class="text-center" *ngIf="tipoUsuario != 3"
              (click)="sortColun(15)"
              [ngClass]="getClassHeadTable(15)">Código Veículo</th>
            <th
              class="text-center"
              (click)="sortColun(4)"
              [ngClass]="getClassHeadTable(4)"
            >
              Placa
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3 && tipoUsuario != 2"
              (click)="sortColun(5)"
              [ngClass]="getClassHeadTable(5)"
            >
              Código do Cliente
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3 && tipoUsuario != 2"
              (click)="sortColun(6)"
              [ngClass]="getClassHeadTable(6)"
            >
              Cliente
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3 && tipoUsuario != 2"
              (click)="sortColun(7)"
              [ngClass]="getClassHeadTable(7)"
            >
              Código do Credenciado
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3"
              (click)="sortColun(8)"
              [ngClass]="getClassHeadTable(8)"
            >
              Credenciado
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3"
              (click)="sortColun(9)"
              [ngClass]="getClassHeadTable(9)"
            >
              Cidade
            </th>
            <th
              class="text-center"
              *ngIf="tipoUsuario != 3"
              (click)="sortColun(10)"
              [ngClass]="getClassHeadTable(10)"
            >
              UF
            </th>
            <th class="text-center" *ngIf="tipoUsuario != 3">Odometro</th>
            <th
              class="text-center"
              (click)="sortColun(11)"
              [ngClass]="getClassHeadTable(11)"
            >
              Valor
            </th>
            <th class="text-center">Valor ANP</th>
            <th
              class="text-center"
              (click)="sortColun(12)"
              [ngClass]="getClassHeadTable(12)"
            >
              DOC
            </th>
            <th class="text-center">Cód. NF</th>
            <th style="min-width: 160px">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trans of arrTransacoes">
            <td
              *ngIf="tipoUsuario == 1"
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.tn_statusConfirma }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.idtransunico }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario == 3"
            >
              {{ trans.csituacao }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3"
            >
              <button
                class="btn btn-success rounded-circle btn-shadow btn-icon"
                *ngIf="trans.idtipo == 1 && trans.tr_codterm != 'WEB_FROTA'"
                title="Aceita sem Inconsistencia"
              >
                <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                class="btn btn-primary rounded-circle btn-shadow btn-icon"
                *ngIf="trans.idtipo == 2 && trans.tr_codterm != 'WEB_FROTA'"
                title="Aceita com Inconsistencia"
              >
                <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                class="btn btn-warning rounded-circle btn-shadow btn-icon"
                *ngIf="trans.idtipo == 3 && trans.tr_codterm != 'WEB_FROTA'"
                title="Aceita com liberação de restrição"
              >
                <i class="fa-solid fa-a fa-sm"></i>
              </button>
              <button
                class="btn btn-danger rounded-circle btn-shadow btn-icon"
                *ngIf="trans.idtipo == 4 && trans.tr_codterm != 'WEB_FROTA'"
                title="Rejeitada"
              >
                <i class="fa-solid fa-r fa-sm"></i>
              </button>
              <button
                class="btn btn-success rounded-circle btn-shadow btn-icon btn-text"
                *ngIf="trans.idtipo == 1 && trans.tr_codterm == 'WEB_FROTA'"
                title="Aceita sem Inconsistencia"
              >
                <ng-container *ngIf="!trans.idqrcode"> WEB </ng-container>
                <ng-container *ngIf="trans.idqrcode"> APP </ng-container>
              </button>
              <button
                class="btn btn-primary rounded-circle btn-shadow btn-icon btn-text"
                *ngIf="trans.idtipo == 2 && trans.tr_codterm == 'WEB_FROTA'"
                title="Aceita com Inconsistencia"
              >
                <ng-container *ngIf="!trans.idqrcode"> WEB </ng-container>
                <ng-container *ngIf="trans.idqrcode"> APP </ng-container>
              </button>
              <button
                class="btn btn-warning rounded-circle btn-shadow btn-icon btn-text"
                *ngIf="trans.idtipo == 3 && trans.tr_codterm == 'WEB_FROTA'"
                title="Aceita com liberação de restrição"
              >
                <ng-container *ngIf="!trans.idqrcode"> WEB </ng-container>
                <ng-container *ngIf="trans.idqrcode"> APP </ng-container>
              </button>
              <button
                class="btn btn-danger rounded-circle btn-shadow btn-icon btn-text"
                *ngIf="trans.idtipo == 4 && trans.tr_codterm == 'WEB_FROTA'"
                title="Rejeitada"
              >
                <ng-container *ngIf="!trans.idqrcode"> WEB </ng-container>
                <ng-container *ngIf="trans.idqrcode"> APP </ng-container>
              </button>
            </td>
            <td
              *ngIf="tipoUsuario == 3"
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.razao }}
            </td>
            <td
              *ngIf="tipoUsuario == 3"
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.cliente }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.dcadasFormat }}
            </td>
            <td>
              <span class="text-danger" *ngIf="trans.lcoringa == 1">{{
                trans.tr_ncartao | cartao : tipoUsuario
              }}</span>
              <span *ngIf="trans.lcoringa == 0">{{
                trans.tr_ncartao | cartao : tipoUsuario
              }}</span>
            </td>
            <td
              *ngIf="tipoUsuario != 3"
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.codveiculo }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.placa }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3 && tipoUsuario != 2"
            >
              {{ trans.codtn_cliente }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3 && tipoUsuario != 2"
              style="
                max-width: 130px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ trans.cliente }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3 && tipoUsuario != 2"
            >
              <ng-container *ngIf="trans.credenciado != 'GOODCARD'">
                {{ trans.codtn_cred }}
              </ng-container>
              <ng-container *ngIf="trans.credenciado == 'GOODCARD'">
                -
              </ng-container>
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3"
              style="
                max-width: 130px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <ng-container *ngIf="trans.credenciado != 'GOODCARD'">
                {{ trans.credenciado }}
              </ng-container>
              <ng-container *ngIf="trans.credenciado == 'GOODCARD'">
                {{ trans.goodNOME }}
              </ng-container>
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3"
            >
              <ng-container *ngIf="trans.credenciado != 'GOODCARD'">
                {{ trans.municipio }}
              </ng-container>
              <ng-container *ngIf="trans.credenciado == 'GOODCARD'">
                {{ trans.goodCIDADE }}
              </ng-container>
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3"
            >
              <ng-container *ngIf="trans.credenciado != 'GOODCARD'">
                {{ trans.uf }}
              </ng-container>
              <ng-container *ngIf="trans.credenciado == 'GOODCARD'">
                {{ trans.goodESTADO }}
              </ng-container>
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
              *ngIf="tipoUsuario != 3"
            >
              {{ trans.tr_odometro }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.valortrans | real }}
              <br />
              {{
                trans.nparcelas != NULL
                  ? "Parcela " + trans.parcela + " de " + trans.nparcelas
                  : ""
              }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.valor_anp | real }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.idTransAGI }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
              align="center"
            >
              {{ trans.idnf }}
            </td>
            <td
              [ngClass]="{
                linhaCancelada: trans.csituacao == 'CANCELADA',
                linhaPendente: trans.csituacao == 'PENDENTE'
              }"
            >
              <button
                type="button"
                *ngIf="
                  trans.idveiculo != '0' &&
                  trans.csituacao != 'PENDENTE' &&
                  tipoUsuario != 3
                "
                class="btn btn-sm me-1 btn-frota"
                title="Visualizar Veículo"
                (click)="abrirModalVeiculo(trans.idveiculo)"
              >
                <i class="fa-solid fa-car text-white"></i>
              </button>
              <button
                type="button"
                *ngIf="trans.csituacao != 'PENDENTE'"
                class="btn btn-sm me-1 btn-info"
                title="Visualizar Transação"
                [routerLink]="['visualizar', trans.idtrans, trans.tiponome]"
              >
                <i class="fa-solid fa-eye"></i>
              </button>
              <button
                type="button"
                *ngIf="
                  trans.csituacao != 'CANCELADA' &&
                  trans.idtipo != 4 &&
                  idperfil == 2
                "
                class="btn btn-sm me-1 btn-secondary"
                title="Cancelar Transação"
                (click)="cancelarTransacao(trans.idtrans)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
              <button
                type="button"
                *ngIf="
                  usuarioCancela &&
                  trans.idtipo != 4 &&
                  trans.csituacao == 'ABERTA'
                "
                class="btn btn-sm me-1 btn-danger"
                title="Cancelar Transacão"
                (click)="cancelar(trans.idtrans)"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row justify-content-end" *ngIf="conteudoCarregado">
  <div class="col-auto">
    <button
      type="button"
      disabled
      class="btn border border-0 me-2 btn-shadow linhaPendente"
    >
      PENDENTES *Validada em breve
    </button>
    <button
      type="button"
      disabled
      class="btn border border-0 btn-shadow linhaCancelada"
    >
      CANCELADAS
    </button>
  </div>
</div>

<div class="row d-flex justify-content-end" [hidden]="!conteudoCarregado">
  <div class="col-auto">
    <ngb-pagination
      [(page)]="filtro.paginaAtual"
      [pageSize]="filtro.limite"
      [collectionSize]="filtro.quantidade"
      [boundaryLinks]="true"
      [maxSize]="8"
      [rotate]="true"
      (pageChange)="paginacaoDireto($event)"
    ></ngb-pagination>
  </div>
</div>

<app-base-modal
  modalTitle="Dados sobre Veiculo"
  modalSize="xl"
  (closeModal)="showModalVeiculo = false"
  *ngIf="showModalVeiculo"
>
  <ng-container modal-body>
    <visualizar-veiculos
      *ngIf="visualizarVeiculo"
      [modal]="true"
      [idveiculo]="idVeiculoVisualizar"
    >
    </visualizar-veiculos>
  </ng-container>
</app-base-modal>

<busca-credenciado
  (codtn)="setCredCodtn($event)"
  (closeBusca)="showModalBuscaCredenciado = false"
  *ngIf="showModalBuscaCredenciado"
></busca-credenciado>

<busca-clientes
  (closeBusca)="showModalBuscaCliente = false"
  (codtn)="setClienteCodtn($event)"
  *ngIf="showModalBuscaCliente"
></busca-clientes>
