import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";

@Component({
  selector: "app-toll-details",
  templateUrl: "./toll-details.component.html",
  standalone: true,
  imports: [CommonModule],
})
export class TollDetailsComponent {
  tollsDetails = input();
}
