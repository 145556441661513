<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<ng-container *ngIf="conteudoCarregado">
  <div class="row pb-2 align-items-end">
    <div class="col-6">
      <label class="form-label" requiredInput>CPF/CNPJ:</label>
      <input
        class="form-control"
        mask="CPF_CNPJ"
        [disabled]="cpfValido"
        autocomplete="off"
        [(ngModel)]="usuario.cgc"
        (change)="cpfValido = false"
      />
    </div>
    <div class="col-auto">
      <button class="btn btn-success" *ngIf="!cpfValido" (click)="verificar()">
        <i class="fa-solid fa-check"></i> Verificar
      </button>
    </div>
  </div>
  <ng-container *ngIf="cpfValido">
    <div class="row">
      <div class="col">
        <div class="row pb-2">
          <div class="col">
            <label class="form-label" requiredInput>Nome:</label>
            <input class="form-control" [(ngModel)]="usuario.nome" />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col">
            <label class="form-label" requiredInput>E-mail:</label>
            <input
              class="form-control"
              [(ngModel)]="usuario.email"
              [formControl]="formControlEmail"
            />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col">
            <label class="form-label" requiredInput>Status:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                { id: 1, label: 'Ativo' },
                { id: 2, label: 'Bloqueado' }
              ]"
              [(ngValue)]="usuario.status"
            ></app-search-select>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col">
            <label class="form-label" requiredInput>Perfil:</label>
            <app-search-select
              value="id"
              [arrToFilter]="arrPerfis"
              [(ngValue)]="usuario.idperfil"
              [disabled]="usuario.idperfil == '9' || usuario.idperfil == '15'"
            ></app-search-select>
          </div>
        </div>
        <div
          class="row pb-2"
          *ngIf="usuario.idperfil == '6' || usuario.idperfil == '18'"
        >
          <div class="col">
            <label class="form-label">Sub-rede:</label>
            <div class="w-100">
              <mat-radio-group [(ngModel)]="usuario.idempresa">
                <mat-radio-button
                  *ngIf="usuario.idperfil == '18'"
                  color="primary"
                  value="0"
                  class="me-2"
                >
                  Nenhuma
                </mat-radio-button>
                <mat-radio-button
                  *ngFor="let empresa of arrEmpresas"
                  color="primary"
                  [value]="empresa.idempresa"
                  class="me-2"
                >
                  {{ empresa.cnomeemp }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div
          class="row pb-2"
          *ngIf="
            usuario.idperfil == '10' ||
            usuario.idperfil == '11' ||
            usuario.idperfil == '12'
          "
        >
          <div class="col">
            <label class="form-label">Centro de Custo:</label>
            <app-search-select
              value="idregional"
              [arrToFilter]="arrCentroCusto"
              [(ngValue)]="usuario.centrocusto"
            ></app-search-select>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row pb-2">
          <div class="col">
            <label class="form-label" requiredInput *ngIf="usuario.id == 0">
              Senha:
            </label>
            <label class="form-label" *ngIf="usuario.id > 0"> Senha: </label>
            <div class="input-group">
              <input
                [type]="senhaVisivel ? 'text' : 'password'"
                class="form-control border-end-0"
                placeholder="Confirmar Senha"
                [(ngModel)]="usuario.senha"
              />
              <span
                class="input-group-text bg-transparent border-start-0 pointer"
                (click)="senhaVisivel = !senhaVisivel"
              >
                <i
                  class="fa-sharp text-secondary"
                  [ngClass]="senhaVisivel ? 'fa-eye-slash' : 'fa-eye'"
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="usuario.id == 0 || usuario.senha">
          <div class="col">
            <div class="text-danger fw-bold">
              <div
                class="w-100"
                [ngClass]="{
                  'text-success': usuario.senha.match('^(?=.*[A-Z])')
                }"
              >
                A senha precisa ter pelo menos uma letra maiúscula
              </div>
              <div
                class="w-100"
                [ngClass]="{
                  'text-success': usuario.senha.match('(?=.*[a-z])')
                }"
              >
                A senha precisa ter pelo menos uma letra minúscula
              </div>
              <div
                class="w-100"
                [ngClass]="{
                  'text-success': usuario.senha.match('(.*[0-9].*)')
                }"
              >
                A senha precisa ter pelo menos um número
              </div>
              <div
                class="w-100"
                [ngClass]="{
                  'text-success': usuario.senha.match('(?=.*[!@#$%^&*])')
                }"
              >
                A senha precisa ter pelo menos um caractere especial
              </div>
              <div
                class="w-100"
                [ngClass]="{
                  'text-success': usuario.senha.match('.{8,}')
                }"
              >
                A senha precisa ter pelo menos 8 caracteres
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row pb-2"
      *ngIf="usuario.idperfil == '3' || usuario.idperfil == '2'"
    >
      <div class="col">
        <mat-checkbox
          color="primary"
          value="0"
          [(ngModel)]="usuario.alttransacao"
          class="text-success me-2"
        >
          Conceder Permissão para Alterar as Transações
        </mat-checkbox>
      </div>
      <div class="col">
        <mat-checkbox
          color="primary"
          value="0"
          [(ngModel)]="usuario.deltransacao"
          class="text-success me-2"
        >
          Conceder Permissão para Excluir as Transações
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <dual-list
          [source]="arrModulos"
          display="nome"
          key="id"
          height="212px"
          [filter]="true"
          [format]="format"
          [(destination)]="usuario.modulos"
        ></dual-list>
      </div>
    </div>
  </ng-container>
</ng-container>

<div class="row pt-3">
  <div class="col text-end">
    <button
      class="btn btn-success"
      *ngIf="cpfValido && conteudoCarregado"
      (click)="salvar(usuario)"
    >
      <i class="fa-solid fa-check"></i> Salvar Alterações
    </button>
    <button class="btn btn-danger ms-1" (click)="voltarPesquisa()">
      <i class="fa-solid fa-close"></i>
      Cancelar
    </button>
  </div>
</div>
