import { Component, OnInit } from "@angular/core";
import { RegraService } from "../regras.service";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";

@Component({
  selector: "app-log-alteracoes-regras",
  templateUrl: "./log-alteracoes-regras.component.html",
})
export class LogAlteracoesRegrasComponent implements OnInit {
  arrDados = [];
  camposRegras = {
    produtos2: { label: "Regras de Produtos" },
    localidade: { label: "Regras de Localização" },
    credenciados: { label: "Regras de Rede Por Credenciado" },
    restricoes: { label: "Regras de Restrições" },
  };
  cliente;
  exclusiveFields = [];

  constructor(
    private regraService: RegraService,
    private clienteService: ClienteService
  ) {}

  ngOnInit() {
    this.cliente = this.clienteService.getCliente();
    this.regraService
      .getLogsRegras({ idcliente: this.cliente.idcliente })
      .subscribe((res) => {
        res.dados.forEach((item) => {
          item.diferencas = item.diferencas.map((diff) => ({
            ...diff,
            depois: diff.depois.filter((diffAntes) => diffAntes !== null),
          }));
          this.arrDados.push(item);
        });
      });
  }

  doIsNaN(variable): boolean {
    return (
      isNaN(variable) || variable == "" || !variable.toString().includes(".")
    );
  }
}
