<div class="w-100 ps-3 py-2 fs-6 text-dark fw-semibold">
  Detalhamento da cobrança {{ tollsDetails()[0].idcobranca }}
</div>
<div class="w-100 table-responsive">
  <table class="table table-bordered table-striped mb-0 border-secondary">
    <thead>
      <tr>
        <th class="text-center border-top-0 border-start-0">Descrição</th>
        <th class="text-center border-top-0">Quantidade</th>
        <th class="text-center border-top-0">Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detalhe of tollsDetails()">
        <td class="border-start-0">
          {{
            (detalhe.codveiculo ? detalhe.codveiculo + " - " : "") +
              detalhe.descricao
          }}
        </td>
        <td class="text-center">
          {{ detalhe.nqtditens | number: "1.0-0" }}
        </td>
        <td class="text-center">R$ {{ detalhe.nvalor | number: "1.2-2" }}</td>
      </tr>
    </tbody>
  </table>
</div>
