import { Component, Input, ViewEncapsulation, OnInit } from "@angular/core";

declare const Stimulsoft: any;

@Component({
  selector: "stimulsoft-report",
  templateUrl: "./stimulsoft-report.html",
  encapsulation: ViewEncapsulation.None,
})
export class StimulsoftReportComponent implements OnInit {
  viewer: any;
  report: any = new Stimulsoft.Report.StiReport();

  @Input() raw: boolean = false;
  @Input("dados") dados: any;
  @Input("caminhoRelatorio") caminhoRelatorio: any;
  @Input("tipoZoom") tipoZoom = "width";

  ngOnInit() {
    Stimulsoft.Base.StiLicense.key =
      "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn1YEM/IsqveG10jZ6w9/aJas/Hmp60g5tQzBWf/yJEUcn+yx" +
      "fvJ1St/lUJbcD1TQFrJsWkJoqMytuvIVVE2TxC2IcIIIB96/a+bQv1JSNXcsvK5cURDQIBFqheXGaTwIaX17b74ZS9" +
      "NNg4/wnl3FuhwJfUbIy9bIwPSM+WFi0pMGDQotid6I6RPaCqkOH+RPG0QVHe0IjAoWWFEXXC7eROMAUM3dEWGHA9KI" +
      "ewhSXQXZua9N2Z2dCxBq9CQ6/Tj01ltYvP4od3AiRVOjAUezuIGi6A1owiWJ1G6nHMHiMRHKAcvTGp6U68pAJglno9" +
      "pEF+Dm/RxgCeSqRHLTLB0UOeR+Lc9f0TeenQUeSOL1J+BczaY+GTUN3iuiV9cM6n9+SqHhyoNO16CSxQRIqfxnIjhc" +
      "5k15tzhBLsuUjY3PAQO7s5P2LhzOQDtU8vkVunUTqbxSZoI+cJV3xx2gTSLyJiFsckZ9dpAfekE03gkfX+HlgWBuU0" +
      "dDggyJw8AU1hvafFx4qR7VEwEvKHoZPDuwp1G/6bah/hlQUUd6PHz87UQQ==";

    const options = new Stimulsoft.Viewer.StiViewerOptions();

    options.appearance.scrollbarsMode = true;

    if (this.tipoZoom == "width") {
      options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;
    } else {
      options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageHeight;
    }

    options.height = "700px";
    options.toolbar.showBookmarksButton = false;
    options.toolbar.showParametersButton = false;
    options.toolbar.showFullScreenButton = false;

    this.viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);

    let infos: any = {};

    infos = { ...this.dados };

    this.report.loadFile(this.caminhoRelatorio);

    setTimeout(() => {
      const dataSet = new Stimulsoft.System.Data.DataSet("DadosRelatorio");

      Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile(
        "assets/Localizations/pt-BR.xml"
      );

      // Load JSON data file from specified URL to the DataSet object
      dataSet.readJson(infos);

      // Remove all connections from the report template
      this.report.dictionary.databases.clear();
      // Register DataSet object
      this.report.regData("DadosRelatorio", "DadosRelatorio", dataSet);
      // Render report with registered data

      this.viewer.report = this.report;
      this.viewer.renderHtml("viewer");
    }, 10);
  }
}
