<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" [hidden]="!conteudoCarregado">
  <div class="col">
    <div class="row">
      <div class="col">
        <div class="w-100 border border-primary p-1">
          <div class="row">
            <div class="col-auto">
              <img src="assets/img/icon_carro_topo.png" width="90" />
            </div>
            <div class="col d-flex flex-column justify-content-center">
              <div class="mb-1">
                Placa:
                <span class="text-primary">
                  {{ vehicleData?.plate ?? "Não Informado" }}
                </span>
              </div>
              <div class="mb-1">
                Montadora:
                <span class="text-primary">
                  {{ fipeData?.makerDescription ?? "Não Informado" }}
                </span>
              </div>
              <div class="mb-1">
                Modelo:
                <span class="text-primary">
                  {{ fipeData?.modelDescription ?? "Não Informado" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          dynamicHeight
        >
          <mat-tab label="Cadastro">
            <div class="container-fluid pt-3">
              <app-cadastro
                [vehicleData]="vehicleData"
                *ngIf="conteudoCarregado"
              ></app-cadastro>
            </div>
          </mat-tab>
          <mat-tab label="Fipe">
            <div class="container-fluid pt-3">
              <app-fipe
                [fipeData]="fipeData"
                [ipvaData]="ipvaData"
                *ngIf="conteudoCarregado"
              ></app-fipe>
            </div>
          </mat-tab>
          <mat-tab label="Especificações" *ngIf="specsData">
            <div class="container-fluid pt-3">
              <app-especificacoes
                [specsData]="specsData"
                *ngIf="conteudoCarregado"
              ></app-especificacoes>
            </div>
          </mat-tab>
          <mat-tab label="Revisões" *ngIf="revisionData">
            <div class="container-fluid pt-3">
              <app-revisoes
                [revisionData]="revisionData"
                *ngIf="conteudoCarregado"
              ></app-revisoes>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<div class="row mt-2" *ngIf="!modal">
  <div class="col text-end">
    <button class="btn btn-danger btn-shadow" (click)="handleBack()">
      <i class="fa-solid fa-arrow-left"></i>
      Voltar
    </button>
  </div>
</div>
