<app-base-modal
  [modalTitle]="'Busca de Credenciados'"
  (closeModal)="closeModal()"
>
  <ng-container modal-body>
    <div class="container">
      <div class="row text-end">
        <div class="col">
          <button
            class="btn btn-success text-uppercase btn-shadow btn-sm"
            (click)="visible = !visible"
          >
            <i class="fa-solid fa-filter"></i>
            &nbsp; Filtros
          </button>
          <button
            class="btn btn-info btn-shadow ms-1 btn-sm"
            (click)="buscaCredenciados(filtros)"
          >
            <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
          </button>
        </div>
      </div>

      <div
        class="row"
        [visible]="visible"
        cCollapse
        (keydown.enter)="buscaCredenciados(filtros)"
      >
        <div class="col">
          <div class="row pb-2">
            <div class="col">
              <div class="form-group">
                <label class="form-label">Código:</label>
                <input
                  [(ngModel)]="filtros.codtn"
                  class="form-control filter"
                  mask="0*"
                  maxlength="6"
                  placeholder="Código"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="form-label">CNPJ:</label>
                <input
                  [(ngModel)]="filtros.cnpj"
                  class="form-control filter"
                  mask="00.000.000/0000-00"
                  maxlength="19"
                  placeholder="CNPJ"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label">Razão ou Fantasia:</label>
                <input
                  [(ngModel)]="filtros.razao"
                  class="form-control filter"
                  placeholder="Razão Social ou Fantasia"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">Cep:</label>
                    <input
                      [(ngModel)]="filtros.cep"
                      class="form-control filter"
                      mask="00.000-000"
                      placeholder="CEP"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">UF:</label>
                    <input
                      [(ngModel)]="filtros.cuf"
                      class="form-control text-uppercase"
                      maxlength="2"
                      placeholder="Estado"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">Município:</label>
                    <input
                      [(ngModel)]="filtros.localidade"
                      class="form-control filter"
                      placeholder="Municipio"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label">Bairro:</label>
                    <input
                      [(ngModel)]="filtros.bairro"
                      class="form-control filter"
                      placeholder="Bairros"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br /><br />

      <div class="row" *ngIf="carregarConteudo">
        <div class="col text-center">
          <mat-spinner diameter="40" class="m-auto"></mat-spinner>
        </div>
      </div>
      <div class="row" *ngIf="arrCredenciados < 1 && !carregarConteudo">
        <div class="col text-center">
          <small class="text-warning fs-5">
            Filtre e clique em "Pesquisar" para poder selecionar os Credenciados
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col" [hidden]="carregarConteudo">
          <div class="w-100 table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th class="text-center">Codigo Telenet</th>
                  <th class="text-center">Credenciado</th>
                  <th class="text-center">Nome Fantasia</th>
                  <th class="text-center">Seguimento</th>
                  <th class="text-center">Cep</th>
                  <th class="text-center">UF</th>
                  <th class="text-center">Cidade</th>
                  <th class="text-center">Bairro</th>
                  <th class="text-center">Endereço</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let loc of arrCredenciados; let i = index">
                  <td>
                    {{ loc.codtn }}
                  </td>
                  <td>
                    {{ loc.credenciado }}
                  </td>
                  <td class="text-success">
                    {{ loc.nome_fantasia }}
                  </td>
                  <td class="text-success" *ngIf="loc.idsegmento == 18">
                    AUTOMOTIVO
                  </td>
                  <td class="text-success" *ngIf="loc.idsegmento == 17">
                    POSTOS DE COMBUSTÍVEL/ CONVENIÊNCIA
                  </td>
                  <td class="text-success">
                    {{ loc.ccep }}
                  </td>
                  <td class="text-success">
                    {{ loc.uf }}
                  </td>
                  <td class="text-success">
                    {{ loc.municipio }}
                  </td>
                  <td class="text-success">
                    {{ loc.bairro }}
                  </td>
                  <td class="text-success">
                    {{ loc.logradouro }}
                  </td>
                  <td>
                    <button
                      type="button"
                      (click)="selecionarCredenciado(loc.codtn, i)"
                      class="btn btn-success text-nowrap {{
                        this.arrCredenciados[i].btn
                      }} btnaction{{ i }}"
                      title="Selecionar Credenciado"
                    >
                      <i class="fa-solid fa-check fa-fw"></i> Selecionar
                      Credenciado
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-base-modal>
