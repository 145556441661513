import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterByModule",
})
export class FilterModules implements PipeTransform {
  transform(data, moduleFieldInData: string, moduleToFilter: number) {
    return data.filter((item) => item[moduleFieldInData] == moduleToFilter);
  }
}
