<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="row" *ngIf="conteudoCarregado">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header bg-secondary h5 text-white">
          Grupo: Manutenção
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <mat-checkbox
                color="primary"
                [disabled]="regranivel != 0"
                [checked]="regranivel != 0"
                [(ngModel)]="regras.tipoproduto.status"
              >
                701 - Manutenção Avançada
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col text-end" *ngIf="regranivel == 1 || regranivel == 0">
      <button class="btn btn-success btn-shadow me-1" (click)="salvarAsRegras()">
        <i class="fa-solid fa-check"></i> Salvar Regras Manutenção
      </button>
      <button
        class="btn btn-danger btn-shadow"
        [routerLink]="['/clientes']"
        *ngIf="voltar"
      >
        <i class="fa-solid fa-close"></i>
        Voltar
      </button>
    </div>
  </div>
</div>
