<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  [dynamicHeight]="true"
>
  <mat-tab label="Lista">
    <div class="row">
      <div class="col pb-2 text-end">
        <button
          title="Pesquisar"
          class="btn btn-info btn-shadow btn-sm"
          (click)="pesquisar()"
        >
          <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i>
        </button>
        <button
          title="Cadastrar"
          class="btn btn-success btn-shadow ms-1 btn-sm"
          (click)="cadastrar()"
          *ngIf="!telaCadastrar && alterarDados"
        >
          <i class="fa-solid fa-plus fa-fw"></i>
        </button>
      </div>
    </div>

    <pesquisar-usuarios
      [hidden]="!telaPesquisa"
      [evento]="evento"
      [idrelacionado]="idrelacionado"
      [tipo]="tipo"
      (carregado)="carregadoPesquisa($event)"
      (editar)="editar($event)"
      (visualizar)="visualizar($event)"
    ></pesquisar-usuarios>
    <cadastrar-usuarios
      *ngIf="telaCadastrar"
      (cancelar)="cancelarTela($event)"
      [idrelacionado]="idrelacionado"
      [tipo]="tipo"
    ></cadastrar-usuarios>
    <alterar-usuarios
      *ngIf="telaAlterar"
      [id]="id"
      (cancelar)="cancelarTela($event)"
      [idrelacionado]="idrelacionado"
      [tipo]="tipo"
    ></alterar-usuarios>
    <visualizar-usuarios
      *ngIf="telaVisualizar"
      [id]="id"
      [idrelacionado]="idrelacionado"
      [tipo]="tipo"
    ></visualizar-usuarios>
  </mat-tab>
  <mat-tab label="Log de Acessos">
    <acessos-usuarios
      [idrelacionado]="idrelacionado"
      [tipo]="tipo"
    ></acessos-usuarios>
  </mat-tab>
</mat-tab-group>
