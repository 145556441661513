import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TransacaoService } from "../../services/transacao.service";
import { CredenciadoService } from "../../../credenciados/services/credenciado.service";
import Swal from "sweetalert2";

interface TransacaoError extends Error {
  title: string;
  message: string;
}

const MANIPULADOR_ERROS_FORMULARIO = {
  NUMEROCARTAO: {
    title: "Cartão vazio",
    message: "Você deve digitar um cartão",
  },
  ODOMETRO: {
    title: "Odômetro vazio",
    message: "Você deve digitar um odômetro",
  },
  CODMOTORISTA: {
    title: "Código do motorista vazio",
    message: "Você deve digitar o código do motorista",
  },
  SENMOTORISTA: {
    title: "Senha do motorista vazia",
    message: "Você deve digitar a senha do motorista",
  },
  CODTN: { title: "Erro", message: "O código do credenciado é obrigatório" },
  IDCRED: { title: "Erro", message: "O ID do credenciado é obrigatório" },
  TIPOPRODUTO: {
    title: "Tipo do produto vazio",
    message: "Você deve selecionar o Tipo de Produto que deseja",
  },
  PRODUTO: {
    title: "Produto/Serviço Vazio",
    message: "Você deve selecionar o Produto que deseja",
  },
  NOME_PRODUTO: {
    title: "Produto/Serviço Vazio",
    message: "Você deve selecionar o Produto que deseja",
  },
  QUANTIDADE: {
    title: "Quantidade Vazia",
    message: "Você deve selecionar a quantidade que deseja",
  },
  VALOR: {
    title: "Valor Vazio",
    message: "Você deve selecionar o valor desejado",
  },
};

@Component({
  selector: "transacao-web",
  templateUrl: "./transacao-web.component.html",
})
export class TransacaoWebComponent implements OnInit {
  @Input("codtn") codtn: string;
  @Input("idcred") idcred: string;

  showModalConfirmacao = false;
  showModalTransacao = false;

  idEsp: number;
  tipoEsp: string;
  produtos: any[];
  tipoProdutos: any[];
  transacaoForm: UntypedFormGroup;
  vizualizarTransacao = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private transacaoService: TransacaoService,
    private credenciadoService: CredenciadoService
  ) {}

  ngOnInit(): any {
    this.preparaCombos();
    this.transacaoForm = this.montarTransacaoForm();
    this.resetarFormulario();
  }

  preparaCombos(): void {
    const filtro = { idcred: this.idcred };
    this.credenciadoService
      .getCredenciadoProdutos(filtro)
      .subscribe((response) => {
        this.tipoProdutos = response.tipoproduto?.filter(
          (tipo) => tipo.id !== 6
        );
        this.produtos = response.produtos;
      });
  }

  montarTransacaoForm(): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      numeroCartao: ["", Validators.required],
      odometro: ["0", Validators.required],
      codmotorista: ["", Validators.required],
      senmotorista: ["", Validators.required],
      codtn: ["", Validators.required],
      idcred: ["", Validators.required],
      parcela: [""],
      itens: this.formBuilder.array([]),
    });

    return formGroup;
  }

  salvarItensTransacaoWeb(): any {
    try {
      this.verificaProdutosDuplicados();

      this.validarCampos(this.transacaoForm, ["codmotorista", "senmotorista"]);
      this.itens.controls.map((group) =>
        this.validarCampos(group as UntypedFormGroup)
      );
      this.showModalConfirmacao = true;
    } catch (error) {
      const erro = error as TransacaoError;
      Swal.fire(erro.title, erro.message, "error");
    }
  }

  confirmarCompraWeb(): any {
    try {
      this.validarCampos(this.transacaoForm);

      Swal.fire({
        title: "Salvar a Transação Web ?",
        text: "Você está prestes a salvar essa Transação",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, salvar!",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.transacaoService.transacaoWeb(this.transacaoForm.value);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.success) {
            Swal.fire(
              "Transação feita",
              "Transação feita com sucesso",
              "success"
            );
            this.abrirModalTransacao(result.value.idEsp, result.value.tipoEsp);
            this.resetarFormulario();
          } else {
            Swal.fire("ERROR", result.value.message, "error");
          }
        }
      });
    } catch (error) {
      const erro = error as TransacaoError;
      Swal.fire(erro.title, erro.message, "error");
    }
  }

  resetarFormulario() {
    this.transacaoForm.reset();

    this.transacaoForm.controls.codtn.setValue(this.codtn);
    this.transacaoForm.controls.idcred.setValue(this.idcred);
  }

  adicionarProduto(): any {
    this.itens.push(
      this.formBuilder.group({
        tipoproduto: ["", Validators.required],
        produto: ["", Validators.required],
        nome_produto: ["", Validators.required],
        quantidade: ["", Validators.required],
        valor: ["", Validators.required],
      })
    );
  }

  removerProdutos(indice: number): void {
    Swal.fire({
      title: "REMOVER PRODUTO ?",
      text: "Deseja realmente REMOVER esse Produto ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, Remover!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("REMOVIDO!", "Produto Removido com Sucesso!", "success");
        this.removerProduto(indice);
      }
    });
  }

  removerProduto(indice): void {
    this.itens.removeAt(indice);
  }

  abrirModalTransacao(id: number, tipo: string): void {
    this.idEsp = id;
    this.tipoEsp = tipo;

    this.vizualizarTransacao = true;

    this.showModalConfirmacao = false;
    this.showModalTransacao = true;
  }

  verificaProdutosDuplicados() {
    const transacaoItens = (
      this.transacaoForm.controls.itens as UntypedFormArray
    ).value as any[];

    transacaoItens.forEach((transacao) => {
      const produtoDuplicado =
        transacaoItens.filter((item) => item.produto === transacao.produto)
          .length > 1;

      if (produtoDuplicado) {
        const produto = this.produtos.find(
          (item) => item.codTln === transacao.produto
        );

        throw {
          title: "PRODUTO/SERVIÇO DUPLICADO",
          message: `O PRODUTO/SERVIÇO de Nome: ${produto.name} está Duplicado`,
        } as TransacaoError;
      }
    });

    return true;
  }

  validarCampos(formGroup: UntypedFormGroup, excludeFields: string[] = []) {
    const controls = formGroup.controls;

    for (const key in controls) {
      const control = controls[key];

      if (
        control.invalid &&
        ["itens", ...excludeFields].includes(key) === false
      ) {
        throw MANIPULADOR_ERROS_FORMULARIO[key.toLocaleUpperCase()];
      }
    }
  }

  preencherNomeProduto(transacaoItemGroup: UntypedFormGroup): void {
    const codTln = transacaoItemGroup.controls.produto.value;

    const produto = this.produtos.find((item) => codTln === item.codTln);

    transacaoItemGroup.controls.nome_produto.setValue(produto.name ?? "");
  }

  getTipoProdutos(formGroup: UntypedFormGroup) {
    const tipoProduto = formGroup.value.tipoproduto;

    if (tipoProduto && tipoProduto.length > 0) {
      return Array.from(this.produtos).filter(
        (produto: any) => produto.tipoproduto === Number(tipoProduto)
      );
    }

    return Array.from(this.produtos);
  }

  getProduto(formGroup: UntypedFormGroup) {
    return formGroup.value.produto == "701";
  }

  get itens() {
    return this.transacaoForm.controls["itens"] as UntypedFormArray;
  }
}
