<form [formGroup]="transacaoForm">
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" requiredInput>Número do Cartão:</label>
      <input
        class="form-control"
        formControlName="numeroCartao"
        mask="0000 0000 0000 0000 0"
      />
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-6">
      <label class="form-label" requiredInput>Odômetro:</label>
      <input class="form-control" formControlName="odometro" mask="000000000" />
    </div>
  </div>
  <div class="row pb-2 align-items-end">
    <div class="col-6">
      <label class="form-label">Parcelas:</label>
      <input class="form-control" formControlName="parcela" mask="00" />
    </div>
    <div class="col">
      <button
        type="button"
        (click)="adicionarProduto()"
        class="btn btn-success"
      >
        <i class="fa-solid fa-plus"></i>
        Adicionar Produto
      </button>
    </div>
  </div>
  <div class="row pb-2">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Tipo Produto</th>
              <th>Produto</th>
              <th>Quantidade (ml)</th>
              <th>Valor Total R$ (Reais)</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="itens">
              <ng-container
                *ngFor="
                  let transacaoItem of itens.controls;
                  let itemTransacaoIndex = index
                "
              >
                <tr [formGroupName]="itemTransacaoIndex">
                  <td>
                    <select
                      class="form-select"
                      formControlName="tipoproduto"
                      [ngClass]="{
                        erroValidacao:
                          transacaoItem.controls.tipoproduto.invalid &&
                          transacaoItem.controls.tipoproduto.dirty,
                      }"
                    >
                      <option value="">SELECIONE</option>
                      <option
                        [value]="tipo.id"
                        *ngFor="let tipo of tipoProdutos"
                      >
                        {{ tipo.name }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select
                      class="form-select"
                      formControlName="produto"
                      (change)="preencherNomeProduto(transacaoItem)"
                      [ngClass]="{
                        erroValidacao:
                          transacaoItem.controls.produto.invalid &&
                          transacaoItem.controls.produto.dirty,
                      }"
                    >
                      <option value="">SELECIONE</option>
                      <option
                        [value]="prod.codTln"
                        *ngFor="let prod of getTipoProdutos(transacaoItem)"
                      >
                        {{ prod.codTln }} - {{ prod.name }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      formControlName="quantidade"
                      class="form-control"
                      currencyMask
                      [options]="{
                        prefix: ' ',
                        thousands: '.',
                        decimal: ',',
                        align: 'left',
                      }"
                      [ngClass]="{
                        erroValidacao:
                          transacaoItem.controls.quantidade.invalid &&
                          transacaoItem.controls.quantidade.dirty,
                      }"
                    />
                  </td>
                  <td>
                    <input
                      formControlName="valor"
                      class="form-control"
                      currencyMask
                      [options]="{
                        prefix: 'R$ ',
                        thousands: '.',
                        decimal: ',',
                        align: 'left',
                      }"
                      [ngClass]="{
                        erroValidacao:
                          transacaoItem.controls.valor.invalid &&
                          transacaoItem.controls.valor.dirty,
                      }"
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      title="Remover Produtos"
                      class="btn btn-danger btn-sm"
                      (click)="removerProdutos(itemTransacaoIndex)"
                    >
                      <i class="fa-solid fa-trash fa-fw"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button
        type="submit"
        (click)="salvarItensTransacaoWeb()"
        class="btn btn-success"
      >
        <i class="fa-solid fa-check"></i> Realizar venda
      </button>
    </div>
  </div>
</form>

<app-base-modal
  modalTitle="Confirmação de Transação"
  (closeModal)="showModalConfirmacao = false"
  *ngIf="showModalConfirmacao"
>
  <ng-container modal-body>
    <div class="row" [formGroup]="transacaoForm">
      <div class="col">
        <div class="row pb-2">
          <div class="col">
            Número do Cartão:
            <span class="fw-bold text-primary">{{
              transacaoForm.value.numeroCartao | cartao
            }}</span>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col">
            Odometro:
            <span class="fw-bold text-primary"
              >{{ transacaoForm.value.odometro }} km</span
            >
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-6">
            <label class="form-label">Código do Motorista:</label>
            <input
              formControlName="codmotorista"
              class="form-control"
              mask="00000000"
            />
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-6">
            <label class="form-label">Senha do Motorista:</label>
            <input
              type="password"
              class="form-control"
              formControlName="senmotorista"
              mask="000000"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Cod.</th>
                  <th>Produto</th>
                  <th>Quantidade</th>
                  <th>Valor R$</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transacao of itens.value; let p = index">
                  <td>
                    <input
                      readonly
                      disabled
                      class="form-control"
                      [value]="transacao.produto"
                    />
                  </td>
                  <td>
                    <input
                      readonly
                      disabled
                      class="form-control"
                      [value]="transacao.nome_produto"
                    />
                  </td>
                  <td>
                    <input
                      readonly
                      disabled
                      [value]="transacao.quantidade | number: '1.2-2'"
                      class="form-control"
                    />
                  </td>
                  <td>
                    <input
                      readonly
                      disabled
                      [value]="transacao.valor | number: '1.2-2'"
                      class="form-control"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button (click)="confirmarCompraWeb()" class="btn btn-success">
              <i class="fa-solid fa-check"></i> Confirmar Transacão
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal
  modalTitle="Dados da Transação"
  (closeModal)="showModalTransacao = false"
  *ngIf="showModalTransacao"
>
  <ng-container modal-body>
    <visualizar-transacoes
      *ngIf="vizualizarTransacao"
      [modal]="1"
      [id]="idEsp"
      [tipo]="tipoEsp"
    ></visualizar-transacoes>
  </ng-container>
</app-base-modal>
