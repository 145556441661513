<div
  class="row"
  [hidden]="
    conteudoCarregadoLocalidade &&
    conteudoCarregadoBairro &&
    conteudoCarregadoTipoLog &&
    conteudoCarregadoLog &&
    conteudoCarregadoCep
  "
>
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div
  [hidden]="
    !conteudoCarregadoLocalidade ||
    !conteudoCarregadoBairro ||
    !conteudoCarregadoTipoLog ||
    !conteudoCarregadoLog ||
    !conteudoCarregadoCep
  "
>
  <div class="row">
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>Estado/UF:</label>
        <label *ngIf="!obrigatorio">Estado/UF:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Estado"
            ><i class="fa-solid fa-map"></i
          ></span>
          <app-search-select
            value="cuf"
            [arrToFilter]="estados"
            [(ngValue)]="inputdados.cuf"
            (ngValueChange)="carregarLocalidade($event)"
          ></app-search-select>
        </div>
      </div>
    </div>
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>CEP:</label>
        <label *ngIf="!obrigatorio">CEP:</label>
        <div class="input-group">
          <span class="input-group-addon" title="CEP"
            ><i class="fa-solid fa-map"></i
          ></span>
          <input
            class="form-control"
            mask="00.000-000"
            [(ngModel)]="inputdados.cep"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>Localidade:</label>
        <label *ngIf="!obrigatorio">Localidade:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Localidade"
            ><i class="fa-solid fa-map"></i
          ></span>
          <app-search-select
            value="idmun"
            [arrToFilter]="localidades"
            [(ngValue)]="inputdados.idmun"
            (ngValueChange)="carregarBairro($event)"
          ></app-search-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>Bairro:</label>
        <label *ngIf="!obrigatorio">Bairro:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Bairro"
            ><i class="fa-solid fa-map"></i
          ></span>
          <app-search-select
            value="idbai"
            [arrToFilter]="bairros"
            [(ngValue)]="inputdados.idbai"
            (ngValueChange)="carregarTipoLog($event)"
          ></app-search-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>Tipo de Logradouro:</label>
        <label *ngIf="!obrigatorio">Tipo de Logradouro:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Tipo de Logradouro"
            ><i class="fa-solid fa-map"></i
          ></span>
          <app-search-select
            value="ctipolog"
            [arrToFilter]="ctipolog"
            [(ngValue)]="inputdados.ctipolog"
            (ngValueChange)="carregarLog(inputdados.idbai, $event)"
          ></app-search-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>Logradouro:</label>
        <label *ngIf="!obrigatorio">Logradouro:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Logradouro"
            ><i class="fa-solid fa-map"></i
          ></span>
          <app-search-select
            value="idlogr"
            [arrToFilter]="log"
            [(ngValue)]="inputdados.idlogr"
            (ngValueChange)="selecionarCep($event)"
          ></app-search-select>
        </div>
      </div>
    </div>
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label *ngIf="obrigatorio" requiredInput>Número:</label>
        <label *ngIf="!obrigatorio">Número:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Número"
            ><i class="fa-solid fa-map"></i
          ></span>
          <input
            class="form-control"
            mask="0*"
            [(ngModel)]="inputdados.cnumero"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-xs-12 col-6 col-lg-5">
      <div class="form-group custom">
        <label class="form-label">Complemento:</label>
        <div class="input-group">
          <span class="input-group-addon" title="Complemento"
            ><i class="fa-solid fa-map"></i
          ></span>
          <input class="form-control" [(ngModel)]="inputdados.cend" />
        </div>
      </div>
    </div>
  </div>
</div>
