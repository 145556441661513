import { Subscription } from "rxjs";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";
import { VeiculoService } from "src/app/modulos/veiculos/veiculo.service";
import { LocalidadeService } from "src/app/modulos/shared/services/localidade.service";
import Swal from "sweetalert2";

@Component({
  selector: "informativo-cliente",
  templateUrl: "./informativo.cliente.component.html",
  styleUrls: ["./informativo.cliente.component.scss"],
})
export class InformativoClienteComponent implements OnInit, OnDestroy {
  placa: string;
  subscription = new Subscription();
  informativo = {
    transInc: [],
    TransRej: [],
    UltTrocaOleo: [],
    UltTrocaFiltro: [],
    VencCnh: [],
    VencIpva: [],
    MenorPrecoCombustivel: [],
    TotalVeiculos: 0,
    TotalMotoristas: 0,
    ConsumoMedio: 0,
    TotalTrocaOleo: 0,
    TotalTrocaFiltro: 0,
    TotalVencCnh: 0,
    TotalVencIpva: 0,
    TotalOcorrencias: 0,
    TrocaOleo: [],
    TrocaOleoStatus: false,
    TrocaFiltro: [],
    TrocaFiltroStatus: false,
    VencCnhA: [],
    VencCnhStatus: false,
    VencIpvaA: [],
    VencIpvaStatus: false,
    UltOcorrencias: [],
    ocorrenciaTotais: 0,
    ProdutoCombustivel: [],
  };
  campoEstados = [];
  campoMunicipios = [];
  preloaderEstado = false;
  conteudoCarregado = false;

  precoCombustivel: any = [];

  filtro: any = {
    estado: "",
    municipio: [],
    cuf: { value: "0", tipo: "string", requerido: true, validado: true },
    idmun: { value: "0", tipo: "integer", requerido: true, validado: true },
  };

  cliente: any;
  produto: any;
  carregado = false;
  carregadoOleo = false;
  carregadoFiltro = false;
  carregadoCnh = false;
  carregadoIpva = false;
  carregadoVeiculo = true;
  loadingImportacao = false;

  idEsp: number;
  tipoEsp: string;
  vizualizarTransacao = false;

  saldoConsultado = false;
  completo = true;
  veiculoPlaca = "";
  veiculo: any;

  regexPlaca = new RegExp(/_/g);

  showModalTransacao = false;
  showModalAlertaOleo = false;
  showModalAlertaFiltro = false;
  showModalAlertaCnh = false;
  showModalAlertaIpva = false;
  showModalBuscarVeiculo = false;
  showModalVeiculos = false;
  showModalEspecificacoes = false;

  idveiculoShow = "";

  @ViewChild("filtrosAbrir") filtrosAbrir: ElementRef;

  constructor(
    private veiculoService: VeiculoService,
    private clienteService: ClienteService,
    private localidadeService: LocalidadeService
  ) {
    const arrayPreco = [];

    arrayPreco[100] = { valor: 0 };
    arrayPreco[101] = { valor: 0 };
    arrayPreco[103] = { valor: 0 };
    arrayPreco[105] = { valor: 0 };
    arrayPreco[106] = { valor: 0 };
    arrayPreco[107] = { valor: 0 };
    arrayPreco[109] = { valor: 0 };

    this.informativo.MenorPrecoCombustivel = arrayPreco;

    this.cliente = clienteService.getCliente();
  }

  ngOnInit(): void {
    this.clienteService
      .getInformativo({ idcliente: this.cliente.idcliente })
      .subscribe((response) => {
        if (response.success) {
          this.informativo = response.data;
          this.informativo.TotalTrocaOleo = 0;
          this.informativo.TotalTrocaFiltro = 0;
          this.informativo.TotalVencCnh = 0;
          this.informativo.TotalVencIpva = 0;
          this.informativo.TotalOcorrencias = 0;

          this.informativo.TrocaOleo = [];
          this.informativo.TrocaOleoStatus = false;

          this.informativo.TrocaFiltro = [];
          this.informativo.TrocaFiltroStatus = false;

          this.informativo.VencCnhA = [];
          this.informativo.VencCnhStatus = false;

          this.informativo.VencIpvaA = [];
          this.informativo.VencIpvaStatus = false;

          this.informativo.UltOcorrencias = response.ocorrencias;
          this.informativo.ocorrenciaTotais = response.ocorrenciaTotais;

          this.getEstados();

          const arrCliente = { id: this.cliente.idcliente };
          this.clienteService
            .getContaOleoInformativo(arrCliente)
            .subscribe((responsee) => {
              if (responsee.success) {
                this.informativo.TotalTrocaOleo =
                  responsee.TotalTrocaOleo.length;
              } else {
                this.informativo.TotalTrocaOleo = 0;
              }
            });

          this.clienteService
            .getContaFiltroInformativo(arrCliente)
            .subscribe((responsee) => {
              if (responsee.success) {
                this.informativo.TotalTrocaFiltro =
                  responsee.TotalTrocaFiltro.length;
              } else {
                this.informativo.TotalTrocaFiltro = 0;
              }
            });

          this.clienteService
            .getVencCnhInformativo(arrCliente)
            .subscribe((responsee) => {
              if (responsee.success) {
                this.informativo.TotalVencCnh = responsee.VencCnh.length;
                if (this.informativo.TotalVencCnh > 0) {
                  this.informativo.VencCnh = responsee.VencCnh;
                }
              } else {
                this.informativo.TotalVencCnh = 0;
              }
            });

          this.clienteService
            .getVencIpvaInformativo(arrCliente)
            .subscribe((responsee) => {
              if (responsee.success) {
                this.informativo.TotalVencIpva = responsee.VencIpva.length;
              } else {
                this.informativo.TotalVencIpva = 0;
              }
            });

          this.carregado = true;
        }
      });
  }

  abrirModalTransacao(valor: number, id: number, tipo: string): void {
    if (valor > 0) {
      this.idEsp = id;
      this.tipoEsp = tipo;

      this.vizualizarTransacao = true;

      this.showModalTransacao = true;
    }
  }

  abrirModalAlertaOleo(): void {
    this.showModalAlertaOleo = true;

    if (this.informativo.TotalTrocaOleo > 0) {
      const arrCliente = { id: this.cliente.idcliente };
      this.clienteService
        .getTrocaOleoInformativo(arrCliente)
        .subscribe((response) => {
          if (response.success) {
            this.informativo.TrocaOleo = response.TrocaOleo;
            this.informativo.TrocaOleoStatus = false;
          } else {
            this.informativo.TrocaOleoStatus = true;
            this.informativo.TrocaOleo = [response.message];
          }

          this.carregadoOleo = true;
        });
    } else {
      this.carregadoOleo = true;
    }
  }

  abrirModalAlertaFiltro(): void {
    this.showModalAlertaFiltro = true;

    if (this.informativo.TrocaFiltro.length > 0) {
      const arrCliente = { id: this.cliente.idcliente };
      this.clienteService
        .getTrocaFiltroInformativo(arrCliente)
        .subscribe((response) => {
          if (response.success) {
            this.informativo.TrocaFiltro = response.TrocaFiltro;
            this.informativo.TrocaFiltroStatus = false;
          } else {
            this.informativo.TrocaFiltroStatus = true;
            this.informativo.TrocaFiltro = [response.message];
          }

          this.carregadoFiltro = true;
        });
    } else {
      this.carregadoFiltro = true;
    }
  }

  abrirModalAlertaCnh(): void {
    this.showModalAlertaCnh = true;
    if (this.informativo.VencCnhA.length === 0) {
      const arrCliente = { id: this.cliente.idcliente };
      this.clienteService
        .getVencCnhInformativo(arrCliente)
        .subscribe((response) => {
          if (response.success) {
            this.informativo.VencCnhA = response.VencCnh;
            this.informativo.VencCnhStatus = false;
          } else {
            this.informativo.VencCnhStatus = true;
            this.informativo.VencCnhA = [response.message];
          }

          this.carregadoCnh = true;
        });
    } else {
      this.carregadoCnh = true;
    }
  }

  abrirModalAlertaIpva(): void {
    this.showModalAlertaIpva = true;

    if (this.informativo.VencIpvaA.length === 0) {
      const arrCliente = { id: this.cliente.idcliente };
      this.clienteService
        .getVencIpvaInformativo(arrCliente)
        .subscribe((response) => {
          if (response.success) {
            this.informativo.VencIpvaA = response.VencIpva;
            this.informativo.VencIpvaStatus = false;
          } else {
            this.informativo.VencIpvaStatus = true;
            this.informativo.VencIpva = [response.message];
          }

          this.carregadoIpva = true;
        });
    } else {
      this.carregadoIpva = true;
    }
  }

  getEstados(): void {
    this.campoEstados = [];
    this.campoMunicipios = [];
    this.conteudoCarregado = true;
    this.localidadeService.getEstados().subscribe((response) => {
      if (response.success) {
        this.campoEstados = response.estados;
        this.campoMunicipios = [];
        this.conteudoCarregado = false;
      }
    });
  }

  getMunicipios(cuf: any): void {
    this.campoMunicipios = [];
    this.preloaderEstado = true;
    this.localidadeService.getLocalidades(cuf).subscribe((response) => {
      if (response.success) {
        this.campoMunicipios = response.localidades;
      }

      this.preloaderEstado = false;
      this.filtro.cuf.validado = true;
    });
  }

  setmunicipio(): void {
    this.filtro.idmun.validado = true;
  }

  attSaldo() {
    this.completo = false;

    this.clienteService
      .getSaldoAtual({
        id: this.clienteService.getCliente().idcliente,
        modulo: 1,
      })
      .subscribe((response) => {
        if (response.success) {
          this.cliente.SaldoAtual = response.dados.SaldoAtual;
          this.cliente.CalcLimitUtilizado = response.dados.CalcLimitUtilizado;
          this.cliente.CalcLimitDisponivel = response.dados.CalcLimitDisponivel;
        }

        this.completo = true;
        this.saldoConsultado = true;
      });
  }

  abrirModalBuscarVeiculo() {
    this.showModalBuscarVeiculo = true;
  }

  buscarVeiculoPorPlaca() {
    const placa = this.veiculoPlaca.replace(this.regexPlaca, "").toUpperCase();
    if (placa.length !== 7) return;

    this.carregadoVeiculo = false;
    const param = {
      placa: placa.replace("-", ""),
      idcliente: this.cliente.idcliente,
    };

    this.subscription.add(
      this.veiculoService.getVeiculoByPlaca(param).subscribe((response) => {
        if (response.success === false) {
          Swal.fire(
            "Veiculo não encontrado",
            `O veículo com a placa ${placa} não foi encontrado!`,
            "error"
          );
          this.carregadoVeiculo = true;
          return;
        }

        this.veiculo = response.veiculo[0] || null;
        this.carregadoVeiculo = true;
        if (this.veiculo === null) {
          Swal.fire(
            "Veiculo não encontrado",
            `O veículo com a placa ${placa} não foi encontrado!`,
            "error"
          );
        }
      })
    );
  }

  openModalVisualizarVeiculo(idVeiculo) {
    this.showModalBuscarVeiculo = false;

    this.idveiculoShow = idVeiculo;
    this.showModalVeiculos = true;
  }

  openModalEspecificacoesVeiculo(placa: string) {
    this.showModalBuscarVeiculo = false;
    this.placa = placa;
    this.showModalEspecificacoes = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
