<div class="row">
  <div class="col pt-2">
    <div class="card">
      <div class="card-header bg-secondary p-3">
        <h2 class="text-white fs-6 m-0 mb-2">
          <ng-content select="[title]"></ng-content>
        </h2>
        <small class="text-white"
          >Consultas personalizadas para gerênciar sua frota</small
        >
        <button
          class="btn btn-danger btn-float"
          title="Voltar Para Consultas"
          (click)="goback()"
        >
          <i class="fa-solid fa-sm fa-arrow-left"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="row" *ngIf="!contentLoaded">
      <div class="col text-center">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>

    <div
      class="row pt-3"
      [visible]="visibleFilters"
      cCollapse
      (keydown.enter)="queryData()"
      [hidden]="!contentLoaded"
    >
      <div class="col">
        <ng-content selector="[filters]"></ng-content>
      </div>
    </div>

    <div class="row pb-5 pt-3" [hidden]="!contentLoaded">
      <div class="col">
        <button
          *ngIf="hasFilters"
          class="btn btn-success text-uppercase btn-shadow btn-sm"
          (click)="visibleFilters = !visibleFilters"
        >
          <i class="fa-solid fa-filter"></i>
          &nbsp; Filtros
        </button>
        <button
          class="btn btn-info btn-shadow ms-1 btn-sm"
          (click)="queryData()"
        >
          <i class="fa-solid fa-magnifying-glass fa-fw text-white"></i> &nbsp;
          Pesquisar
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <stimulsoft-report
          [caminhoRelatorio]="reportPath"
          [dados]="reportData"
          *ngIf="loadedReport"
        >
        </stimulsoft-report>
      </div>
    </div>
  </div>
</div>
