import { Component, Input, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import { DesempenhoService } from "../../../services/desempenho.service";

@Component({
  selector: "line-char-data-credenciado",
  templateUrl: "./line-char-data.html",
  styleUrls: ["./line-char-data.css"],
})
export class LineChartDataCredenciadoComponent implements OnInit {
  charta: any;

  graficoAtivo = false;

  @Input() modulo: number;

  constructor(private desempenhoService: DesempenhoService) {}

  ngOnInit(): void {
    this.defineFilterChanger();
  }

  defineFilterChanger(): void {
    this.desempenhoService
      .getInformativoRedeCredenciado({ modulo: this.modulo })
      .subscribe((response) => {
        if (response.success) {
          for (let i = 0; i < response.credenciados.length; i++) {
            response.credenciados[i] *= 1;
          }

          this.charta = new Chart(<any>{
            chart: {
              type: "column",
              height: 300,
            },
            title: {
              text: "Quantidade de Credenciados",
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                data: response.credenciados,
                name: "Credenciados",
                color: "rgba(244,67,54,0.7)",
              },
            ],
            yAxis: {
              min: 0,
              title: {
                text: "",
              },
            },
            xAxis: {
              categories: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ],
              crosshair: true,
              labels: {
                x: -10,
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              column: {
                pointPadding: 0.1,
                borderWidth: 0,
              },
            },
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      enabled: false,
                    },
                  },
                },
              ],
            },
            exporting: {
              enabled: false,
            },
          });

          this.graficoAtivo = true;

          setTimeout(() => {
            try {
              this.charta.ref.reflow();
            } catch (e) {}
          }, 100);
        }
      });
  }

  Popup() {
    this.charta.ref$.subscribe((ref) => {
      const svg = ref.getSVG();

      const mywindow = window.open("", "my div", "height=400,width=700");
      mywindow.document.write(
        "<html><head><title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</title>"
      );
      mywindow.document.write("</head><body>");
      mywindow.document.write("" + svg + "");
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      setTimeout(() => {
        mywindow.print();
        mywindow.close();
      }, 10);
    });
  }
}
