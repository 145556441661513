import { Component, OnInit } from "@angular/core";

import Swal from "sweetalert2";
import { CredenciadoService } from "src/app/modulos/credenciados/services/credenciado.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CgcPipe } from "src/app/modulos/shared/pipes/cgc.pipe";
import { UserService } from "src/app/modulos/shared/services/user.service";
import { DatePipe } from "@angular/common";
import { FechamentoService } from "src/app/modulos/shared/services/fechamento.service";

@Component({
  selector: "app-fechamentos",
  templateUrl: "./consulta-fechamentos.component.html",
})
export class ConsultaFechamentosComponent implements OnInit {
  conteudoCarregado = true;

  filtro = {
    cliente: "",
    tipoData: "",
    retencaoIR: "",
    subredes: [],
    modulo: 1,
    datade: "",
    dataate: "",
    codtn: "",
    idcentralizadora: "",
    tipoFechamento: "2",
    limite: "100",
    paginaAtual: 1,
    ordenacao: "fim",
    sorting: "desc",
  };

  carregaRelatorio = false;
  caminhoRelatorio = "assets/reports/relatorio_credenciado_fechamentos.mrt";
  dadosRelatorio: any = {};
  tipoUsuario = 0;
  usuario: any;

  constructor(
    private fechamentoService: FechamentoService,
    private credenciadoService: CredenciadoService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cgcPipe: CgcPipe,
  ) {}

  ngOnInit(): any {
    setTimeout(() => {
      if (
        !this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
      ) {
        this.voltar();
      }else{
        this.preparaConsulta();
      }
    }, 100)
  }

  preparaConsulta() {
    this.tipoUsuario = this.userService.tipoUsuario;
    this.usuario = this.userService.usuario;
    this.filtro.codtn =
      this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta.codtn;
    this.filtro.modulo =
      this.credenciadoService.getCredenciadoRelatorios()
        .idModuloCredenciadoConsulta ?? 1;
  }

  voltar(): void {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  consultarDados(): void {
    /*     if (this.filtro.dataDe == "" || this.filtro.dataDe == "") {
      Swal.fire(
        "Datas Vazias",
        "As duas datas precisam estar preenchidas",
        "error"
      );
      return;
    } */

    this.conteudoCarregado = false;
    this.carregaRelatorio = false;
    const cabec = {
      hoje: new Date(),
      credenciado:
        this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
          .credenciado,
      cnpjCredenciado: this.cgcPipe.transform(
        this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
          .cpfcnpj
      ),
    };
    this.fechamentoService.getFechamento(this.filtro).subscribe({
      next: (response) => {
        if (response.success) {
          const lista = response.fechamentos;
          const dados = {
            cabec: cabec,
            lista: lista,
          };

          this.dadosRelatorio = {};
          this.dadosRelatorio = dados;
          this.carregaRelatorio = true;
        }
        this.conteudoCarregado = true;
      },
      error: (err) => {
        Swal.fire("Erro", err.message, "error").then(() => {
          this.conteudoCarregado = true;
        });
      },
    });
    /* .getCartaCircularizacaoCred(this.filtro)
      .subscribe((response) => {
        if (response.success) {
          let lista = response.dados.map(
            dados => {
              dados.cpfcnpj = this.cgcPipe.transform(dados.cpfcnpj);
              dados.Data = this.datePipe.transform(dados.Data.substring(0, 9), 'dd/MM/YYYY');
              return dados;
            }
          );

          response.cabec.cnpjCredenciado = this.cgcPipe.transform(response.cabec.cnpjCredenciado);
          let cabec = response.cabec;
          let dados = {
            cabec: cabec,
            lista: lista,
          };
          this.dadosRelatorio = {};
          this.dadosRelatorio = dados;
          this.carregaRelatorio = true;
        }

        this.conteudoCarregado = true;
      }); */
  }
}
