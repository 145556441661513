<app-base-report
  [reportData]="dadosRelatorio"
  [reportPath]="caminhoRelatorio"
  [contentLoaded]="conteudoCarregado"
  [loadedReport]="carregaRelatorio"
  (onGoBack)="voltar()"
  (onQueryData)="consultarDados(filtro)"
>
  <ng-container title>RELATÓRIO DE DESCONTO POR CLIENTE</ng-container>
  <ng-container filters>
    <div class="row">
      <div class="col">
        <label class="form-label">Período da Transação de:</label>
        <input type="date" class="form-control" [(ngModel)]="filtro.dataDe" />
      </div>
      <div class="col">
        <label class="form-label">Período da Transação até:</label>
        <input type="date" class="form-control" [(ngModel)]="filtro.dataAte" />
      </div>
      <div class="col-6" [hidden]="preloaderClientesDesconto">
        <label class="form-label">CLIENTES COM DESCONTO:</label>
        <app-search-select
          value="idCliente"
          [arrToFilter]="arrCliente"
          [(ngValue)]="filtro.cliente"
        ></app-search-select>
      </div>
      <div class="col-6" *ngIf="preloaderClientesDesconto">
        <mat-spinner diameter="40" class="m-auto"></mat-spinner>
      </div>
    </div>
  </ng-container>
</app-base-report>
