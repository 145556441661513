<div class="w-100 ps-3 py-2 fs-6 text-dark fw-semibold">
  Detalhamento da cobrança {{ coursesDetails[0].idcobranca }}
</div>
<div class="w-100 table-responsive">
  <table class="table table-bordered table-striped mb-0 border-secondary">
    <thead>
      <tr>
        <th class="text-center border-top-0 border-start-0">Motorista</th>
        <th class="text-center border-top-0">Total Gasto Cursos</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let detalhe of coursesDetails">
        <td class="border-start-0 text-center">
          {{ detalhe.motorista }}
        </td>
        <td class="text-center">
          R$ {{ detalhe.totalCursos | number: "1.2-2" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
