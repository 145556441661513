<div class="row" *ngIf="!conteudoCarregado">
  <div class="col text-center">
    <mat-spinner diameter="40" class="m-auto"></mat-spinner>
  </div>
</div>

<div class="panel panel-default col-12" *ngIf="conteudoCarregado">
  <div class="panel-heading bg-secondary" style="padding: 5px 27px">
    <h4 style="color: white; font-weight: 400">
      Grupos de Motoristas Associados ao Veículo
    </h4>
  </div>
  <div class="panel-body">
    <div class="row">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Qtd.Mot.</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let grmot of arrGrupoMotorista">
            <td style="padding: 10px 20px 10px 30px !important">
              {{ grmot.cnome }}
            </td>
            <td style="padding: 10px 20px 10px 20px !important">
              {{ grmot.qntdMot }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="panel panel-default col-12">
  <div class="panel-heading bg-secondary" style="padding: 5px 27px">
    <h4 style="color: white; font-weight: 400">
      Motoristas Associados ao Veículo
    </h4>
  </div>
  <div class="panel-body">
    <div class="row">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Matricula</th>
            <th>Nome</th>
            <th>CPF</th>
            <th>Telefone</th>
            <th>Celular</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let grmot2 of arrMotorista">
            <td style="padding: 10px 20px 10px 30px !important">
              {{ grmot2.codmotorista }}
            </td>
            <td style="padding: 10px 20px 10px 20px !important">
              {{ grmot2.nomemot }}
            </td>
            <td style="padding: 10px 20px 10px 20px !important">
              {{ grmot2.cpf | cgc }}
            </td>
            <td style="padding: 10px 20px 10px 20px !important">
              {{ grmot2.cfone | formatFone }}
            </td>
            <td style="padding: 10px 20px 10px 20px !important">
              {{ grmot2.ccelular | formatFone }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
