<app-base-report
  [reportData]="dadosRelatorio"
  [reportPath]="caminhoRelatorio"
  [contentLoaded]="conteudoCarregado"
  [loadedReport]="carregaRelatorio"
  (onGoBack)="voltar()"
  (onQueryData)="consultarDados()"
>
  <ng-container title>RELATÓRIO DE TRANSAÇÕES</ng-container>
  <ng-container filters>
    <div class="row">
      <div class="col">
        <div class="row pb-2">
          <div class="col">
            <label class="form-label">Período de:</label>
            <input
              class="form-control"
              type="date"
              [(ngModel)]="filtro.dataIni"
            />
          </div>
          <div class="col">
            <label class="form-label">Período até:</label>
            <input
              class="form-control"
              type="date"
              [(ngModel)]="filtro.dataFim"
            />
          </div>
          <div class="col" *ngIf="tipoUsuario == 1">
            <label class="form-label">Módulo:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                {id: 1, label: 'Abastecimento'},
                {id: 2, label: 'Manutenção'},
              ]"
              [(ngValue)]="filtro.modulo"
            ></app-search-select>
          </div>
          <div class="col">
            <label class="form-label">DOC:</label>
            <input
              class="form-control"
              type="text"
              mask="0*"
              [(ngModel)]="filtro.doc"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col"
            *ngIf="usuario.qntdCentralizadora != 0 || tipoUsuario == 1"
          >
            <label class="form-label">Tipo de Consulta Centralizadora:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                {id: 0, label: 'Não'},
                {id: 1, label: 'Sim'},
              ]"
              [(ngValue)]="filtro.tipoCentralizadora"
            ></app-search-select>
          </div>
          <div
            class="col"
            *ngIf="usuario.qntdCentralizadora != 0 || tipoUsuario == 1"
          >
            <label class="form-label">Cód.TN Credenciado:</label>
            <input
              class="form-control"
              mask="000000"
              [disabled]="filtro.tipoCentralizadora != 1"
              [(ngModel)]="filtro.codigoCentralizadoEscolhido"
            />
          </div>
          <div
            class="col"
            *ngIf="usuario.qntdCentralizadora != 0 || tipoUsuario == 1"
          >
            <label class="form-label">CNPJ Credenciado:</label>
            <input
              type="text"
              class="form-control"
              [disabled]="filtro.tipoCentralizadora != 1"
              mask="00.000.000/0000-00"
              [(ngModel)]="filtro.cnpjCred"
            />
          </div>
          <div
            [ngClass]="{'col-4': usuario.qntdCentralizadora == 0 && tipoUsuario != 1, 'col': usuario.qntdCentralizadora != 0 || tipoUsuario == 1}"
          >
            <label class="form-label">Situação:</label>
            <app-search-select
              value="id"
              [arrToFilter]="[
                { id: '', label: 'Todos' },
                { id: '0', label: 'Aberta' },
                { id: '1', label: 'Fechada' },
                { id: '2', label: 'Cancelada' },
              ]"
              [(ngValue)]="filtro.situacao"
            ></app-search-select>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-base-report>
